import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'modules/i18n';
import App from './App';
import { Loader } from 'ncoded-component-library';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
