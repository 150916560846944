import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="81"
    height="80"
    viewBox="0 0 81 80"
    fill="currentColor"
    stroke="transparent"
    strokeWidth="0.4"
    {...props}
  >
    <circle cx="51" cy="30" r="30" fill="#F5F6FC" />
    <path d="M33.0125 45.875C33.0125 45.3817 33.413 44.9813 33.9062 44.9813C34.3995 44.9813 34.8 45.3817 34.8 45.875C34.8 46.3683 34.3995 46.7687 33.9062 46.7687C33.413 46.7687 33.0125 46.3683 33.0125 45.875Z" />
    <path d="M33.5338 61.977C33.535 61.9762 33.5364 61.9752 33.5379 61.9741L33.5495 61.9656L33.5497 61.9654C35.251 60.7283 36.74 59.2379 37.9758 57.5354C40.4529 54.1251 41.7625 50.0926 41.7625 45.875C41.7625 34.9089 32.8411 25.9875 21.875 25.9875C10.9089 25.9875 1.9875 34.9089 1.9875 45.875C1.9875 56.8411 10.9089 65.7625 21.875 65.7625C26.092 65.7625 30.1239 64.4533 33.5338 61.977ZM33.5338 61.977C33.532 61.9783 33.5307 61.9792 33.5298 61.9798L33.5304 61.9794C33.5319 61.9784 33.5335 61.9772 33.5353 61.9759M33.5338 61.977C33.5343 61.9767 33.5348 61.9763 33.5353 61.9759M33.5353 61.9759C33.5364 61.9752 33.5375 61.9743 33.5387 61.9734L33.5355 61.9757L33.5353 61.9759ZM40.0726 67.4483L40.2363 67.3077C41.3363 66.3638 42.3638 65.3364 43.3076 64.2365L43.4482 64.0726L43.6008 64.2253L53.313 73.9375C53.313 73.9375 53.3131 73.9375 53.3131 73.9376C53.7638 74.3878 54.0125 74.9877 54.0125 75.625C54.0125 76.2624 53.7638 76.8622 53.3129 77.3129L40.0726 67.4483ZM40.0726 67.4483L40.2252 67.6009M40.0726 67.4483L40.2252 67.6009M40.2252 67.6009L49.9371 77.3129C50.388 77.7638 50.988 78.0125 51.625 78.0125C52.2619 78.0125 52.8619 77.7638 53.3128 77.3129L40.2252 67.6009ZM54.5768 72.6732L54.5769 72.6732C55.3658 73.4615 55.8 74.5092 55.8 75.625C55.8 76.7408 55.3658 77.7885 54.5765 78.5771L54.5765 78.5771C53.7878 79.3659 52.7402 79.8 51.625 79.8C50.5099 79.8 49.4622 79.3659 48.6731 78.5768L38.1114 68.0151L38.111 68.0147L38.1092 68.0129L34.0738 63.9775L33.9581 63.8618L33.8218 63.9523C30.2723 66.3087 26.1622 67.55 21.875 67.55C9.92358 67.55 0.2 57.8264 0.2 45.875C0.2 33.9236 9.92358 24.2 21.875 24.2C33.8264 24.2 43.55 33.9236 43.55 45.875C43.55 50.1621 42.3088 54.2722 39.9522 57.8218L39.8617 57.9581L39.9774 58.0738L54.5768 72.6732ZM35.586 62.6507L35.415 62.7906L35.5713 62.9469L38.6743 66.05L38.8046 66.1802L38.945 66.0612C40.0672 65.1105 41.1104 64.0672 42.0612 62.9451L42.1802 62.8046L42.05 62.6743L38.9469 59.5713L38.7906 59.415L38.6507 59.586C37.7331 60.7077 36.7078 61.733 35.586 62.6507Z" />
    <path d="M4.575 45.875C4.575 36.3358 12.3358 28.575 21.875 28.575C31.4142 28.575 39.175 36.3358 39.175 45.875C39.175 55.4142 31.4142 63.175 21.875 63.175C12.3358 63.175 4.575 55.4142 4.575 45.875ZM6.3625 45.875C6.3625 54.4288 13.3212 61.3875 21.875 61.3875C30.4288 61.3875 37.3875 54.4288 37.3875 45.875C37.3875 37.3212 30.4288 30.3625 21.875 30.3625C13.3212 30.3625 6.3625 37.3212 6.3625 45.875Z" />
    <path d="M29.2749 35.2829L29.2749 35.2829C27.0963 33.7566 24.5378 32.95 21.875 32.95C21.3815 32.95 20.9813 33.3502 20.9813 33.8438C20.9813 34.3373 21.3815 34.7375 21.875 34.7375C26.4532 34.7375 30.6323 37.6125 32.2769 41.8928C32.4133 42.2479 32.7518 42.4663 33.1114 42.4663L29.2749 35.2829ZM29.2749 35.2829C31.4032 36.7738 33.0179 38.8376 33.9454 41.2516C34.1225 41.7124 33.8924 42.2294 33.4317 42.4065L29.2749 35.2829Z" />
  </svg>
);
