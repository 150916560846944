import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { DropzoneInputProps, useDropzone } from 'react-dropzone';
import './Dropzone.styles.scss';
import { Button } from 'ncoded-component-library';
import UploadIcon from 'icons/Upload.icon';
import FileIcon from 'icons/File.icon';
import XIcon from 'icons/X.icon';
import { useTranslation } from 'react-i18next';
import PopNotification from 'providers/PopNotifications/components/PopNotification';

const MAX_SIZE = 500 * (2 << 19);

export type DropzoneProps = {
  className?: string;
  files: File[];
  onFilesChange?: (filesArray: File[]) => void;
  maxFiles?: number;
  onDropFiles?: (filesArray: File[]) => void;
} & DropzoneInputProps;

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const {
    files = [],
    onFilesChange = () => {},
    className,
    maxFiles,
    onDropFiles,
    ...rest
  } = props;
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onDropFiles?.(acceptedFiles);
      onFilesChange([...files, ...acceptedFiles]);
    },
    [onDropFiles, onFilesChange, files],
  );

  const handleFileRemove = useCallback(
    (indexToRemove: number) => {
      onFilesChange(files.filter((_, index) => index !== indexToRemove));
    },
    [files, onFilesChange],
  );

  const renderFiles = useMemo(
    () =>
      files.map((file, index) => (
        <div className="file" key={file.name}>
          <FileIcon className="file__file-svg" />
          <Button
            icon={<XIcon />}
            variant="icon"
            onClick={(ev) => {
              ev.stopPropagation();
              handleFileRemove(index);
            }}
          />
          <label>{file.name}</label>
        </div>
      )),
    [handleFileRemove, files],
  );

  const isFileDuplicate = (file: File) =>
    files.find((el) => file.name === el.name)
      ? {
          code: 'duplicated-file',
          message: `This file already exist!`,
        }
      : null;

  const isMaxSize = (file: File) =>
    [...files, file].length > maxFiles
      ? {
          code: 'max-files',
          message: `Dropzone is already provided with maximum number of files!`,
        }
      : null;

  const composeValidators = (
    file: File,
    validators = [isFileDuplicate, isMaxSize],
  ) => validators.find((validator) => validator(file) !== null)?.(file) || null;

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      ...rest,
      onDrop,
      validator: composeValidators,
      maxSize: MAX_SIZE,
      maxFiles,
    });

  const { onClick, ...restOfRootProps } = getRootProps();

  const classes = classNames('yx-dropzone', className);
  const uploadIconClasses = classNames({ 'upload-icon--hidden': isDragActive });
  const chooseFileClasses = classNames('choose-file', {
    'choose-file--hidden': isDragActive,
  });
  const dragItHereClasses = classNames({
    'drag-it-here--hidden': isDragActive,
  });
  const dropFileClasses = classNames('drop-file', {
    'drop-file--hidden': !isDragActive,
  });

  return (
    <div className={classes} {...restOfRootProps}>
      {!(files.length > 0) ? (
        <div className="yx-dropzone__container">
          <UploadIcon className={uploadIconClasses} />
          <Button className={chooseFileClasses} onClick={onClick}>
            {t('chooseAFile')}
          </Button>
          <p className={dropFileClasses}>{t('dropFile')}!</p>
          <p className={dragItHereClasses}>{t('orDragItHere')}</p>
        </div>
      ) : (
        <>
          <Button className="choose-file" onClick={onClick}>
            {t('chooseAFile')}
          </Button>
          <div className="yx-dropzone__files">{renderFiles}</div>
        </>
      )}
      {fileRejections.length > 0 &&
        fileRejections.map(({ file: { name }, errors: [{ message }] }) => (
          <PopNotification key={name} side="bottom-right" type="Error">
            <div className="yx-dropzone__error-message">
              <span>{`Error occured in file: ${name}`}</span>
              <span>{message}</span>
            </div>
          </PopNotification>
        ))}
      <input {...getInputProps()} />
    </div>
  );
};

export default Dropzone;
