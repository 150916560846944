import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContext from './Page.context';

const PageProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState('yacthX');

  const { t } = useTranslation();

  return (
    <PageContext.Provider value={{ title: t(title), setTitle }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
