import React from 'react';
import classNames from 'classnames';
import './CreatingProfile.styles.scss';
import { Loader } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

type CreatingProfileProps = {
  className?: string;
};

const CreatingProfile: React.FC<CreatingProfileProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-creating-profile', className);
  const { t } = useTranslation();

  return (
    <div className={classes}>
      <img src="/assets/images/logo.png" alt="yachtx-logo" />
      <Loader size="big" inline />
      <p>{t('creatingYourProfile')}</p>
    </div>
  );
};

export default CreatingProfile;
