import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { AgreementChildProps } from './components/AgreementChild/AgreementChild.component';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import AgreementNavigation from './components/AgreementNavigation';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import { transformTitle } from './utils';

import './AgreementWrapper.styles.scss';
import './AgreementWrapper.responsive.styles.scss';

declare global {
  interface Document {
    fonts?: any;
  }
}

export type AgreementWrapperProps = {
  className?: string;
  title: string;
  onDecline: () => void;
  onAccept: () => void;
  children: Array<ReactElement<AgreementChildProps>>;
};

const AgreementWrapper: React.FC<AgreementWrapperProps> = (props) => {
  const { children, className, title, onAccept, onDecline } = props;
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [activeIds, setActiveIds] = useState<string[]>([]);
  const rootContainer = useRef(null);
  const [atTheBottom, setAtTheBottom] = useState(false);

  const { hash } = useLocation();
  const { t } = useTranslation();

  const navTitles = useMemo(
    () => children.map((el) => el.props.title),
    [children],
  );

  const lastTitle = useMemo(
    () => transformTitle(navTitles[navTitles.length - 1]),
    [navTitles],
  );

  const classes = classNames('yx-agreement-wrapper', className);

  const intersectionCallback = useCallback(
    (entries: Array<IntersectionObserverEntry>) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.2) {
          setActiveIds((old) => [...old, entry.target.id]);

          if (lastTitle !== undefined && lastTitle === entry.target.id) {
            setScrolledToBottom(true);
          }
        } else {
          setActiveIds((old) => old.filter((id) => id !== entry.target.id));
        }
      });
    },
    [lastTitle],
  );

  const onContainerScroll = useCallback(
    (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const htmlTarget = ev.target as HTMLElement;
      const { scrollHeight, clientHeight, scrollTop } = htmlTarget;
      setAtTheBottom(scrollHeight < clientHeight + scrollTop + 50);
    },
    [],
  );

  useEffect(() => {
    if (!rootContainer && !rootContainer.current) {
      return;
    }

    let observer = new IntersectionObserver(intersectionCallback, {
      root: rootContainer.current,
      rootMargin: '0px',
      threshold: 0.2,
    });

    let targets = document.querySelectorAll('.agreement-child-element');

    targets.forEach((el) => observer.observe(el));

    return () => {
      targets.forEach((el) => observer.unobserve(el));
    };
  }, [intersectionCallback]);

  useLayoutEffect(() => {
    const goToHash = async () => {
      if (document.fonts && hash) {
        await document.fonts.ready;

        const el = document.querySelector(hash) as HTMLElement;
        if (el) el.scrollIntoView({ block: 'start' });
      }
    };
    goToHash();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes}>
      <div className="yx-agreement-wrapper__content">
        <h1>{title}</h1>
        <div
          className="yx-agreement-wrapper__content__main-content"
          ref={rootContainer}
          onScroll={onContainerScroll}
        >
          <aside>
            <AgreementNavigation titles={navTitles} activeLink={activeIds} />
          </aside>
          <main>{children}</main>
        </div>
      </div>
      <div
        className={classNames('yx-agreement-wrapper__actions', {
          'yx-agreement-wrapper__actions--float': atTheBottom,
        })}
      >
        <Button styleType="secondary" onClick={() => onDecline()}>
          {t('decline')}
        </Button>
        <Button
          styleType="primary"
          disabled={!scrolledToBottom}
          onClick={() => onAccept()}
        >
          {t('accept')}
        </Button>
      </div>
    </div>
  );
};

export default AgreementWrapper;
