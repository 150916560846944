import { NavigationOption } from 'components/Navigation/Navigation.component';
import { CardTypes } from 'models/User';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';

export const DASHBOARD_ROUTES = {
  PROFILE_SETTINGS: 'Profile settings',
  HOME: 'Home',
  MY_YACHTS: 'My Yachts',
  MY_SERACHES: 'MY Searhces',
  MY_MATCHES: 'My Matches',
} as const;

export type DashboardRoutes =
  typeof DASHBOARD_ROUTES[keyof typeof DASHBOARD_ROUTES];

export const cardTypeName: Record<CardTypes, string> = {
  mastercard: 'Mastercard',
  visa: 'Visa',
};

export type DashboardNavigationType = Pick<NavigationOption, 'icon'> & {
  navigation: boolean;
};

export type DashboardRouterType = DashboardNavigationType & EnhancedRouteProps;
