import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import FieldLabel from 'components/Fields/components/FieldLabel';

import './SpecificEquipmentStep.styles.scss';

type SpecificEquipmentStepProps = {
  className?: string;
};

const SpecificEquipmentStep: React.FC<SpecificEquipmentStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-specific-equipment-step', className);

  const { t } = useTranslation();

  return (
    <StepWrapper title={t('anySpecificEquipment')} className={classes}>
      <FieldLabel>{t('electricEquipment')}</FieldLabel>
      <Field
        name="hasShorePowerInlet"
        component={CheckboxField}
        label={t('shorePowerInlet')}
        optional
      />
      <Field
        name="hasGenerator"
        component={CheckboxField}
        label={t('generator')}
        optional
      />
      <Field
        name="hasInverter"
        component={CheckboxField}
        label={t('inverter')}
        optional
      />

      <FieldLabel>{t('outsideEquipment')}</FieldLabel>
      <Field
        name="hasTender"
        component={CheckboxField}
        label={t('tender')}
        optional
      />

      <FieldLabel>{t('electronics')}</FieldLabel>
      <Field
        name="hasAutopilot"
        component={CheckboxField}
        label={t('autopilot')}
        optional
      />
      <Field
        name="hasCockpitSpeakers"
        component={CheckboxField}
        label={t('cockpitSpeakers')}
        optional
      />

      <FieldLabel>{t('insideEquipment')}</FieldLabel>
      <Field
        name="hasStabilizer"
        component={CheckboxField}
        label={t('stabilizer')}
        optional
      />
      <Field
        name="hasMarineHead"
        component={CheckboxField}
        label={t('marineHead')}
        optional
      />
      <Field
        name="hasSternThruster"
        component={CheckboxField}
        label={t('sternThruster')}
        optional
      />
      <Field
        name="hasBowThruster"
        component={CheckboxField}
        label={t('bowThruster')}
        optional
      />
      <Field
        name="hasAirConditioninig"
        component={CheckboxField}
        label={t('airConditioning')}
        optional
      />
      <Field
        name="hasHotWater"
        component={CheckboxField}
        label={t('hotWater')}
        optional
      />
      <Field
        name="hasFreshWaterMaker"
        component={CheckboxField}
        label={t('freshWaterMaker')}
        optional
      />
    </StepWrapper>
  );
};

export default SpecificEquipmentStep;
