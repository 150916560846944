import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import AddFields from 'components/Fields/AddFields';
import TextAreaField from 'components/Fields/TextAreaField';
import { yearOptions } from '../../services/options';
import validators from 'validators';

import './MaintenanceStep.styles.scss';

type MaintenanceStepProps = {
  className?: string;
};

const MaintenanceStep: React.FC<MaintenanceStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-maintenance-step', className);

  const { t } = useTranslation();

  const validateObject = useMemo(
    () => ({
      yearValidation: validators.checkIfOtherFieldIsGreaterThan(
        'yearBuilt',
        t('maintenanceYearMustBeGreaterThanYearBuilt'),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('maintenanceHistory')}
      description={t('maintenance-info')}
      className={classes}
    >
      <AddFields
        name="maintenanceHistoryInfo"
        secondField={TextAreaField}
        buttonLabel={t('addYear')}
        selectName="year"
        options={yearOptions}
        tooltipContent={<>{t('maintenance-tooltip')}</>}
        selectValidate={validateObject.yearValidation}
      />
    </StepWrapper>
  );
};

export default MaintenanceStep;
