import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import validators from 'validators';
import { composeValidators } from 'validators/general';

import './GeometryStep.styles.scss';

type GeometryStepProps = {
  className?: string;
};

const GeometryStep: React.FC<GeometryStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-geometry-step', className);

  const { t } = useTranslation();

  const validationObject = useMemo(
    () => ({
      overallLength: composeValidators(
        validators.required(t('geometry-lengthOverall-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      watellineLength: composeValidators(
        validators.required(t('geometry-waterLine-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      beam: composeValidators(
        validators.required(t('geometry-beam-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      draft: composeValidators(
        validators.required(t('geometry-draft-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('geometry')}
      description={t('geometry-info')}
      className={classes}
    >
      <div className="yx-geometry-step__preview">
        <img src="/assets/images/yacht-profile.png" alt="yacht" />
        <img src="/assets/images/yacht-front.png" alt="yacht" />
        <img src="/assets/images/yacht-dimensions.png" alt="yacht" />
      </div>

      <Field
        name="overallLength"
        min={1}
        unitType="meters"
        component={InputField}
        label={t('geometry-lengthOverall')}
        placeholder={t('enterLoaSize')}
        validate={validationObject.overallLength}
      />

      <Field
        name="waterlineLength"
        unitType="meters"
        component={InputField}
        label={t('geometry-waterline')}
        placeholder={t('enterLwlSize')}
        validate={validationObject.watellineLength}
      />

      <Field
        name="beam"
        unitType="meters"
        component={InputField}
        label={t('geometry-beam')}
        placeholder={t('enterBeamSize')}
        validate={validationObject.beam}
      />

      <Field
        name="draft"
        unitType="meters"
        component={InputField}
        label={t('geometry-draft')}
        placeholder={t('enterDraftSize')}
        validate={validationObject.draft}
      />
    </StepWrapper>
  );
};

export default GeometryStep;
