import api from 'api';
import { AxiosRequestConfig } from 'axios';
import { DropzoneFieldFile } from 'components/Fields/DropzoneField/DropzoneField.component';
import { DropzoneFieldRequests } from 'hooks/useDropzoneField';
import { useCallback } from 'react';

export default function useDropzoneRequest(yachtId: string) {
  const uploadFile: DropzoneFieldRequests['uploadRequest'] = useCallback(
    (file: File, fileName: string, config: AxiosRequestConfig) => {
      return api.yacht.uploadYachtFile(file, fileName, yachtId, config);
    },
    [yachtId],
  );

  const uploadFiles: DropzoneFieldRequests['uploadRequest'] = useCallback(
    (file: File, fileName: string, config: AxiosRequestConfig) => {
      return api.yacht.uploadYachtFiles(file, fileName, yachtId, config);
    },
    [yachtId],
  );

  const deleteFile: DropzoneFieldRequests['deleteRequest'] = useCallback(
    (file: DropzoneFieldFile, fieldName: string) => {
      return api.yacht.deleteYachtFile(yachtId, fieldName, file.id);
    },
    [yachtId],
  );

  return {
    uploadFiles,
    uploadFile,
    deleteFile,
  };
}
