import { AxiosError } from 'axios';
import React from 'react';
import _forown from 'lodash.forown';
import { getUnitValue } from 'router/subrouters/CreateSearchProfile/services/utils';
import { NUMBERS_ONLY } from 'validators/general';

export const googleMapsAPIKey: string =
  'AIzaSyDVSkZ8_baVvl7TTF6Hkbt4Calapn1VO7E';

export const MARKER_URL = '/assets/images/MapsMarker.png';

function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  let mq = function (query: string) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || 'DocumentTouch' in window) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'ncoded', ')'].join(
    '',
  );
  return mq(query);
}

export const parseStringValue = (value: any) =>
  /^\d+$/.test(value)
    ? +value
    : /^(true|false)$/.test(value)
    ? JSON.parse(value)
    : value;

export function convertFormDataToJSONObject(formData: FormData) {
  const obj: any = {};
  formData.forEach((val, key) => {
    const isArray = key.includes('[]') || key.includes('files');

    if (isArray) {
      const newKey = key.split('[]')[0];
      if (!obj[newKey]) obj[newKey] = [];
      obj[newKey].push(parseStringValue(val));
    } else {
      obj[key] = parseStringValue(val);
    }
  });
  return obj;
}

export const convertObjToFormData = (
  obj: Record<string, any>,
  formData = new FormData(),
  path = '',
) => {
  if (obj === undefined) return;

  for (const prop in obj) {
    const newPath = path ? `${path}[${prop}]` : prop;
    if (typeof obj[prop] !== 'object') {
      if (obj[prop] instanceof File) {
        const file: File = obj[prop];
        formData.append(newPath, file, file.name);
      } else {
        formData.append(newPath, obj[prop]);
      }
    } else if (obj[prop] === null) {
      formData.append(newPath, obj[prop]);
    } else {
      convertObjToFormData(obj[prop], formData, newPath);
    }
  }

  return formData;
};

export function debounce(func: Function, wait: number, isImmediate = false) {
  let timeout: NodeJS.Timeout | null;
  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      if (!isImmediate) {
        func(...args);
      }
    };
    const callNow = isImmediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
}

function toastError(e: AxiosError) {
  console.error({ text: e.message || e?.response.data?.message || 'Error' });
}

function getStringError(error: AxiosError) {
  if (!error?.response?.data) return "Couldn't parse error";

  let {
    response: {
      data: { message },
    },
  } = error;

  if (!message) return "Couldn't parse error";

  message =
    typeof message === 'string'
      ? message
      : typeof message.errors[0].message === 'string'
      ? message.errors[0].message
      : 'Invalid data';
  return message;
}

function noop(): any {}

export function transformToIsoDate(date: string) {
  return new Date(`${date}T00:00:00.00Z`);
}

function nonNegativeModulo(value: number, modulo: number) {
  var remain = value % modulo;
  return Math.floor(remain >= 0 ? remain : remain + modulo);
}

function renderIcon(item: React.ReactNode | string) {
  if (typeof item === 'string') return item;
  else if (typeof item === 'object') return item;
  else {
    const IconToRender = item as React.FC;
    return <IconToRender />;
  }
}

function getDotedNumber(value: string) {
  return value
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .split('.')
    .map((str, ind) => (ind > 0 ? str.replace(/,/g, '') : str))
    .join('.');
}

function checkForZero(number: number): string {
  return number < 10 ? `0${number}` : `${number}`;
}

function toDateFormat(dateStr: string) {
  const date = new Date(dateStr);
  return `${checkForZero(date.getDate())}.${checkForZero(
    date.getMonth(),
  )}.${date.getFullYear()}`;
}

type PastTime = {
  num: number;
  type: 'month' | 'day' | 'hour' | 'min' | 'sec';
};

export function calculatePastTime(dateStr: string): PastTime {
  const date = new Date(dateStr);
  const currentDate = new Date();

  const timeDiff = Math.abs((date.getTime() - currentDate.getTime()) / 1000);

  return timeDiff > 60 * 60 * 24 * 30
    ? {
        num: parseInt((timeDiff / (60 * 60 * 24 * 30)).toFixed(0)),
        type: 'month',
      }
    : timeDiff > 60 * 60 * 24
    ? { num: parseInt((timeDiff / (60 * 60 * 24)).toFixed(0)), type: 'day' }
    : timeDiff > 60 * 60
    ? { num: parseInt((timeDiff / (60 * 60)).toFixed(0)), type: 'hour' }
    : timeDiff > 60
    ? { num: parseInt((timeDiff / 60).toFixed(0)), type: 'min' }
    : { num: parseInt(timeDiff.toFixed(0)), type: 'sec' };
}

const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

function toAmountForm(value: number | string) {
  const tier = (Math.log10(Math.abs(value as number)) / 3) | 0;
  if (tier === 0) return getDotedNumber(value.toString());
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);
  var scaled = (value as number) / scale;
  return scaled.toFixed(1) + suffix;
}

function getIntegerFromDottedNumber(value: string) {
  return parseFloat(value.replace(/,/g, ''));
}

function getYesNoValue(value: boolean) {
  return value ? 'Yes' : 'No';
}

const numberToUnitsBlacklist = ['yearBuilt', 'yearRefit', 'year'];

function mapNestedNumbersToUnits(
  data: Object,
  direction: 'toServer' | 'toClient',
) {
  const mappedData = JSON.parse(JSON.stringify(data));
  _forown(mappedData, (value, key) => {
    if (typeof value === 'object') {
      mappedData[key] = mapNestedNumbersToUnits(value, direction);
    } else {
      if (NUMBERS_ONLY.test(value) && !numberToUnitsBlacklist.includes(key)) {
        mappedData[key] = getUnitValue(value, direction);
      }
    }
  });

  return mappedData;
}

export const getCompositeParamFromPaginatedData = (
  paramName: string,
  param: string | string[],
) => {
  return !!param
    ? Array.isArray(param)
      ? param
          .slice(1)
          .reduce(
            (acc, rel) => `${acc}&${paramName}=${rel}`,
            `${paramName}=${param[0]}`,
          )
      : `${paramName}=${param}`
    : '';
};

export const getRelationsFromPaginatedData = ($relations: string | string[]) =>
  getCompositeParamFromPaginatedData('$relations', $relations);

export default {
  noop,
  toastError,
  isTouchDevice,
  parseStringValue,
  convertObjToFormData,
  getIntegerFromDottedNumber,
  convertFormDataToJSONObject,
  debounce,
  transformToIsoDate,
  getStringError,
  renderIcon,
  getDotedNumber,
  getYesNoValue,
  nonNegativeModulo,
  toDateFormat,
  toAmountForm,
  checkForZero,
  calculatePastTime,
  mapNestedNumbersToUnits,
  getCompositeParamFromPaginatedData,
  getRelationsFromPaginatedData,
};
