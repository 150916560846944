import React from 'react';
import classNames from 'classnames';
import { FieldConfig, FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import RadioCardField from '../RadioCardField';
import { RadioCardProps } from 'components/RadioCard/RadioCard.component';

import './RadioFields.styles.scss';

export type RadioFieldsType<T extends Record<string, any> = RadioCardProps> = {
  validate?: FieldValidator<any>;
} & T;

type RadioFieldsProps = {
  className?: string;
  name: string;
  radioFields: Array<RadioFieldsType>;
  renderAsGrid?: boolean;
  label?: string;
  bgImage?: boolean;
} & FieldConfig<any>;

const RadioFields: React.FC<RadioFieldsProps> = (props) => {
  const {
    name,
    radioFields,
    renderAsGrid = false,
    label,
    className,
    bgImage,
  } = props;

  const classes = classNames(
    'yx-radio-fields',
    { 'yx-radio-fields--grid': renderAsGrid },
    className,
  );

  return (
    <div className="yx-radio-fields__container">
      {label && <label>{label}</label>}
      <div className={classes}>
        {radioFields.map((field) => (
          <Field
            {...field}
            type="radio"
            key={field.label}
            name={name}
            bgImage={bgImage}
            component={RadioCardField}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioFields;
