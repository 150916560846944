import React, { useCallback, useContext, useMemo, useState } from 'react';
import FormHeader from 'components/FormHeader';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Routes from 'router/components/Routes';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import { RoutingStep } from '../SignUp/models/Routes';
import FormContent from 'components/FormContent';
import { Yacht } from 'models/Yacht';
import arrayMutators from 'final-form-arrays';
import useFormPatching from 'hooks/useFormPatchings';
import { Loader } from 'ncoded-component-library';
import api from 'api';
import credentialsService from 'services/credentialsService';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import DropzoneRequestsProvider from 'providers/DropzoneRequests';
import { useHistory, useParams } from 'react-router-dom';
import storageService from 'services/storageService';
import useDropzoneRequest from './hooks/useDropzoneRequest';
import useIsEditRoute from 'hooks/useIsEditRoute';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';

import './CreateYacht.styles.scss';
import utils from 'utils';

export type YachtFormKeys = keyof Yacht;

export type CreateYachtProps = {
  steps: Array<RoutingStep>;
  routes: Array<EnhancedRouteProps>;
};

const CreateYachtRouter: React.FC<CreateYachtProps> = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const { popServerError, popSuccess } = useContext(PopNotificationsContext);

  const { goBack, removeCompletedStepsFromStorage } = useContext(StepsContext);
  const { yachtId } = useParams<{ yachtId: string }>();

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const isEdit = useIsEditRoute();
  const { Form } = withTypes<Yacht>();

  const localInitialData = useMemo(
    () =>
      ({
        yachtStatus: 'New',
        yachtType: 'Motor',
        hasGenerator: false,
        hasAirCondition: false,
        hasWaterMarks: false,
        hasMarineHead: false,
        hasInverter: false,
      } as Yacht),
    [],
  );

  const cleanObject = useCallback(
    ({
      id,
      _id,
      __v,
      userId,
      createdAt,
      updatedAt,
      hasGenerator,
      hasAirCondition,
      hasWaterMarks,
      ...restData
    }: Partial<Yacht>) => restData,
    [],
  );

  const onSubmit = useCallback(
    async ({ status, optionalDocuments, ...restValue }: Partial<Yacht>) => {
      try {
        if (!credentialsService.draftYachtId && !isEdit) return;
        setLoading(true);

        const data = utils.mapNestedNumbersToUnits(
          cleanObject(restValue),
          'toServer',
        );

        if (isEdit && window.saveDraft) {
          await api.yacht.editYacht(yachtId, data);
          window.saveDraft = false;
          popSuccess(t('draftSuccess'));
        } else {
          await api.yacht.publishYacht(credentialsService.draftYachtId, data);
          if (!window.finishSubmit) storageService.removeDraftYacht();
          popSuccess(t('successfullyPublished'));
        }

        removeCompletedStepsFromStorage();

        goBack(false);
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
        window.finishSubmit = false;
      }
    },
    [
      isEdit,
      yachtId,
      cleanObject,
      removeCompletedStepsFromStorage,
      goBack,
      popSuccess,
      t,
      popServerError,
    ],
  );

  const initRequest = useMemo(
    () => () => api.yacht.getYachtById(yachtId),
    [yachtId],
  );

  const patchRequest = useMemo(() => {
    if (!yachtId) return null;

    return (stepName: string, data: Partial<Yacht>) => {
      return api.yacht.patchYachtData(
        yachtId,
        stepName,
        utils.mapNestedNumbersToUnits(cleanObject(data), 'toServer'),
      );
    };
  }, [cleanObject, yachtId]);

  const {
    initialData: patchedData,
    loadingInitialData,
    onStepSubmit,
  } = useFormPatching({
    name: 'yacht',
    patchOnEdit: false,
    setLoading,
    initRequest,
    patchRequest,
    onSubmit,
    onForbiddenRequest: () => {
      if (!isEdit) {
        storageService.removeDraftYacht();
        removeCompletedStepsFromStorage();
        history.push('/create-yacht');
      }
    },
  });

  const { uploadFiles, deleteFile } = useDropzoneRequest(yachtId);

  const initialData = useMemo(
    () => ({
      ...localInitialData,
      ...utils.mapNestedNumbersToUnits(patchedData, 'toClient'),
    }),
    [localInitialData, patchedData],
  );

  return (
    <DropzoneRequestsProvider
      uploadRequest={uploadFiles}
      deleteRequest={deleteFile}
    >
      <div className="yx-create-yacht">
        <FormHeader
          title={
            isEdit
              ? t('edit_yacht_template', {
                  template: loadingInitialData
                    ? '...'
                    : initialData.vesselName ||
                      t('notSpecifiedParam', { param: t('vesselName') }),
                })
              : t('createYacht')
          }
        />
        <FormContent>
          {(loading || loadingInitialData) && <Loader />}
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={onStepSubmit}
            initialValues={initialData}
            render={() => <Routes routes={routes} />}
          />
        </FormContent>
      </div>
    </DropzoneRequestsProvider>
  );
};

export default CreateYachtRouter;
