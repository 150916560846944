import { useCallback, useState } from 'react';

const useCallbackRef = <T>(init?: T) => {
  const [ref, setRef] = useState<T | undefined>(init);

  const refCallback = useCallback((element: T) => {
    if (element !== null) {
      setRef(element);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [ref, refCallback] as [T, (element: T) => void];
};

export default useCallbackRef;
