import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from 'components/Fields/InputField';
import { Button, Loader } from 'ncoded-component-library';
import { useCallback } from 'react';
import api from 'api';
import eventSocketService from 'services/socketService/eventSocketService';
import socketEventNames from 'services/socketService/socketEventNames';
import credentialsService from 'services/credentialsService';
import validators from 'validators';
import { composeValidators } from 'validators/general';
import QRCode from 'qrcode';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

import './IdentityVerification.styles.scss';
import './IdentityVerification.responsive.styles.scss';

type IdentityVerificationProps = {
  className?: string;
};

const IdentityVerification: React.FC<IdentityVerificationProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-identity-verification', className);

  const { t } = useTranslation();
  const { valid } = useFormState();
  const { popInfo, popServerError } = useContext(PopNotificationsContext);

  const { submit, getState } = useForm();
  const [verified, setVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<any>('');

  const {
    input: { value: phoneNumber },
  } = useField<string>('phoneNumber');

  const validatorsObject = useMemo(
    () => ({
      phone: composeValidators(
        validators.phoneNumberValidation(t('identityVeritication-phoneNumber')),
      ),
    }),
    [t],
  );

  const getQrCodeImage = useCallback(
    async () =>
      credentialsService.verificationUrl
        ? await QRCode.toDataURL(credentialsService.verificationUrl)
        : null,
    [],
  );

  const sendVerificationSMS = useCallback(async () => {
    try {
      if (!credentialsService.patchToken || !valid) return;
      setLoading(true);

      await api.users.sendIndetityVerificationSMS(
        credentialsService.patchToken,
        phoneNumber,
      );
    } catch (e) {
      popServerError(e);
    } finally {
      setLoading(false);
    }
  }, [phoneNumber, popServerError, valid]);

  useEffect(() => {
    const getVeriticationQRCode = async () => {
      const qrCode = await getQrCodeImage();
      setQrCode(qrCode);
    };

    getVeriticationQRCode();
  }, [getQrCodeImage]);

  useEffect(() => {
    const init = async () => {
      try {
        await eventSocketService.connect();
      } catch (err) {
        console.error(err);
      }
    };

    init();

    return () => {
      eventSocketService.disconnect();
    };
  }, []);

  useEffect(() => {
    const verificationApproved = () => {
      const { valid } = getState();
      if (valid) {
        setVerified(true);
        popInfo({ content: t('successVerification'), type: 'Success' });
      }
      submit();
    };

    eventSocketService.addListener(
      socketEventNames.VERIFICATION_SUBMITTED,
      verificationApproved,
    );

    return () => {
      eventSocketService.removeListener(
        socketEventNames.VERIFICATION_SUBMITTED,
        verificationApproved,
      );
    };
  }, [submit, popInfo, t, getState]);

  const identitVerificationForm = useMemo(
    () => (
      <StepWrapper
        className={classes}
        renderActions={false}
        title={t('identityVerification')}
      >
        <p>{t('identityVeritication-info')}</p>
        <h2>{t('scanQRCode')}</h2>
        <img src={qrCode} alt="" />
        <div className="yx-identity-verification__or" />
        <h2>{t('identityVeritication-enterYourPhoneNumber')}</h2>

        <div className="yx-identity-verification__actions">
          <Field
            name="phoneNumber"
            component={InputField}
            placeholder={t('enterYourPhoneNumber')}
            validate={validatorsObject.phone}
          />
          <Button onClick={sendVerificationSMS}>{t('send')}</Button>
        </div>
      </StepWrapper>
    ),

    [classes, t, qrCode, validatorsObject.phone, sendVerificationSMS],
  );

  const successfulyVerifiedForm = useMemo(
    () => (
      <StepWrapper
        className="yx-identity-verification__success"
        renderActions={false}
      >
        <img src="/assets/images/success.png" alt="success" />
        <h1>{t('identityVeritication-verificationSuccessful')}</h1>
        <p>{t('identityVeritication-verificationHasBeenVerified')}</p>
      </StepWrapper>
    ),
    [t],
  );

  return (
    <>
      {loading && <Loader />}
      {verified ? successfulyVerifiedForm : identitVerificationForm}
    </>
  );
};

export default IdentityVerification;
