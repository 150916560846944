import { MatchStep } from '../components/MatchStepper/MatchStepper.component';
import {
  BuyerSteps,
  MatchRouterType,
  MatchStatus,
  SellerSteps,
} from '../models';

export const getSteps = (routes: MatchRouterType[]) =>
  routes.map((route) => ({ label: route.labelId } as MatchStep));

export const CANCEL_MATCH_REASON = {
  CHANGED_MY_MIND: 'I changed my mind about selling this boat',
  HAD_ACCIDENT: 'I had an accident',
  DONT_FEEL_WELL: 'I dont feel well',
  ANOTHER_URGENT_MATTER: 'I have another urgent matter to attend to',
  OTHER: 'Other',
} as const;

export const MATCH_STATUS = {
  NEW: 'New',
  REQUEST_FOR_VIEWING: 'Request for viewing',
  ACCPET_REQUEST_FOR_VIEWING: 'Accept request for viewing',
  VIEWING: 'Viewing',
  SELLER_POSTPONEMENT_REQUESTED: 'Seller request for postponoment',
  BUYER_POSTPONEMENT_REQUESTED: 'Buyer request for postponoment',
  SELLER_CANCELLED: 'Seller cancelled',
  BUYER_CANCELLED: 'Buyer cancelled',
} as const;

export const BUYER_MATCH_ROUTE: Record<MatchStatus, BuyerSteps> = {
  [MATCH_STATUS.NEW]: 'sneak-peak',
  [MATCH_STATUS.REQUEST_FOR_VIEWING]: 'viewing-request',
  [MATCH_STATUS.ACCPET_REQUEST_FOR_VIEWING]: 'viewing-confirmation',
  [MATCH_STATUS.VIEWING]: 'viewing-confirmation',
  [MATCH_STATUS.SELLER_CANCELLED]: 'cancelled-match',
  [MATCH_STATUS.BUYER_CANCELLED]: 'cancelled-match',
  [MATCH_STATUS.BUYER_POSTPONEMENT_REQUESTED]: 'postponement-requested',
  [MATCH_STATUS.SELLER_POSTPONEMENT_REQUESTED]: 'postponement-requested',
} as const;

export const SELLER_MATCH_ROUTE: Record<MatchStatus, SellerSteps> = {
  [MATCH_STATUS.NEW]: 'viewing-arrangement',
  [MATCH_STATUS.REQUEST_FOR_VIEWING]: 'viewing-arrangement',
  [MATCH_STATUS.ACCPET_REQUEST_FOR_VIEWING]: 'viewing-request',
  [MATCH_STATUS.VIEWING]: 'viewing-confirmation',
  [MATCH_STATUS.SELLER_CANCELLED]: 'cancelled-match',
  [MATCH_STATUS.BUYER_CANCELLED]: 'cancelled-match',
  [MATCH_STATUS.BUYER_POSTPONEMENT_REQUESTED]: 'postponement-requested',
  [MATCH_STATUS.SELLER_POSTPONEMENT_REQUESTED]: 'postponement-requested',
} as const;

export const SELLER_MATCH_STEP: Record<string, number> = {
  [MATCH_STATUS.NEW]: 1,
  [MATCH_STATUS.REQUEST_FOR_VIEWING]: 1,
  [MATCH_STATUS.ACCPET_REQUEST_FOR_VIEWING]: 2,
  [MATCH_STATUS.VIEWING]: 3,
} as const;

export const BUYER_MATCH_STEP: Record<string, number> = {
  [MATCH_STATUS.NEW]: 1,
  [MATCH_STATUS.REQUEST_FOR_VIEWING]: 2,
  [MATCH_STATUS.ACCPET_REQUEST_FOR_VIEWING]: 3,
  [MATCH_STATUS.VIEWING]: 3,
} as const;

export const createTimeSlots = (timeSlotOptions: Date[]) =>
  timeSlotOptions.map((timeslot) => ({
    from: new Date(timeslot),
    to: new Date(timeslot.setHours(timeslot.getHours() + 1)),
  }));
