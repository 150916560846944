import React, { useContext } from 'react';
import classNames from 'classnames';
import './TemporaryHeader.styles.scss';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import LogoWithTextIcon from '../../../../icons/LogoWithText.icon';
import GeneralContext from 'providers/General/General.context';

type TemporaryHeaderProps = {
  className?: string;
};

const TemporaryHeader: React.FC<TemporaryHeaderProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const { logout } = useContext(GeneralContext);

  const classes = classNames('yx-temporary-header', className);

  return (
    <header className={classes}>
      <NavLink to="/" className="yx-header__logo-section">
        <LogoWithTextIcon />
      </NavLink>
      <Button variant="link" onClick={() => logout()}>
        {t('logout')}
      </Button>
    </header>
  );
};

export default TemporaryHeader;
