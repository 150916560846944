import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import './MatchStepper.styles.scss';
import './MatchStepper.styles.responsive.scss';
import { useTranslation } from 'react-i18next';
import MatchContext from '../../providers/Match/Match.context';

export type MatchStep = {
  label: string;
};

type MatchStepperProps = {
  className?: string;
  currentStep?: number;
  steps: MatchStep[];
};

const MatchStepper: React.FC<MatchStepperProps> = (props) => {
  const { className, currentStep: stepProps, steps } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-match-stepper', className);

  const { currentStep: providerStep } = useContext(MatchContext);

  const currentStep = stepProps || providerStep || 1;

  const dot = useCallback(
    (label) => (
      <div className="dot-container">
        <div className="dot-container__dot dot-container__dot--active-outer-dot" />
        <div className="dot-container__dot dot-container__dot--outer-dot" />
        <div className="dot-container__dot dot-container__dot--inner-dot" />
        <label>{t(label)}</label>
      </div>
    ),
    [t],
  );

  const renderSteps = useMemo(
    () =>
      steps.map(({ label }, index) => (
        <React.Fragment key={label}>
          <li>
            <div
              className={classNames('yx-match-stepper__step', {
                'yx-match-stepper__step--active': index === currentStep - 1,
              })}
            >
              {dot(label)}
            </div>
          </li>
          {index + 1 !== steps.length && (
            <span className={classNames('yx-match-stepper__spacer')} />
          )}
        </React.Fragment>
      )),
    [currentStep, dot, steps],
  );

  return (
    <nav className={classes}>
      <ul>{renderSteps}</ul>
    </nav>
  );
};

export default MatchStepper;
