import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/Fields/SelectField';
import { countries } from 'services/countries';
import validators from 'validators';

import './ContactForm.styles.scss';
import FormRow from 'components/FormRow';

type ContactFormProps = {
  className?: string;
  isLegalPerson?: boolean;
};

const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { className, isLegalPerson = false } = props;

  const classes = classNames('yx-contact-form', className);

  const { t } = useTranslation();

  const fieldName = useMemo(
    () => (isLegalPerson ? 'legalPersonInfo.' : ''),
    [isLegalPerson],
  );

  const validatorsObject = useMemo(
    () => ({
      firstName: validators.required(t('contactDetails-validation-firstName')),
      lastName: validators.required(t('contactDetails-validation-lastName')),
      streetName: validators.required(
        t('contactDetails-validation-streetAndNumber'),
      ),
      zipCode: validators.required(t('contactDetails-validation-zipCode')),
      city: validators.required(t('contactDetails-validation-city')),
      country: validators.required(t('contactDetails-validation-country')),
    }),

    [t],
  );

  return (
    <div className={classes}>
      <Field
        name={`${fieldName}firstName`}
        component={InputField}
        placeholder={t('firstName')}
        validate={validatorsObject.firstName}
      />
      <Field
        name={`${fieldName}lastName`}
        component={InputField}
        placeholder={t('lastName')}
        validate={validatorsObject.lastName}
      />
      <Field
        name={`${fieldName}company`}
        component={InputField}
        placeholder={t('companyName')}
        optional
      />
      <Field
        name={`${fieldName}address.streetAddress`}
        component={InputField}
        placeholder={t('streetAndNumber')}
        validate={validatorsObject.streetName}
      />
      <Field
        name={`${fieldName}address.building`}
        component={InputField}
        optional
        placeholder={t('PrivatePerson-ContactDetails-Building')}
      />

      <FormRow>
        <Field
          name={`${fieldName}address.zipCode`}
          component={InputField}
          placeholder={t('zipCode')}
          validate={validatorsObject.zipCode}
        />
        <Field
          name={`${fieldName}address.city`}
          component={InputField}
          placeholder={t('city')}
          validate={validatorsObject.city}
        />
      </FormRow>

      <Field
        name={`${fieldName}address.country`}
        options={countries}
        searchable
        component={SelectField}
        placeholder={t('PrivatePerson-ContactDetails-Country')}
        validate={validatorsObject.country}
      />
    </div>
  );
};

export default ContactForm;
