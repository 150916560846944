import React from 'react';
import classNames from 'classnames';
import './ChoseLocation.styles.scss';
import './ChoseLocation.styles.responsive.scss';
import { GoogleMapProvider } from '@ubilabs/google-maps-react-hooks';
import { useRef } from 'react';
import { googleMapsAPIKey } from 'utils';
import ChoseLocation, {
  ChooseLocationComponentProps,
} from './ChoseLocation.component';

type ChoseLocationProviderProps = {
  className?: string;
};

const center = {
  lat: 51.5074,
  lng: 0,
};

const ChoseLocationProvider: React.FC<
  ChoseLocationProviderProps & ChooseLocationComponentProps
> = (props) => {
  const { className, ...rest } = props;
  const mapRef = useRef(null);

  const classes = classNames('yx-chose-location', className);

  return (
    <div className={classes}>
      <GoogleMapProvider
        googleMapsAPIKey={googleMapsAPIKey}
        mapContainer={mapRef.current}
        libraries={['places']}
        options={{
          center,
          zoom: 10,
        }}
      >
        <ChoseLocation mapRef={mapRef} {...rest} />
      </GoogleMapProvider>
    </div>
  );
};

export default ChoseLocationProvider;
