import api from 'api';
import { Match } from 'models/Match';
import { Search } from 'models/Search';
import { Yacht } from 'models/Yacht';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import React, { useContext, useEffect, useState } from 'react';
import HomeContext from './Home.context';

type HomeProps = {
  children?: React.ReactNode;
};

const Home: React.FC<HomeProps> = (props) => {
  const { children } = props;

  const { popServerError } = useContext(PopNotificationsContext);
  const [yachtsLoading, setYachtsLoading] = useState<boolean>(false);
  const [searchesLoading, setSearchesLoading] = useState<boolean>(false);
  const [matchesLoading, setMatchesLoading] = useState<boolean>(false);

  const [yachts, setYachts] = useState<Array<Yacht>>();
  const [searches, setSearches] = useState<Array<Search>>();
  const [matches, setMatches] = useState<Array<Match>>();

  useEffect(() => {
    const loadYachts = async () => {
      try {
        setYachtsLoading(true);
        const { data } = await api.yacht.getYachts({
          $page: '1',
          $limit: '2',
          status: 'Published',
        });

        setYachts(data.items);
      } catch (e) {
        popServerError(e);
      } finally {
        setYachtsLoading(false);
      }
    };

    loadYachts();
  }, [popServerError]);

  useEffect(() => {
    const loadSearches = async () => {
      try {
        setSearchesLoading(true);
        const { data } = await api.search.getSearches({
          $page: '1',
          $limit: '2',
        });

        setSearches(data.items);
      } catch (e) {
        popServerError(e);
      } finally {
        setSearchesLoading(false);
      }
    };
    loadSearches();
  }, [popServerError]);

  useEffect(() => {
    (async () => {
      try {
        setMatchesLoading(true);
        const { data } = await api.matches.getUserMatches({
          $page: '1',
          $limit: '3',
        });

        setMatches(data.items);
      } catch (e) {
        popServerError(e);
      } finally {
        setMatchesLoading(false);
      }
    })();
  }, [popServerError]);

  return (
    <HomeContext.Provider
      value={{
        yachtsLoading,
        searchesLoading,
        matchesLoading,
        yachts,
        searches,
        setSearches,
        setYachts,
        matches,
        setMatches,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default Home;
