import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import RadioFields from 'components/Fields/RadioFields';
import { locationFields } from './radioFields';

import './LocationStep.styles.scss';

type LocationStepProps = {
  className?: string;
};

const LocationStep: React.FC<LocationStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-location-step', className);
  const { t } = useTranslation();

  return (
    <StepWrapper
      title={t('newOneOrUsedBoat')}
      description={t('location-info')}
      className={classes}
    >
      <RadioFields
        className="yx-location-step__locations"
        radioFields={locationFields}
        name="location"
        bgImage
      />
    </StepWrapper>
  );
};

export default LocationStep;
