import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import './Avatar.styles.scss';
import { NavLink } from 'react-router-dom';

type AvatarProps = {
  className?: string;
  imageClassName?: string;
  imageUrl: string;
  alt?: string;
  to?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const { className, imageUrl, alt, imageClassName, to, onClick } = props;

  const classes = classNames('yx-avatar', className);
  const imageClasses = classNames('yx-avatar__image', imageClassName);

  return to ? (
    <NavLink to={to} className={classes}>
      <img src={imageUrl} alt={alt} className={imageClasses} />
    </NavLink>
  ) : (
    <div className={classes} onClick={onClick}>
      <img src={imageUrl} alt={alt} className={imageClasses} />
    </div>
  );
};

export default Avatar;
