import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { Yacht } from 'models/Yacht';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MyYachtsContext from './MyYachts.context';
import confirm from 'modules/confirm';
import useQueryParams from 'hooks/useQueryParams';
import credentialsService from 'services/credentialsService';
import storageService from 'services/storageService';

export const MY_YACHTS_PARAMS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
} as const;

export type ActiveDraftKeys =
  typeof MY_YACHTS_PARAMS[keyof typeof MY_YACHTS_PARAMS];

type MyYachtsProps = {
  children: React.ReactNode;
};

const MyYachts: React.FC<MyYachtsProps> = (props) => {
  const { children } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const { popSuccess, popServerError } = useContext(PopNotificationsContext);

  const {
    params: { 'yacht-state': yachtState },
    setQueryParam,
  } = useQueryParams<{
    'yacht-state': ActiveDraftKeys;
  }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [publishedYachts, setPublishedYachts] = useState<boolean>(
    yachtState === MY_YACHTS_PARAMS.ACTIVE
      ? true
      : yachtState === MY_YACHTS_PARAMS.DRAFT
      ? false
      : false,
  );

  const {
    items: yachts,
    setItems: setYachts,
    loading: loadingYachts,
    onContainerScrolled: onYachtsScroll,
  } = useInfinitePagination<Yacht>({
    makeRequest: (currentPage: number) => {
      return api.yacht
        .getYachts({
          $page: currentPage.toString(),
          $limit: '10',
          status: publishedYachts ? 'Published' : ['Drafted', 'Unpublished'],
          $sort: '-createdAt',
          $relations: 'numberOfMatches',
        })
        .then(({ data }) => data);
    },
    dependencies: [publishedYachts],
    resetDeps: [publishedYachts],
  });

  const deleteYacht = useCallback(
    async (yachtId: string) => {
      try {
        setLoading(true);

        await api.yacht.deleteYacht(yachtId);
        popSuccess(t('successfullyDeletedYacht'));

        if (credentialsService.draftYachtId === yachtId) {
          storageService.removeDraftYacht();
        }

        setYachts((oldYachts) =>
          oldYachts.filter((yacht) => yacht.id !== yachtId),
        );
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [popServerError, popSuccess, setYachts, t],
  );

  const editYacht = useCallback(
    (yachtId: string, isPublished = false) => {
      history.push(`/edit-yacht${isPublished ? '' : '-draft'}/${yachtId}`);
    },
    [history],
  );

  const publishYacht = useCallback(
    async (yachtId: string, yachtData: Yacht) => {
      try {
        if (!(await confirm({ title: t('areYouSureYouWantToPublishYacht') })))
          return;
        setLoading(true);

        const {
          _id,
          __v,
          id,
          isApproved,
          isPublished,
          numberOfMatches,
          userId,
          createdAt,
          updatedAt,
          status,
          ...restData
        } = yachtData;

        const {
          data: { yacht },
        } = await api.yacht.publishYacht(yachtId, restData);
        popSuccess(t('successfullyPublished'));

        setYachts((oldYachts) =>
          oldYachts.filter((yacht) => yacht.id !== yachtId),
        );

        return yacht;
      } catch (e) {
        popServerError(e);
        history.push(`/create-yacht/${yachtId}`);
      } finally {
        setLoading(false);
      }
    },
    [history, popServerError, popSuccess, setYachts, t],
  );

  const unpublishYacht = useCallback(
    async (yachtId: string) => {
      try {
        if (!(await confirm({ title: t('areYouSureYouWantToUnpublishYacht') })))
          return;

        setLoading(true);

        await api.yacht.unpublishYacht(yachtId);
        popSuccess(t('successfullyUnpublished'));

        setYachts((oldYachts) =>
          oldYachts.filter((yacht) => yacht.id !== yachtId),
        );
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [popServerError, popSuccess, setYachts, t],
  );

  const setActiveUrlSource = useCallback(
    (value: boolean) =>
      value
        ? setQueryParam('yacht-state', MY_YACHTS_PARAMS.ACTIVE)
        : !value
        ? setQueryParam('yacht-state', MY_YACHTS_PARAMS.DRAFT)
        : null,
    [setQueryParam],
  );

  useEffect(
    () =>
      yachtState === MY_YACHTS_PARAMS.ACTIVE
        ? setPublishedYachts(true)
        : yachtState === MY_YACHTS_PARAMS.DRAFT
        ? setPublishedYachts(false)
        : null,
    [yachtState],
  );

  return (
    <MyYachtsContext.Provider
      value={{
        loading,
        loadingYachts,
        yachts,
        publishedYachts,
        setPublishedYachts,
        setYachts,
        deleteYacht,
        editYacht,
        publishYacht,
        unpublishYacht,
        setActiveUrlSource,
        onYachtsScroll,
      }}
    >
      {children}
    </MyYachtsContext.Provider>
  );
};

export default MyYachts;
