import { Match } from 'models/Match';
import { EnhancedRouteProps } from '../../../../../routes/Enhanced/Enhanced.route';
import { MATCH_STATUS } from '../utils';

export type MatchRouterType = EnhancedRouteProps & { labelId?: string };

export type MatchesRoutes = typeof MATCH_TYPE[keyof typeof MATCH_TYPE];

export const MATCH_TYPE = {
  BUYER: 'buyer',
  SELLER: 'seller',
} as const;

export type SearchProp = {
  searchId: string;
  matchingScore: number;
  search: Record<string, any>;
};

export type MatchContextProps = {
  match: Match;
  currentStep: number;
  routeName: MatchesRoutes;
  matchScore: number;
  buyerProfile: Record<string, any>;
  search: SearchProp;
  onChoseMeetingDate: (date: Date) => void;
  onViewingRequestAccept: (values: {
    timeSlotOptions: Date[];
    location: string;
    locationCoordinates: { latitude: number; longitude: number };
  }) => void;
  onArrangeMeeting: () => void;
  postponeMatch: () => void;
  acceptPostponement: () => void;
  cancelPostponement: () => void;
  cancelMatch: (cancellationReasons: {
    cancellationReason: string;
    otherCancellationReason?: string;
  }) => void;
};

export const ONLY_BUYER_STEPS: Record<string, BuyerMatchesSteps> = {
  SNEAK_PEAK: 'sneak-peak',
  VIEWING_REQUEST: 'viewing-request',
  VIEWING_CONFIRMATION: 'viewing-confirmation',
} as const;

export const ONLY_SELLER_STEPS: Record<string, SellerMatchesSteps> = {
  VIEWING_ARRANGEMENT: 'viewing-arrangement',
  VIEWING_REQUEST: 'viewing-request',
  VIEWING_CONFIRMATION: 'viewing-confirmation',
} as const;

export type BuyerMatchesSteps =
  | 'sneak-peak'
  | 'viewing-request'
  | 'viewing-confirmation';

export type SellerMatchesSteps =
  | 'viewing-arrangement'
  | 'viewing-confirmation'
  | 'viewing-request';

export type CommonMatchesSteps =
  | 'skipped'
  | 'cancelled-match'
  | 'postponement-requested';

export type BuyerSteps = BuyerMatchesSteps | CommonMatchesSteps;
export type SellerSteps = SellerMatchesSteps | CommonMatchesSteps;

export type MatchStatus = typeof MATCH_STATUS[keyof typeof MATCH_STATUS];
