import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import classNames from 'classnames';

import './Collapsible.styles.scss';
import useCollapsibleHeight from './hooks/useCollapsibleHeight';

export type CollapsibleRef = {
  contentElement: HTMLDivElement;
  open: boolean;
  setOpen: () => void;
};

type CollapsibleProps = {
  defaultOpen?: boolean;
  children?: React.ReactNode;
  className?: string;
  trigger?: React.ReactNode;
};

const Collapsible: React.ForwardRefRenderFunction<
  CollapsibleRef,
  CollapsibleProps
> = (props, ref) => {
  const { trigger, children, className, defaultOpen = true } = props;
  const [heightRef, height] = useCollapsibleHeight<HTMLDivElement>();

  const [open, setOpen] = useState<boolean>(defaultOpen);

  const classes = classNames(
    'yx-collapsible',
    { 'yx-collapsible--open': open },
    className,
  );

  const contentStyles = useSpring({
    from: { height: 0 },
    to: { height: open ? height : 0 },
  });

  useImperativeHandle(
    ref,
    () =>
      ({ contentElement: heightRef.current, open, setOpen } as CollapsibleRef),
    [heightRef, open],
  );

  return (
    <section className={classes}>
      <header
        className="yx-collapsible__trigger"
        onClick={() => setOpen((old) => !old)}
      >
        {trigger}
      </header>
      <animated.main
        className="yx-collapsible__animated"
        style={{ ...contentStyles, overflow: 'hidden' }}
      >
        <div className="yx-collapsible__content" ref={heightRef}>
          {children}
        </div>
      </animated.main>
    </section>
  );
};

export default forwardRef(Collapsible);
