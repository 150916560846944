import React, { useContext } from 'react';
import classNames from 'classnames';
import './ShowMenu.styles.scss';
import './ShowMenu.styles.responsive.scss';
import { Pickers } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import DatepickerContext from '../../provider/Datepicker.context';

type ShowMenuProps = {
  className?: string;
};

const ShowMenu: React.FC<ShowMenuProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const { setShowInMenu, showInMenu } = useContext(DatepickerContext);

  const classes = classNames('yx-show-menu', className);

  return (
    <div className={classes}>
      {Object.values(Pickers).map((picker) => (
        <Button
          key={picker}
          className={classNames({ active: picker === showInMenu })}
          onClick={() => setShowInMenu(picker)}
        >
          {t(picker)}
        </Button>
      ))}
    </div>
  );
};

export default ShowMenu;
