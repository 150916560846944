import { FileType } from 'models/File';
import { Yacht } from 'models/Yacht';
import { createContext } from 'react';
import utils from 'utils';

type YachtContextProps = {
  loading: boolean;
  yacht: Yacht;
  activeTabIndex: number;
  setYacht: (value: Yacht) => void;
  setLoading: (value: boolean) => void;
  setActiveTabSource: (value: number) => void;
  uploadYachtDocument: (file: FileType) => void;
  deleteYachtDocument: (file: FileType) => void;
};

export default createContext<Partial<YachtContextProps>>({
  loading: false,
  yacht: null,
  activeTabIndex: 0,
  setYacht: utils.noop,
  setLoading: utils.noop,
  setActiveTabSource: utils.noop,
  uploadYachtDocument: utils.noop,
  deleteYachtDocument: utils.noop,
});
