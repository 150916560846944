import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import './MyMatches.styles.scss';
import GlowScroll from '../../../../../components/GlowScroll';
import InfiniteScroll from '../../../../../components/InfiniteScroll';
import useInfinitePagination from '../../../../../hooks/useInfinitePagination';
import PopNotificationsContext from '../../../../../providers/PopNotifications/PopNotifications.context';
import api from '../../../../../api';
import { Button, Loader } from 'ncoded-component-library';
import { useHistory } from 'react-router-dom';
import { Match, MatchSearch } from '../../../../../models/Match';
import MessageScreen from '../../../../../router/subrouters/Dashboard/subrouters/Match/components/MessageScreen';
import CurrentUserContext from '../../../../../router/subrouters/Dashboard/providers/CurrentUser/CurrentUser.context';
import { useTranslation } from 'react-i18next';
import EmptyMatchesIcon from '../../../../../icons/EmptyMatches.icon';
import HomeMatchCard from '../Home/components/HomeMatchCard';
import { MATCH_TYPE } from '../../subrouters/Match/models';
import AddIcon from 'icons/Add.icon';

type MyMatchesProps = {
  className?: string;
};

const MyMatches: React.FC<MyMatchesProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-my-matches', className);
  const { popServerError } = useContext(PopNotificationsContext);
  const history = useHistory();
  const {
    currentUser: { _id: userId },
  } = useContext(CurrentUserContext);
  const { t } = useTranslation();
  const [serverError, setSerrverError] = useState(false);

  const fetchMatch = useCallback(
    async (currentPage: number) =>
      api.matches
        .getUserMatches({
          $page: currentPage.toString(),
          $limit: '10',
          $relations: ['seller', 'buyer', 'searches.search'],
        })
        .then(({ data }) => data)
        .catch((e) => {
          popServerError(e);
          setSerrverError(true);
        }),
    [popServerError],
  );

  const {
    items: matches,
    loading: loadingMatches,
    onContainerScrolled: onMatchesScroll,
  } = useInfinitePagination<Match>({
    makeRequest: fetchMatch,
    dependencies: [fetchMatch],
    resetDeps: [fetchMatch],
  });

  const searches: MatchSearch[] = useMemo(
    () =>
      matches.map((match) => {
        let max = 0;
        let maxSearch;
        match.searches.forEach((search: MatchSearch) => {
          if (max < search.matchingScore) {
            max = search.matchingScore;
            maxSearch = search;
          }
        });
        return maxSearch;
      }),
    [matches],
  );

  const renderMatches = useMemo(() => {
    const routeName = window.location.pathname.split('/').slice(-1)[0];

    return matches.length > 0 ? (
      matches.map(
        (
          { status, _id, buyerId, yachtName, yachtLength, yachtAskingPrice },
          index,
        ) => (
          <li key={_id}>
            <HomeMatchCard
              type={userId === buyerId ? MATCH_TYPE.BUYER : MATCH_TYPE.SELLER}
              name={yachtName}
              length={yachtLength}
              matchingScore={searches?.[index].matchingScore}
              status={status}
              price={yachtAskingPrice}
              onView={() => history.push(`/matches/match/${_id}`)}
            />
          </li>
        ),
      )
    ) : (
      <div className="empty-state-container">
        <MessageScreen
          icon={EmptyMatchesIcon}
          title={t('emptyMatches')}
          description={t('youHaveNoMatches', { role: routeName })}
        />
        <div className="empty-state-container__actions">
          <Button
            icon={AddIcon}
            onClick={() => history.push('/create-search-profile')}
          >
            {t('createNewSearch')}
          </Button>
          <p>{t('or')}</p>
          <Button icon={AddIcon} onClick={() => history.push('/create-yacht')}>
            {t('createNewYacht')}
          </Button>
        </div>
      </div>
    );
  }, [history, matches, searches, t, userId]);

  const matchesError = useMemo(
    () => (
      <div className="yx-matches__error">
        <h2>{t('serverErrorMatchesTitle')}</h2>
        <p>{t('serverErrorMatchesDescription')}</p>
      </div>
    ),
    [t],
  );

  const renderContent = useMemo(
    () =>
      serverError ? (
        matchesError
      ) : loadingMatches ? (
        <Loader />
      ) : (
        <ul>{renderMatches}</ul>
      ),
    [matchesError, renderMatches, serverError, loadingMatches],
  );

  return (
    <GlowScroll>
      <InfiniteScroll onScroll={onMatchesScroll} className={classes}>
        {renderContent}
      </InfiniteScroll>
    </GlowScroll>
  );
};

export default MyMatches;
