import React, { useCallback, useContext, useMemo, useState } from 'react';
import FormHeader from 'components/FormHeader';
import { useTranslation } from 'react-i18next';
import Routes from 'router/components/Routes';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import { RoutingStep } from '../SignUp/models/Routes';
import FormContent from 'components/FormContent';
import arrayMutators from 'final-form-arrays';
import {
  FUEL_TYPE_SEARCH,
  MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER,
  PREFERED_ENGINE_SEARCH,
  Search,
  SearchSteps,
  SPEED_TYPE_SEARCH,
  YACHT_SEARCH_STATUS,
  YACHT_SEARCH_TYPE,
} from 'models/Search';

import useFormPatching from 'hooks/useFormPatchings';
import api from 'api';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Loader } from 'ncoded-component-library';
import { withTypes } from 'react-final-form';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import useIsEditRoute from 'hooks/useIsEditRoute';
import utils from 'utils';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';

import './CreateSearchProfile.styles.scss';

export type CreateSearchProfileProps = {
  steps: Array<RoutingStep>;
  routes: Array<EnhancedRouteProps>;
};

const CreateSearchProfileRouter: React.FC<CreateSearchProfileProps> = (
  props,
) => {
  const { routes } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { removeCompletedStepsFromStorage } = useContext(StepsContext);
  const isEdit = useIsEditRoute();
  const { Form } = withTypes<Search>();
  const { popServerError } = useContext(PopNotificationsContext);
  const [loading, setLoading] = useState<boolean>(false);

  const ADVANCED_SEARCH_LOCATION = 'advanced-search';

  const storageKey = useMemo(
    () =>
      isEdit ? STORAGE_KEYS.EDITING_SEARCH_ID : STORAGE_KEYS.CREATING_SEARCH_ID,
    [isEdit],
  );

  const onSubmit = useCallback(
    async (values: Partial<Search>) => {
      try {
        const searchId = storageService.getItem<string>(storageKey);
        if (!searchId) throw Error('no searchId is found');

        setLoading(true);

        const {
          id,
          userId,
          createdAt,
          updatedAt,
          numberOfMatches,
          ...restData
        } = values;

        const mappedSearchData = utils.mapNestedNumbersToUnits(
          restData,
          'toServer',
        );

        if (isEdit) {
          await api.search.editSearch(searchId, mappedSearchData);
        } else {
          await api.search.submitSearch(searchId, mappedSearchData);
          storageService.removeDraftSearch();
        }
        removeCompletedStepsFromStorage();

        if (!isEdit) {
          history.push(
            `/create-search-profile/${storageService.getItem<string>(
              storageKey,
            )}/success`,
          );
        } else {
          history.push('/my-searches');
        }
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [
      storageKey,
      isEdit,
      removeCompletedStepsFromStorage,
      history,
      popServerError,
    ],
  );

  const { searchId } = useParams<{ searchId: string }>();

  const localData = useMemo(
    () =>
      ({
        yachtType: YACHT_SEARCH_TYPE.MOTOR,
        yachtStatus: YACHT_SEARCH_STATUS.NEW,
        speedType: SPEED_TYPE_SEARCH.CRUISING,
        fuelType: FUEL_TYPE_SEARCH.PETROL,
        doesFuelConsumptionMatter: false,
        preferedEngine: PREFERED_ENGINE_SEARCH.OUTBOARD,
        makeVesselCommercialyAvailable:
          MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER.I_DONT_CARE,
      } as Search),
    [],
  );

  const initRequest = useMemo(
    () => () => api.search.getSearchById(searchId),
    [searchId],
  );

  const locationName = useMemo(
    () => location.pathname.split('/')[3],
    [location.pathname],
  );

  const patchRequest = useMemo(() => {
    if (!searchId) return null;

    return (stepName: SearchSteps, data: Partial<Search>) => {
      return api.search.updateSearch(searchId, stepName, data);
    };
  }, [searchId]);

  const {
    initialData: patchedData,
    loadingInitialData,
    onStepSubmit,
  } = useFormPatching({
    name: 'search',
    patchCondition: locationName !== ADVANCED_SEARCH_LOCATION,
    setLoading,
    initRequest,
    patchRequest,
    onSubmit,
    onForbiddenRequest: () => {},
  });

  const initialData = useMemo(() => {
    const { _id, __v, ...restData } = patchedData;
    return {
      ...localData,
      ...utils.mapNestedNumbersToUnits(restData, 'toClient'),
    };
  }, [localData, patchedData]);

  return (
    <div className="yx-create-yacht">
      <FormHeader
        title={t(isEdit ? 'editSearchProfile' : 'createSearchProfile')}
        redirectPath="/home"
      />

      <FormContent>
        {(loading || loadingInitialData) && <Loader />}
        <Form
          initialValues={initialData}
          onSubmit={onStepSubmit}
          render={() => <Routes routes={routes} />}
          mutators={{ ...arrayMutators }}
        />
      </FormContent>
    </div>
  );
};

export default CreateSearchProfileRouter;
