import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="transparent"
    stroke="currentColor"
    strokeWidth="1.2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M13 1.25H2.5A1.5 1.5 0 001 2.75v10.5a1.5 1.5 0 001.5 1.5H13a1.5 1.5 0 001.5-1.5V2.75a1.5 1.5 0 00-1.5-1.5zM5.5 14.75v-9m-4.5 0h13.5H1z" />
  </svg>
);
