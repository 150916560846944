import React from 'react';
import classNames from 'classnames';
import './Donut.styles.scss';

type Props = {
  className?: string;
  loading?: boolean;
  percentage?: string;
};

const Donut: React.FC<Props> = ({ className, percentage, loading }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 40"
    className={classNames(['donut', { 'donut--loading': loading }, className])}
  >
    <circle
      className="donut__ring"
      cx="20"
      cy="20"
      r="15.91549430918954"
      fill="transparent"
      strokeWidth="3.5"
    />
    <circle
      className="donut__segment"
      cx="20"
      cy="20"
      r="15.91549430918954"
      fill="transparent"
      strokeWidth="3.5"
      strokeDasharray={
        percentage && !loading ? `${percentage} ${100 - +percentage}` : '25 75'
      }
      strokeDashoffset="25"
    />
    {percentage && !loading && (
      <text
        className="donut__text"
        x="50%"
        y="50%"
        textAnchor="middle"
        fontSize="10"
        dy=".375em"
      >
        {percentage}%
      </text>
    )}
  </svg>
);

export default Donut;
