import { Button, Modal } from 'ncoded-component-library';
import ReactDOM from 'react-dom';
import React from 'react';
import classNames from 'classnames';

import './ConfirmModal.styles.scss';

type ConfirmConfig = {
  className?: string;
  type?: 'confirm' | 'delete';
  title?: string;
  content?: React.ReactNode;
  submitBtnText?: string;
  cancelBtnText?: string;
  onSubmit?: () => void;
  onClose?: () => void;
};

export type ConfirmObject = {
  simpleConfirm: (conf: ConfirmConfig) => Promise<boolean>;
};

const ConfirmModal: React.FC<ConfirmConfig> = (props) => {
  const {
    type = 'confirm',
    className,
    title,
    content,
    submitBtnText = 'OK',
    cancelBtnText = 'Cancel',
    onClose,
    onSubmit,
    ...rest
  } = props;

  const classes = classNames('yx-confirm-modal', type, className);

  return (
    <Modal
      className={classes}
      footer={
        <>
          <Button styleType="secondary" onClick={onClose}>
            {cancelBtnText}
          </Button>
          <Button
            styleType={type === 'delete' ? 'secondary' : 'primary'}
            className={classNames({ error: type === 'delete' })}
            onClick={onSubmit}
          >
            {submitBtnText}
          </Button>
        </>
      }
      open
      onClose={onClose}
      {...rest}
    >
      <h1>{title}</h1>
      {content}
    </Modal>
  );
};

export default async (config: ConfirmConfig) => {
  return new Promise((resolve, reject) => {
    const { title, content, ...rest } = config;

    const div = document.createElement('div');
    document.body.appendChild(div);

    const cleanup = () => {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    };

    return ReactDOM.render(
      <ConfirmModal
        title={title}
        content={content}
        onSubmit={() => {
          resolve(true);
          cleanup();
        }}
        onClose={() => {
          resolve(false);
          cleanup();
        }}
        {...rest}
      />,
      div,
    );
  });
};
