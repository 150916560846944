import socketEventNames from './socketEventNames';

export type SocketEventNames =
  typeof socketEventNames[keyof typeof socketEventNames];

export type SocketService<T extends string = SocketEventNames> = {
  socket: SocketIOClient.Socket;
  consumers: Partial<Record<T, Array<Function>>>;
  connect: Function;
  disconnect: Function;
  addListener: (event: T, callback: (data: any) => void) => void;
  removeListener: (event: T, callback: (data: any) => void) => void;
  sendEvent: (event: T, payload: any) => void;
};

export default function <T extends string = SocketEventNames>(
  connect: Function,
) {
  return {
    socket: null,
    consumers: {},
    connect,
    disconnect() {
      if (!this.socket) return;
      this.socket.disconnect();
    },
    addListener(event: T, callback: (data: any) => void) {
      if (!this.consumers[event]) {
        this.consumers[event] = [callback];
      } else this.consumers[event].push(callback);
    },
    removeListener(event: T, callback: Function) {
      const callbackArray = this.consumers[event];
      if (!callbackArray) return;
      this.consumers[event] = callbackArray.filter((cb) => cb !== callback);
    },
    sendEvent(eventName: T, payload: any) {
      if (!this.socket)
        throw new Error('Socket has to be initializes before events are sent');
      this.socket.emit(eventName, payload);
    },
  } as SocketService<T>;
}
