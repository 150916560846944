import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import InputField from 'components/Fields/InputField';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import './DesignStep.styles.scss';

type DesignStepProps = {
  className?: string;
};

const DesignStep: React.FC<DesignStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-design-step', className);
  const { t } = useTranslation();

  return (
    <StepWrapper
      title={t('design')}
      description={t('design-info')}
      className={classes}
    >
      <Field
        name="exteriorDesigner"
        optional
        component={InputField}
        label={t('design-exteriorDesigner')}
        placeholder={t('design-placeholder')}
      />
      <Field
        name="interiorDesigner"
        optional
        component={InputField}
        label={t('design-interiorDesigner')}
        placeholder={t('design-placeholder')}
      />
    </StepWrapper>
  );
};

export default DesignStep;
