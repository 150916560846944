import React, { useContext } from 'react';
import classNames from 'classnames';
import Datepicker from './components/Datepicker';
import SelectedDates from './components/SelectedDates';
import SelectTimeSlot from './components/SelectTimeSlot';
import { useTranslation } from 'react-i18next';
import CalendarContext from './provider/Calendar.context';
import { DateTime } from 'luxon';
import { CalendarProps } from './models';
import './Calendar.styles.scss';
import './Calendar.styles.responsive.scss';

const Calendar: React.FC<CalendarProps> = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const { currentDate, setCurrentDate } = useContext(CalendarContext);

  const classes = classNames('yx-calendar', className);

  return (
    <div className={classes}>
      <header>
        <p>{t('calendarHeader')}</p>
        <p>{t('calendarSubHeader')}</p>
      </header>
      <main>
        <section className="yx-calendar__select-dates">
          <p>{t('selectDates')}</p>
          <Datepicker
            value={currentDate.toJSDate()}
            onChange={(val) => setCurrentDate(DateTime.fromJSDate(val))}
            {...rest}
          />
        </section>
        <section className="yx-calendar__select-timeslots">
          <p>{t('selectTimeSlots')}</p>
          <SelectTimeSlot />
        </section>
        <section className="yx-calendar__selected-dates">
          <p>{t('selectedDates')}</p>
          <SelectedDates />
        </section>
      </main>
    </div>
  );
};

export default Calendar;
