import React, { useEffect, useCallback } from 'react';
import utils from 'utils';

import './InfiniteScroll.styles.scss';

type InfiniteScrollProps = {
  treshold?: number;
  debounceTime?: number;
  className?: string;
  scrollRef?: (element: HTMLDivElement) => void;
  onScroll: () => void;
};

const InfiniteScroll: React.FC<InfiniteScrollProps> = (props) => {
  const {
    children,
    treshold = 90,
    debounceTime = 150,
    onScroll,
    className,
  } = props;

  const handleScroll = useCallback(
    (div: HTMLDivElement) => {
      if (!div) return;

      if (window.infiniteLocalLoading) return;

      if (
        ((div.clientHeight + Math.abs(div.scrollTop)) / div.scrollHeight) *
          100 >=
        treshold
      ) {
        onScroll?.();
      }
    },
    [onScroll, treshold],
  );

  useEffect(() => {
    const handleWindowScroll = utils.debounce(
      (ev: Event) => handleScroll(ev.target as HTMLDivElement),
      debounceTime,
    );

    window.addEventListener('scroll', handleWindowScroll, true);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll, true);
    };
  }, [handleScroll, debounceTime]);

  return <div className={className}>{children}</div>;
};

export default InfiniteScroll;
