import React, { useMemo } from 'react';
import classNames from 'classnames';
import DropzoneField from 'components/Fields/DropzoneField';
import { Field } from 'react-final-form';
import ImagesTooltip from '../BasicStep/components/ImagesTooltip';
import validators from 'validators';
import { useTranslation } from 'react-i18next';
import StepWrapper from 'components/StepWrapper';

import './MediaFilesStep.styles.scss';

type MediaFilesStepProps = {
  className?: string;
};

const MediaFilesStep: React.FC<MediaFilesStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-media-files-step', className);
  const { t } = useTranslation();

  const validatorsObject = useMemo(
    () => ({
      profileShots: validators.requiredArray(
        t('basicInfo-profileShots-validator'),
      ),
      mainSalonShots: validators.requiredArray(
        t('basicInfo-mainSalonShots-validator'),
      ),
      masterCabinShots: validators.requiredArray(
        t('basicInfo-masterCabinShots-validator'),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('basic')}
      description={t('basicInfo-info')}
      className={classes}
    >
      <Field
        name="profileShots"
        maxFiles={40}
        component={DropzoneField}
        label={t('basicInfo-profileShots')}
        validate={validatorsObject.profileShots}
        tooltip={
          <ImagesTooltip
            title={t('basicInfo-makeAppropriateShots')}
            description={t('basicInfo-makeAppropriateShots-info')}
          />
        }
      />
      <Field
        name="mainSalonShots"
        maxFiles={40}
        component={DropzoneField}
        label={t('basicInfo-mainSalonShots')}
        validate={validatorsObject.mainSalonShots}
        tooltip={
          <ImagesTooltip
            title={t('basicInfo-makeAppropriateShots')}
            description={t('basicInfo-makeAppropriateShots-info')}
          />
        }
      />
      <Field
        name="masterCabinShots"
        maxFiles={40}
        component={DropzoneField}
        label={t('basicInfo-masterCabinShots')}
        validate={validatorsObject.masterCabinShots}
        tooltip={
          <ImagesTooltip
            title={t('basicInfo-makeAppropriateShots')}
            description={t('basicInfo-makeAppropriateShots-info')}
          />
        }
      />
      <Field
        name="miscellaneousShots"
        maxFiles={40}
        component={DropzoneField}
        label={t('basicInfo-miscellaneousShots')}
        tooltip={
          <ImagesTooltip
            title={t('basicInfo-makeAppropriateShots')}
            description={t('basicInfo-makeAppropriateShots-info')}
          />
        }
      />
      <Field
        name="videos"
        maxFiles={10}
        component={DropzoneField}
        label={t('basicInfo-videos')}
        tooltip={
          <ImagesTooltip
            title={t('basicInfo-makeAppropriateShots')}
            description={t('basicInfo-makeAppropriateShots-info')}
          />
        }
      />
    </StepWrapper>
  );
};

export default MediaFilesStep;
