import React from 'react';
import SelectNative from 'components/SelectNative';
import utils from 'utils';
import SelectField, { SelectFieldProps } from './SelectField.component';
import { SelectNativeProps } from 'components/SelectNative/SelectNative.component';

const DecideSelect: React.FC<SelectFieldProps> = (props) => {
  return utils.isTouchDevice() ? (
    <SelectNative {...(props as any as SelectNativeProps)} />
  ) : (
    <SelectField {...props} />
  );
};
export default DecideSelect;
