import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 35 40"
    fill="transparent"
    stroke="currentColor"
    strokeWidth="1.8"
    strokeMiterlimit="10"
    strokeLinecap="round"
    {...props}
  >
    <path d="M17.502 32.166v-8.25m-8.25-6.206V9.594A8.428 8.428 0 0 1 17.502 1a8.427 8.427 0 0 1 8.25 8.594l-.002 8.115H9.252ZM34 19.729a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v16.624a2 2 0 0 0 2 2h29a2 2 0 0 0 2-2V19.728Z" />
  </svg>
);
