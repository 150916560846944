import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" {...props}>
    <path
      d="M1 11.973V15.3243C1 16.2459 1.75405 17 2.67568 17H14.4054C14.8498 17 15.276 16.8235 15.5903 16.5092C15.9045 16.195 16.0811 15.7687 16.0811 15.3243V11.973M12.7297 6.94595L8.54054 11.1351L4.35135 6.94595M8.54054 10.1297V1.5"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
