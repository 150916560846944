import { createContext, ReactNode } from 'react';
import { RoutingStep } from 'router/subrouters/SignUp/models/Routes';
import utils from 'utils';

export const renderAsLinks = (
  steps: Array<RoutingStep> | Array<ReactNode>,
): steps is Array<RoutingStep> => {
  if (!steps.length) return false;

  return (
    !['string', 'boolean'].includes(typeof steps[0]) &&
    'path' in (steps as RoutingStep[])[0]
  );
};

export type StepPath = {
  label: string;
  path: string;
  isStepValid: boolean;
};

export type StepsContextProps = {
  storageKeySuffix: string;
  currentStep: number;
  stepLabels: ReactNode[];
  linkSteps: StepPath[];
  renderAsLinks: boolean;
  completedSteps: number;
  steps: RoutingStep[];
  useDraftButtons?: boolean;
  blockOtherStepsWhenCurrentIsInvalid: boolean;
  setSteps: React.Dispatch<React.SetStateAction<RoutingStep[]>>;
  setCompletedSteps: (value: number) => void;
  setCurrentStep: (value: number) => void;
  removeCompletedStepsFromStorage: () => void;
  nextStep: () => void;
  prevStep: () => void;
  goBack: (ask?: boolean) => any;
};

export default createContext<StepsContextProps>({
  storageKeySuffix: '',
  currentStep: 0,
  stepLabels: [],
  linkSteps: null,
  renderAsLinks: false,
  completedSteps: 0,
  steps: [],
  useDraftButtons: false,
  blockOtherStepsWhenCurrentIsInvalid: false,
  setSteps: utils.noop,
  setCompletedSteps: utils.noop,
  setCurrentStep: utils.noop,
  removeCompletedStepsFromStorage: utils.noop,
  nextStep: utils.noop,
  prevStep: utils.noop,
  goBack: utils.noop,
});
