import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="80" height="36" fill="transparent" viewBox="0 0 80 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.827 25.48a18.46 18.46 0 004.194-11.75A18.46 18.46 0 003.818 3.303 18.476 18.476 0 0114.15 19.917c0 1.939-.296 3.807-.847 5.563a11.267 11.267 0 00-8.64-.616l-1.837.616z"
      fill="#9EBBE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.728 28.778c-.004.001-.007-.003-.004-.006a24.029 24.029 0 005.408-15.24c0-4.75-1.367-9.176-3.724-12.9-.11-.174.077-.384.258-.288 7.537 4.012 12.676 12.009 12.676 21.22 0 2.514-.383 4.938-1.093 7.215-.001.002-.003.003-.005.002a14.492 14.492 0 00-11.156-.798l-2.36.795z"
      fill="#0D2CA7"
    />
    <path
      d="M24.363 28.929L.5 35.429c-.223.06-.166.376.065.36l17.392-2.58c5.074-.748 5.264-1.025 6.515-4.167.027-.066-.037-.133-.108-.113z"
      fill="#000053"
    />
    <path
      d="M39.193 25.506L34.829 36h-1.947l1.615-3.986-2.748-6.508h1.963l1.751 4.394 1.767-4.394h1.963zM45.564 32.557v-.906c-.211.333-.533.59-.966.77-.423.182-.83.272-1.223.272-.966 0-1.807-.337-2.522-1.011-.714-.685-1.072-1.57-1.072-2.658 0-1.087.353-1.968 1.057-2.642.715-.685 1.56-1.027 2.537-1.027.392 0 .795.086 1.208.257.423.16.75.418.981.77v-.876h1.842v7.051h-1.842zm-3.306-4.892c-.383.352-.574.806-.574 1.36 0 .553.191 1.01.574 1.373.392.352.85.529 1.374.529a1.97 1.97 0 001.359-.514c.382-.352.573-.815.573-1.389 0-.584-.196-1.047-.589-1.389a1.951 1.951 0 00-1.343-.513c-.524 0-.982.18-1.374.543zM50.158 31.666c-.745-.694-1.117-1.58-1.117-2.657s.373-1.953 1.117-2.627c.745-.685 1.641-1.027 2.688-1.027.594 0 1.148.12 1.66.363a3.39 3.39 0 011.27 1.011l-1.465 1.102c-.343-.463-.83-.694-1.465-.694-.533 0-.997.176-1.39.528-.392.343-.588.79-.588 1.344s.196 1.017.589 1.39a1.98 1.98 0 001.389.543c.634 0 1.122-.237 1.465-.71l1.48 1.087c-.333.443-.76.785-1.284 1.027a3.853 3.853 0 01-1.661.362c-1.047 0-1.943-.347-2.688-1.041zM59.18 28.798v3.76h-1.842V21.67h1.843v4.922c.15-.392.448-.694.89-.906.453-.221.886-.332 1.299-.332.906 0 1.605.287 2.099.86.493.575.74 1.38.74 2.417v3.925h-1.843v-3.835c0-.483-.15-.866-.453-1.147a1.449 1.449 0 00-1.041-.423c-.453 0-.851.136-1.193.407-.332.262-.498.675-.498 1.239zM68.616 22.818v2.688h1.495v1.48h-1.495v5.571H66.79v-5.571h-1.283v-1.48h1.283v-2.688h1.827zM79.28 22.154l-3.261 4.847 3.623 5.556h-2.295l-2.476-4-2.476 4h-2.28l3.609-5.556-3.262-4.847h2.31l2.1 3.307 2.098-3.307h2.31z"
      fill="#0D2CA7"
    />
  </svg>
);
