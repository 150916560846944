import React, { useContext } from 'react';
import './Datepicker.styles.scss';
import './Datepicker.styles.responsive.scss';
import DatepickerContext from './provider/Datepicker.context';
import pickers from './picker';
import { Pickers } from './utils';
import classNames from 'classnames';
import ShowMenu from './components/ShowMenu';

const Datepicker: React.FC = () => {
  const { showInMenu } = useContext(DatepickerContext);

  const headerClasses = classNames({
    bordered: showInMenu === Pickers.DAY_PICKER,
  });

  const Header = pickers[showInMenu].header;
  const Main = pickers[showInMenu].picker;

  return (
    <div className="datepicker-menu-container">
      <header className={headerClasses}>
        <Header />
        <ShowMenu />
      </header>
      <Main />
    </div>
  );
};

export default Datepicker;
