import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import { Search } from 'models/Search';
import InputField from 'components/Fields/InputField';
import FieldLabel from 'components/Fields/components/FieldLabel';
import validators from 'validators';

import './PreviewStep.styles.scss';

type PreviewStepProps = {
  className?: string;
};

const PreviewStep: React.FC<PreviewStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-preview-step', className);

  const { t } = useTranslation();

  const { values } = useFormState<Search>();

  const searchData = useMemo(() => {
    return [
      {
        label: t('type'),
        value: values?.yachtType,
      },
      {
        label: t('n/u/b'),
        value: values?.yachtStatus,
      },
      {
        label: t('minBudget'),
        value: `EUR ${values?.runningCost?.from}`,
      },
      {
        label: t('maxBudget'),
        value: `EUR ${values?.runningCost?.to}`,
      },
      {
        label: t('guestCapacity'),
        value: values?.guestCapacity,
      },
      {
        label: t('location'),
        value: values?.location,
      },
      {
        label: t('speed'),
        value: values?.speedType,
      },
      {
        label: t('minBuildYear'),
        value: values?.yearBuilt,
      },
      {
        label: t('engine'),
        value: values?.preferedEngine,
      },
      {
        label: t('fuelType'),
        value: values?.fuelType,
      },
      {
        label: t('charter'),
        value: values?.makeVesselCommercialyAvailable,
      },
    ] as Array<Record<string, any>>;
  }, [t, values]);

  return (
    <StepWrapper title={t('reviewYourSearchProfile')} className={classes}>
      {searchData.map((data, index) => (
        <div className="yx-preview-step__row" key={index}>
          <p>{data.label}</p>
          <p>{data.value}</p>
        </div>
      ))}
      <FieldLabel className="yx-preview-step__label">
        {t('pickNameForSearch')}
      </FieldLabel>
      <Field
        name="searchName"
        label={t('searchProfileName')}
        placeholder={t('enterDescriptiveName')}
        component={InputField}
        validate={validators.required(t('requiredField'))}
      />
    </StepWrapper>
  );
};

export default PreviewStep;
