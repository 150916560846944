import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import Routes from 'router/components/Routes';
import routes from './routes';
import './Signup.styles.scss';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51JU8voI8WE9cX4JnJQD72WZJnRAqHhstNMCN4PxyP2fpe6BIMHc7H6MTwFZ1MnWPMwYUasonylMjJvZYsABsLNou00bFbq9M5G',
);

const SignUpRouter: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <Routes routes={routes} />
    </Elements>
  );
};

export default SignUpRouter;
