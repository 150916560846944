import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { Input } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FieldLabel from '../components/FieldLabel';
import utils from 'utils';
import useCallbackRef from 'hooks/useCallbackRef';

import './InputField.styles.scss';

type CurrencyUnits = 'EUR' | 'USD';
type SIUnits = 'meters' | 'hp' | 'kn' | 'kW' | 'liters';
type Units = CurrencyUnits | SIUnits;

type InputProps = ExtractPropTypes<typeof Input>;

type InputFieldProps = InputProps &
  FieldRenderProps<string, HTMLElement> & {
    unitType?: Units;
    label: string;
    optional?: boolean;
    onButtonClick?: () => void;
    tooltip?: React.FC;
  };

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    className,
    label,
    input,
    buttonText,
    meta: { touched, error },
    required,
    optional = required !== undefined && !required,
    tooltip,
    unitType,
    onButtonClick,
    suffixNode,
    ...rest
  } = props;
  const { t } = useTranslation();

  const [inputEl, inputRef] = useCallbackRef<HTMLInputElement>();

  const hasError = useMemo(() => touched && error, [error, touched]);
  const { value, onChange: fOnChange, ...restInput } = input;

  const classes = classNames(
    'yx-input-field',
    {
      'yx-input-field--error': hasError,
      'yx-input-field--readonly': rest.readOnly,
    },
    className,
  );

  const unit = useMemo(
    () => unitType && <div className="yx-input-field__unit">{unitType}</div>,
    [unitType],
  );

  const preserveCursorPosition = useRef<number>(-1);

  useEffect(() => {
    if (preserveCursorPosition.current >= 0 && unitType) {
      inputEl.selectionStart = preserveCursorPosition.current;
      inputEl.selectionEnd = preserveCursorPosition.current;
      preserveCursorPosition.current = -1;
    }
  }, [inputEl, unitType, value]);

  const onChange = useCallback(
    ({
      target: { value, selectionStart },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (unitType) {
        const newValue = utils.getDotedNumber(value.replace(/[,]/g, ''));
        const { length: oldLength } = value;
        const { length: newLength } = newValue;
        preserveCursorPosition.current = selectionStart + newLength - oldLength;
        fOnChange(newValue);
      } else {
        fOnChange(value);
      }
    },
    [fOnChange, unitType],
  );

  return (
    <div className={classes}>
      {label && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      <Input
        ref={inputRef}
        inputMode={unitType ? 'numeric' : 'text'}
        value={value}
        hasError={hasError}
        onChange={onChange}
        {...restInput}
        {...rest}
        suffixNode={
          <>
            {(suffixNode || (optional && !value)) && (
              <div className="suffix">
                {optional && !value && (
                  <span className="suffix__optional-label">
                    {t('optional')}
                  </span>
                )}
                {suffixNode}
              </div>
            )}
            {unit}
          </>
        }
      />
      {hasError && <p>{error}</p>}
    </div>
  );
};

export default InputField;
