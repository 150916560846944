import React from 'react';
import classNames from 'classnames';
import { CountryCodes } from 'services/countries';

import './Flag.styles.scss';

type FlagProps = {
  className?: string;
  code?: CountryCodes;
};

const Flag: React.FC<FlagProps> = (props) => {
  const { code, className } = props;

  const classes = classNames(
    'flag-icon',
    `flag-icon-${code.toLowerCase()}`,
    className,
  );

  return <span className={classes} />;
};

export default Flag;
