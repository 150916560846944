import React, { useMemo } from 'react';
import classNames from 'classnames';
import './PercentageValue.styles.scss';

type PercentageValueProps = {
  className?: string;
  value: number;
  sqSize?: number;
  barThickness?: number;
};

const PercentageValue: React.FC<PercentageValueProps> = (props) => {
  const { value, className, sqSize = 52, barThickness = 5 } = props;

  const classes = classNames('yx-percentage-value', className);

  const radius = useMemo(
    () => (sqSize - barThickness) / 2,
    [barThickness, sqSize],
  );

  const percentage = useMemo(() => +(value * 100).toFixed(1), [value]);

  const dashArray = 2 * radius * Math.PI;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg
      className={classes}
      width={sqSize}
      height={sqSize}
      viewBox={`0 0 ${sqSize} ${sqSize}`}
      fill="transparent"
    >
      <circle
        className="circle-background"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${barThickness}px`}
        stroke="#F6F6FB"
      />
      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${barThickness}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        stroke="currentColor"
        strokeLinecap="round"
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      <text
        className="circle-text"
        x="50%"
        y={`${sqSize / 2 + 5}`}
        textAnchor="middle"
        fill="currentColor"
      >
        {`${percentage}%`}
      </text>
    </svg>
  );
};

export default PercentageValue;
