import React, { useContext } from 'react';
import classNames from 'classnames';
import LogoWithTextIcon from 'icons/LogoWithText.icon';
import { NavLink } from 'react-router-dom';
import ArrowheadIcon from 'icons/Arrowhead.icon';
import ProfileControls from '../ProfileControls';
import NotificationDropdown from '../NotificationDropdown';
import { NotificationOption } from '../NotificationDropdown/NotificationDropdown.component';
import CurrentUserContext from 'router/subrouters/Dashboard/providers/CurrentUser/CurrentUser.context';

import './Header.styles.scss';
import './Header.responsive.styles.scss';

const optionsTest: NotificationOption[] = [
  { label: 'notifikacija1', to: '/catalog', isNotRead: true },
  { label: 'notifikacija2', to: '/catalog', isNotRead: true },
  { label: 'notifikacija3', to: '/catalog', isNotRead: false },
  { label: 'notifikacija4', to: '/catalog', isNotRead: true },
];

export type HeaderProps = {
  title?: string;
  className?: string;
  childrenPosition?: 'left' | 'right';
  backTo?: string;
  childrenClassName?: string;
};

const Header: React.FC<HeaderProps> = (props) => {
  const {
    children,
    className,
    title,
    childrenPosition,
    backTo,
    childrenClassName,
  } = props;

  const {
    currentUser: { firstName, lastName, profileImage: { url } = { url: '' } },
  } = useContext(CurrentUserContext);

  const userName = `${firstName} ${lastName}`;

  const classes = classNames(className, 'yx-header', {
    'yx-header--children-right': childrenPosition === 'right',
  });
  const childrenClasses = classNames(
    'yx-header__content__children',
    childrenClassName,
  );

  return (
    <header className={classes}>
      <NavLink to="/" className="yx-header__logo-section">
        <LogoWithTextIcon />
      </NavLink>
      <div className="yx-header__content" id="header-content">
        <div className="yx-header__content__left">
          {backTo ? (
            <NavLink to={backTo} className="header-link-back">
              <ArrowheadIcon />
              {title}
            </NavLink>
          ) : (
            <h1>{title}</h1>
          )}
        </div>
        <div className={childrenClasses}>{children}</div>
        <div className="yx-header__content__right">
          <NotificationDropdown options={optionsTest} />
          <ProfileControls userName={userName} imageUrl={url} />
        </div>
      </div>
    </header>
  );
};

export default Header;
