import { Search } from 'models/Search';
import i18n from 'i18next';

export const charterValue = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'yes';
    case 'No':
      return 'no';
    default:
      return 'iDontCare';
  }
};

export const createAuxiliaryEquipmentValues = (
  search: Search,
): [string, string[]] => {
  if (!search) {
    return;
  }

  const auxiliaryEquipmentKeys: Array<keyof typeof search> = [
    'hasMarineHead',
    'hasInverter',
    'hasShorePowerInlet',
    'hasBowThruster',
    'hasSternThruster',
    'hasAncillaryEquipment',
    'hasFireChainWasher',
    'hasBilgePump',
    'hasSewageTreatment',
    'hasEngineRoomBlowers',
  ];

  const requiredAuxiliary = auxiliaryEquipmentKeys
    .map((key) => search[key] && i18n.t(key))
    .filter(Boolean);

  return [requiredAuxiliary[0], requiredAuxiliary.slice(1)];
};
