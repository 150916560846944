import React from 'react';
import MySearches from './MySearches.component';
import SearchProvider from './providers/Search';

const WrapperSearch = () => (
  <SearchProvider>
    <MySearches />
  </SearchProvider>
);

export default WrapperSearch;
