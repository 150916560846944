import React from 'react';
import classNames from 'classnames';
import CheckIcon from 'icons/Check.icon';
import utils from 'utils';
import { useTranslation } from 'react-i18next';

import './RadioCard.styles.scss';

export type RadioCardProps = {
  className?: string;
  imagePath?: string;
  icon?: React.ReactNode;
  hasError?: boolean;
  label?: string;
  description?: string;
  bgImage?: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const RadioCard: React.FC<RadioCardProps> = (props) => {
  const {
    className,
    name,
    value,
    checked,
    imagePath,
    icon,
    hasError,
    label,
    description,
    bgImage = false,
    onChange,
  } = props;

  const { t } = useTranslation();

  const classes = classNames(
    'yx-radio-card',
    { 'yx-radio-card--error': hasError },
    { 'yx-radio-card--active': checked },
    { 'yx-radio-card--with-description': description || icon },
    { 'yx-radio-card--bg-image': bgImage },

    className,
  );

  return (
    <label className={classes}>
      <input
        hidden
        type="radio"
        onChange={onChange}
        name={name}
        value={value}
        checked={checked}
      />
      {checked && (
        <span className="yx-radio-card__circle">
          <CheckIcon />
        </span>
      )}
      {imagePath && <img src={imagePath} alt="radio" />}
      {icon && (
        <span className="yx-radio-card__icon-circle">
          {utils.renderIcon(icon)}
        </span>
      )}
      <div className="yx-radio-card__text">
        <p>{t(label)}</p>
        {description && <p>{t(description)}</p>}
      </div>
    </label>
  );
};

export default RadioCard;
