import auth from './auth';
import users from './users';
import yacht from './yacht';
import search from './search';
import notifications from './notifications';
import matches from './matches';

export default {
  auth,
  users,
  yacht,
  search,
  notifications,
  matches,
};
