import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import Avatar from '../Avatar';
import ArrowheadIcon from '../../icons/Arrowhead.icon';
import { useHistory } from 'react-router-dom';
import GeneralContext from 'providers/General/General.context';
import OptionMenu from 'components/OptionMenu';

import './ProfileControls.styles.scss';

export type ProfileControlOption = {
  label: string;
  onClick: () => void;
};

type ProfileControlsProps = {
  className?: string;
  imageUrl: string;
  userName: string;
};

const ProfileControls: React.FC<ProfileControlsProps> = (props) => {
  const { className, imageUrl, userName } = props;

  const history = useHistory();
  const { logout } = useContext(GeneralContext);

  const classes = classNames('yx-profile-controls', className);

  const defaultOptions = useMemo(
    () => [
      {
        id: 'editProfile',
        label: 'editProfile',
        onClick: () => {
          history.push('/profile-settings');
        },
      },
      {
        id: 'logout',
        label: 'logout',
        onClick: () => {
          logout();
        },
      },
    ],
    [history, logout],
  );

  const trigger = useMemo(
    () => (
      <div>
        <div className="yx-profile-controls__avatar">
          <Avatar imageUrl={imageUrl} />
        </div>
        <span>{userName}</span>
        <ArrowheadIcon />
      </div>
    ),
    [imageUrl, userName],
  );

  return (
    <OptionMenu
      trigger={trigger}
      className={classes}
      options={defaultOptions}
    />
  );
};

export default ProfileControls;
