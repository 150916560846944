import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Search } from 'models/Search';
import Card from 'components/Card';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import DeleteIcon from 'icons/Delete.icon';
import EditIcon from 'icons/Edit.icon';
import Image from 'components/Image';
import InfoCard, { InfoCardType } from 'components/InfoCard/InfoCard.component';
import BeamIcon from 'icons/Beam.icon';
import BoatIcon from 'icons/Boat.icon';
import utils from 'utils';
import CrewIcon from 'icons/Crew.icon';
import SpeedIcon from 'icons/Speed.icon';
import { useHistory } from 'react-router-dom';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import SearchContext from '../../providers/Search/Search.context';
import AdvancedIcon from 'icons/Advanced.icon';

import './SearchCard.styles.scss';
import './SearchCard.responsive.styles.scss';

type SearchCardProps = {
  className?: string;
} & Search;

const SearchCard: React.FC<SearchCardProps> = (props) => {
  const { className, ...restData } = props;

  const classes = classNames('yx-search-card', className);

  const { t } = useTranslation();
  const history = useHistory();

  const { deleteSearch } = useContext(SearchContext);

  const {
    id: searchId,
    searchName = t('notSpecifiedParam', { param: t('searchName') }),
    numberOfMatches = 0,
    yachtType = t('noData'),
    yachtStatus = t('noData'),
    budget,
    guestCapacity = t('noData'),
    speedType = t('noData'),
    yearBuilt = t('noData'),
    location = t('noLocation'),
  } = restData;

  const dropdownContent = useMemo(
    () => (
      <>
        <Button
          onClick={() => {
            storageService.setItem(STORAGE_KEYS.EDITING_SEARCH_ID, searchId);
            history.push(`/edit-search-profile/${searchId}`);
          }}
          icon={<EditIcon width="14px" height="14px" />}
          variant="link"
        >
          {t('edit')}
        </Button>
        <Button
          onClick={() => {
            storageService.setItem(STORAGE_KEYS.EDITING_SEARCH_ID, searchId);
            history.push(`/edit-search-profile/${searchId}/advanced-search`);
          }}
          icon={<AdvancedIcon width="14px" height="14px" />}
          variant="link"
        >
          {t('advancedEdit')}
        </Button>
        <Button
          onClick={() => deleteSearch(restData)}
          icon={DeleteIcon}
          variant="link"
        >
          {t('delete')}
        </Button>
      </>
    ),
    [t, searchId, history, restData, deleteSearch],
  );

  const sideContent = useMemo(
    () => (
      <div className="yx-search-card__matches">
        <Image src="/assets/images/match-yacht.png" />
        {!numberOfMatches ? (
          <p>{t('noMatchesForSearch')}</p>
        ) : (
          <>
            <p>{t('youHaveMatch', { count: numberOfMatches })}</p>
            <Button
              onClick={() => history.push(`/matches/${searchId}/search`)}
              styleType="secondary"
            >
              {t('viewMatches')}
            </Button>
          </>
        )}
      </div>
    ),
    [history, numberOfMatches, searchId, t],
  );

  const searchInformations = useMemo(
    () =>
      [
        {
          icon: BeamIcon,
          title: t('type'),
          content: yachtType,
        },
        {
          icon: BeamIcon,
          title: t('newOrUsed'),
          content: yachtStatus,
        },
        {
          icon: BoatIcon,
          title: t('budget'),
          content: `EUR ${
            budget?.from
              ? utils.getDotedNumber(budget.from.toString())
              : t('noData')
          } to EUR ${
            budget?.to
              ? utils.getDotedNumber(budget.to.toString())
              : t('noData')
          }`,
        },
        {
          icon: CrewIcon,
          title: t('guestCapacity'),
          content: guestCapacity,
        },
        {
          icon: SpeedIcon,
          title: t('speed'),
          content: speedType,
        },
        {
          icon: CrewIcon,
          title: t('yearOfBuild'),
          content: yearBuilt,
        },
      ] as Array<InfoCardType>,
    [t, budget, yachtStatus, yachtType, guestCapacity, speedType, yearBuilt],
  );

  return (
    <Card
      className={classes}
      title={searchName}
      dropdownContent={dropdownContent}
      sideContent={sideContent}
      countryFlag={location}
    >
      <div className="yx-search-card__content">
        {searchInformations.map((infoData, index) => (
          <InfoCard key={index} infoData={infoData} />
        ))}
      </div>
    </Card>
  );
};

export default SearchCard;
