import React, { useMemo } from 'react';
import classNames from 'classnames';
import './SelectNative.styles.scss';
import { ExtractPropTypes } from 'types';
import { Select } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import FieldLabel from 'components/Fields/components/FieldLabel';
import { useTranslation } from 'react-i18next';

type SelectComponentProps = ExtractPropTypes<typeof Select>;

export type SelectNativeProps = Pick<SelectComponentProps, 'options'> &
  FieldRenderProps<string, HTMLElement> & {
    label?: string;
    className?: string;
    tooltip?: React.FC;
  };

const SelectNative: React.FC<SelectNativeProps> = (
  props: SelectNativeProps,
) => {
  const {
    label,
    meta: { touched, error },
    className,
    input,
    options,
    tooltip,
  } = props;
  const hasError = useMemo(() => touched && error, [error, touched]);
  const { t } = useTranslation();

  const classes = classNames(
    'yx-select-native',
    { 'yx-select-native--error': hasError },
    { 'yx-select-native--no-val': !input.value },
    className,
  );

  const optionIcon = useMemo(() => {
    if (!options?.length || !('searchValue' in options[0])) return null;

    return (
      options.find((o) =>
        'searchValue' in o && o.value === input.value ? o.label.icon : false,
      )?.label as any
    )?.icon;
  }, [input.value, options]);

  return (
    <div className={classes}>
      {label && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      <div className="yx-select-native__wrapper">
        {!!optionIcon && <span className="option-icon">{optionIcon}</span>}
        <select {...input}>
          <option disabled value="" hidden>
            {t('search...')}
          </option>
          {options.map((op, ind) => (
            <option
              key={ind}
              value={
                typeof op.value === 'object'
                  ? JSON.stringify(op.value)
                  : (op.value as string | number)
              }
            >
              {'searchValue' in op ? op.searchValue : op.label}
            </option>
          ))}
        </select>
      </div>
      {hasError && <p className="yx-select-native__error">{error}</p>}
    </div>
  );
};

export default SelectNative;
