import React from 'react';
import { CreateYachtSteps, Yacht, YACHT_CREATE_STEPS } from 'models/Yacht';
import redirect from 'router/modules/redirect';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import TenderAndToysStep from './pages/TenderAndToysStep';
import { RoutingStep } from '../SignUp/models/Routes';
import AuxiliaryEquipmentStep from './pages/AuxiliaryEquipmentStep';
import BasicStep from './pages/BasicStep';
import DesignStep from './pages/DesignStep';
import ConstructionStep from './pages/ConstructionStep';
import GeometryStep from './pages/GeometryStep';
import EngineStep from './pages/EngineStep';
import CapacitiesStep from './pages/CapacitiesStep';
import MaintenanceStep from './pages/MaintenanceStep';
import CommercialComplianceStep from './pages/CommercialComplianceStep';
import PublishStep from './pages/PublishStep';
import DecidingYacht from './components/DecidingYacht';
import StepsProvider from 'components/StepProgress/providers/Steps/Steps.provider';
import CreateYachtRouter, { CreateYachtProps } from './CreateYacht.router';
import credentialsService from 'services/credentialsService';
import DocumentationStepBroker from './pages/DocumentationStepBroker';
import DocumentationStepPrivate from './pages/DocumentationStepPrivate';
import MediaFilesStep from './pages/MediaFilesStep';
import NavigationCommunicationStep from './pages/NavigationCommunicationStep';
import { USER_TYPES } from 'models/User';

export const steps = [
  {
    path: '/basic',
    exact: true,
    component: BasicStep,
    stepName: YACHT_CREATE_STEPS.BASIC,
    labelId: 'basic',
  },
  {
    path: '/construction',
    exact: true,
    component: ConstructionStep,
    stepName: YACHT_CREATE_STEPS.CONSTRUCTION,
    labelId: 'construction',
  },
  {
    path: '/geometry',
    exact: true,
    component: GeometryStep,
    stepName: YACHT_CREATE_STEPS.GEOMETRY,
    labelId: 'geometry',
  },
  {
    path: '/engine',
    exact: true,
    component: EngineStep,
    stepName: YACHT_CREATE_STEPS.ENGINE,
    labelId: 'engine',
  },
  {
    path: '/design',
    exact: true,
    component: DesignStep,
    stepName: YACHT_CREATE_STEPS.DESIGN,
    labelId: 'design',
  },
  {
    path: '/auxiliary-equipment',
    exact: true,
    component: AuxiliaryEquipmentStep,
    stepName: YACHT_CREATE_STEPS.AUXILIARY_EQUIPMENT,
    labelId: 'auxiliaryEquipment',
  },
  {
    path: '/equipment',
    exact: true,
    component: NavigationCommunicationStep,
    stepName: YACHT_CREATE_STEPS.NAVIGATION_COMMUNICATION_DECK_EQUIPMENT,
    labelId: 'navigationCommunication',
  },
  {
    path: '/capacities',
    exact: true,
    component: CapacitiesStep,
    stepName: YACHT_CREATE_STEPS.CAPACITIES,
    labelId: 'capacities',
  },
  {
    path: '/commercial-compliance',
    exact: true,
    component: CommercialComplianceStep,
    stepName: YACHT_CREATE_STEPS.COMMERCIAL_COMPLIANCE,
    labelId: 'commercialCompliance',
    patchMap: ({ commercialYachtSurveyReport, ...restData }: Yacht) => restData,
  },
  {
    path: '/tenders-and-toys',
    exact: true,
    component: TenderAndToysStep,
    stepName: YACHT_CREATE_STEPS.TENDER_AND_TOYS,
    labelId: 'tenderAndToys',
  },
  {
    path: '/maintenance-history',
    exact: true,
    component: MaintenanceStep,
    stepName: YACHT_CREATE_STEPS.MAINTENANCE_HISTORY,
    labelId: 'maintenanceHistory',
  },
  {
    path: '/media-files',
    exact: true,
    component: MediaFilesStep,
    stepName: YACHT_CREATE_STEPS.MEDIA_FILES,
    labelId: 'mediaFiles',
    patchMap: ({
      profileShots,
      masterCabinShots,
      mainSalonShots,
      miscellaneousShots,
      videos,
      ...restData
    }: Yacht) => restData,
  },
  {
    path: '/documentation',
    exact: true,
    component: (...props: any) =>
      credentialsService.user?.userType === USER_TYPES.PRIVATE ? (
        <DocumentationStepPrivate {...props} />
      ) : (
        <DocumentationStepBroker {...props} />
      ),
    stepName: 'Documentation',
    labelId: 'documentation',
    patchMap: ({
      centralAgencyAgreement,
      certificateOfRegistry,
      engineCertificate,
      billOfSale,
      ...restData
    }: Yacht) => restData,
  },
  {
    path: '/publish',
    exact: true,
    component: PublishStep,
    labelId: 'publish',
    patchMap: ({ optionalDocuments, ...restData }: Yacht) => restData,
  },
] as Array<RoutingStep<CreateYachtSteps>>;

export const yachtRoutes = [
  ...steps,
  {
    path: '',
    component: redirect('/basic'),
  },
];

const WrapperYacht = (props: CreateYachtProps) => (
  <StepsProvider
    useDraftButtons
    storageKeySuffix="yacht"
    closeFallbackRoute="/my-yachts"
    steps={steps}
  >
    <CreateYachtRouter routes={yachtRoutes} {...props} />
  </StepsProvider>
);

export default [
  {
    path: '/:yachtId',
    component: WrapperYacht,
    exact: false,
  },
  {
    path: '',
    component: DecidingYacht,
  },
] as Array<EnhancedRouteProps>;
