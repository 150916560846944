import utils from 'utils';

export const getUnitValue = (
  value: string | number,
  direction: 'toServer' | 'toClient',
) => {
  if (direction === 'toServer' && typeof value === 'string')
    return utils.getIntegerFromDottedNumber(value as string);
  else if (direction === 'toClient' && typeof value === 'number')
    return utils.getDotedNumber(value.toString());

  return value;
};
