import { useRef, useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function <El extends HTMLElement = HTMLElement>({
  observeHeight = true,
} = {}) {
  const ref = useRef<El>();
  const [height, set] = useState(0);
  const heightRef = useRef(height);
  const { current: resizeObserver } = useRef(
    new ResizeObserver(() => {
      if (ref.current && heightRef.current !== ref.current.offsetHeight) {
        heightRef.current = ref.current.offsetHeight;
        set(ref.current.offsetHeight);
      }
    }),
  );

  useLayoutEffect(() => {
    if (observeHeight && ref.current) {
      set(ref.current.offsetHeight);
      resizeObserver.observe(ref.current);
    }
    return () => resizeObserver.disconnect();
  }, [observeHeight, resizeObserver]);

  return [ref, height] as const;
}
