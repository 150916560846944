import { PaginatedData } from 'models/PaginatedData';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import utils from 'utils';

type InfiniteProps<T> = {
  makeRequest: (
    currentPage: number,
    searchString: string,
  ) => Promise<PaginatedData<T>>;
  defaultItems?: T[];
  customSetItems?: React.Dispatch<React.SetStateAction<T[]>>;
  dependencies?: Array<any>;
  resetDeps?: Array<any>;
  debounceTime?: number;
  skipFirst?: boolean;
};

export default function <T>({
  defaultItems = [],
  dependencies = [],
  resetDeps = [],
  debounceTime = null,
  skipFirst = false,
  customSetItems,
  makeRequest,
}: InfiniteProps<T>) {
  const [loading, setLoading] = useState(!skipFirst);
  const [items, setItems] = useState<T[]>(defaultItems);
  const [searchString, setSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const lastSearchString = useRef(searchString);
  const first = useRef(true);
  const resetDataFlag = useRef(false);
  const skipFirstRef = useRef(skipFirst);

  const { popServerError } = useContext(PopNotificationsContext);

  const onContainerScrolled = useCallback(() => {
    if (loading) return;
    if (currentPage < totalPages) {
      setCurrentPage((old) => old + 1);
    }
  }, [loading, currentPage, totalPages]);

  const setItemsFinal = useMemo(
    () => customSetItems || setItems,
    [customSetItems],
  );

  const getFiles = useCallback(
    utils.debounce(
      async (
        currentPage: number,
        searchString: string,
        setItemsFinal: React.Dispatch<React.SetStateAction<T[]>>,
      ) => {
        try {
          setLoading(true);

          const { items, totalPages } = await makeRequest(
            currentPage,
            searchString,
          );
          if (resetDataFlag.current) {
            setItemsFinal(items);
            resetDataFlag.current = false;
          } else if (lastSearchString.current !== searchString) {
            lastSearchString.current = searchString;
            setItemsFinal(items);
          } else {
            setItemsFinal((old: T[]) => [...old, ...items]);
          }
          setTotalPages(totalPages);
        } catch (e) {
          popServerError(e);
        } finally {
          setLoading(false);
        }
      },
      debounceTime,
    ),
    [debounceTime, ...dependencies],
  );

  useEffect(() => {
    setCurrentPage(1);
    resetDataFlag.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...resetDeps]);

  useEffect(() => {
    if (skipFirstRef.current && first.current) {
      first.current = false;
      return;
    }

    getFiles(currentPage, searchString, setItemsFinal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchString, ...dependencies, setItemsFinal]);

  return {
    items,
    loading,
    searchString,
    currentPage,
    totalPages,
    setItems,
    onContainerScrolled,
    setSearchString,
    setCurrentPage,
  };
}
