import { User } from 'models/User';
import { AuthBody } from '../models/Auth';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  get authBody() {
    return storageService.getItem<AuthBody>(STORAGE_KEYS.AUTH);
  },

  get user() {
    return storageService.getItem<User>(STORAGE_KEYS.USER);
  },

  get token(): string {
    return storageService.getItem<string>(STORAGE_KEYS.TOKEN);
  },

  get refreshToken(): string {
    return storageService.getItem<string>(STORAGE_KEYS.REFRESH_TOKEN);
  },

  get patchToken(): string {
    return storageService.getItem<User>(STORAGE_KEYS.USER)?.patchToken;
  },

  get verificationUrl(): string {
    return storageService.getItem<string>(STORAGE_KEYS.VERIFICATION_URL);
  },

  set isLogged(isLoged: boolean) {
    storageService.setItem(STORAGE_KEYS.IS_LOGGED, isLoged);
  },

  get isLogged(): boolean {
    return storageService.getItem<boolean>(STORAGE_KEYS.IS_LOGGED);
  },

  set token(token: string) {
    storageService.setItem(STORAGE_KEYS.TOKEN, token);
  },

  set refreshToken(refreshToken: string) {
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  },

  get draftYachtId(): string {
    return storageService.getItem<string>(STORAGE_KEYS.CREATING_YACHT_ID);
  },

  get hasActiveSubscription(): boolean {
    return storageService.getItem<User>(STORAGE_KEYS.USER)
      ?.hasActiveSubscription;
  },

  get isOnFreeTrial(): boolean {
    return storageService.getItem<User>(STORAGE_KEYS.USER)?.isOnFreeTrial;
  },

  saveAuthBody(authBody: AuthBody) {
    storageService.setItem(STORAGE_KEYS.TOKEN, authBody.token);
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, authBody.refreshToken);
    storageService.setItem(STORAGE_KEYS.USER, authBody.user);
  },

  saveUserData(userData: User) {
    storageService.setItem(STORAGE_KEYS.USER, userData);
  },

  saveVerificationCode(verificationUrl: string) {
    storageService.setItem(STORAGE_KEYS.VERIFICATION_URL, verificationUrl);
  },

  removeAuthBody() {
    storageService.removeItem(STORAGE_KEYS.USER);
    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  },

  removeTemporaryAuthBody() {
    storageService.removeItem(STORAGE_KEYS.USER);
    storageService.removeItem(STORAGE_KEYS.VERIFICATION_URL);
  },
};
