import httpClient from '../httpClient';
import { User } from 'models/User';
import { AuthBody } from 'models/Auth';
import publicHttpClient from 'api/publicHttpClient';

const authPath = (routePath: TemplateStringsArray) => `auth/${routePath}`;

const refreshToken = (refreshToken: string) => {
  return publicHttpClient.post(`auth/refresh-token`, { refreshToken }, {
    isRefresh: true,
  } as any);
};

function signup(email: string, password: string) {
  return publicHttpClient.post<User>(authPath`signup`, { email, password });
}

function resendEmail(userId: string) {
  return publicHttpClient.post<User>(`auth/${userId}/verify-email/resend`);
}

function login(email: string, password: string) {
  return publicHttpClient.post<AuthBody>(authPath`login`, {
    email,
    password,
  });
}

function verifyEmail(emailToken: string) {
  return publicHttpClient.get<User>(`auth/verify-email/${emailToken}`);
}

function forgotPassword(email: string) {
  return publicHttpClient.post(authPath`forgot-password`, { email });
}

function resetPassword(password: string, token: string) {
  return publicHttpClient.post<User>(`auth/${token}/reset-password`, {
    password,
  });
}

function changePassword(oldPassword: string, newPassword: string) {
  return httpClient.post<User>(`/auth/change-password`, {
    oldPassword,
    newPassword,
  });
}

export default {
  refreshToken,
  signup,
  login,
  resendEmail,
  verifyEmail,
  forgotPassword,
  resetPassword,
  changePassword,
};
