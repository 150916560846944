import React, { useMemo } from 'react';
import classNames from 'classnames';
import Image from 'components/Image';
import { Yacht } from 'models/Yacht';
import SimpleCard from '../SimpleCard';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { useHistory } from 'react-router-dom';

import './SimpleYachtCard.styles.scss';
import './SimpleYachtCard.responsive.styles.scss';

type SimpleYachtInforamtion = Pick<
  Yacht,
  'id' | 'profileShots' | 'askingPrice' | 'overallLength' | 'vesselName'
>;

type SimpleYachtCardProps = {
  className?: string;
} & SimpleYachtInforamtion;

const SimpleYachtCard: React.FC<SimpleYachtCardProps> = (props) => {
  const {
    id: yachtId,
    className,
    profileShots,
    askingPrice,
    overallLength,
    vesselName,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const classes = classNames('yx-simple-yacht-card', className);
  const profileShot = useMemo(() => profileShots[0], [profileShots]);

  return (
    <SimpleCard
      className={classes}
      title={vesselName}
      onClick={() => history.push(`/my-yacht/${yachtId}`)}
    >
      <Image src={profileShot.url} alt={profileShot.name} />
      <p>
        <span>{`${t('askingPrice')}: `}</span>
        EUR {utils.getDotedNumber(askingPrice.toString())}
      </p>
      <p>
        <span>{`${t('loa')}: `}</span>
        {`${overallLength} ${t('meters')}`}
      </p>
    </SimpleCard>
  );
};

export default SimpleYachtCard;
