import { NavigationOption } from '../../../components/Navigation/Navigation.component';
import HomeIcon from '../../../icons/Home.icon';
import SearchIcon from '../../../icons/Search.icon';
import YachtIcon from '../../../icons/Yacht.icon';
import { CardTypes } from 'models/User';

export const DashboardNavigationOptions: NavigationOption[] = [
  {
    label: 'Home',
    to: '/home',
    icon: HomeIcon,
  },
  {
    label: 'My Searches',
    to: '/my-searches',
    icon: SearchIcon,
  },
  {
    label: 'My Yachts',
    to: '/my-yachts',
    icon: YachtIcon,
  },
];

export const CardTypeImageUrls: Record<CardTypes, string> = {
  mastercard: '/assets/images/cards/master-card.jpg',
  visa: '/assets/images/cards/visa.png',
};
