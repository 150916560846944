import React, { useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { colRoutes, getRoutes, stepperRoutes } from './routes';
import Routes from '../../../../components/Routes';
import './Match.styles.scss';
import './Match.styles.responsive.scss';
import MatchContext from './providers/Match/Match.context';
import { MatchesRoutes } from './models';

const MatchRouter: React.FC<RouteComponentProps> = (props) => {
  const { routeName, currentStep } = useContext(MatchContext);

  const routes = useMemo(
    () => getRoutes(routeName as MatchesRoutes),
    [routeName],
  );

  return (
    <div className="yx-match-router">
      <Routes routes={stepperRoutes} />
      {!(currentStep === 2) && <Routes routes={colRoutes} />}
      <div className="yx-match-router__page">
        <Routes routes={routes} />
      </div>
      {currentStep === 2 && <Routes routes={colRoutes} />}
    </div>
  );
};

export default MatchRouter;
