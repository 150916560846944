import socketService, { SocketService } from './socketService';
import io from 'socket.io-client';
import env from 'env';
import socketEventNames from './socketEventNames';
import credentialsService from 'services/credentialsService';

function connect(this: SocketService) {
  return new Promise((resolve, reject) => {
    this.socket = io.connect(`${env.SERVER_ENDPOINT}/users`);

    Object.values(socketEventNames).forEach((socketEvent) => {
      this.socket.on(socketEvent, (data: any) => {
        this.consumers[socketEvent]?.forEach((cb) => cb(data));
      });
    });

    this.socket.on('connect', () => {
      console.log('connect');
      this.sendEvent(socketEventNames.AUTH, credentialsService.token);
    });

    this.socket.on(socketEventNames.ONLINE_ACKNOWLEDGE, () => {
      this.sendEvent(socketEventNames.ONLINE_ACKNOWLEDGE_SUCCESS, null);
    });

    this.socket.on(socketEventNames.AUTH_SUCCESS, resolve);
    this.socket.on(socketEventNames.AUTH_FAIL, reject);

    this.socket.on('disconnect', () => {
      console.log('Client disconnected');
      reject({
        message: 'Not authorized to send messages',
      });
    });
  });
}

export default socketService(connect);
