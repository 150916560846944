import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="76"
    height="68"
    viewBox="-2 0 75 73"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="43" cy="30" r="30" fill="#F5F6FC" stroke="transparent" />
    <path
      d="M44.7917 34.5013H38.5417C37.9667 34.5013 37.5 34.0346 37.5 33.4596C37.5 32.8846 37.9667 32.418 38.5417 32.418H43.75V27.2096C43.75 26.6346 44.2167 26.168 44.7917 26.168C45.3667 26.168 45.8333 26.6346 45.8333 27.2096V33.4596C45.8333 34.0346 45.3667 34.5013 44.7917 34.5013Z"
      fill="#currentColor"
    />
    <path
      d="M25 72C11.2146 72 0 60.7854 0 47C0 33.2146 11.2146 22 25 22C32.9167 22 40.4354 25.8125 45.1104 32.1979C45.45 32.6625 45.35 33.3146 44.8854 33.6542C44.4208 33.9937 43.7667 33.8937 43.4292 33.4292C39.1458 27.5771 32.2542 24.0833 25 24.0833C12.3646 24.0833 2.08333 34.3646 2.08333 47C2.08333 59.6354 12.3646 69.9167 25 69.9167C37.6354 69.9167 47.9167 59.6354 47.9167 47C47.9167 45.925 47.8521 44.9521 47.7188 44.0229C47.6375 43.4542 48.0333 42.9271 48.6021 42.8438C49.1688 42.7625 49.6979 43.1562 49.7812 43.7271C49.9292 44.7542 50 45.825 50 47C50 60.7854 38.7854 72 25 72Z"
      fill="currentColor"
    />
    <path d="M25 59.5C31.9036 59.5 37.5 53.9036 37.5 47C37.5 40.0964 31.9036 34.5 25 34.5C18.0964 34.5 12.5 40.0964 12.5 47C12.5 53.9036 18.0964 59.5 25 59.5Z" />
    <path d="M25 39.5V47L30 49.5" />
  </svg>
);
