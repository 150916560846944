import { MatchesRoutes, MatchRouterType, MATCH_TYPE } from './models';
import ViewingArrangement from './pages/SellerPages/ViewingArrangement';
import ViewingRequest from './pages/SellerPages/ViewingRequest';
import ViewingConfirmation from './pages/SellerPages/ViewingConfirmation';
import ViewingRequestBuyer from './pages/BuyerPages/ViewingRequest';
import ViewingConfirmationBuyer from './pages/BuyerPages/ViewingConfirmation';
import CanceledMatch from './pages/CanceledMatch';
import ArrangeMeetingButton from './components/ArangeMeetingButton';
import PostponementRequested from './pages/PostponementRequested';
import MatchCollapsible from './pages/Collapsible';
import Stepper from './pages/Stepper';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';

export const colRoutes: Array<EnhancedRouteProps> = [
  {
    path: '/(sneak-peak|viewing-arrangement|viewing-confirmation|viewing-request)',
    component: MatchCollapsible,
  },
];

export const stepperRoutes: Array<EnhancedRouteProps> = [
  {
    path: '/(sneak-peak|viewing-arrangement|viewing-confirmation|viewing-request)',
    component: Stepper,
  },
];

export const sellerStepperRoutes = [
  {
    path: '/viewing-arrangement',
    labelId: 'viewingArrangement',
    component: ViewingArrangement,
  },
  {
    path: '/viewing-request',
    labelId: 'viewingRequest',
    component: ViewingRequest,
  },
  {
    path: '/viewing-confirmation',
    labelId: 'viewingConfirmation',
    component: ViewingConfirmation,
  },
] as Array<MatchRouterType>;

export const buyerStepperRoutes = [
  {
    path: '/sneak-peak',
    labelId: 'sneakPeak',
    component: () => null,
  },
  {
    path: '/viewing-request',
    labelId: 'viewingRequest',
    component: ViewingRequestBuyer,
  },
  {
    path: '/viewing-confirmation',
    labelId: 'viewingConfirmation',
    component: ViewingConfirmationBuyer,
  },
] as Array<MatchRouterType>;

export const buyerCollapsibleRoutes = [
  {
    path: '/sneak-peak',
    labelId: 'sneakPeak',
    component: ArrangeMeetingButton,
  },
  {
    path: '/viewing-request',
    labelId: 'viewingRequest',
    component: () => null,
  },
  {
    path: '/viewing-confirmation',
    labelId: 'viewingConfirmation',
    component: () => null,
  },
] as Array<MatchRouterType>;

export const commonRoutes = [
  {
    path: '/postponement-requested',
    component: PostponementRequested,
  },
  {
    path: '/cancelled-match',
    component: CanceledMatch,
  },
] as Array<MatchRouterType>;

export const getStepperRoutes = (routeType: MatchesRoutes) => {
  if (routeType === MATCH_TYPE.BUYER) {
    return buyerStepperRoutes;
  } else {
    return sellerStepperRoutes;
  }
};

const sellerRoutes = [...sellerStepperRoutes, ...commonRoutes];
const buyerRoutes = [...buyerStepperRoutes, ...commonRoutes];

export const getRoutes = (routeType: MatchesRoutes) => {
  if (routeType === MATCH_TYPE.SELLER) {
    return sellerRoutes;
  } else {
    return buyerRoutes;
  }
};
