import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M12.8688 8.10005L8.3875 12.5163C8.27141 12.6308 8.13355 12.7217 7.9818 12.7837C7.83005 12.8457 7.66739 12.8776 7.50313 12.8776C7.33886 12.8776 7.1762 12.8457 7.02445 12.7837C6.8727 12.7217 6.73484 12.6308 6.61875 12.5163L1.25 7.23159V1.07227H7.5L12.8688 6.36312C13.1016 6.59393 13.2322 6.90615 13.2322 7.23159C13.2322 7.55703 13.1016 7.86925 12.8688 8.10005V8.10005Z"
      stroke="#0D2CA7"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
