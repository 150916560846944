import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import XIcon from '../../../../icons/X.icon';
import CalendarContext from '../../provider/Calendar.context';
import { DateTime } from 'luxon';
import './SelectedDates.styles.scss';
import './SelectedDates.styles.responsive.scss';
import { useTranslation } from 'react-i18next';

type SelectedDateCardProps = {
  date: DateTime;
  onXClick: () => void;
};

export const SelectedDateCard: React.FC<SelectedDateCardProps> = ({
  date,
  onXClick,
}) => {
  const [hours, minutes] = date.toISOTime().split(':');
  const toHours = Number(hours) + 1;

  return (
    <div className="selected-date-card">
      <div className="selected-date-card__date-container">
        <span className="selected-date-card__date">{date.toISODate()}</span>
        <span className="selected-date-card__time">{`${hours}:${minutes}-${toHours}:${minutes}`}</span>
      </div>
      <XIcon onClick={onXClick} />
    </div>
  );
};

type SelectedDatesProps = {
  className?: string;
};

const SelectedDates: React.FC<SelectedDatesProps> = (props) => {
  const { className } = props;
  const { dates, removeDate } = useContext(CalendarContext);
  const { t } = useTranslation();

  const classes = classNames('yx-selected-dates', className);

  const renderSelectedDates = useMemo(
    () =>
      dates.map((date, index) => (
        <li key={date.toISO() + index}>
          <SelectedDateCard date={date} onXClick={() => removeDate(index)} />
        </li>
      )),
    [dates, removeDate],
  );

  const emptyState = useMemo(
    () => (
      <p className="yx-selected-dates__empty-states">
        {t('selectedDates-emptyState')}
      </p>
    ),
    [t],
  );

  return (
    <div className={classes}>
      <ul>{dates.length ? renderSelectedDates : emptyState}</ul>
    </div>
  );
};

export default SelectedDates;
