import { AxiosError, AxiosResponse } from 'axios';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';
import { FormApi } from 'final-form';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import set from 'lodash.set';
import get from 'lodash.get';
import useIsEditRoute from './useIsEditRoute';

type FormPatchingProps<
  PatchData,
  PatchObj,
  Name extends string,
  StepName extends string,
> = {
  readonly name: Name;
  patchCondition?: boolean;
  patchOnEdit?: boolean;
  initRequest: () => Promise<
    AxiosResponse<{
      [key in Name]: PatchObj;
    }>
  >;
  patchRequest: (
    step: StepName,
    patchValues: Record<string, any>,
  ) => Promise<AxiosResponse<PatchData>>;
  setLoading?: (loading?: boolean) => void;
  onSubmit: (values: Record<string, any>) => void;
  onForbiddenRequest?: () => void;
};

const beFields = [
  'id',
  '_id',
  '_v',
  'createdAt',
  'updatedAt',
  'isApproved',
  'isSubmitted',
  'status',
  'isPublished',
];

export default function useFormPatching<
  PatchData extends Record<string, any>,
  PatchObj extends Record<string, any>,
  Name extends string,
  StepName extends string,
>({
  name,
  patchCondition = true,
  patchOnEdit = true,
  onSubmit,
  setLoading,
  initRequest,
  patchRequest,
  onForbiddenRequest,
}: FormPatchingProps<PatchData, PatchObj, Name, StepName>) {
  const { popServerError } = useContext(PopNotificationsContext);
  const initialized = useRef(false);
  const {
    currentStep,
    steps,
    steps: { length: stepsNumber },
    nextStep,
  } = useContext(StepsContext);

  const activePatchStep = useMemo(
    () => steps[currentStep],
    [steps, currentStep],
  );

  const isEdit = useIsEditRoute();

  const [initialData, setInitialData] = useState<Partial<PatchObj>>({});
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const mNextStep = useCallback(
    (values: Record<string, any>) => {
      setInitialData((old) => ({ ...old, ...values }));
      nextStep();
    },
    [nextStep],
  );

  useEffect(() => {
    if (!initRequest || initialized.current) return;

    const getPatchedData = async () => {
      try {
        setLoadingInitialData(true);
        const { data } = await initRequest();
        setInitialData(data[name]);
      } catch (er) {
        popServerError(er);
        if ((er as AxiosError).response?.status === 403) {
          onForbiddenRequest?.();
        }
      } finally {
        setLoadingInitialData(false);
        initialized.current = true;
      }
    };

    getPatchedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, popServerError, initRequest]);

  const patchData = useCallback(
    async (values: Record<string, any>) => {
      const mappedValues = activePatchStep?.patchMap
        ? activePatchStep.patchMap(values)
        : values;
      await patchRequest(activePatchStep.stepName as StepName, mappedValues);
    },
    [activePatchStep, patchRequest],
  );

  const onStepSubmit = useCallback(
    async (values: Record<string, any>, form: FormApi<any>) => {
      const stepFieldKeys = Object.keys(form.getState().modified);

      const valuesFromThisStep = {};
      stepFieldKeys.forEach((key) =>
        set(valuesFromThisStep, key, get(values, key)),
      );

      if (activePatchStep?.skipPatch) {
        if (currentStep < stepsNumber - 1) {
          mNextStep(valuesFromThisStep);
        } else {
          onSubmit(values);
        }
        return;
      }

      if (
        !window.finishSubmit &&
        currentStep < stepsNumber - 1 &&
        currentStep >= 0 &&
        patchCondition
      ) {
        try {
          setLoading(true);

          if (!isEdit || patchOnEdit) await patchData(valuesFromThisStep);

          mNextStep(valuesFromThisStep);
        } catch (er) {
          popServerError(er);
        } finally {
          setLoading(false);
        }
        return;
      }

      const filteredValues = JSON.parse(JSON.stringify(values));

      beFields.forEach((key) => delete filteredValues[key]);
      // persona submit code
      onSubmit(filteredValues);
    },
    [
      activePatchStep?.skipPatch,
      currentStep,
      stepsNumber,
      patchCondition,
      patchOnEdit,
      onSubmit,
      mNextStep,
      setLoading,
      isEdit,
      patchData,
      popServerError,
    ],
  );
  return {
    initialData,
    loadingInitialData,
    onStepSubmit,
  } as const;
}
