import React from 'react';
import classNames from 'classnames';
import { transformTitle } from '../../utils';
import './AgreementChild.styles.scss';

export type AgreementChildProps = {
  className?: string;
  title: string;
};

const AgreementChild: React.FC<AgreementChildProps> = (props) => {
  const { children, className, title } = props;

  const classes = classNames(
    'yx-agreement-child',
    'agreement-child-element',
    className,
  );

  return (
    <section className={classes} id={transformTitle(title)}>
      <h2>{title}</h2>
      <p className="yx-agreement-child__children">{children}</p>
    </section>
  );
};

export default AgreementChild;
