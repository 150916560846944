import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import './FormWrapper.styles.scss';

export type FormWrapperProps = {
  className?: string;
  onSubmit?: () => void;
};

const FormWrapper: React.FC<FormWrapperProps> = (props) => {
  const { children, className, onSubmit } = props;

  const classes = classNames('yx-form-wrapper', className);

  const { submit } = useForm();

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      await onSubmit?.();
      submit();

      setTimeout(() => {
        const errorEl = document.querySelector('[class*="error"]');
        if (errorEl) {
          errorEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 0);
    },
    [onSubmit, submit],
  );

  return (
    <form className={classes} onSubmit={onFormSubmit}>
      {children}
    </form>
  );
};

export default FormWrapper;
