import React from 'react';
import classNames from 'classnames';
import './ShowLocation.styles.scss';
import './ShowLocation.styles.responsive.scss';
import { googleMapsAPIKey, MARKER_URL } from 'utils';
import { XOR } from 'models/TypeOperations';

type WithLatLong = {
  lat: number;
  long: number;
};

type WithPlace = {
  place: string;
};

type ShowLocationProps = {
  className?: string;
  zoom?: number;
  width?: number;
  height?: number;
  title: string;
  description: string;
  actionText: string;
  onActionClick: () => void;
} & XOR<WithLatLong, WithPlace>;

const MAP = 'map';

const ShowLocation: React.FC<ShowLocationProps> = (props) => {
  const {
    className,
    lat,
    long,
    place,
    zoom = 10,
    width = 800,
    title,
    description,
    actionText,
    onActionClick,
  } = props;

  const classes = classNames('yx-show-location', className);

  const location = place || `${lat},${long}`;

  const height = width * 0.45;

  return (
    <div className={classes}>
      <header>
        <p>{title}</p>
        <p>{description}</p>
      </header>
      <main>
        <div className="image-container">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?
                  center=${location}
                  &zoom=${zoom}
                  &size=${width}x${height}
                  &markers=icon:${window.origin}${MARKER_URL}%7C${location}
                  &maptype=roadmap
                  &key=${googleMapsAPIKey}`}
            alt={MAP}
          />
        </div>

        <p role="button" onClick={onActionClick}>
          {actionText}
        </p>
      </main>
    </div>
  );
};

export default ShowLocation;
