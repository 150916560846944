import React, { useMemo } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import utils from 'utils';
import { Overlay } from 'ncoded-component-library';

import './Navigation.styles.scss';
import './Navigation.responsive.styles.scss';

export type NavigationOption = {
  label: string;
  to: string;
  icon?: React.ReactNode;
  className?: string;
};

export type NavigationProps = {
  className?: string;
  options: NavigationOption[];
  isOpen?: boolean;
  setIsOpen?: (open: boolean) => void;
};

const Navigation: React.FC<NavigationProps> = (props) => {
  const { className, options, isOpen, setIsOpen } = props;
  const classes = classNames(
    'yx-navigation',
    { 'yx-navigation--open': isOpen },
    className,
  );

  const renderOptions = useMemo(
    () =>
      options.map(({ label, to, className: optionClassName, icon }) => (
        <li key={to + label}>
          <NavLink
            to={to}
            className={optionClassName}
            activeClassName="activeLink"
            isActive={(match) => match?.isExact}
            onClick={() => setIsOpen(false)}
          >
            {utils.renderIcon(icon)}
            <span>{label}</span>
          </NavLink>
        </li>
      )),
    [options, setIsOpen],
  );

  return (
    <>
      <nav className={classes}>
        <ul>{renderOptions}</ul>
      </nav>
      {isOpen && (
        <Overlay
          className="nav-overlay"
          background="black"
          noContent
          open
          overlayCloses
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Navigation;
