import { OptionalDocumentsKeys, Yacht } from 'models/Yacht';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import YachtContext from './Yacht.context';
import useQueryParams from 'hooks/useQueryParams';
import { useParams } from 'react-router-dom';
import api from 'api';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import { FileType } from 'models/File';
import { useTranslation } from 'react-i18next';

export type YachtProps = {
  yachtId?: string;
  isLocked?: boolean;
  token?: string;
  className?: string;
};

const NAVIGATION_TABS = ['details', 'history', 'documents'] as const;
type MyYachtNavigationKeys = typeof NAVIGATION_TABS[number];

const QUERY_YACHT_TAB: Record<MyYachtNavigationKeys, number> = {
  details: 0,
  history: 1,
  documents: 2,
};

const YachtProvider: React.FC<YachtProps> = (props) => {
  const { children, yachtId: pYachtId, token } = props;

  const { t } = useTranslation();
  const { yachtId: rYachtId } = useParams<{ yachtId: string }>();
  const { popSuccess, popServerError } = useContext(PopNotificationsContext);

  const [yacht, setYacht] = useState<Yacht>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    params: { section },
    setQueryParam,
  } = useQueryParams<{
    section: MyYachtNavigationKeys;
  }>();

  const yachtId = useMemo(
    () => (pYachtId ? pYachtId : rYachtId),
    [pYachtId, rYachtId],
  );

  const setActiveTabSource = useCallback(
    (tabIndex: number) => {
      setQueryParam('section', NAVIGATION_TABS[tabIndex]);
    },
    [setQueryParam],
  );

  const activeTabIndex = useMemo(
    () =>
      NAVIGATION_TABS.includes(section)
        ? QUERY_YACHT_TAB[section]
        : QUERY_YACHT_TAB['details'],
    [section],
  );

  const uploadYachtDocument = useCallback(
    async (file: FileType) => {
      const fileName = file.key.split('/')[0];

      popSuccess(t('successfullyUploadedYachtDocument'));
      setYacht((oldData) => {
        return {
          ...oldData,
          [fileName]: file,
        };
      });
    },
    [popSuccess, t],
  );

  const deleteYachtDocument = useCallback(
    async (file: FileType) => {
      try {
        setLoading(true);
        const fileName = file.key.split('/')[0];

        await api.yacht.deleteYachtFile(yacht.id, fileName, file.id);
        popSuccess(t('successfullyDeletedYachtDocument'));

        setYacht((oldData) => {
          const {
            [fileName as OptionalDocumentsKeys]: extractedVariable,
            ...restData
          } = oldData;

          return restData;
        });
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [t, popServerError, popSuccess, yacht],
  );

  useEffect(() => {
    const loadYacht = async () => {
      try {
        setLoading(true);

        const {
          data: { yacht },
        } = await api.yacht.getYachtById(yachtId, token);

        setYacht(yacht);
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    };

    loadYacht();
  }, [popServerError, token, yachtId]);

  return (
    <YachtContext.Provider
      value={{
        yacht,
        loading,
        activeTabIndex,
        setYacht,
        setActiveTabSource,
        deleteYachtDocument,
        uploadYachtDocument,
      }}
    >
      {children}
    </YachtContext.Provider>
  );
};

export default YachtProvider;
