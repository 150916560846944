import { Notification } from 'models/Notification';
import { createContext } from 'react';
import utils from 'utils';

type NotificationContextProps = {
  loading: boolean;
  loadingNotifications: boolean;
  numOfUnreadNotifications: number;
  notifications: Array<Notification>;
  setNumOfUnreadNotifications: (value: number) => void;
  showNotification: (notification: Notification) => void;
  readNotification: (notifId: string) => void;
  readAllNotifications: () => void;
  setLoading: (value: boolean) => void;
  setNotifications: (values: Array<Notification>) => void;
  onNotificationScroll: () => void;
};

export default createContext<Partial<NotificationContextProps>>({
  loading: false,
  numOfUnreadNotifications: 0,
  loadingNotifications: false,
  notifications: [],
  setNumOfUnreadNotifications: utils.noop,
  showNotification: utils.noop,
  readNotification: utils.noop,
  readAllNotifications: utils.noop,
  setLoading: utils.noop,
  setNotifications: utils.noop,
  onNotificationScroll: utils.noop,
});
