import { createContext } from 'react';
import utils from 'utils';

type GeneralContextProps = {
  from: string;
  logout: () => void;
};

export default createContext<Partial<GeneralContextProps>>({
  from: '',
  logout: utils.noop,
});
