import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import StepWrapper from '../../../../../components/StepWrapper';
import { Field } from 'react-final-form';
import DropzoneField from '../../../../../components/Fields/DropzoneField';
import validators from 'validators';
import { documentsOptions } from '../../services/options';
import AddDocumentFields from 'components/Fields/AddDocumentFields';
import useDropzoneRequest from '../../hooks/useDropzoneRequest';
import credentialsService from 'services/credentialsService';

import './DocumentationStepBroker.styles.scss';

type DocumentationStepBrokerProps = {
  className?: string;
};

const DocumentationStepBroker: React.FC<DocumentationStepBrokerProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-ducumentation-step', className);
  const { uploadFile } = useDropzoneRequest(credentialsService.draftYachtId);

  const validateObject = useMemo(
    () => ({
      requiredArray: validators.requiredArray(
        t('documentation-centralAgencyAgreement-validation'),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('documentation-title')}
      description={t('documentation-subTitle')}
      className={classes}
    >
      <Field
        uploadRequest={uploadFile}
        maxFiles={1}
        name="centralAgencyAgreement"
        label={t('centralAgencyAgreement')}
        component={DropzoneField}
        validate={validateObject.requiredArray}
      />

      <AddDocumentFields
        name="optionalDocuments"
        className="yx-ducumentation-step__array-section"
        options={documentsOptions}
        buttonLabel={t('addAdditionalDocuments')}
      />
    </StepWrapper>
  );
};

export default DocumentationStepBroker;
