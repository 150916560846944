import React, { useCallback } from 'react';
import classNames from 'classnames';
import './ChoseAppointmentTime.styles.scss';
import './ChoseAppointmentTime.styles.responsive.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Button } from 'ncoded-component-library';
import { XOR } from '../../models/TypeOperations';
import { googleMapsAPIKey, MARKER_URL } from '../../utils';
import { DateTime } from 'luxon';

type WithLatLong = {
  lat: number;
  long: number;
};

type WithPlace = {
  place: string;
};

type ChoseAppointmentTimeProps = {
  className?: string;
  dates: Date[];
  onChange: React.Dispatch<React.SetStateAction<Date>>;
  selected?: Date;
  location: string;
  onSubmit: (date: Date) => void;
} & XOR<WithLatLong, WithPlace>;

const ChoseAppointmentTime: React.FC<ChoseAppointmentTimeProps> = (props) => {
  const {
    className,
    dates = [],
    onChange,
    selected,
    place,
    lat,
    long,
    location,
    onSubmit,
  } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-chose-appointment-time', className);

  const mapLocation = place || `${lat},${long}`;
  const width = 300,
    height = 300;
  const zoom = 10;

  const formatedDate = useCallback(
    (date: DateTime) => (
      <p className="appointment-date-card__card">
        <span>{date.toFormat('EEEE')}</span>
        <span>{date.toFormat('dd.MM.yyyy')}</span>
        <span>{date.toFormat('hh:mm')}</span>
      </p>
    ),
    [],
  );

  const renderAppointments = useMemo(
    () =>
      dates.map((date) => (
        <li key={date.toISOString()}>
          <div className="appointment-date-card">
            {date && formatedDate(DateTime.fromJSDate(date))}
            <Button
              className={classNames('appointment-date-card__button', {
                selected: date === selected,
              })}
              onClick={() => onChange(date)}
              styleType="secondary"
            >
              {date === selected ? t('selected') : t('select')}
            </Button>
          </div>
        </li>
      )),
    [dates, formatedDate, onChange, selected, t],
  );

  return (
    <div className={classes}>
      <header>
        <p>{t('choseAppointmentTime-title')}</p>
        <p>{t('choseAppointmentTime-description')}</p>
      </header>
      <main>
        <div className="appointment-list">
          <p>{t('choseAppointmentTime-listTitle')}</p>
          <ul>{renderAppointments}</ul>
        </div>
        <div className="map-container">
          <div className="image-container">
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?
                    center=${mapLocation}
                    &zoom=${zoom}
                    &size=${width}x${height}
                    &markers=icon:${window.origin}${MARKER_URL}%7C${mapLocation}
                    &maptype=roadmap
                    &key=${googleMapsAPIKey}`}
              alt="map"
            />
          </div>
        </div>
      </main>
      <footer>
        <div className="footer-menu">
          {selected && (
            <div className="footer-menu__column">
              <p>{t('dateAndTime')}</p>
              {selected && formatedDate(DateTime.fromJSDate(selected))}
            </div>
          )}
          <div className="footer-menu__column">
            <p>{t('location')}</p>
            <p>{location}</p>
          </div>
        </div>
        <Button
          disabled={!selected}
          onClick={() => selected && onSubmit(selected)}
        >
          {t('confirm')}
        </Button>
      </footer>
    </div>
  );
};

export default ChoseAppointmentTime;
