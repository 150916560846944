import bus from 'modules/bus';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import React, { useCallback, useRef, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import refreshTokenService from 'services/refreshTokenService';
import yachtSocketService from 'services/socketService/yachtSocketService';
import GeneralContext from './General.context';

type GeneralProps = {};

const General: React.FC<GeneralProps> = (props) => {
  const { children } = props;

  const { popInfo } = useContext(PopNotificationsContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const from = useRef('');

  const logout = useCallback(() => {
    localStorage.clear();
    yachtSocketService.disconnect();
    history.push('/login');
  }, [history]);

  useEffect(() => {
    const customLogout = () => {
      popInfo({ content: t('session-expired'), type: 'Error' });
      refreshTokenService.reinit();
      logout();
    };

    bus.addEventListener('LOGOUT_SIGNAL', customLogout);
    return () => {
      bus.removeEventListener('LOGOUT_SIGNAL', customLogout);
    };
  }, [t, popInfo, logout]);

  useEffect(() => {
    if (!(location.state as any)?.skipFrom) {
      from.current = `${location.pathname}${location.search}`;
    }
  }, [location]);

  return (
    <GeneralContext.Provider value={{ from: from.current, logout }}>
      {children}
    </GeneralContext.Provider>
  );
};

export default General;
