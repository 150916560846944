import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { Search } from 'models/Search';
import confirm from 'modules/confirm';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchContext from './Search.context';

type SearchProps = {
  children?: React.ReactNode;
};

const SearchProvider: React.FC<SearchProps> = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const { popServerError, popSuccess } = useContext(PopNotificationsContext);

  const {
    items: searches,
    setItems: setSearches,
    loading: loadingSearches,
    onContainerScrolled: onSearchScroll,
  } = useInfinitePagination<Search>({
    makeRequest: (currentPage: number) => {
      return api.search
        .getSearches({
          $page: currentPage.toString(),
          $limit: '15',
          $sort: '-createdAt',
        })
        .then(({ data }) => data);
    },
  });

  const deleteSearch = useCallback(
    async (search: Search) => {
      try {
        if (
          !(await confirm({
            title: t('deleteSearchConfirm', { name: search.searchName }),
            type: 'delete',
            content: t('successfullyDeletedSearch-info'),
          }))
        )
          return;
        setLoading(true);
        await api.search.deleteSearch(search.id);
        popSuccess(t('successfullyDeletedSearch'));

        setSearches((oldSearches) =>
          oldSearches.filter((searchData) => searchData.id !== search.id),
        );
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [popServerError, popSuccess, setSearches, t],
  );

  return (
    <SearchContext.Provider
      value={{
        loading,
        loadingSearches,
        searches,
        deleteSearch,
        setSearches,
        onSearchScroll,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
