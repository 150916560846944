import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import { MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER } from 'models/Search';

export const AvailableVesselForCommercilaCharterFields = [
  {
    value: MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER.YES,
    label: 'yes',
  },
  {
    value: MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER.NO,
    label: 'no',
  },
  {
    value: MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER.I_DONT_CARE,
    label: 'iDontCare',
  },
] as Array<RadioFieldsType>;
