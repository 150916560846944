import React, { useCallback, useContext, useState } from 'react';
import InputField from 'components/Fields/InputField';
import { Button, Loader } from 'ncoded-component-library';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useValidation from 'hooks/useValidation';
import useQueryParams from 'hooks/useQueryParams';

import './ResetPasswordPage.styles.scss';
import api from 'api';
import { useHistory } from 'react-router-dom';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

type ResetPasswordFormType = {
  newPassword: string;
  confirmPassword: string;
};

const ResetPasswordPage: React.FC = () => {
  const { params } = useQueryParams<{ token: string }>();
  const history = useHistory();

  const { t } = useTranslation();

  const { validations } = useValidation({ passwordFieldName: 'newPassword' });

  const [loading, setLoading] = useState<boolean>(false);
  const { popInfo, popServerError } = useContext(PopNotificationsContext);

  const onResetPasswordSubmit = useCallback(
    async (values: ResetPasswordFormType) => {
      try {
        if (!params?.token) return;

        setLoading(true);

        await api.auth.resetPassword(values.newPassword, params.token);

        popInfo({ content: t('successfulyResetPassword') });

        history.push('/login');
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [history, params, popInfo, popServerError, t],
  );

  const handleValidation = useCallback(
    async (values: ResetPasswordFormType) => {
      let errors = {} as { matchError: string };
      if (values.newPassword !== values.confirmPassword)
        errors.matchError = t('passwordValidation-mustMatch');
      return errors;
    },
    [t],
  );

  return (
    <Form
      onSubmit={onResetPasswordSubmit}
      validate={handleValidation}
      render={({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="yx-reset-password-page">
          {loading && <Loader />}
          <div className="yx-reset-password-page__form">
            <h2>{t('resetPassword')}</h2>
            <p>{t('createNewPassword')}</p>
            <Field
              name="newPassword"
              type="password"
              component={InputField}
              placeholder={t('newPassword')}
              validate={validations.password}
            />
            <Field
              name="confirmPassword"
              type="password"
              component={InputField}
              placeholder={t('confirmNewPassword')}
              validate={validations.confirmPassword}
            />
            <Button type="submit">{t('reset')}</Button>
          </div>
        </form>
      )}
    />
  );
};

export default ResetPasswordPage;
