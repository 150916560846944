import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import { LOCATION_SEARCH } from 'models/Search';

export const locationFields = [
  {
    value: LOCATION_SEARCH.MONACO,
    label: 'Monaco',
    imagePath: '/assets/images/monaco.jpeg',
  },
  {
    value: LOCATION_SEARCH.ITALY,
    label: 'Italy',
    imagePath: '/assets/images/italy.png',
  },
  {
    value: LOCATION_SEARCH.TURKEY,
    label: 'Turkey',
    imagePath: '/assets/images/turkey.png',
  },
  {
    value: LOCATION_SEARCH.GREECE,
    label: 'Greece',
    imagePath: '/assets/images/greece.png',
  },
  {
    value: LOCATION_SEARCH.ADRIATIC,
    label: 'Adriatic',
    imagePath: '/assets/images/adriatic.png',
  },
  {
    value: LOCATION_SEARCH.FRANCE,
    label: 'France',
    imagePath: '/assets/images/france.png',
  },
  {
    value: LOCATION_SEARCH.SPAIN,
    label: 'Spain',
    imagePath: '/assets/images/spain.png',
  },
] as Array<RadioFieldsType>;
