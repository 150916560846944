import { User } from 'models/User';
import { createContext } from 'react';

type CurrentUserContextProps = {
  currentUser: User & { imageUrl?: string };
  changePassword: (value: { oldPassword: string; newPassword: string }) => void;
  patchUser: (user: User) => void;
  uploadProfileImage: (file: File) => void;
  deleteProfileImage: () => void;
};

export default createContext<Partial<CurrentUserContextProps>>({
  currentUser: null,
  changePassword: null,
  patchUser: null,
  uploadProfileImage: null,
  deleteProfileImage: null,
});
