import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import FormWrapper from 'components/FormWrapper';
import { FormWrapperProps } from 'components/FormWrapper/FormWrapper.component';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { Yacht } from 'models/Yacht';
import useIsEditRoute from 'hooks/useIsEditRoute';
import { ReactNode } from 'react-router/node_modules/@types/react';
import { RoutingStep } from 'router/subrouters/SignUp/models/Routes';
import useCheckInRoute from 'hooks/useCheckInRoute';

import './StepWrapper.styles.scss';
import './StepWrapper.responsive.styles.scss';

declare global {
  interface Window {
    finishSubmit?: boolean;
    saveDraft?: boolean;
  }
}

type StepWrapperProps = {
  className?: string;
  title?: string;
  description?: string;
  renderActions?: boolean;
  buttonTitle?: string;
  additionalActions?: ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  onFinish?: () => void;
} & FormWrapperProps;

const StepWrapper: React.FC<StepWrapperProps> = (props) => {
  const {
    currentStep,
    useDraftButtons,
    steps,
    blockOtherStepsWhenCurrentIsInvalid,
    goBack,
    setSteps,
    setCompletedSteps,
  } = useContext(StepsContext);

  const {
    children,
    className,
    title,
    description,
    buttonTitle,
    renderActions = true,
    additionalActions,
    onCancel = goBack,
    onSubmit,
    ...rest
  } = props;

  const { valid, modified } = useFormState<Yacht>();

  const classes = classNames('yx-step-wrapper', className);

  const { t } = useTranslation();

  useEffectSkipFirst(() => {
    if (blockOtherStepsWhenCurrentIsInvalid) {
      if (Object.values(modified).some(Boolean)) setCompletedSteps(currentStep);
    }

    setSteps((oldSteps) =>
      oldSteps.map((step: RoutingStep) => {
        if (step.path === steps[currentStep]?.path) {
          return {
            ...step,
            isStepValid: valid,
          } as RoutingStep;
        } else return step;
      }),
    );
  }, [blockOtherStepsWhenCurrentIsInvalid, valid, modified]);

  const isLastStep = useMemo(
    () => currentStep === steps.length - 1,
    [currentStep, steps],
  );

  const buttonText = useMemo(
    () => (isLastStep ? t('Create') : buttonTitle ? buttonTitle : t('Next')),
    [t, isLastStep, buttonTitle],
  );

  const isEdit = useIsEditRoute();
  const isDraft = useCheckInRoute('draft');

  const actions = useMemo(
    () => (
      <>
        <div className="yx-step-wrapper__actions">
          <Button variant="link" onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
          <Button
            className="submit"
            type={!onSubmit ? 'submit' : 'button'}
            variant={isEdit && useDraftButtons ? 'link' : 'solid'}
            onClick={() => onSubmit?.()}
          >
            {buttonText}
          </Button>
          {isEdit && useDraftButtons && (
            <div className="yx-step-wrapper__publish-actions">
              <Button
                styleType="secondary"
                type="submit"
                onClick={() => {
                  window.finishSubmit = true;
                  window.saveDraft = true;
                }}
              >
                {isDraft ? t('save_draft') : t('save')}
              </Button>
              {isDraft && (
                <Button
                  type="submit"
                  onClick={() => {
                    window.finishSubmit = true;
                  }}
                >
                  {t('publish')}
                </Button>
              )}
            </div>
          )}
        </div>
      </>
    ),
    [onCancel, t, onSubmit, isEdit, useDraftButtons, buttonText, isDraft],
  );

  return (
    <FormWrapper className={classes} {...rest}>
      <div className="yx-step-wrapper__heading">
        {title && <h1>{title}</h1>}
        {description && <h2>{description}</h2>}
      </div>
      <div className="yx-step-wrapper__children">{children}</div>
      {renderActions && actions}
    </FormWrapper>
  );
};

export default StepWrapper;
