import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import validators from 'validators';
import { composeValidators } from 'validators/general';

type Validation = {
  passwordFieldName?: string;
};
export default function useValidation(conf?: Validation) {
  const { passwordFieldName = 'password' } = conf || {};
  const { t } = useTranslation();

  const emailValidatonObject = useMemo(
    () => ({
      email: validators.emailValidation(
        t('emailValidation-pleaseEnterValidEmailAddress'),
      ),
      letter: validators.mustContainerLetter(
        t('emailValidation-pleaseFillTheEmail'),
      ),
    }),
    [t],
  );

  const passwordValidationObject = useMemo(
    () => ({
      length: validators.minLength(t('passwordValidation-minLength'), 6),
      letter: validators.mustContainerLetter(
        t('passwordValidation-mustContainLetter'),
      ),
      number: validators.mustContainerNumber(
        t('passwordValidation-mustContainNumber'),
      ),
      char: validators.mustContainerSpecialChar(
        t('passwordValidation-mustContainChar'),
      ),
      passwordsMatch: validators.equalValues(
        t('passwordValidation-mustMatch'),
        passwordFieldName,
      ),
    }),
    [t, passwordFieldName],
  );

  const validations = useMemo(
    () => ({
      email: composeValidators(
        emailValidatonObject.letter,
        emailValidatonObject.email,
      ),
      password: composeValidators(
        passwordValidationObject.letter,
        passwordValidationObject.length,
        passwordValidationObject.number,
        passwordValidationObject.char,
      ),
      confirmPassword: composeValidators(
        passwordValidationObject.letter,
        passwordValidationObject.length,
        passwordValidationObject.number,
        passwordValidationObject.char,
        passwordValidationObject.passwordsMatch,
      ),
    }),
    [emailValidatonObject, passwordValidationObject],
  );

  return {
    validations,
  };
}
