import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './ChoseLocation.styles.scss';
import { Button, Input } from 'ncoded-component-library';
import {
  useAutocomplete,
  useGoogleMap,
} from '@ubilabs/google-maps-react-hooks';
import useCallbackRef from 'hooks/useCallbackRef';
import { MARKER_URL } from 'utils';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

export type ChooseLocationComponentProps = {
  className?: string;
  onChange?: (
    location: string,
    locationCoordinates: { lat: number; lng: number },
  ) => void;
  onSubmit?: () => void;
  buttonDisabled?: boolean;
};

type ChooseLocationProviderProps = {
  mapRef: React.LegacyRef<HTMLDivElement>;
};

const ChoseLocation: React.FC<
  ChooseLocationProviderProps & ChooseLocationComponentProps
> = (props) => {
  const {
    className,
    mapRef,
    onChange,
    buttonDisabled = false,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const [input, inputRef] = useCallbackRef(null);
  const [inputValue, setInputValue] = useState('');
  const [coordinates, setCoordinates] =
    useState<{ lat: number; lng: number }>();
  const { map } = useGoogleMap();
  const mapReference = useRef<google.maps.Map>();
  const { popInfo } = useContext(PopNotificationsContext);

  const classes = classNames('yx-chose-location', className);

  useEffect(() => {
    mapReference.current = map;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        map &&
          map.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      popInfo({
        type: 'Info',
        content: t('geolocationNotAvailable'),
      });
    }
  }, [map, popInfo, t]);

  const onPlaceChanged = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const { current: map } = mapReference;
      if (place) {
        setInputValue(place.formatted_address || place.name);
        setCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });

        map && map.setCenter(place.geometry.location);
      }
      input && input.focus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useAutocomplete({
    inputField: inputRef && input,
    onPlaceChanged,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(
    () => coordinates && inputValue && onChange(inputValue, coordinates),
    [coordinates, inputValue, onChange],
  );

  useEffect(() => {
    if (!window.google) return;
    const markerCoordinates = new google.maps.LatLng(coordinates);

    const image = {
      url: MARKER_URL,
      size: new google.maps.Size(91, 91),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(45.5, 45.5),
    };

    const marker = new window.google.maps.Marker({
      position: markerCoordinates,
      icon: image,
      map: map,
    });

    return () => marker.setMap(null);
  }, [map, coordinates]);

  return (
    <div className={classes}>
      <div className="yx-chose-location__content">
        <div className="yx-chose-location__content__input-container">
          <label>{t('chooseLocationLabel')}</label>
          <p>{t('chooseLocationDescription')}</p>
          <Input
            placeholder={t('enterLocation')}
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <div className="yx-chose-location__content__map" ref={mapRef} />
      </div>
      <div className="yx-chose-location__bottom-container">
        <div className="yx-chose-location__column">
          {inputValue && (
            <>
              <label>{t('location')}</label>
              <p>{inputValue}</p>
            </>
          )}
        </div>
        <Button disabled={buttonDisabled} onClick={() => onSubmit()}>
          {t('send')}
        </Button>
      </div>
    </div>
  );
};

export default ChoseLocation;
