import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import { YACHT_SEARCH_STATUS, YACHT_SEARCH_TYPE } from 'models/Search';

export const yachtStatusFields = [
  {
    value: YACHT_SEARCH_STATUS.NEW,
    label: 'New',
  },
  {
    value: YACHT_SEARCH_STATUS.USED,
    label: 'Used',
  },
  {
    value: YACHT_SEARCH_STATUS.I_DONT_CARE,
    label: 'Both',
  },
] as Array<RadioFieldsType>;

export const yachtTypeFields = [
  {
    value: YACHT_SEARCH_TYPE.MOTOR,
    label: 'Motor',
  },
  {
    value: YACHT_SEARCH_TYPE.SAILING,
    label: 'Sail',
  },
  {
    value: YACHT_SEARCH_TYPE.I_DONT_CARE,
    label: 'Both',
  },
] as Array<RadioFieldsType>;
