import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import SearchContext from './providers/Search/Search.context';
import InfiniteScroll from 'components/InfiniteScroll';
import { Loader } from 'ncoded-component-library';
import SearchCard from './components/SearchCard';
import GlowScroll from 'components/GlowScroll';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EmptySearchIcon from 'icons/EmptySearch.icon';

import './MySearches.styles.scss';
import './MySearches.responsive.styles.scss';

type MySearchesProps = {
  className?: string;
};

const MySearches: React.FC<MySearchesProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-my-searches', className);
  const { t } = useTranslation();
  const history = useHistory();

  const { searches, loadingSearches, onSearchScroll } =
    useContext(SearchContext);

  const emptyState = useMemo(
    () =>
      searches.length === 0 && (
        <EmptyState
          icon={EmptySearchIcon}
          label={t('youHaveNoSearchesYet')}
          buttonText={t('createNewSearch')}
          onButtonClick={() => history.push('/create-search-profile')}
        />
      ),
    [searches, t, history],
  );

  return (
    <GlowScroll>
      {loadingSearches && <Loader />}
      <InfiniteScroll onScroll={() => onSearchScroll()} className={classes}>
        {searches.length === 0
          ? emptyState
          : searches.map((search) => (
              <SearchCard key={search.id} {...search} />
            ))}
      </InfiniteScroll>
    </GlowScroll>
  );
};

export default MySearches;
