import React, { useCallback, useContext, useState } from 'react';
import { Loader } from 'ncoded-component-library';
import Routes from 'router/components/Routes';
import { useTranslation } from 'react-i18next';
import { withTypes } from 'react-final-form';
import api from 'api';
import credentialsService from 'services/credentialsService';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import { RoutingStep } from '../../models/Routes';
import { useHistory } from 'react-router-dom';
import FormHeader from 'components/FormHeader';
import FormContent from 'components/FormContent';
import { UserRegistrationStep } from 'models/Auth';
import useFormPatching from 'hooks/useFormPatchings';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';

import './PersonaRouter.styles.scss';

export type PersonaRouterProps = {
  steps: RoutingStep<UserRegistrationStep>[];
  routes: Array<EnhancedRouteProps>;
};

const PersonaRouter: React.FC<PersonaRouterProps> = ({ routes }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { Form } = withTypes<Record<string, any>>();

  const { popServerError } = useContext(PopNotificationsContext);
  const { removeCompletedStepsFromStorage } = useContext(StepsContext);

  const [loading, setLoading] = useState(false);

  const handleOnCloseRegistration = useCallback(() => {
    credentialsService.removeTemporaryAuthBody();
    removeCompletedStepsFromStorage();
    history.push('/login');
  }, [history, removeCompletedStepsFromStorage]);

  const onSubmit = useCallback(async () => {
    try {
      if (!credentialsService.patchToken) return;
      setLoading(true);

      const { data } = await api.users.finishRegistration();

      credentialsService.removeTemporaryAuthBody();
      removeCompletedStepsFromStorage();
      credentialsService.isLogged = true;

      if (data.token || data.refreshToken) {
        credentialsService.saveAuthBody(data);
      }

      history.push('/home');
    } catch (e) {
      popServerError(e);
    } finally {
      setLoading(false);
    }
  }, [removeCompletedStepsFromStorage, history, popServerError]);

  const { initialData, loadingInitialData, onStepSubmit } = useFormPatching({
    name: 'user',
    setLoading,
    initRequest: api.users.getUserByPatchToken,
    patchRequest: api.users.patchUserByStep,
    onSubmit,
  });

  return (
    <div className="yx-membership-registration">
      <FormHeader
        title={t('MembershipRegistration')}
        onClose={handleOnCloseRegistration}
      />

      {loadingInitialData || loading ? (
        <Loader />
      ) : (
        <FormContent>
          <Form
            initialValues={initialData}
            onSubmit={onStepSubmit}
            render={() => <Routes routes={routes} />}
          />
        </FormContent>
      )}
    </div>
  );
};

export default PersonaRouter;
