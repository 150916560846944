import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import './CheckboxGroup.styles.scss';
import { Checkbox } from 'ncoded-component-library';
import useChangeDetection from 'hooks/useChangeDetection';

export type CheckboxOption = {
  label: string;
  value: string;
};

type CheckboxGroupProps = {
  className?: string;
  options: CheckboxOption[];
  inRow?: boolean;
  label?: string;
  onChange?: (value: string[]) => void;
  defaultValue?: string[];
};

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const {
    className,
    options = [],
    inRow,
    label,
    onChange,
    defaultValue = [],
  } = props;

  const [value, setValue] = useState<string[]>(defaultValue);

  const classes = classNames('yx-checkbox-group', className);
  const optionsClasses = classNames(
    { 'yx-checkbox-group__options': !inRow },
    {
      'yx-checkbox-group__options--row': inRow,
    },
  );

  useChangeDetection(
    () => {
      onChange?.(value);
    },
    [value],
    [onChange, value],
    false,
  );

  const renderOption = useCallback(
    ({ label, value: optionValue }: CheckboxOption) => (
      <Checkbox
        onChange={(val: boolean | React.ChangeEvent<HTMLInputElement>) =>
          val
            ? setValue((oldVal) => [...oldVal, optionValue])
            : setValue((oldVal) => oldVal.filter((val) => val !== optionValue))
        }
        label={label}
        value={value}
        checked={value.includes(optionValue)}
        key={optionValue}
      />
    ),
    [value],
  );

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <div className={optionsClasses}>
        {options.map((option) => renderOption(option))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
