import React from 'react';
import classNames from 'classnames';
import './AppointmentCard.styles.scss';

type AppointmentCardProps = {
  className?: string;
  title: string;
  mainInfo: string;
  description: string;
};

const AppointmentCard: React.FC<AppointmentCardProps> = (props) => {
  const { title, description, mainInfo, className } = props;

  const classes = classNames('yx-appointment-card', className);

  return (
    <div className={classes}>
      <p>{title}</p>
      <p>{mainInfo}</p>
      <p>{description}</p>
    </div>
  );
};

export default AppointmentCard;
