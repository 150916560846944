import HomePage from './pages/Home';
import { DashboardRouterType } from './models';
import HomeIcon from 'icons/Home.icon';
import YachtIcon from 'icons/Yacht.icon';
import SearchIcon from 'icons/Search.icon';
import ProfileSettings from './pages/ProfileSettings';
import MyYachts from './pages/MyYachts';
import YachtPreview from './pages/YachtPreview';
import MySearches from './pages/MySearches';
import Notification from './pages/Notification/Notification.page';
import MatchRouter from './subrouters/Match';
import MatchesPage from 'pages/Matches/Matches.page';
import redirect from 'router/modules/redirect';
import MyMatches from './pages/MyMatches';

export default [
  {
    path: '/home',
    component: HomePage,
    exact: false,
    onlyPublic: false,
    icon: HomeIcon,
    label: 'Home',
    navigation: true,
  },
  {
    path: '/my-searches',
    component: MySearches,
    exact: false,
    onlyPublic: false,
    icon: SearchIcon,
    label: 'My Searches',
    navigation: true,
  },
  {
    path: '/my-yachts',
    exact: true,
    component: MyYachts,
    authorized: true,
    onlyPublic: false,
    icon: YachtIcon,
    label: 'My Yachts',
    navigation: true,
  },
  {
    path: '/notifications',
    component: Notification,
    exact: false,
    onlyPublic: false,
    labelId: 'notifications',
  },
  {
    path: '/profile-settings',
    component: ProfileSettings,
    exact: false,
    onlyPublic: false,
    icon: HomeIcon,
    labelId: 'profileSettings',
  },
  {
    path: '/my-matches',
    exact: true,
    component: MyMatches,
    authorized: true,
    onlyPublic: false,
    icon: YachtIcon,
    label: 'My Matches',
    navigation: true,
  },
  {
    path: '/my-yacht/:yachtId',
    exact: true,
    component: YachtPreview,
    authorized: true,
    onlyPublic: false,
  },
  {
    path: '/matches/match/:id',
    authorized: true,
    exact: false,
    component: MatchRouter,
  },
  {
    path: '/matches/:id',
    authorized: true,
    exact: false,
    component: MatchesPage,
  },
  {
    path: '/',
    component: redirect('/home'),
  },
] as Array<DashboardRouterType>;
