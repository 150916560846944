import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { Input, Select } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import FieldLabel from '../components/FieldLabel';
import convert, { Measure, Unit } from 'convert-units';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import utils from 'utils';
import useCallbackRef from 'hooks/useCallbackRef';

import './InputUnitField.styles.scss';

type InputProps = ExtractPropTypes<typeof Input>;

type InputUnitFieldProps = {
  label?: string;
  tooltip?: React.FC;
  className?: string;
  unit?: Unit;
  measure?: Measure;
} & InputProps &
  FieldRenderProps<string, HTMLElement>;

function InputUnitField(props: InputUnitFieldProps) {
  const {
    input: { value, onChange, ...restInput },
    meta: { touched, error },
    className,
    label,
    tooltip,
    unit,
    measure = 'length',
    ...rest
  } = props;

  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = classNames(
    'yx-input-unit-field',
    { 'yx-input-unit-field--error': hasError },
    className,
  );

  const [inputEl, inputRef] = useCallbackRef<HTMLInputElement>();

  const [activeUnit, setActiveUnit] = useState<Unit>(
    unit || convert().possibilities(measure)[0],
  );

  const preserveCursorPosition = useRef<number>(-1);

  const setDottedUnitValue = useCallback(
    (value: string, selectionStart: number) => {
      const newValue = utils.getDotedNumber(value.replace(/[,]/g, ''));
      const { length: oldLength } = value;
      const { length: newLength } = newValue;
      preserveCursorPosition.current = selectionStart + newLength - oldLength;
      onChange(newValue);
    },
    [onChange],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, selectionStart },
      } = event;
      setDottedUnitValue(value, selectionStart);
    },
    [setDottedUnitValue],
  );

  const convertUnitValue = useCallback(
    (unit: Unit) => {
      if (!value) return;

      const activeUnitValue = convert(utils.getIntegerFromDottedNumber(value))
        .from(activeUnit)
        .to(unit)
        .toString();

      setDottedUnitValue(activeUnitValue, 1);
    },
    [activeUnit, setDottedUnitValue, value],
  );

  const unitOptions = useMemo(() => {
    const acitveUnitOptions = convert()
      .possibilities(measure)
      .map((unit) => {
        return {
          label: unit,
          value: unit,
        } as OptionValue;
      });

    return (
      <Select
        options={acitveUnitOptions}
        value={activeUnit}
        onChange={(option) => {
          convertUnitValue(option.value as Unit);
          setActiveUnit(option.value as Unit);
        }}
      />
    );
  }, [activeUnit, convertUnitValue, measure]);

  useEffect(() => {
    if (preserveCursorPosition.current >= 0 && activeUnit) {
      inputEl.selectionStart = preserveCursorPosition.current;
      inputEl.selectionEnd = preserveCursorPosition.current;
      preserveCursorPosition.current = -1;
    }
  }, [inputEl, activeUnit, value]);

  return (
    <div className={classes}>
      {label && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      <Input
        ref={inputRef}
        hasError={hasError}
        onChange={handleOnChange}
        suffixNode={unitOptions}
        value={value}
        {...restInput}
        {...rest}
      />
      {hasError && <p>{error}</p>}
    </div>
  );
}

export default InputUnitField;
