import { transformToIsoDate } from "utils";



export const checkDateInRange =
  (errorMsg: string, minDate: Date, maxDate: Date) => (value: string) => {
    if (!value) {
      return;
    }

    const date = transformToIsoDate(value);

    if (date < minDate || date > maxDate) {
      return errorMsg;
    }
  };

export const onlyPast = (errorMsg: string) => (value: string) => {
  if (!value) {
    return;
  }

  const minDate = transformToIsoDate('1900-01-01');

  const maxDate = new Date();
  const date = transformToIsoDate(value);

  if (date < minDate || date > maxDate) {
    return errorMsg;
  }
};

export const onlyFuture = (errorMsg: string) => (value: string) => {
  if (!value) {
    return;
  }

  const maxDate = transformToIsoDate('2100-12-31');

  const minDate = new Date();
  const date = transformToIsoDate(value);

  if (date < minDate || date > maxDate) {
    return errorMsg;
  }
};

export const afterField =
  (errorMsg: string, fieldName: string) =>
    (value: string, allValues: Record<string, any>) => {
      if (!value) {
        return;
      }

      let fieldValue = allValues[fieldName];

      if (!fieldValue) return;

      const date = transformToIsoDate(value);
      const fieldDate = transformToIsoDate(fieldValue);

      if (date < fieldDate) {
        return errorMsg;
      }
    };

export const beforeField =
  (errorMsg: string, fieldName: string) =>
    (value: string, allValues: Record<string, any>) => {
      if (!value) {
        return;
      }

      let fieldValue = allValues[fieldName];

      if (!fieldValue) return;

      const date = transformToIsoDate(value);
      const fieldDate = transformToIsoDate(fieldValue);

      if (date > fieldDate) {
        return errorMsg;
      }
    };
