import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Yacht } from 'models/Yacht';
import TableData, {
  TableDataType,
} from 'components/TableData/TableData.component';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

import './YachtDetails.styles.scss';
import './YachtDetails.responsive.styles.scss';

type YachtDetailsProps = {
  className?: string;
  yachtData?: Yacht;
};

type YachtInfoType = {
  label: string;
  description?: string;
  tableData: Array<TableDataType>;
};

const YachtDetails: React.FC<YachtDetailsProps> = (props) => {
  const { yachtData, className } = props;

  const classes = classNames('yx-yacht-details', className);

  const { t } = useTranslation();
  const {
    generatorInfo,
    yachtStatus = t('noData'),
    yachtType = t('noData'),
    grossRegisteredTonnage = t('noData'),
    guestCapacity = t('noData'),
    numberOfCabins = t('noData'),
    flag = t('noData'),
    shipyardBuilder = t('noData'),
    yearBuilt = t('noData'),
    yearRefit = t('noData'),
    hullMaterial = t('noData'),
    superStructure = t('noData'),
    deck = t('noData'),
    cruisingSpeed = '',
    enginePower = t('noData'),
    fuelType = t('noData'),
    numberOfEngines = t('noData'),
    engineManufacturer = t('noData'),
    engineHours = t('noData'),
    engineModel = t('noData'),
    exteriorDesigner = t('noData'),
    interiorDesigner = t('noData'),
    stabilization = t('noData'),
    waterCapacity = t('noData'),
    fuelCapacity = t('noData'),
    description = '',
    overallLength = '',
    waterlineLength = '',
    beam = '',
    draft = '',
    hasAirCondition,
    hasWaterMarks,
    hasMarineHead,
    hasInverter,
    hasSternThruster,
    airConditioningInfo: { capacity: airCapacity = t('noData') } = {},
    waterMakerInfo: {
      numberOfLitersPerHourPerWaterMaker = t('noData'),
      numberOfWaterMarks = t('noData'),
    } = {},
  } = yachtData ?? {};

  const {
    numberOfGenerators = t('noData'),
    brand = t('noData'),
    model = t('noData'),
    hours = t('noData'),
    capacity = '',
    electricity = '',
  } = generatorInfo ?? {};

  const basicInfo = useMemo(
    () =>
      [
        { key: t('nec'), value: yachtStatus },
        { key: t('type'), value: yachtType },
        { key: t('grt'), value: grossRegisteredTonnage },
        { key: t('pricePerTonnage'), value: grossRegisteredTonnage },
        { key: t('guestCapacity'), value: guestCapacity },
        { key: t('cabins'), value: numberOfCabins },
        { key: t('flag'), value: flag },
      ] as Array<TableDataType>,
    [
      flag,
      grossRegisteredTonnage,
      guestCapacity,
      numberOfCabins,
      yachtStatus,
      yachtType,
      t,
    ],
  );

  const constructionInfo = useMemo(
    () =>
      [
        { key: t('shipyyardBuilder'), value: shipyardBuilder },
        { key: t('yearBuilt'), value: yearBuilt },
        { key: t('yearRefit'), value: yearRefit },
        { key: t('hullMaterial'), value: hullMaterial },
        { key: t('superstructure'), value: superStructure },
        { key: t('deck'), value: deck },
      ] as Array<TableDataType>,
    [
      deck,
      hullMaterial,
      shipyardBuilder,
      superStructure,
      yearBuilt,
      yearRefit,
      t,
    ],
  );

  const geometryInfo = useMemo(
    () =>
      [
        {
          key: t('overallLength'),
          value: overallLength
            ? `${overallLength} ${t('meters')}`
            : t('noData'),
        },
        {
          key: t('waterlineLength'),
          value: waterlineLength
            ? `${waterlineLength} ${t('meters')}`
            : t('noData'),
        },
        {
          key: t('beam'),
          value: beam ? `${beam} ${t('meters')}` : t('noData'),
        },
        {
          key: t('draft'),
          value: draft ? `${draft} ${t('meters')}` : t('noData'),
        },
      ] as Array<TableDataType>,
    [beam, draft, overallLength, waterlineLength, t],
  );

  const engineInfo = useMemo(
    () =>
      [
        {
          key: t('cruisingSpeed'),
          value: cruisingSpeed ? `${cruisingSpeed} ${t('knots')}` : t('noData'),
        },
        { key: t('enginePower'), value: enginePower },
        { key: t('fuel'), value: fuelType },
        { key: t('numberOfEngines'), value: numberOfEngines },
        { key: t('engineManufacturer'), value: engineManufacturer },
        { key: t('engineModel'), value: engineModel },
        { key: t('engineHours'), value: engineHours },
      ] as Array<TableDataType>,
    [
      cruisingSpeed,
      engineHours,
      engineManufacturer,
      engineModel,
      enginePower,
      fuelType,
      numberOfEngines,
      t,
    ],
  );

  const designInfo = useMemo(
    () =>
      [
        { key: t('exteriorDesigner'), value: exteriorDesigner },
        { key: t('interiorDesigner'), value: interiorDesigner },
      ] as Array<TableDataType>,
    [exteriorDesigner, interiorDesigner, t],
  );

  const auxiliaryInfo = useMemo(
    () =>
      [
        { key: t('numberOfGenerators'), value: numberOfGenerators },
        { key: t('brand'), value: brand },
        { key: t('model'), value: model },
        { key: t('hours'), value: hours },
        { key: t('capacity'), value: capacity ? capacity + 'kW' : t('noData') },
        {
          key: t('electricity'),
          value: electricity ? electricity + 'V' : t('noData'),
        },
        {
          key: t('airConditioning'),
          value: utils.getYesNoValue(hasAirCondition),
        },
        { key: t('amount'), value: airCapacity },
        { key: t('stabilization'), value: stabilization },
        {
          key: t('waterMakers'),
          value: utils.getYesNoValue(hasWaterMarks),
        },
        { key: t('amount'), value: numberOfWaterMarks },
        { key: t('litersPerHour'), value: numberOfLitersPerHourPerWaterMaker },
        { key: t('marineHead'), value: utils.getYesNoValue(hasMarineHead) },
        { key: t('inverter'), value: utils.getYesNoValue(hasInverter) },
        { key: t('thrusters'), value: utils.getYesNoValue(hasSternThruster) },
      ] as Array<TableDataType>,
    [
      t,
      numberOfGenerators,
      brand,
      model,
      hours,
      capacity,
      electricity,
      hasAirCondition,
      airCapacity,
      stabilization,
      hasWaterMarks,
      numberOfWaterMarks,
      numberOfLitersPerHourPerWaterMaker,
      hasMarineHead,
      hasInverter,
      hasSternThruster,
    ],
  );

  const capacitiesInfo = useMemo(
    () =>
      [
        { key: t('waterCapacity'), value: waterCapacity },
        { key: t('fuelCapacity'), value: fuelCapacity },
      ] as Array<TableDataType>,
    [fuelCapacity, waterCapacity, t],
  );

  const tenderAndToysinfo = useMemo(
    () =>
      yachtData?.tenderAndToysInfo?.map((tender) => {
        return {
          key: t(tender.tenderAndToysType),
          value: tender.description,
        } as TableDataType;
      }),
    [yachtData, t],
  );

  const yachtInformations = useMemo(
    () =>
      [
        {
          label: t('basic'),
          description: description,
          tableData: basicInfo,
        },
        { label: t('construction'), tableData: constructionInfo },
        { label: t('geometry'), tableData: geometryInfo },
        { label: t('engine'), tableData: engineInfo },
        { label: t('design'), tableData: designInfo },
        { label: t('auxiliaryEquipment'), tableData: auxiliaryInfo },
        { label: t('capacities'), tableData: capacitiesInfo },
        { label: t('tenderAndToys'), tableData: tenderAndToysinfo },
      ] as Array<YachtInfoType>,
    [
      description,
      auxiliaryInfo,
      basicInfo,
      capacitiesInfo,
      constructionInfo,
      designInfo,
      engineInfo,
      geometryInfo,
      tenderAndToysinfo,
      t,
    ],
  );

  return (
    <div className={classes}>
      {yachtInformations.map(
        (data) =>
          data.tableData && (
            <div key={data.label} className="yx-yacht-details__information">
              <h1>{data.label}</h1>
              <p>{data?.description}</p>

              <TableData tableData={data.tableData} tableColumnCount={4} />
            </div>
          ),
      )}
    </div>
  );
};

export default YachtDetails;
