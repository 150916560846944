import React, { useContext, useCallback, useMemo } from 'react';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import DropzoneField from 'components/Fields/DropzoneField';
import SelectField from 'components/Fields/SelectField';
import { methodOptions } from './utils';
import { required, requiredObjOrArray } from 'validators/general';
import api from 'api';
import { DropzoneFieldFile } from 'components/Fields/DropzoneField/DropzoneField.component';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

import './ProofOfAddressStep.styles.scss';

export type BrokerProofOfAddressValues = {
  method: string;
  files: File[];
};

const ProofOfAddressStep: React.FC = () => {
  const { t } = useTranslation();
  const { popServerError } = useContext(PopNotificationsContext);

  const validateObject = useMemo(
    () => ({
      requiredObjOrArray: requiredObjOrArray(t('requiredField')),
      required: required(t('requiredField')),
    }),
    [t],
  );

  const deleteRequest = useCallback(
    async (file: DropzoneFieldFile, fileName: string) => {
      try {
        await api.users.deleteFileOnTemporaryUser(fileName, file.id);
      } catch (er) {
        popServerError(er);
      }
    },
    [popServerError],
  );

  return (
    <StepWrapper
      className="yx-proof-of-address-step"
      title={t('proofOfAddress')}
    >
      <p className="yx-proof-of-address-step__paragraph">
        {t('proofOfAddress-paragraph')}
      </p>
      <Field
        name="proofOfAddressType"
        placeholder={t('select')}
        validate={validateObject.required}
        component={SelectField}
        options={methodOptions}
      />
      <Field
        name="proofOfAddress"
        label={t('proofOfAddress')}
        component={DropzoneField}
        validate={validateObject.requiredObjOrArray}
        uploadRequest={api.users.uploadFileOnTemporaryUser}
        deleteRequest={deleteRequest}
        maxFiles={1}
      />
    </StepWrapper>
  );
};

export default ProofOfAddressStep;
