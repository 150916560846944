import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Yacht } from 'models/Yacht';
import { Button, Loader } from 'ncoded-component-library';
import DotsIcon from 'icons/Dots.icon';
import TableData from 'components/TableData';
import { useTranslation } from 'react-i18next';
import { TableDataType } from 'components/TableData/TableData.component';
import LocationIcon from 'icons/Location.icon';
import { countryNamesByCode } from 'services/countries';
import MyYachtsContext from 'router/subrouters/Dashboard/providers/MyYachts/MyYachts.context';
import UnpublishIcon from 'icons/Unpublish.icon';
import EditIcon from 'icons/Edit.icon';
import DeleteIcon from 'icons/Delete.icon';
import { NavLink, useHistory } from 'react-router-dom';
import confirm from 'modules/confirm';
import Image from 'components/Image';
import utils from 'utils';
import OptionMenu from 'components/OptionMenu';
import CheckIcon from 'icons/Check.icon';

import './YachtCard.styles.scss';
import './YachtCard.responsive.styles.scss';

type YachtCardProps = {
  className?: string;
  yachtData?: Yacht;
};

const YachtCard: React.FC<YachtCardProps> = (props) => {
  const { className, yachtData } = props;

  const { t } = useTranslation();

  const {
    id: yachtId,
    vesselName = t('notSpecifiedParam', { param: t('vesselName') }),
    status,
    beam,
    draft,
    hullMaterial,
    grossRegisteredTonnage,
    guestCapacity,
    cruisingSpeed,
    numberOfCabins,
    askingPrice,
    profileShots,
  } = yachtData;

  const isPublished = useMemo(() => status === 'Published', [status]);
  const history = useHistory();

  const classes = classNames(
    'yx-yacht-card',
    { 'default-image': !profileShots?.[0]?.url },
    className,
  );

  const { loading, deleteYacht, editYacht, publishYacht, unpublishYacht } =
    useContext(MyYachtsContext);

  const handleOnDeleteYacht = useCallback(async () => {
    if (
      await confirm({
        title: t('deleteYacht'),
        type: 'delete',
        content: t('deleteYacht-info'),
      })
    )
      deleteYacht(yachtId);
  }, [deleteYacht, t, yachtId]);

  const publishActionButton = useMemo(
    () => (isPublished ? UnpublishIcon : CheckIcon),
    [isPublished],
  );

  const yachtOptions = useMemo(
    () => (
      <OptionMenu
        className="yx-yacht-card__dropdown"
        contentClassName="yx-yacht-card__dropdown__content"
        trigger={
          <Button className="yx-yacht-card__action-button" icon={DotsIcon} />
        }
        options={[
          {
            label: (
              <Button
                icon={publishActionButton}
                variant="link"
                onClick={() => {
                  isPublished
                    ? unpublishYacht(yachtId)
                    : publishYacht(yachtId, yachtData);
                }}
              >
                {isPublished ? t('unpublish') : t('publish')}
              </Button>
            ),
          },
          {
            label: (
              <Button
                icon={<EditIcon width="14px" height="14px" />}
                variant="link"
                onClick={() => editYacht(yachtId, isPublished)}
              >
                {t('edit')}
              </Button>
            ),
          },
          {
            label: (
              <Button
                icon={DeleteIcon}
                variant="link"
                onClick={() => handleOnDeleteYacht()}
              >
                {t('delete')}
              </Button>
            ),
          },
        ]}
      />
    ),
    [
      isPublished,
      yachtData,
      yachtId,
      publishActionButton,
      unpublishYacht,
      publishYacht,
      editYacht,
      handleOnDeleteYacht,
      t,
    ],
  );

  const yachtMatchs = useMemo(() => {
    if (!yachtData.numberOfMatches) return <p>{t('noMatches')}</p>;
    else
      return (
        <>
          <p>{t('youHaveMatch', { count: yachtData?.numberOfMatches })}</p>
          <Button
            styleType="secondary"
            onClick={() => history.push(`matches/${yachtId}/yacht`)}
          >
            {t('viewMatches')}
          </Button>
        </>
      );
  }, [history, t, yachtData.numberOfMatches, yachtId]);

  const tableData = useMemo(
    () =>
      [
        { key: t('geometry-beam'), value: beam ? beam + 'm' : '' },
        { key: t('geometry-draft'), value: draft ? draft + 'm' : '' },
        { key: t('hull'), value: hullMaterial },
        {
          key: t('grt'),
          value: utils.getDotedNumber(grossRegisteredTonnage?.toString() || ''),
        },
        { key: t('guests'), value: guestCapacity },
        {
          key: t('cruisingSpeed'),
          value: cruisingSpeed ? `${cruisingSpeed} knots` : null,
        },
        { key: t('cabins'), value: numberOfCabins },
        {
          key: t('price'),
          value: askingPrice
            ? `${utils.getDotedNumber(askingPrice.toString())}$`
            : '',
        },
      ] as Array<TableDataType>,
    [
      askingPrice,
      beam,
      cruisingSpeed,
      draft,
      grossRegisteredTonnage,
      guestCapacity,
      hullMaterial,
      numberOfCabins,
      t,
    ],
  );

  return (
    <div className={classes}>
      {loading && <Loader />}
      <NavLink
        to={`/my-yacht/${yachtId}`}
        className={classNames('yx-yacht-card__profile-shot', {
          default: !profileShots?.[0]?.url,
        })}
      >
        <Image src={profileShots?.[0]?.url} alt="yacht-profile-shot" />
      </NavLink>

      <section className="yx-yacht-card__content">
        <div className="yx-yacht-card__content__heading">
          <NavLink to={`/my-yacht/${yachtId}`}>{vesselName}</NavLink>
          {yachtOptions}
        </div>

        <span>
          <LocationIcon />

          {countryNamesByCode[yachtData.flag] || t('noLocation')}
        </span>

        <div className="yx-yacht-card__table">
          <TableData tableData={tableData} />
        </div>
      </section>

      {isPublished && (
        <section className="yx-yacht-card__matches">
          <Image src="/assets/images/match-yacht.png" />
          {yachtMatchs}
        </section>
      )}
    </div>
  );
};

export default YachtCard;
