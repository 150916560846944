import React, { useCallback, useContext, useMemo } from 'react';
import '../../Match.styles.scss';
import '../../Match.styles.responsive.scss';
import MatchContext from '../../providers/Match/Match.context';
import { useTranslation } from 'react-i18next';
import { MATCH_TYPE } from '../../models';
import CollapsibleContainer from '../../components/CollapsibleContainer';
import Routes from '../../../../../../components/Routes';
import { MATCH_STATUS } from '../../utils';
import { buyerCollapsibleRoutes } from '../../routes';
import YachtPreview from '../../../../pages/YachtPreview';
import TableData from '../../../../.././../../components/TableData';
import { TableDataType } from '../../../../.././../../components/TableData/TableData.component';
import utils from 'utils';
import { charterValue, createAuxiliaryEquipmentValues } from './utils';
import { Search } from 'models/Search';

const Collapsible: React.FC = () => {
  const {
    match: { status, yachtId },
    routeName,
    matchScore,
    currentStep,
    search: { search },
    buyerProfile,
  } = useContext(MatchContext);

  const { t } = useTranslation();

  const auxiliaryTooltipComponent = useCallback(
    (auxiliaryTooltipOptions: Array<string>) => (
      <ul className="search-table__tooltip">
        {auxiliaryTooltipOptions.map((option) => (
          <li>{option}</li>
        ))}
      </ul>
    ),
    [],
  );

  const searchTableData: TableDataType[] = useMemo(() => {
    const [auxiliaryLabel, auxiliaryTooltip] = createAuxiliaryEquipmentValues(
      search as Search,
    );

    return !search
      ? []
      : [
          { key: t('type'), value: search.yachtType },
          { key: t('n/u/b'), value: search.yachtStatus },
          { key: t('speed'), value: search.speed },
          {
            key: t('fuelConsumption'),
            value: search.fuelConsumptionPlaysRole
              ? t('playsRole')
              : t('doesNotPlayRole'),
          },
          {
            key: t('minimumBudget'),
            value: `${utils.getDotedNumber(String(search.budget.from))}$`,
          },
          { key: t('guestCapacity'), value: search.guestCapacity },
          { key: t('minYearBuilt'), value: search.yearBuilt },
          {
            key: t('charter'),
            value: t(charterValue(search.makeVesselCommercialyAvailable)),
          },
          {
            key: t('maximumBudget'),
            value: `${utils.getDotedNumber(String(search.budget.to))}$`,
          },
          {
            key: t('location'),
            value: search.location,
          },
          {
            key: t('typeOfFuel'),
            value: search.fuelType,
          },
          {
            key: t('specificEquipment'),
            value: auxiliaryLabel,
            tooltip: auxiliaryTooltipComponent(auxiliaryTooltip),
          },
        ];
  }, [auxiliaryTooltipComponent, search, t]);

  return routeName === MATCH_TYPE.BUYER ? (
    <CollapsibleContainer
      title={t('yachtCollapsibleTitle')}
      matchScore={matchScore}
      defaultOpen={status === MATCH_STATUS.NEW}
      pageContent={<Routes routes={buyerCollapsibleRoutes} />}
    >
      <YachtPreview yachtId={yachtId} isLocked={currentStep === 1} />
    </CollapsibleContainer>
  ) : (
    <CollapsibleContainer
      title={t('searchCollapsibleTitle')}
      description={t('searchCollapsibleDescription', {
        userType: t(buyerProfile?.userType),
        place: buyerProfile?.city,
      })}
      matchScore={matchScore}
      defaultOpen={currentStep === 1}
    >
      <TableData
        tableData={searchTableData}
        tableColumnCount={4}
        className="search-table"
      />
    </CollapsibleContainer>
  );
};

export default Collapsible;
