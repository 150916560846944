import React, { useContext } from 'react';
import '../../Match.styles.scss';
import '../../routes';
import MatchContext from '../../providers/Match/Match.context';
import { MatchesRoutes } from '../../models';
import MatchStepper from '../../components/MatchStepper/MatchStepper.component';
import { getSteps } from '../../utils';
import { getStepperRoutes } from '../../routes';

const Stepper: React.FC = () => {
  const { routeName } = useContext(MatchContext);
  return (
    <div className="yx-match-router__container">
      <MatchStepper
        steps={getSteps(getStepperRoutes(routeName as MatchesRoutes))}
      />
    </div>
  );
};
export default Stepper;
