import { DropzoneFieldRequests } from 'hooks/useDropzoneField';
import React from 'react';
import DropzoneRequestsContext from './DropzoneRequests.context';

type DropzoneRequestsProps = DropzoneFieldRequests;

const DropzoneRequests: React.FC<DropzoneRequestsProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <DropzoneRequestsContext.Provider value={rest}>
      {children}
    </DropzoneRequestsContext.Provider>
  );
};

export default DropzoneRequests;
