import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import validators from 'validators';
import { Field } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import { composeValidators } from 'validators/general';

import './CapacitiesStep.styles.scss';

type CapacitiesStepProps = {
  className?: string;
};

const CapacitiesStep: React.FC<CapacitiesStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-capacities-step', className);

  const { t } = useTranslation();

  const validatorsObject = useMemo(
    () => ({
      waterCapacity: composeValidators(
        validators.required(t('capacities-waterCapacity-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
      ),
      fueldCapacity: composeValidators(
        validators.required(t('capacities-fuelCapacity-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('capacities')}
      description={t('capacities-info')}
      className={classes}
    >
      <Field
        unitType="liters"
        name="waterCapacity"
        component={InputField}
        label={t('capacities-waterCapacity')}
        placeholder={t('capacities-placeholder')}
        validate={validatorsObject.waterCapacity}
      />

      <Field
        unitType="liters"
        name="fuelCapacity"
        component={InputField}
        label={t('capacities-fuelCapacity')}
        placeholder={t('capacities-placeholder')}
        validate={validatorsObject.fueldCapacity}
      />
    </StepWrapper>
  );
};

export default CapacitiesStep;
