import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import FieldLabel from 'components/Fields/components/FieldLabel';

import './NavigationCommunicationStep.styles.scss';

type NavigationCommunicationStepProps = {
  className?: string;
};

const NavigationCommunicationStep: React.FC<NavigationCommunicationStepProps> =
  (props) => {
    const { className } = props;

    const classes = classNames('yx-navigation-communication-step', className);

    const { t } = useTranslation();

    return (
      <StepWrapper
        title={t('navigationCommunicationDeckEquipment')}
        description={t('equipment-info')}
        className={classes}
      >
        <FieldLabel>{t('navigationAndCommuncation')}</FieldLabel>
        <Field name="hasRadars" component={CheckboxField} label={t('radars')} />
        <Field
          name="hasAutopilot"
          component={CheckboxField}
          label={t('autoPilot')}
        />
        <Field
          name="hasGPS"
          component={CheckboxField}
          label={t('gpsPlotter')}
        />
        <Field name="hasECDIS" component={CheckboxField} label={t('ecdis')} />
        <Field
          name="hasCompass"
          component={CheckboxField}
          label={t('compassGyro')}
        />
        <Field
          name="hasWindIndicator"
          component={CheckboxField}
          label={t('windIndicator')}
        />
        <Field name="hasAIS" component={CheckboxField} label={t('ais')} />
        <Field
          name="hasInternet"
          component={CheckboxField}
          label={t('internet')}
        />

        <FieldLabel>{t('deckEquipment')}</FieldLabel>
        <Field
          name="hasWindlass"
          component={CheckboxField}
          label={t('windlass')}
        />
        <Field
          name="hasAnchorAndChains"
          component={CheckboxField}
          label={t('anchorAndChains')}
        />
        <Field name="hasCrane" component={CheckboxField} label={t('crane')} />
        <Field
          name="hasSwimLadder"
          component={CheckboxField}
          label={t('swimLadder')}
        />
        <Field
          name="hasBiminiTop"
          component={CheckboxField}
          label={t('biminiTop')}
        />
      </StepWrapper>
    );
  };

export default NavigationCommunicationStep;
