import { AddDocumentsType } from 'components/Fields/AddDocumentFields/AddDocumentFields.component';
import { CountryCodes } from 'services/countries';
import { FileType } from './File';

export const YACHT_CREATE_STEPS = {
  BASIC: 'Basic',
  CONSTRUCTION: 'Construction',
  GEOMETRY: 'Geometry',
  ENGINE: 'Engine',
  DESIGN: 'Design',
  AUXILIARY_EQUIPMENT: 'Auxiliary equipment',
  NAVIGATION_COMMUNICATION_DECK_EQUIPMENT:
    'Navigation, communication and deck equipment',
  CAPACITIES: 'Capacities',
  COMMERCIAL_COMPLIANCE: 'Commercial compliance',
  TENDER_AND_TOYS: 'Tender and toys',
  MAINTENANCE_HISTORY: 'Maintenance history',
  MEDIA_FILES: 'Media files',
  DOCUMENTATION: 'Documentation',
} as const;

export type CreateYachtSteps =
  typeof YACHT_CREATE_STEPS[keyof typeof YACHT_CREATE_STEPS];

//Yacht Status
export const YACHT_STATUS = {
  NEW: 'New',
  IN_USE: 'In Use',
  CONCEPT: 'Concept',
} as const;

export type YachStatus = typeof YACHT_STATUS[keyof typeof YACHT_STATUS];

//Yacht Type
export const YACHT_TYPE = {
  MOTOR: 'Motor',
  SAILING: 'Sailing',
  EXPEDITION: 'Expedition',
  GULET: 'Gulet',
  OPEN: 'Open',
  CATAMARAN: 'Catamaran',
  SPORTS_FISHING: 'Sports Fishing',
} as const;

export type YachtType = typeof YACHT_TYPE[keyof typeof YACHT_TYPE];

//Vat Status
export const VAT_STATUS = {
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  EXEMPT: 'Exempt',
} as const;
export type VatStatus = typeof VAT_STATUS[keyof typeof VAT_STATUS];

export const FUEL_TYPE = {
  DIESEL: 'Diesel',
  PETROL: 'Petrol',
  ELECTRIC: 'Electric',
  HYBRID: 'Hybrid',
} as const;
export type FuelType = typeof FUEL_TYPE[keyof typeof FUEL_TYPE];

export const CONFIRMATION_TYPE = {
  YES: 'yes',
  NO: 'no',
} as const;
export type ConfirmationType =
  typeof CONFIRMATION_TYPE[keyof typeof CONFIRMATION_TYPE];

export const STABILIZATION = {
  ACTIVE_TANKS: 'Active tanks',
  GYRO_STABILIZERS: 'Gyro stabilizers',
  ACTIVE_FIN_STABILIZERS: 'Active fin stabilizers',
  NONE: 'None',
} as const;
export type StabilizationType =
  typeof STABILIZATION[keyof typeof STABILIZATION];

export const HULL_MATERIAL = {
  STEEL: 'Steel',
  ALUMINIUM: 'Aluminium',
  WOOD: 'Wood',
  FERROCEMENT: 'Ferrocement',
  FIBERGLASS: 'Fiberglass',
} as const;
export type HullMaterialType = typeof HULL_MATERIAL[keyof typeof HULL_MATERIAL];

export const DECK_TYPE = {
  TEEK: 'Teek',
  NO_TEEK: 'No teek',
} as const;
export type DeckType = typeof DECK_TYPE[keyof typeof DECK_TYPE];

export const ELECTRICIY = [110, 220, 380, 400] as const;
export type ElectricityType = typeof ELECTRICIY[number];

export const CLASSIFICATION_SOCIETY = {
  ABS: 'American Bureau of Shipping',
  KR: 'Korean Register',
  BV: 'Bureau Veritas',
  LR: "Lloyd's Register",
  CCS: 'China Classification Society',
  NK: 'Nippon Kaiji Kyokai (Class NK)',
  CRS: 'Croatian Register of Shipping',
  PRS: 'Polish Register of Shipping',
  DNV_GL: 'DNV GL AS',
  RINA: 'RINA',
  IRS: 'Indian Register of Shipping',
  RS: 'Russian Maritime Register of Shipping',
} as const;
export type ClassificationSocietyType =
  typeof CLASSIFICATION_SOCIETY[keyof typeof CLASSIFICATION_SOCIETY];

export const TENDER_AND_TOYS = {
  TENDER: 'Tender',
  JETSKI: 'Jetski',
  SEABOB: 'Seabob',
  AQUALUNG: 'Aqualung',
  FLYBOARD: 'Flyboard',
  WAKEBOARD: 'Wakeboard',
} as const;
export type TenderAndToysType =
  typeof TENDER_AND_TOYS[keyof typeof TENDER_AND_TOYS];

export type GeneratorInfo = {
  numberOfGenerators: number;
  brand: string;
  model: string;
  hours: number;
  capacity: number | string;
  electricity: number;
};

export type AirConditionInfo = {
  capacity: number;
};

export type WaterMakerInfo = {
  numberOfWaterMarks: number;
  numberOfLitersPerHourPerWaterMaker: number;
};

export type TenderAndToyItemInfo = {
  tenderAndToysType: TenderAndToysType | string;
  description: string;
};

export type MaintenanceHistoryItem = {
  year: number;
  description: string;
};

// steps types

//step - 1
export type BasicInfoType = {
  yachtStatus: YachStatus;
  yachtType: YachtType;
  vesselName: string;
  teaserDescription: string;
  description?: string;
  askingPrice: string | number;
  grossRegisteredTonnage: string | number;
  guestCapacity: string | number;
  numberOfCabins: number;
  flag: CountryCodes;
  registryNumber: string;
  classificationSociety: string;
  vatStatus: string;
};

//step - 2
export type ConstructionInfo = {
  shipyardBuilder: string;
  yearBuilt: number;
  yearRefit: number;
  hullMaterial: HullMaterialType;
  superStructure: string;
  deck: string;
};

//step -3
export type GeometryInfo = {
  overallLength: number | string;
  waterlineLength: number | string;
  beam: number | string;
  draft: number | string;
};

//step - 4
export type EngineInfo = {
  cruisingSpeed: number | string;
  enginePower: number | string;
  fuelType: FuelType;
  numberOfEngines: number;
  engineManufacturer: string;
  engineModel: string;
  engineHours: number | string;
  additionalComments: string;
  fuelConsumptionAtCruisingSpeed: number | string;
  range: number | string;
};

//step - 5
export type DesignInfo = {
  exteriorDesigner: string;
  interiorDesigner: string;
};

//stpe - 6
export type AuxiliaryEquipmentInfo = {
  generatorInfo: GeneratorInfo;
  airConditioningInfo: AirConditionInfo;
  stabilization: StabilizationType;
  waterMakerInfo: WaterMakerInfo;
  hasMarineHead: boolean;
  hasInverter: boolean;
  hasBowThruster: boolean;
  bowThrusterPower: number | string;
  hasSternThruster: boolean;
  sternThrusterPower: number | string;
  hasAncillaryEquipment: boolean;
  hasFireChainWasher: boolean;
  hasBilgePump: boolean;
  hasSewageTreatment: boolean;
  hasEngineRoomBlowers: boolean;
};

//step - 7
export type NavigationCommuniacitionInfo = {
  hasRadars: boolean;
  hasAutopilot: boolean;
  hasGPS: boolean;
  hasECDIS: boolean;
  hasCompass: boolean;
  hasWindIndicator: boolean;
  hasAIS: boolean;
  hasInternet: boolean;
  hasWindlass: boolean;
  hasAnchorAndChains: boolean;
  hasCrane: boolean;
  hasSwimLadder: boolean;
  hasBiminiTop: boolean;
};

//step - 8
export type CapacitiesInfo = {
  waterCapacity: number | string;
  fuelCapacity: number | string;
};

//step - 9
export type CommercialComplianceInfo = {
  hasCommercialCompliance: boolean;
};

//step - 10
export type TenderAndToyInfo = {
  tenderAndToysInfo: Array<TenderAndToyItemInfo>;
};

//step - 11
export type MaintenanceHistoryInfo = {
  maintenanceHistoryInfo: Array<MaintenanceHistoryItem>;
};

export type YachtRadioValues = {
  hasGenerator: boolean;
  hasAirCondition: boolean;
  hasWaterMarks: boolean;
};

export type YachtStatus = 'Drafted' | 'Suspended' | 'Published' | 'Unpublished';

export type Yacht = YachtResponseData & {
  id: string;
  debt?: boolean; //TODO prop
  status: YachtStatus;
  numberOfMatches?: number;
  profileShots: Array<FileType>;
  mainSalonShots: Array<FileType>;
  masterCabinShots: Array<FileType>;
  miscellaneousShots: Array<FileType>;
  videos: Array<FileType>;
  optionalDocuments: Array<AddDocumentsType>;
  //new
  isApproved?: boolean;
  isPublished?: boolean;
  commercialYachtSurveyReport?: FileType;
  certificateOfRegistry?: FileType;
  centralAgencyAgreement?: FileType;
  engineCertificate?: FileType;
  billOfSale?: FileType;
  CECertificate?: FileType;
  shipStationLicence?: FileType;
  cargoShipSafetyRadioCertificate?: FileType;
  internationalAirPollutionCertificate?: FileType;
  antiFoulingSystemCertificate?: FileType;
  internationalLoadLineCertficate?: FileType;
  internationalSewagePollutionPreventionCertificate?: FileType;
  statementOfComplianceForLabourConvention?: FileType;
  minimumSafeManningCertificate?: FileType;
  maltaCertificateOfSurvey?: FileType;
  internationalTonnageCertificate?: FileType;
  certificateOfCompliance?: FileType;
  internationalOilPollutionPreventionCertificate?: FileType;
  marpolCompliance?: FileType;
  internationalAntiFoulingSystemCertificate?: FileType;
  publicAccessToken?: string;
} & BasicInfoType &
  ConstructionInfo &
  GeometryInfo &
  EngineInfo &
  DesignInfo &
  AuxiliaryEquipmentInfo &
  NavigationCommuniacitionInfo &
  CapacitiesInfo &
  CommercialComplianceInfo &
  TenderAndToyInfo &
  MaintenanceHistoryInfo &
  YachtRadioValues;

export type YachtResponseData = {
  _id: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
  userId: string;
};

export type OptionalDocumentsType = Pick<
  Yacht,
  | 'shipStationLicence'
  | 'cargoShipSafetyRadioCertificate'
  | 'internationalAirPollutionCertificate'
  | 'antiFoulingSystemCertificate'
  | 'internationalLoadLineCertficate'
  | 'internationalSewagePollutionPreventionCertificate'
  | 'statementOfComplianceForLabourConvention'
  | 'minimumSafeManningCertificate'
  | 'maltaCertificateOfSurvey'
  | 'internationalTonnageCertificate'
  | 'certificateOfCompliance'
  | 'internationalOilPollutionPreventionCertificate'
  | 'marpolCompliance'
  | 'internationalAntiFoulingSystemCertificate'
>;

export type OptionalDocumentsKeys = keyof OptionalDocumentsType;
