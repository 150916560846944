import React, { useContext } from 'react';
import classNames from 'classnames';
import DatepickerContext from '../../../provider/Datepicker.context';
import { LeftArrow, RightArrow } from '../../../svgs';
import { getDecrementedMonth, getIncrementedMonth } from '../../../../utils';
import { Pickers } from '../../../utils';
import '../../../styles/Header.scss';

type HeaderProps = {
  className?: string;
};
const { YEAR_PICKER } = Pickers;

const Header: React.FC<HeaderProps> = ({ className }) => {
  const headerClassNames = classNames('header', className);

  const { setCurrentDate, currentDate, setShowInMenu } =
    useContext(DatepickerContext);

  return (
    <div className={headerClassNames}>
      <LeftArrow
        onClick={() =>
          setCurrentDate?.((oldVal) => getDecrementedMonth(oldVal))
        }
      />
      <div
        className="header__heading"
        onClick={() => setShowInMenu?.(YEAR_PICKER)}
      >
        <p>
          <span>{currentDate?.monthLong}</span>
          <span>{currentDate?.year}</span>
        </p>
      </div>
      <RightArrow
        onClick={() =>
          setCurrentDate?.((oldVal) => getIncrementedMonth(oldVal))
        }
      />
    </div>
  );
};
export default Header;
