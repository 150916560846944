import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="38" height="43" viewBox="0 0 38 43" {...props}>
    <path
      d="M24.741 1h-16.4a4.1 4.1 0 00-4.1 4.1v32.8c0 2.255 1.845 4.1 4.1 4.1h24.6a4.1 4.1 0 004.1-4.1V13.3L24.741 1z"
      fill="#fff"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.46 1.142v11.875h12.3L24.46 1.143zm-8.2 12.3h-4.1 4.1z"
      fill="#fff"
    />
    <path
      d="M28.56 17.4h-16.4m16.4 12.442h-16.4m4.1-16.4h-4.1m12.3-12.3v11.876h12.3L24.46 1.142z"
      fill="#fff"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="transparent"
      d="M3.817 32.387H0l3.817 3.393v-3.393z"
      fill="#DE544F"
    />
    <path
      stroke="transparent"
      d="M0 23.662a4 4 0 014-4h21.69a4 4 0 014 4v4.724a4 4 0 01-4 4H0v-8.724z"
      fill="#FD635D"
    />
    <path
      stroke="transparent"
      d="M7.695 28.51h.878v-2.045h1.193c1.35 0 2.017-.815 2.017-1.889 0-1.07-.662-1.883-2.014-1.883H7.695v5.818zm.878-2.789v-2.276h1.102c.87 0 1.224.472 1.224 1.131 0 .66-.355 1.145-1.213 1.145H8.573zm6.123 2.79c1.772 0 2.798-1.1 2.798-2.918 0-1.81-1.026-2.9-2.739-2.9h-1.943v5.818h1.884zm-1.006-.767V23.46h1.011c1.273 0 1.93.761 1.93 2.133 0 1.378-.657 2.15-1.984 2.15h-.957zm4.896.767h.877v-2.537h2.472v-.753h-2.472v-1.773h2.73v-.755h-3.607v5.818z"
      fill="#fff"
    />
  </svg>
);
