import { createContext } from 'react';
import utils from 'utils';

type PageContextProps = {
  title: string;
  setTitle: (title: string) => void;
};

export default createContext<Partial<PageContextProps>>({
  title: 'yachtX',
  setTitle: utils.noop,
});
