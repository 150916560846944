import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import SelectField from 'components/Fields/SelectField';
import InputField from 'components/Fields/InputField';
import {
  deckOptions,
  hullMaterialOptions,
  yearOptions,
} from '../../services/options';
import validators from 'validators';

import './ConstructionStep.styles.scss';

type ConstructionStepProps = {
  className?: string;
};

const ConstructionStep: React.FC<ConstructionStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-construction-step', className);

  const { t } = useTranslation();

  const validatorsObject = useMemo(
    () => ({
      shipyardBuilder: validators.required(t('requiredField')),
      yearBuilt: validators.required(t('requiredField')),
      yearRefit: validators.checkIfOtherFieldIsGreaterThan(
        'yearBuilt',
        t('construction-yearRefit-validtor'),
      ),
      hullMaterial: validators.required(t('requiredField')),
      deck: validators.required(t('requiredField')),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('construction')}
      description={t('construction-info')}
      className={classes}
    >
      <Field
        name="shipyardBuilder"
        component={InputField}
        label={t('construction-shipyardBuilder')}
        placeholder={t('enterShipyardBuilder')}
        validate={validatorsObject.shipyardBuilder}
      />

      <Field
        name="yearBuilt"
        options={yearOptions}
        component={SelectField}
        label={t('construction-yearBuilt')}
        placeholder={t('selectYear')}
        validate={validatorsObject.yearBuilt}
      />

      <Field
        name="yearRefit"
        options={yearOptions}
        component={SelectField}
        label={t('construction-yearRefit')}
        placeholder={t('selectYear')}
        validate={validatorsObject.yearRefit}
      />

      <Field
        name="hullMaterial"
        component={SelectField}
        options={hullMaterialOptions}
        label={t('construction-hullMaterial')}
        placeholder={t('selectMaterial')}
        validate={validatorsObject.hullMaterial}
      />

      <Field
        name="superStructure"
        component={SelectField}
        options={hullMaterialOptions}
        label={t('construction-superStructure')}
        placeholder={t('selectSuperstructure')}
      />

      <Field
        name="deck"
        component={SelectField}
        options={deckOptions}
        label={t('construction-deck')}
        placeholder={t('enterDeck')}
        validate={validatorsObject.deck}
      />
    </StepWrapper>
  );
};

export default ConstructionStep;
