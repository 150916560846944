import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="5"
    height="10"
    viewBox="0 0 9 15"
    stroke="currentColor"
    fill="transparent"
    {...props}
  >
    <path
      d="M1 1l7 6.5L1 14"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
