import { ObjectValues } from 'types';
import { HullMaterialType } from './Yacht';

export const SEARCH_CREATE_STEPS = {
  TYPE_OF_VESSEL: 'Type of vessel',
  NEW_OR_USED: 'New or used',
  BUDGET: 'Budget',
  GUEST_CAPACITY: 'Guest capacity',
  LOCATION: 'Location',
  SPEED: 'Speed',
  YEAR_OF_BUILD: 'Year of build',
  FUEL_CONSUMPTION: 'Fuel consumption',
  SPECIFIC_EQUIPMENT: 'Specific equipment',
  CHARTER: 'Charter',
} as const;

export type SearchSteps = ObjectValues<typeof SEARCH_CREATE_STEPS>;

export const YACHT_SEARCH_TYPE = {
  MOTOR: 'Motor',
  SAILING: 'Sailing',
  I_DONT_CARE: "I don't care",
} as const;
export type YachtSearchType = ObjectValues<typeof YACHT_SEARCH_TYPE>;

export const YACHT_SEARCH_STATUS = {
  NEW: 'New',
  USED: 'Used',
  I_DONT_CARE: "I don't care",
} as const;
export type YachtSearchStatus = ObjectValues<typeof YACHT_SEARCH_STATUS>;

export const LOCATION_SEARCH = {
  ITALY: 'Italy',
  MONACO: 'Monaco',
  TURKEY: 'Turkey',
  GREECE: 'Greece',
  ADRIATIC: 'Adriatic',
  FRANCE: 'France',
  SPAIN: 'Spain',
} as const;
export type LocationSearch = ObjectValues<typeof LOCATION_SEARCH>;

export const SPEED_TYPE_SEARCH = {
  CRUISING: 'Cruising',
  A_TO_B: 'Getting from point A to point B ASAP',
  I_DONT_CARE: "I don't care",
} as const;
export type SpeedTypeSearch = ObjectValues<typeof SPEED_TYPE_SEARCH>;

export const FUEL_TYPE_SEARCH = {
  PETROL: 'Petrol',
  DIESEL: 'Diesel',
} as const;
export type FuelTypeSearch = ObjectValues<typeof FUEL_TYPE_SEARCH>;

export const PREFERED_ENGINE_SEARCH = {
  INBOARD: 'Inboard',
  OUTBOARD: 'Outboard',
} as const;
export type PreferedEngineSearch = ObjectValues<typeof PREFERED_ENGINE_SEARCH>;

export const MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER = {
  YES: 'Yes',
  NO: 'No',
  I_DONT_CARE: "I don't care",
} as const;
export type MakeVesselAvailableForCommercialCharter = ObjectValues<
  typeof MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER
>;

export const ADVANCED_SEARCH_YACHT_TYPE = {
  SAILING: 'Sailing',
  MOTOR: 'Motor',
  BOTH: 'Both',
} as const;

export type AdvancedSearchYachtType = ObjectValues<
  typeof ADVANCED_SEARCH_YACHT_TYPE
>;

export const ADVANCED_SEARCH_YACHT_STATUS = {
  NEW: 'New',
  USED: 'Used',
  BOTH: 'Both',
} as const;

export type AdvancedSearchYachtStatus = ObjectValues<
  typeof ADVANCED_SEARCH_YACHT_TYPE
>;

export type NumberRange = {
  from: number | string;
  to: number | string;
};

export type AvailableVesselForCommercilaCharterInfo = {
  potentialAnnualCharterIncome: NumberRange;
  runningCost: NumberRange;
};

//step 1 - Type Of Vessel
type TypeOfVesselInfo = {
  yachtType: YachtSearchType;
};

//step 2 - New Or Used
type NewOrUsedInfo = {
  yachtStatus: YachtSearchStatus;
};

//step 3 - Budget
type BudgetInfo = {
  budget: NumberRange;
  runningCost: NumberRange;
  sizeRange: NumberRange;
};

//step 4 - Guest Capacity
type GuestCapacityInfo = {
  guestCapacity: number;
  numberOfCabins: number;
};

//step 5 - Location
type LocationInfo = {
  location: LocationSearch;
};

//step 6 - Speed
type SpeedInfo = {
  speedType?: SpeedTypeSearch;
};

//step 7 - Year Build
type YearBuildInfo = {
  yearBuildMatters?: boolean;
  yearBuilt?: number;
};

//step 8 - Fuel Consumption
type FuelConsumptionInfo = {
  fuelType?: FuelTypeSearch;
  doesFuelConsumptionMatter?: boolean;
  preferedEngine?: PreferedEngineSearch;
};

//step 9 - Specific Equipment
type SpecificEquipmentInfo = {
  hasShorePowerInlet?: boolean;
  hasGenerator?: boolean;
  hasInverter?: boolean;
  hasTender?: boolean;
  hasAutopilot?: boolean;
  hasCockpitSpeakers?: boolean;
  hasStabilizer?: boolean;
  hasMarineHead?: boolean;
  hasSternThruster?: boolean;
  hasBowThruster?: boolean;
  hasAirConditioninig?: boolean;
  hasHotWater?: boolean;
  hasFreshWaterMaker?: boolean;
};

//step 10 - Charter
type CharterInfo = {
  makeVesselCommercialyAvailable?: MakeVesselAvailableForCommercialCharter;
  vesselAvailableForCommercialCharterInfo?: AvailableVesselForCommercilaCharterInfo;
};

//advanced search
type AddvancedSearchInfo = {
  category?: string;
  builderName?: string;
  yachtModel?: string;
  yearDelivery?: NumberRange;
  yearBuild?: NumberRange;
  yearRefit?: NumberRange;
  hullType?: HullMaterialType;
  hullConfiguration?: string;
  hullMaterial?: string;
  minSleeps?: number;
  grossTonnage?: NumberRange;
  minBeam?: number;
  draft?: NumberRange;
  engineManufacturer?: string;
  engineModel?: string;
  maxSpeed?: number;
  state?: string;
  city?: string;
  taxStatus?: string;
};

export type Search = {
  _id: string;
  __v: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  isSubmitted: boolean;
  hasAdvancedSearch: boolean;
  hasMoreInfo: boolean;
  searchName: string;
  userId: string;
  numberOfMatches?: number;
  hasAncillaryEquipment?: boolean;
  hasFireChainWasher?: boolean;
  hasBilgePump?: boolean;
  hasSewageTreatment?: boolean;
  hasEngineRoomBlowers?: boolean;
  fuelConsumptionPlaysRole?: boolean;
  speed?: number;
} & TypeOfVesselInfo &
  NewOrUsedInfo &
  BudgetInfo &
  GuestCapacityInfo &
  LocationInfo &
  SpeedInfo &
  YearBuildInfo &
  FuelConsumptionInfo &
  SpecificEquipmentInfo &
  CharterInfo &
  AddvancedSearchInfo;
