import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import CloseIcon from 'icons/Close.icon';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';

import './FormHeader.styles.scss';
import './FormHeader.responsive.styles.scss';

type FormHeaderProps = {
  className?: string;
  title: string;
  redirectPath?: string;
  onClose?: () => void;
};

const FormHeader: React.FC<FormHeaderProps> = (props) => {
  const { goBack } = useContext(StepsContext);
  const { className, title, onClose = goBack } = props;

  const classes = classNames('yx-form-header', className);

  const handleOnClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <div className={classes}>
      <h1>{title}</h1>
      <Button variant="link" icon={CloseIcon} onClick={handleOnClose} />
    </div>
  );
};

export default FormHeader;
