import React, { useMemo } from 'react';
import classNames from 'classnames';
import './PasswordInputField.styles.scss';
import PasswordInput from '../PasswordInput';
import { FieldRenderProps } from 'react-final-form';
import { ExtractPropTypes } from 'types';
import { Input } from 'ncoded-component-library';

type InputProps = ExtractPropTypes<typeof Input>;

type PasswordInputFieldProps = InputProps &
  FieldRenderProps<string, HTMLElement> & {
    label: string;
    buttonText?: string;
    onButtonClick?: () => void;
  };

const PasswordInputField: React.FC<PasswordInputFieldProps> = (props) => {
  const {
    className,
    label,
    input,
    buttonText,
    onButtonClick,
    meta: { touched, error },
    ...rest
  } = props;
  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = classNames(
    'yx-password-input-field',
    { 'yx-password-input-field--error': hasError },
    className,
  );

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <PasswordInput {...input} {...rest} hasError={hasError} />
      {hasError && <p>{error}</p>}
    </div>
  );
};

export default PasswordInputField;
