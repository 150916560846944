import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import ImagesIcon from 'icons/Images.icon';
import ImagePreview from './components/ImagePreview';
import useMatchMedia from 'hooks/useMatchMedia';

import './ImageCarousel.styles.scss';
import './ImageCarousel.responsive.styles.scss';

export type ImageCarouselType = {
  url: string;
  type: string;
};

type ImageCarouselProps = {
  className?: string;
  title?: string;
  elements: Array<ImageCarouselType>;
};

const ImageCarousel: React.FC<ImageCarouselProps> = (props) => {
  const isLaptop = useMatchMedia('isLaptop');
  const isPhablet = useMatchMedia('isPhablet');
  const { className, title, elements } = props;

  const { t } = useTranslation();
  const classes = classNames('yx-image-carousel', className);

  const [previewModalOpened, setPreviewModalOpened] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const previewImages = useMemo(
    () => elements.filter((el) => !el.type.includes('video')),
    [elements],
  );

  const previewElements = useMemo(
    () => elements?.slice(0, isLaptop || isPhablet ? 3 : 5),
    [elements, isLaptop, isPhablet],
  );

  const elementsCount = useMemo(
    () => elements.filter((el) => el.type.includes('image')).length,
    [elements],
  );

  const videoElements = useMemo(
    () => previewElements.filter((pe) => pe.type.includes('video')),
    [previewElements],
  );

  useEffect(() => {
    if (previewModalOpened) {
      const video = document.querySelector('video');

      if (video && !video.paused) {
        video.pause();
      }
    }
  }, [previewModalOpened]);

  if (!elementsCount) return null;

  return (
    <div className={classes}>
      {previewModalOpened && (
        <ImagePreview
          title={title}
          images={previewImages}
          activeIndex={activeIndex}
          onClose={() => setPreviewModalOpened(false)}
        />
      )}

      {previewElements?.map((element, index, { length }) => {
        const isFirstVideo =
          element.type.includes('video') &&
          videoElements[0].url === element.url;

        return (
          <div
            key={index}
            className={classNames('yx-image-carousel__item', {
              'yx-image-carousel__video': element.type.includes('video'),
              'yx-image-carousel__image': element.type.includes('image'),
              'yx-image-carousel__item--only': length === 1,
              'yx-image-carousel__item--span-2': length === 2,
              'yx-image-carousel__item--span-3':
                (isLaptop || isPhablet) && index === 0 && length > 2,
              'yx-image-carousel__item--last':
                index === previewElements.length - 1,
            })}
            onClick={() => {
              if (element.type.includes('video')) return;
              setPreviewModalOpened(true);
              setActiveIndex(index - videoElements.length);
            }}
          >
            {element.type.includes('video') && (
              <video
                muted={isFirstVideo}
                autoPlay={isFirstVideo}
                playsInline={isFirstVideo}
                loop={isFirstVideo}
                controls
                src={element.url}
              />
            )}

            {element.type.includes('image') && (
              <img src={element.url} alt="yacth profile shot" />
            )}

            {index === previewElements.length - 1 && index > 0 && (
              <div className="yx-image-carousel__show-all">
                <Button
                  icon={ImagesIcon}
                  styleType="secondary"
                  onClick={() => setPreviewModalOpened(true)}
                >
                  {t('showAllPhotos', { count: elementsCount })}
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ImageCarousel;
