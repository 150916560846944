import httpClient from 'api/httpClient';
import { PaginatedData, PaginationData } from 'models/PaginatedData';
import { Search, SearchSteps } from 'models/Search';

const searchPath = (routePath: TemplateStringsArray) => `searches/${routePath}`;

function getSearchById(searchId: string) {
  return httpClient.get<{ search: Search }>(`searches/${searchId}`);
}

function getSearches(params: PaginationData) {
  return httpClient.get<PaginatedData<Search>>(`searches`, {
    params,
  });
}

function createInitialSearch() {
  return httpClient.post<{ search: Partial<Search> }>(searchPath``);
}

function updateSearch(
  searchId: string,
  step: SearchSteps,
  data: Partial<Search>,
) {
  return httpClient.put<{ search: Partial<Search> }>(
    `searches/${searchId}/${step}`,
    data,
  );
}

function submitSearch(searchId: string, data: Partial<Search>) {
  return httpClient.post<{ search: Partial<Search> }>(
    `searches/${searchId}/submit`,
    data,
  );
}

function editSearch(searchId: string, data: Partial<Search>) {
  return httpClient.put<{ search: Search }>(`searches/${searchId}`, data);
}

function deleteSearch(searchId: string) {
  return httpClient.delete(`searches/${searchId}`);
}

export default {
  getSearchById,
  getSearches,
  createInitialSearch,
  updateSearch,
  submitSearch,
  editSearch,
  deleteSearch,
};
