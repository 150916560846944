import { LocationSearch, LOCATION_SEARCH } from 'models/Search';
import {
  ClassificationSocietyType,
  CLASSIFICATION_SOCIETY,
  DeckType,
  DECK_TYPE,
  ElectricityType,
  FuelType,
  FUEL_TYPE,
  HullMaterialType,
  HULL_MATERIAL,
  OptionalDocumentsKeys,
  STABILIZATION,
  StabilizationType,
  TenderAndToysType,
  TENDER_AND_TOYS,
  VAT_STATUS,
} from 'models/Yacht';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

export const vatStasusOptions = [
  {
    label: VAT_STATUS.PAID,
    value: 'Paid',
  },
  {
    label: VAT_STATUS.UNPAID,
    value: 'Unpaid',
  },
  {
    label: VAT_STATUS.EXEMPT,
    value: 'Exempt',
  },
] as Array<OptionValue>;

export const yearOptions = Array.from({
  length: new Date().getFullYear() - 1900,
}).map((_, index) => {
  return {
    label: (new Date().getFullYear() - index).toString(),
    value: new Date().getFullYear() - index,
  } as OptionValue<number | string>;
});

export const hullMaterialOptions = [
  {
    value: HULL_MATERIAL.STEEL,
    label: 'Steel',
  },
  {
    value: HULL_MATERIAL.ALUMINIUM,
    label: 'Aluminium',
  },
  {
    value: HULL_MATERIAL.WOOD,
    label: 'Wood',
  },
  {
    value: HULL_MATERIAL.FERROCEMENT,
    label: 'Ferrocement',
  },
  {
    value: HULL_MATERIAL.FIBERGLASS,
    label: 'Fiberglass',
  },
] as Array<OptionValue<HullMaterialType>>;

export const fuelOptions = [
  {
    value: FUEL_TYPE.DIESEL,
    label: 'Diesel',
  },
  {
    value: FUEL_TYPE.PETROL,
    label: 'Petrol',
  },
  {
    value: FUEL_TYPE.ELECTRIC,
    label: 'Electric',
  },
  {
    value: FUEL_TYPE.HYBRID,
    label: 'Hybrid',
  },
] as Array<OptionValue<FuelType>>;

export const stabilizationOptions = [
  {
    value: STABILIZATION.ACTIVE_FIN_STABILIZERS,
    label: 'Active fin stabilizers',
  },
  {
    value: STABILIZATION.ACTIVE_TANKS,
    label: 'Active tanks',
  },
  {
    value: STABILIZATION.GYRO_STABILIZERS,
    label: 'Gyro stabilizers',
  },
  {
    value: STABILIZATION.NONE,
    label: 'None',
  },
] as Array<OptionValue<StabilizationType>>;

export const tenderAndToysOptions = [
  {
    value: TENDER_AND_TOYS.JETSKI,
    label: 'Jet Ski',
  },
  {
    value: TENDER_AND_TOYS.AQUALUNG,
    label: 'Aqualung',
  },
  {
    value: TENDER_AND_TOYS.FLYBOARD,
    label: 'Flyboard',
  },
  {
    value: TENDER_AND_TOYS.SEABOB,
    label: 'Seabob',
  },
  {
    value: TENDER_AND_TOYS.TENDER,
    label: 'Tender',
  },
  {
    value: TENDER_AND_TOYS.WAKEBOARD,
    label: 'Wakeboard',
  },
] as Array<OptionValue<TenderAndToysType | string>>;

export const documentsOptions = [
  {
    value: 'shipStationLicence',
    label: 'Ship Station Licence',
  },
  {
    value: 'cargoShipSafetyRadioCertificate',
    label: 'Cargo Ship Saferty Radio Certificate',
  },
  {
    value: 'internationalAirPollutionCertificate',
    label: 'International Air Pollution Certificate',
  },
  {
    value: 'antiFoulingSystemCertificate',
    label: 'Anti Fouling System Certificate',
  },
  {
    value: 'internationalLoadLineCertficate',
    label: 'International Load Line Certificate',
  },
  {
    value: 'internationalSewagePollutionPreventionCertificate',
    label: 'International Sewage Pollution Prevention Certificate',
  },
  {
    value: 'statementOfComplianceForLabourConvention',
    label: 'Statement Of Compliance For Labour Convention',
  },
  {
    value: 'minimumSafeManningCertificate',
    label: 'Minimum Safe Manning Certificate',
  },
  {
    value: 'maltaCertificateOfSurvey',
    label: 'Malta Certificate Of Survey',
  },
  {
    value: 'internationalTonnageCertificate',
    label: 'International Tonnage Certificacte',
  },
  {
    value: 'certificateOfCompliance',
    label: 'Certificate Of Complience',
  },
  {
    value: 'internationalOilPollutionPreventionCertificate',
    label: 'Internation Oil Pollution Prevention Certificate',
  },
  {
    value: 'marpolCompliance',
    label: 'Marpol Compliance',
  },
  {
    value: 'internationalAntiFoulingSystemCertificate',
    label: 'International Anti Fouling System Certificate',
  },
] as Array<OptionValue<OptionalDocumentsKeys>>;

export const deckOptions = [
  {
    value: DECK_TYPE.TEEK,
    label: 'Teek',
  },
  {
    value: DECK_TYPE.NO_TEEK,
    label: 'No teek',
  },
] as Array<OptionValue<DeckType>>;

export const electricityOptions = [
  {
    value: 110,
    label: '110V',
  },
  {
    value: 220,
    label: '220V',
  },
  {
    value: 380,
    label: '380V',
  },
  {
    value: 400,
    label: '400V',
  },
] as Array<OptionValue<ElectricityType>>;

export const classificationSocietyOptions = [
  {
    value: CLASSIFICATION_SOCIETY.ABS,
    label: 'American Bureau of Shipping',
  },
  {
    value: CLASSIFICATION_SOCIETY.BV,
    label: 'Bureau Veritas',
  },
  {
    value: CLASSIFICATION_SOCIETY.CCS,
    label: 'China Classification Society',
  },
  {
    value: CLASSIFICATION_SOCIETY.CRS,
    label: 'Croatian Register of Shipping',
  },
  {
    value: CLASSIFICATION_SOCIETY.DNV_GL,
    label: 'DNV GL AS',
  },
  {
    value: CLASSIFICATION_SOCIETY.IRS,
    label: 'Indian Register of Shipping',
  },
  {
    value: CLASSIFICATION_SOCIETY.KR,
    label: 'Korean Register',
  },
  {
    value: CLASSIFICATION_SOCIETY.LR,
    label: "Lloyd's Register",
  },
  {
    value: CLASSIFICATION_SOCIETY.NK,
    label: 'Nippon Kaiji Kyokai (Class NK)',
  },
  {
    value: CLASSIFICATION_SOCIETY.PRS,
    label: 'Polish Register of Shipping',
  },
  {
    value: CLASSIFICATION_SOCIETY.RINA,
    label: 'RINA',
  },
  {
    value: CLASSIFICATION_SOCIETY.RS,
    label: 'Russian Maritime Register of Shipping',
  },
] as Array<OptionValue<ClassificationSocietyType>>;

export const searchLocations = [
  {
    value: LOCATION_SEARCH.ITALY,
    label: 'Italy',
  },
  {
    value: LOCATION_SEARCH.MONACO,
    label: 'Monaco',
  },
  {
    value: LOCATION_SEARCH.TURKEY,
    label: 'Turkey',
  },
  {
    value: LOCATION_SEARCH.GREECE,
    label: 'Greece',
  },
  {
    value: LOCATION_SEARCH.ADRIATIC,
    label: 'Adriatic',
  },
  {
    value: LOCATION_SEARCH.FRANCE,
    label: 'France',
  },
  {
    value: LOCATION_SEARCH.SPAIN,
    label: 'Spain',
  },
] as Array<OptionValue<LocationSearch>>;
