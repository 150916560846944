import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="28" height="23" viewBox="0 0 28 23" fill="transparent" {...props}>
    <path
      d="M18.736 16.363l-4.728-4.728-4.728 4.728M14.008 11.635v10.637"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.924 19.187A5.91 5.91 0 0021.1 8.09H19.61a9.455 9.455 0 10-16.24 8.628"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.736 16.363l-4.728-4.728-4.728 4.728"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
