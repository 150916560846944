import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useIsEditRoute from 'hooks/useIsEditRoute';
import confirm from 'modules/confirm';
import GeneralContext from 'providers/General/General.context';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useContext,
  useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { RoutingStep } from 'router/subrouters/SignUp/models/Routes';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import StepProviderContext, {
  renderAsLinks,
  StepPath,
  StepsContextProps,
} from './Steps.context';

type StepsProps = {
  storageKeySuffix: string;
  steps?: Array<RoutingStep> | Array<ReactNode>;
  children?: React.ReactNode;
  useDraftButtons?: boolean;
  closeFallbackRoute?: string;
  blockOtherStepsWhenCurrentIsInvalid?: boolean;
};

export type StepsRef = StepsContextProps;

const StepsProvider: React.ForwardRefRenderFunction<StepsRef, StepsProps> = (
  props,
  ref,
) => {
  const {
    steps: pSteps = [],
    storageKeySuffix,
    useDraftButtons = false,
    children,
    closeFallbackRoute = '/home',
    blockOtherStepsWhenCurrentIsInvalid = false,
  } = props;

  const { from } = useContext(GeneralContext);

  const location = useLocation();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isEdit = useIsEditRoute();

  const initialFrom = useRef(from);

  const goBack = useCallback(
    async (ask = true) => {
      if (
        !ask ||
        (await confirm({
          title: t('unsavedData'),
          content: t('unsavedDataCopy'),
        }))
      ) {
        if (
          !initialFrom.current ||
          location.pathname.includes(initialFrom.current) ||
          initialFrom.current.includes(location.pathname)
        ) {
          history.push(closeFallbackRoute);
        } else history.push(initialFrom.current || closeFallbackRoute);
      }
    },
    [t, location.pathname, history, closeFallbackRoute],
  );

  const initCompletedFlagsForEdit = useCallback(
    (steps: Array<RoutingStep>) =>
      steps.map((step) => ({ ...step, isStepValid: true })),
    [],
  );

  const [steps, setSteps] = useState<Array<RoutingStep>>(
    isEdit
      ? initCompletedFlagsForEdit(pSteps as Array<RoutingStep>)
      : (pSteps as Array<RoutingStep>),
  );

  const key = useMemo(
    () => [STORAGE_KEYS.COMPLETED_STEPS, storageKeySuffix].join('-'),
    [storageKeySuffix],
  );

  const [completedSteps, setCompletedSteps] = useState(
    storageService.getItem(key) || 0,
  );

  const stepperLength = useMemo(() => steps.length, [steps.length]);

  const useLinks = useMemo(() => renderAsLinks(steps), [steps]);

  const routingStepIndex = useMemo(
    () =>
      renderAsLinks(steps)
        ? steps.findIndex(
            (step) => `${routeMatch.url}${step.path}` === location.pathname,
          )
        : null,
    [location.pathname, routeMatch.url, steps],
  );

  const [currentStep, setCurrentStep] = useState<number>(
    useLinks ? routingStepIndex : completedSteps,
  );

  const nextStep = useCallback(() => {
    if (renderAsLinks(steps)) {
      if (currentStep < stepperLength - 1) {
        history.push(`${routeMatch.url}${steps[currentStep + 1].path}`);
      }
    } else {
      setCurrentStep((oldStep) => Math.min(oldStep + 1, stepperLength - 1));
    }
  }, [steps, currentStep, stepperLength, history, routeMatch.url]);

  const prevStep = useCallback(() => {
    if (renderAsLinks(steps)) {
      if (currentStep > 0) {
        history.push(`${routeMatch.url}${steps[currentStep - 1].path}`);
      }
    } else {
      setCurrentStep((oldStep) => Math.max(oldStep - 1, 0));
    }
  }, [steps, currentStep, history, routeMatch.url]);

  const stepLabels = useMemo(
    () =>
      renderAsLinks(steps) ? steps.map((route) => t(route.labelId)) : steps,
    [steps, t],
  );

  const linkSteps = useMemo(
    () =>
      renderAsLinks(steps)
        ? steps.map(
            (route) =>
              ({
                label: t(route.labelId),
                path: `${routeMatch.url}${route.path}`,
                isStepValid: route.isStepValid,
              } as StepPath),
          )
        : null,
    [routeMatch.url, steps, t],
  );

  const removeCompletedStepsFromStorage = useCallback(() => {
    storageService.removeItem(key);
  }, [key]);

  const providedValues = useMemo(
    () =>
      ({
        currentStep,
        steps,
        stepLabels,
        linkSteps,
        renderAsLinks: useLinks,
        completedSteps,
        storageKeySuffix,
        useDraftButtons,
        blockOtherStepsWhenCurrentIsInvalid,
        setSteps,
        removeCompletedStepsFromStorage,
        setCompletedSteps,
        setCurrentStep,
        prevStep,
        nextStep,
        goBack,
      } as StepsRef),
    [
      currentStep,
      steps,
      linkSteps,
      stepLabels,
      useLinks,
      completedSteps,
      storageKeySuffix,
      useDraftButtons,
      blockOtherStepsWhenCurrentIsInvalid,
      setSteps,
      removeCompletedStepsFromStorage,
      setCompletedSteps,
      nextStep,
      prevStep,
      goBack,
    ],
  );

  useImperativeHandle(ref, () => providedValues, [providedValues]);

  useEffectSkipFirst(() => {
    if (useLinks && routingStepIndex >= 0) {
      setCurrentStep(routingStepIndex);
      setCompletedSteps((old) => Math.max(old, routingStepIndex));
    }
  }, [routingStepIndex, useLinks]);

  useEffectSkipFirst(() => {
    if (!useLinks) setCompletedSteps((old) => Math.max(old, currentStep));
  }, [useLinks, currentStep]);

  useEffectSkipFirst(() => {
    storageService.setItem(key, completedSteps);
  }, [completedSteps]);

  useEffect(() => {
    if (useLinks) return;

    setCurrentStep((old) => (completedSteps < old ? completedSteps : old));
  }, [completedSteps, useLinks]);

  useEffect(() => {
    if (isEdit) {
      setCompletedSteps(steps.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    return () => {
      removeCompletedStepsFromStorage();
    };
  }, [removeCompletedStepsFromStorage]);

  useEffectSkipFirst(() => {
    document.querySelector('#root')?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  if (useLinks && completedSteps < currentStep) {
    return (
      <Redirect
        to={((steps[completedSteps] as RoutingStep).path as string).slice(1)}
      />
    );
  }

  return (
    <StepProviderContext.Provider value={providedValues}>
      {children}
    </StepProviderContext.Provider>
  );
};

export default forwardRef(StepsProvider);
