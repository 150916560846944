import React from 'react';
import classNames from 'classnames';
import './FieldLabel.styles.scss';
import { TooltipProps } from '../../../Tooltip/Tooltip.component';

type FieldLabelProps = {
  className?: string;
  tooltip?: React.FC<TooltipProps>;
};

const FieldLabel: React.FC<FieldLabelProps> = (props) => {
  const { children, className, tooltip } = props;

  const classes = classNames('yx-field-label', className);

  return (
    <label className={classes}>
      <span>{children}</span>
      <span>{tooltip}</span>
    </label>
  );
};

export default FieldLabel;
