import React, { useMemo } from 'react';
import classNames from 'classnames';
import TrashIcon from '../../../../../icons/Trash.icon';
import useCallbackRef from 'hooks/useCallbackRef';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { Modal } from 'ncoded-component-library';
import { DropzoneFieldFile } from '../../DropzoneField.component';

import './DisplayDropzoneFile.styles.scss';

type DisplayDropzoneFileProps = {
  className?: string;
  type: string;
  onFileDelete: (file: DropzoneFieldFile) => void;
  fileKey: string;
  id: string;
} & FileProps;

type FileProps = {
  key: string;
  name: string;
  url: string;
  className?: string;
};

const Image: React.FC<FileProps> = ({ key, name, url, className }) => (
  <img src={url} alt={name} key={key} className={className} />
);

const Video: React.FC<FileProps & { controls?: boolean }> = ({
  key,
  name,
  url,
  className,
  controls,
}) => <video src={url} key={key} className={className} controls={controls} />;

const Application: React.FC<FileProps> = ({ key, name, url, className }) => (
  <iframe src={url} key={key} className={className} title={name} />
);

const fileJsxWithType = {
  image: Image,
  video: Video,
  application: Application,
} as const;

export type FileType = keyof typeof fileJsxWithType;

const DisplayDropzoneFile: React.FC<DisplayDropzoneFileProps> = (props) => {
  const {
    onFileDelete,
    className,
    children,
    fileKey: key,
    ...dropzoneFile
  } = props;

  const { type: propType, id, ...rest } = dropzoneFile;
  const [modal, modalRef] = useCallbackRef<OverlayRef>();

  const type = useMemo(
    () =>
      (propType.includes('/')
        ? propType.split('/')?.[0]
        : propType) as FileType,
    [propType],
  );

  const classes = classNames('yx-display-dropzone-file', className);
  const modalClasses = classNames('yx-display-dropzone-file__modal', {
    'iframe-modal': type === 'application',
  });

  return (
    <div className={classes}>
      {fileJsxWithType[type]?.({
        ...rest,
        key,
        className: 'yx-display-dropzone-file__file',
      })}
      <div
        className="yx-display-dropzone-file__overlay"
        onClick={() => modal?.open()}
      >
        <button
          className="yx-display-dropzone-file__overlay__delete"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            onFileDelete({ ...dropzoneFile, key } as DropzoneFieldFile);
          }}
        >
          <TrashIcon />
        </button>
      </div>
      <Modal className={modalClasses} ref={modalRef}>
        {fileJsxWithType[type]?.({ ...rest, key, controls: true })}
      </Modal>
    </div>
  );
};

export default DisplayDropzoneFile;
