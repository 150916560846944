import React from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

import './SimpleCard.styles.scss';
import './SimpleCard.responsive.styles.scss';

type SimpleCardProps = {
  className?: string;
  children: React.ReactNode;
  title?: string;
  onClick: () => void;
};

const SimpleCard: React.FC<SimpleCardProps> = (props) => {
  const { children, title = '', className, onClick } = props;

  const classes = classNames('yx-simple-card', className);
  const { t } = useTranslation();

  return (
    <div className={classes}>
      <label>{title}</label>
      {children}
      <Button styleType="secondary" onClick={onClick}>
        {t('seeMore')}
      </Button>
    </div>
  );
};

export default SimpleCard;
