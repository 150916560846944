import React from 'react';
import Home from './Home.page';
import HomeProvider from './providers/Home';

const wrapperHome = () => (
  <HomeProvider>
    <Home />
  </HomeProvider>
);

export default wrapperHome;
