import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="108" height="107" viewBox="0 0 108 107" fill="transparent">
    <circle cx="61.2092" cy="39.8439" r="21.0246" fill="#F5F6FC" />
  </svg>
);
