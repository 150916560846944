import React from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';

import './HomeSegment.styles.scss';
import './HomeSegment.responsive.styles.scss';

type HomeSegmentProps = {
  className?: string;
  title: string;
  btnTitle?: string;
  onClick?: () => void;
};

const HomeSegment: React.FC<HomeSegmentProps> = (props) => {
  const { children, title, btnTitle = '', onClick, className } = props;

  const classes = classNames('yx-home-segment', className);

  return (
    <section className={classes}>
      <div className="yx-home-segment__header">
        <h3>{title}</h3>
        {btnTitle && (
          <Button variant="link" onClick={onClick}>
            {btnTitle}
          </Button>
        )}
      </div>
      <div className="yx-home-segment__content">{children}</div>
    </section>
  );
};

export default HomeSegment;
