import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import { InfoCardType } from 'components/InfoCard/InfoCard.component';
import { useTranslation } from 'react-i18next';
import { Button, Loader, Tabs } from 'ncoded-component-library';
import { useHistory, useParams } from 'react-router-dom';
import { Tab } from 'ncoded-component-library/build/components/molecules/Tabs/Tabs.component';
import YachtDetails from './components/YachtDetails';
import BeamIcon from 'icons/Beam.icon';
import BoatIcon from 'icons/Boat.icon';
import SpeedIcon from 'icons/Speed.icon';
import CrewIcon from 'icons/Crew.icon';
import DeleteIcon from 'icons/Delete.icon';
import EditIcon from 'icons/Edit.icon';
import StatusLabel from 'components/StatusLabel';
import MyYachtsContext from '../../providers/MyYachts/MyYachts.context';
import HistoryList from './components/HistoryList';
import { HistoryItem } from './components/HistoryList/HistoryList.component';
import ImageCarousel from 'components/ImageCarousel';
import confirm from 'modules/confirm';
import DocumentsTable from './components/DocumentsTable';
import utils from 'utils';
import LocationIcon from 'icons/Location.icon';
import TagIcon from 'icons/Tag.icon';
import LinkIcon from 'icons/Link.icon';
import YachtContext from './providers/Yacht/Yacht.context';
import credentialsService from 'services/credentialsService';
import { ImageCarouselType } from 'components/ImageCarousel/ImageCarousel.component';

import './YachtPreview.styles.scss';
import './YachtPreview.responsive.styles.scss';
import LockedOverlay from 'components/LockedOverlay';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

export type YachtPreviewProps = {
  className?: string;
  isLocked?: boolean;
};

const YachtPreview: React.FC<YachtPreviewProps> = (props) => {
  const { className, isLocked } = props;

  const classes = classNames('yx-yacht-preview', className);

  const { t } = useTranslation();
  const history = useHistory();
  const { yachtId } = useParams<{ yachtId: string }>();
  const { popInfo } = useContext(PopNotificationsContext);

  const { deleteYacht, editYacht, publishYacht } = useContext(MyYachtsContext);

  const {
    yacht: yachtData,
    activeTabIndex,
    loading: yachtLoading,
    setYacht,
    setActiveTabSource,
  } = useContext(YachtContext);

  const {
    status,
    vesselName = t('notSpecifiedParam', { param: t('vesselName') }),
    beam,
    draft,
    hullMaterial,
    grossRegisteredTonnage,
    cruisingSpeed,
    guestCapacity,
    numberOfCabins,
    numberOfMatches,
    profileShots,
    mainSalonShots,
    masterCabinShots,
    miscellaneousShots,
    videos,
    askingPrice,
    overallLength,
    flag,
    userId: yachtOwnerId,
    publicAccessToken,
  } = yachtData ?? {};

  const isPublished = useMemo(() => status === 'Published', [status]);

  const isUserOwnerOfYacht = useMemo(
    () => yachtOwnerId === credentialsService.user?._id,
    [yachtOwnerId],
  );

  const yachtInformation = useMemo(
    () =>
      [
        {
          icon: BeamIcon,
          title: t('geometry-beam'),
          content: beam ? beam + ` ${t('meters')}` : t('noData'),
        },
        {
          icon: BeamIcon,
          title: t('geometry-draft'),
          content: draft ? draft + ` ${t('meters')}` : t('noData'),
        },
        {
          icon: BoatIcon,
          title: t('hull'),
          content: hullMaterial ? hullMaterial : t('noData'),
        },
        {
          icon: BoatIcon,
          title: t('grt'),
          content: grossRegisteredTonnage
            ? `${grossRegisteredTonnage} ${t('tons')}`
            : t('noData'),
        },
        {
          icon: SpeedIcon,
          title: t('cruisingSpeed'),
          content: cruisingSpeed
            ? `${cruisingSpeed} ${t('knots')} `
            : t('noData'),
        },
        {
          icon: CrewIcon,
          title: t('guests'),
          content: guestCapacity ? guestCapacity : t('noData'),
        },
        {
          icon: CrewIcon,
          title: t('cabins'),
          content: numberOfCabins ? numberOfCabins : t('noData'),
        },
      ] as Array<InfoCardType>,
    [
      beam,
      cruisingSpeed,
      draft,
      grossRegisteredTonnage,
      guestCapacity,
      hullMaterial,
      numberOfCabins,
      t,
    ],
  );

  const yachtFiles = useMemo(
    () =>
      [
        yachtData?.commercialYachtSurveyReport,
        yachtData?.certificateOfRegistry,
        yachtData?.centralAgencyAgreement,
        yachtData?.engineCertificate,
        yachtData?.billOfSale,
        yachtData?.CECertificate,
        yachtData?.shipStationLicence,
        yachtData?.cargoShipSafetyRadioCertificate,
        yachtData?.internationalAirPollutionCertificate,
        yachtData?.antiFoulingSystemCertificate,
        yachtData?.internationalLoadLineCertficate,
        yachtData?.statementOfComplianceForLabourConvention,
        yachtData?.internationalSewagePollutionPreventionCertificate,
        yachtData?.statementOfComplianceForLabourConvention,
        yachtData?.minimumSafeManningCertificate,
        yachtData?.maltaCertificateOfSurvey,
        yachtData?.internationalTonnageCertificate,
        yachtData?.certificateOfCompliance,
        yachtData?.internationalOilPollutionPreventionCertificate,
        yachtData?.marpolCompliance,
        yachtData?.internationalAntiFoulingSystemCertificate,
      ].filter((document) => document !== undefined),
    [yachtData],
  );

  const historyListData = useMemo(
    () =>
      yachtData?.maintenanceHistoryInfo?.map((history) => {
        return {
          label: history.year.toString(),
          description: history.description,
        } as HistoryItem;
      }),
    [yachtData],
  );

  const basicInformation = useMemo(
    () =>
      [
        {
          icon: TagIcon,
          title: t('askingPrice'),
          content: askingPrice
            ? `${t('eur')} ${utils.getDotedNumber(askingPrice?.toString())}`
            : '-',
        },
        {
          icon: LocationIcon,
          title: t('region'),
          content: flag ? flag : '-',
        },
        {
          icon: BeamIcon,
          title: t('loa'),
          content: overallLength
            ? `${utils.getDotedNumber(overallLength?.toString())} ${t(
                'meters',
              )}`
            : '-',
        },
      ] as Array<InfoCardType>,
    [askingPrice, flag, overallLength, t],
  );

  const navTabs = useMemo(
    () =>
      [
        {
          label: t('details'),
          element: <YachtDetails yachtData={yachtData} />,
        },
        {
          label: t('history'),
          element: <HistoryList items={historyListData} />,
        },
        {
          label: t('documents', {
            count: yachtFiles?.length || 0,
          }),
          element: <DocumentsTable userId={yachtOwnerId} files={yachtFiles} />,
        },
      ] as Array<Tab>,
    [t, yachtData, historyListData, yachtFiles, yachtOwnerId],
  );

  const statusLabel = useMemo(
    () =>
      status === 'Published' ? (
        <StatusLabel statusType="active" label={t('active')} />
      ) : status === 'Drafted' ? (
        <StatusLabel statusType="inactive" label={t('draft')} />
      ) : status === 'Unpublished' ? (
        <StatusLabel statusType="inactive" label={t('unpublished')} />
      ) : (
        <StatusLabel statusType="inactive" label={t('suspended')} />
      ),
    [status, t],
  );

  const previewElements = useMemo(
    () =>
      [
        ...(videos || []),
        ...(profileShots || []),
        ...(masterCabinShots || []),
        ...(mainSalonShots || []),
        ...(miscellaneousShots || []),
      ]?.map((element) => {
        return {
          url: element.url,
          type: element.type,
        } as ImageCarouselType;
      }),
    [
      videos,
      mainSalonShots,
      masterCabinShots,
      miscellaneousShots,
      profileShots,
    ],
  );

  const handleOnDeleteYacht = useCallback(async () => {
    if (
      await confirm({
        title: t('deleteYacht'),
        type: 'delete',
        content: t('deleteYacht-info'),
      })
    ) {
      deleteYacht(yachtId);
      history.goBack();
    }
  }, [deleteYacht, history, t, yachtId]);

  const copyYachtLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${window.location.host}/yacht/${yachtId}?token=${publicAccessToken}`,
    );
    popInfo({ content: t('copiedYachtLink') });
  }, [popInfo, publicAccessToken, t, yachtId]);

  return (
    <div className={classes}>
      <div className="yx-yacht-preview__container">
        {yachtLoading && <Loader />}

        {previewElements?.length > 0 && (
          <div className="yx-yacht-preview__images">
            <ImageCarousel elements={previewElements} />
          </div>
        )}

        <div className="yx-yacht-preview__heading">
          <div className="yx-yacht-preview__heading--left">
            <h1>{vesselName}</h1>
            {statusLabel}
          </div>

          <div className="yx-yacht-preview__heading--center">
            {basicInformation.map((data, index) => (
              <InfoCard key={index} infoData={data} />
            ))}
          </div>

          {isUserOwnerOfYacht && (
            <div className="yx-yacht-preview__heading--right">
              {isPublished ? (
                <Button
                  className={classNames('yx-yacht-preview__matches', {
                    disabled: !numberOfMatches,
                  })}
                  onClick={() => {}}
                  disabled={!numberOfMatches}
                >
                  {t('countMatches', { count: numberOfMatches })}
                </Button>
              ) : (
                <Button
                  onClick={async () => {
                    const yacht = await publishYacht(yachtId, yachtData);

                    if (yacht) {
                      setYacht(yacht);
                    }
                  }}
                >
                  {t('publish')}
                </Button>
              )}
              <Button
                className="yx-yacht-preview__edit"
                icon={LinkIcon}
                onClick={() => copyYachtLinkToClipboard()}
              />
              <Button
                className="yx-yacht-preview__edit"
                icon={EditIcon}
                onClick={() => editYacht(yachtId, isPublished)}
              />
              <Button
                className="yx-yacht-preview__delete"
                icon={<DeleteIcon width="18px" height="18px" />}
                onClick={handleOnDeleteYacht}
              />
            </div>
          )}
        </div>

        <div className="yx-yacht-preview__info">
          {yachtInformation.map((info, index) => (
            <InfoCard key={index} infoData={info} />
          ))}
        </div>

        <div className="yx-yacht-preview__tabs--container">
          {isLocked && <LockedOverlay text={t('yachtLockedText')} />}
          <Tabs
            activeIndex={activeTabIndex}
            itemClicked={(index) => setActiveTabSource(index)}
            className="yx-yacht-preview__tabs"
            items={navTabs}
          />
        </div>
      </div>
    </div>
  );
};

export default YachtPreview;
