import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import RadioFields from 'components/Fields/RadioFields';
import { yachtStatusFields } from './radioFields';

import './NewOrUsedStep.styles.scss';

type NewOrUsedStepProps = {
  className?: string;
};

const NewOrUsedStep: React.FC<NewOrUsedStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-new-or-used-step', className);

  const { t } = useTranslation();

  return (
    <StepWrapper title={t('newOneOrUsedBoat')} className={classes}>
      <RadioFields
        className="yx-new-or-used-step__fields"
        radioFields={yachtStatusFields}
        name="yachtStatus"
      />
    </StepWrapper>
  );
};

export default NewOrUsedStep;
