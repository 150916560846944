import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import './ViewingStepActions.styles.scss';
import { useTranslation } from 'react-i18next';
import CancelMeeting from '../../modals/CancelMeeting';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import confirm from 'modules/confirm';
import MatchContext from '../../providers/Match/Match.context';

type ViewingStepActionsProps = {
  className?: string;
};

const ViewingStepActions: React.FC<ViewingStepActionsProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const [modal, modalRef] = useCallbackRef<ModalRef>();
  const { postponeMatch } = useContext(MatchContext);

  const classes = classNames('yx-viewing-step-actions', className);

  const handlePostpone = useCallback(async () => {
    if (
      await confirm({
        title: t('postponeTitle'),
        content: t('postponeContent'),
      })
    ) {
      postponeMatch();
    }
  }, [postponeMatch, t]);

  return (
    <div className={classes}>
      <p>{t('doYouWantTo')}</p>
      <button onClick={() => modal?.open()}>{t('cancel')}</button>
      <p>{t('or')}</p>
      <button onClick={() => handlePostpone()}>{t('postpone')}</button>
      <p>{t('theMeeting')}?</p>
      <CancelMeeting ref={modalRef} />
    </div>
  );
};

export default ViewingStepActions;
