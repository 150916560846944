import React from 'react';
import classNames from 'classnames';
import './MatchesError.styles.scss';
import { useTranslation } from 'react-i18next';
import CanceledIcon from '../../../../../../../icons/Canceled.icon';
import MessageScreen from '../../components/MessageScreen';

type MatchesErrorProps = {
  className?: string;
};

const MatchesError: React.FC<MatchesErrorProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-matches-error', className);

  return (
    <MessageScreen
      className={classes}
      icon={CanceledIcon}
      title={t('errorMatch-title')}
      description={t('errorMatch-description')}
    />
  );
};

export default MatchesError;
