import React, { useMemo } from 'react';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { Button, ImageSlider } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ImageSlideType } from 'ncoded-component-library/build/components/organisms/ImageSlider/ImageSlider.component';

import './ImagesTooltip.styles.scss';

type ImagesTooltipProps = {
  className?: string;
  title?: string;
  description?: string;
  onBook?: () => void;
};

const ImagesTooltip: React.FC<ImagesTooltipProps> = (props) => {
  const { title, description, className } = props;

  const classes = classNames('yx-images-tooltip', className);

  const { t } = useTranslation();

  const exampleProfileImages = useMemo(
    () =>
      [
        { imageUrl: '/assets/images/profile-shot-1.jpeg' },
        { imageUrl: '/assets/images/profile-shot-2.jpeg' },
        { imageUrl: '/assets/images/profile-shot-3.jpeg' },
      ] as Array<ImageSlideType>,
    [],
  );

  return (
    <Tooltip className={classes}>
      <h1>{title}</h1>
      <p>{description}</p>
      <ImageSlider showDots={false} slides={exampleProfileImages} />
      <Button styleType="secondary">{t('basicInfo-bookPhotographer')}</Button>
    </Tooltip>
  );
};

export default ImagesTooltip;
