import api from 'api';
import { NOTIFICATIONS_MESSAGES } from 'components/NotificationCard/codes';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { Notification } from 'models/Notification';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import yachtSocketService from 'services/socketService/yachtSocketService';
import socketEventNames from 'services/socketService/socketEventNames';
import NotificationContext from './Notification.context';
import notification from 'modules/notification';
import { useHistory } from 'react-router-dom';
import useMatchMedia from 'hooks/useMatchMedia';
import credentialsService from 'services/credentialsService';

type NotificationProps = {
  children?: React.ReactNode;
};

const NotificationProvider: React.FC<NotificationProps> = (props) => {
  const { children } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const isPhablet = useMatchMedia('isPhablet');

  const { popServerError, popInfo } = useContext(PopNotificationsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [numOfUnreadNotifications, setNumOfUnreadNotifications] =
    useState<number>(credentialsService.user.numberOfUnreadNotifications);

  const {
    items: notifications,
    setItems: setNotifications,
    loading: loadingNotifications,
    onContainerScrolled: onNotificationScroll,
  } = useInfinitePagination<Notification>({
    makeRequest: (currentPage: number) => {
      return api.notifications
        .getNotificationsForUser({
          $limit: '15',
          $page: currentPage.toString(),
          $sort: '-createdAt',
        })
        .then((res) => res.data);
    },
  });

  const readNotification = useCallback(
    async (notifId: string) => {
      try {
        setLoading(true);

        const notification = notifications.find(
          (notif) => notif._id === notifId,
        );

        if (notification.isRead) {
          popInfo({ type: 'Info', content: t('notificationAlreadyRead') });
          return;
        }

        await api.notifications.readNotification(notifId);

        setNumOfUnreadNotifications((oldNum) => oldNum - 1);
        setNotifications((oldNotifications) =>
          oldNotifications.map((notif) => {
            if (notif._id === notifId)
              return {
                ...notif,
                isRead: true,
              };
            else return notif;
          }),
        );
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [notifications, popInfo, popServerError, setNotifications, t],
  );

  const readAllNotifications = useCallback(async () => {
    try {
      setLoading(true);

      await api.notifications.readAllNotifications();
      setNumOfUnreadNotifications(0);
      setNotifications((oldNotifications) =>
        oldNotifications.map((notif) => {
          return {
            ...notif,
            isRead: true,
          };
        }),
      );
    } catch (e) {
      popServerError(e);
    } finally {
      setLoading(false);
    }
  }, [popServerError, setNotifications]);

  const showNotification = useCallback(
    (notif: Notification) => {
      const {
        content: { match, sender, matchId },
        typeCode,
      } = notif ?? {};

      setNotifications((oldNotifications) => [notif, ...oldNotifications]);
      setNumOfUnreadNotifications((oldNum) => oldNum + 1);

      notification.show({
        onClick: () => history.push(`/matches/match/${matchId}`),
        imageUrl: sender?.profileImage?.url,
        side: isPhablet ? 'top' : 'bottom-right',
        dissappearAfter: 5000,
        title: t('newNotification', {
          name: match.yachtName,
        }),
        description: t(NOTIFICATIONS_MESSAGES[typeCode], {
          yachtName: match?.yachtName,
          location: match?.location,
        }),
      });
    },
    [history, isPhablet, setNotifications, t],
  );

  useEffect(() => {
    yachtSocketService.addListener(
      socketEventNames.NOTIFICATION_CREATED,
      showNotification,
    );

    return () => {
      yachtSocketService.removeListener(
        socketEventNames.NOTIFICATION_CREATED,
        showNotification,
      );
    };
  }, [showNotification]);

  return (
    <NotificationContext.Provider
      value={{
        loading,
        loadingNotifications,
        numOfUnreadNotifications,
        notifications,
        setNumOfUnreadNotifications,
        showNotification,
        readNotification,
        readAllNotifications,
        setLoading,
        setNotifications,
        onNotificationScroll,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
