import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import MyYachtsContext from '../../providers/MyYachts/MyYachts.context';
import YachtCard from 'components/YachtCard';
import { Loader } from 'ncoded-component-library';
import InfiniteScroll from 'components/InfiniteScroll';
import GlowScroll from 'components/GlowScroll';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EmptyYachtIcon from 'icons/EmptyYacht.icon';

import './MyYachts.styles.scss';
import './MyYachts.responsive.styles.scss';

type MyYachtsProps = {
  className?: string;
};

const MyYachts: React.FC<MyYachtsProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-my-yachts', className);
  const { t } = useTranslation();
  const history = useHistory();

  const { yachts, publishedYachts, loadingYachts, onYachtsScroll } =
    useContext(MyYachtsContext);

  const emptyState = useMemo(
    () =>
      yachts.length === 0 && (
        <EmptyState
          icon={EmptyYachtIcon}
          label={
            publishedYachts
              ? t('youHaveNoActiveYachtsYet')
              : t('youHaveNoDraftedYachtsYet')
          }
          buttonText={t('createNewYacht')}
          onButtonClick={() => history.push('/create-yacht')}
        />
      ),
    [publishedYachts, yachts, t, history],
  );

  return (
    <GlowScroll>
      {loadingYachts && <Loader />}

      <InfiniteScroll onScroll={onYachtsScroll} className={classes}>
        {yachts.length === 0
          ? emptyState
          : yachts.map((yacht) => (
              <YachtCard key={yacht.id} yachtData={yacht} />
            ))}
      </InfiniteScroll>
    </GlowScroll>
  );
};

export default MyYachts;
