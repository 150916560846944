import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Loader } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import api from 'api';
import confirm from 'modules/confirm';

import './DecidingYacht.styles.scss';
import { useHistory } from 'react-router-dom';

type DecidingYachtProps = {
  className?: string;
};

const DecidingYacht: React.FC<DecidingYachtProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const classes = classNames('yx-deciding-yacht', className);

  useEffect(() => {
    const decideYacht = async () => {
      const lsYachtId = storageService.getItem<string>(
        STORAGE_KEYS.CREATING_YACHT_ID,
      );

      const createInitialYacht = async () => {
        const {
          data: { yacht },
        } = await api.yacht.createInitialYacht();
        storageService.setItem(STORAGE_KEYS.CREATING_YACHT_ID, yacht.id);
        history.replace(`/create-yacht/${yacht.id}`);
      };

      setTimeout(async () => {
        if (
          lsYachtId &&
          (await confirm({ content: t('wantToContinueFromLastYacht') }))
        ) {
          history.replace(`/create-yacht/${lsYachtId}`);
        } else {
          createInitialYacht();
        }
      }, 500);
    };
    decideYacht();
  }, [history, t]);

  return (
    <div className={classes}>
      <img src="/assets/images/logo.png" alt="yachtx-logo" />
      <Loader size="big" inline />
      <p>{t('preparingYourYacht')}</p>
    </div>
  );
};

export default DecidingYacht;
