import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import StepWrapper from '../../../../../components/StepWrapper';
import { Field } from 'react-final-form';
import DropzoneField from '../../../../../components/Fields/DropzoneField';
import { documentsOptions } from '../../services/options';
import AddDocumentFields from 'components/Fields/AddDocumentFields';

import './DocumentationStepPrivate.styles.scss';
import useDropzoneRequest from '../../hooks/useDropzoneRequest';
import credentialsService from 'services/credentialsService';
import validators from 'validators';

type DocumentationStepPrivateProps = {
  className?: string;
};

const DocumentationStepPrivate: React.FC<DocumentationStepPrivateProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();
  const { uploadFile } = useDropzoneRequest(credentialsService.draftYachtId);

  const classes = classNames('yx-ducumentation-step', className);

  return (
    <StepWrapper
      title={t('documentation-title')}
      description={t('documentation-privateInfo')}
      className={classes}
    >
      <Field
        uploadRequest={uploadFile}
        maxFiles={1}
        name="certificateOfRegistry"
        label={t('certificateOfRegistry')}
        component={DropzoneField}
        validate={validators.required(t('requiredField'))}
      />

      <Field
        uploadRequest={uploadFile}
        maxFiles={1}
        name="engineCertificate"
        label={t('engineCertificate')}
        component={DropzoneField}
        validate={validators.required(t('requiredField'))}
      />

      <Field
        uploadRequest={uploadFile}
        maxFiles={1}
        name="billOfSale"
        label={t('billsOfSale')}
        component={DropzoneField}
        validate={validators.required(t('requiredField'))}
      />

      <AddDocumentFields
        name="optionalDocuments"
        className="yx-ducumentation-step__array-section"
        options={documentsOptions}
        buttonLabel={t('addAdditionalDocuments')}
      />
    </StepWrapper>
  );
};

export default DocumentationStepPrivate;
