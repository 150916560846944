import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import SelectionCard from 'components/SelectionCard';

import './SuccessRegistrationCard.styles.scss';
import './SuccessRegistrationCard.responsive.styles.scss';

export type SelectionCardType = {
  title: string;
  descirption?: string;
  icon: React.ReactNode;
  path: string;
  handler: () => void;
};

type SuccessRegistrationCardProps = {
  className?: string;
  selectionLinks: Array<SelectionCardType>;
};

const SuccessRegistrationCard: React.FC<SuccessRegistrationCardProps> = (
  props,
) => {
  const { className, selectionLinks } = props;

  const classes = classNames('yx-success-registration-card', className);

  const { t } = useTranslation();

  return (
    <StepWrapper className={classes} renderActions={false}>
      <img src="/assets/images/success.png" alt="success" />
      <h1>{t('confidentialityAgreement-thankYouForRegistration')}</h1>
      <div className="yx-success-registration-card__actions">
        {selectionLinks.map((link) => (
          <NavLink
            key={link.title}
            to={link.path}
            className="yx-success-registration-card__links"
          >
            <SelectionCard
              onClick={link.handler}
              icon={link.icon as React.FC}
              title={link.title}
              description={link.descirption}
            />
          </NavLink>
        ))}
      </div>
    </StepWrapper>
  );
};

export default SuccessRegistrationCard;
