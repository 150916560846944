import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 1500 1500"
    fill="currentColor"
    {...props}
  >
    <circle cx="800" cy="700" r="550" fill="#F5F6FC" stroke="transparent" />
    <path
      transform="translate(150,850)"
      d="M436.896,74.869c-99.84-99.819-262.208-99.819-362.048,0c-99.797,99.819-99.797,262.229,0,362.048
		  c49.92,49.899,115.477,74.837,181.035,74.837s131.093-24.939,181.013-74.837C536.715,337.099,536.715,174.688,436.896,74.869z
		   M361.461,331.317c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251
		  l-75.413-75.435l-75.392,75.413c-4.181,4.16-9.643,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251
		  c-8.341-8.341-8.341-21.845,0-30.165l75.392-75.413l-75.413-75.413c-8.341-8.341-8.341-21.845,0-30.165
		  c8.32-8.341,21.824-8.341,30.165,0l75.413,75.413l75.413-75.413c8.341-8.341,21.824-8.341,30.165,0
		  c8.341,8.32,8.341,21.824,0,30.165l-75.413,75.413L361.461,331.317z"
    />
  </svg>
);
