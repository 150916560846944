import { DropzoneFieldRequests } from 'hooks/useDropzoneField';
import { createContext } from 'react';
import utils from 'utils';

type DropzoneRequestsContextProps = DropzoneFieldRequests;

export default createContext<DropzoneRequestsContextProps>({
  uploadRequest: utils.noop,
  deleteRequest: utils.noop,
});
