import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import InputField from 'components/Fields/InputField';
import { Field } from 'react-final-form';
import TextAreaField from 'components/Fields/TextAreaField';
import SelectField from 'components/Fields/SelectField';
import { countries } from 'services/countries';
import CheckboxField from 'components/CheckboxField';
import validators from 'validators';
import RadioFields from 'components/Fields/RadioFields';
import { yachtStatusFields, yachtTypeFiels } from './radioFields';
import Tooltip from 'components/Tooltip';
import { composeValidators } from 'validators/general';
import {
  classificationSocietyOptions,
  vatStasusOptions,
} from '../../services/options';

import './BasicStep.styles.scss';

type BasicStepProps = {
  className?: string;
};

const BasicStep: React.FC<BasicStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-basic-step', className);
  const { t } = useTranslation();

  const validatorsObject = useMemo(
    () => ({
      vesselName: validators.required(t('basicInfo-vesselName-validator')),
      teaserDescription: validators.required(
        t('basicInfo-teaserDescription-validator'),
      ),
      askingPrice: composeValidators(
        validators.required(t('basicInfo-askingPrice-validator')),
        validators.numbersOnlyValidation('notValidUnitValue'),
      ),
      grossRegisteredTonnage: composeValidators(
        validators.required(t('basicInfo-grossRegisteredTonnage-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      guestCapacity: composeValidators(
        validators.required(t('basicInfo-guestCapacity-validator')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      numberOfCabins: validators.required(
        t('basicInfo-numberOfCabins-validator'),
      ),
      flag: validators.required(t('basicInfo-flag-validator')),
      registryNumber: validators.required(
        t('basicInfo-registryNumber-validator'),
      ),
      classificationSociety: validators.required(
        t('basicInfo-classificationSociety-validator'),
      ),
      vatStatus: validators.required(t('basicInfo-vatStatus-validator')),
      profileShots: validators.requiredArray(
        t('basicInfo-profileShots-validator'),
      ),
      mainSalonShots: validators.requiredArray(
        t('basicInfo-mainSalonShots-validator'),
      ),
      masterCabinShots: validators.requiredArray(
        t('basicInfo-masterCabinShots-validator'),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('basic')}
      description={t('basicInfo-info')}
      className={classes}
    >
      <RadioFields
        className="yacht-status"
        radioFields={yachtStatusFields}
        name="yachtStatus"
        label={t('basicInfo-newExistingConcep')}
        renderAsGrid
      />

      <RadioFields
        className="yacht-type"
        radioFields={yachtTypeFiels}
        name="yachtType"
        label={t('basicInfo-yachtType')}
        renderAsGrid
      />

      <Field
        name="vesselName"
        component={InputField}
        label={t('basicInfo-vesselName')}
        placeholder={t('basicInfo-enterName-placeholder')}
        validate={validatorsObject.vesselName}
        tooltip={<Tooltip>{t('basicInfo-vesselName-tooltip')}</Tooltip>}
      />

      <Field
        name="teaserDescription"
        component={InputField}
        label={t('basicInfo-teaserDdescription')}
        placeholder={t('basicInfo-teaserDescription-placeholder')}
        validate={validatorsObject.teaserDescription}
        tooltip={<Tooltip>{t('basicInfo-teaserDescription-tooltip')}</Tooltip>}
      />

      <Field
        name="description"
        component={TextAreaField}
        label={t('basicInfo-description')}
        placeholder={t('basicInfo-description-placeholder')}
      />

      <Field
        name="askingPrice"
        min={1}
        unitType="EUR"
        component={InputField}
        label={t('basicInfo-askingPrice')}
        placeholder={t('basicInfo-askingPrice-placeholder')}
        validate={validatorsObject.askingPrice}
      />

      <Field
        name="grossRegisteredTonnage"
        unitType="ton"
        component={InputField}
        label={t('basicInfo-grossRegistered')}
        placeholder={t('basicInfo-grossRegistered-placeholder')}
        validate={validatorsObject.grossRegisteredTonnage}
        tooltip={
          <Tooltip>{t('basicInfo-grossRegisteredTonnage-tooltip')}</Tooltip>
        }
      />

      <Field
        name="guestCapacity"
        component={InputField}
        label={t('basicInfo-guestCapacity')}
        placeholder={t('basicInfo-gusetCapacity-placeholder')}
        validate={validatorsObject.guestCapacity}
        tooltip={<Tooltip>guest</Tooltip>}
      />

      <Field
        name="numberOfCabins"
        type="number"
        min={0}
        max={10000}
        component={InputField}
        label={t('basicInfo-numberOfCabins')}
        placeholder={t('basicInfo-numberOfCabins-placeholder')}
        validate={validatorsObject.numberOfCabins}
      />

      <Field
        name="flag"
        component={SelectField}
        searchable
        options={countries}
        label={t('basicInfo-flag')}
        validate={validatorsObject.flag}
      />

      <Field
        name="registryNumber"
        component={InputField}
        label={t('basicInfo-registryNumber')}
        placeholder={t('basicInfo-registryNumber-placeholder')}
        validate={validatorsObject.registryNumber}
      />

      <Field
        name="classificationSociety"
        component={SelectField}
        searchable
        options={classificationSocietyOptions}
        label={t('basicInfo-classificationSociety')}
        placeholder={t('basicInfo-classificationSociety-placeholder')}
        validate={validatorsObject.classificationSociety}
        tooltip={
          <Tooltip>{t('basicInfo-classificationSociety-tooltip')}</Tooltip>
        }
      />

      <Field
        name="vatStatus"
        component={SelectField}
        searchable
        options={vatStasusOptions}
        label={t('basicInfo-vatStatus')}
        placeholder={t('basicInfo-vatStatus-placeholder')}
        validate={validatorsObject.vatStatus}
        tooltip={<Tooltip>{t('basicInfo-vatStatus-tooltip')}</Tooltip>}
      />

      <Field
        name="hasDebts"
        component={CheckboxField}
        label={t('basicInfo-debts')}
      />
    </StepWrapper>
  );
};

export default BasicStep;
