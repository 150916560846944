import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="17" height="9" viewBox="0 0 17 9" fill="none" {...props}>
    <path
      d="M11.9749 0.600006L15.7999 4.42501L11.9749 8.25001M8.1499 4.42501H15.2535H8.1499Z"
      stroke="currentColor"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.175 0.600006L1.35 4.42501L5.175 8.25001M9 4.42501H1.89643H9Z"
      stroke="currentColor"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
