import { AxiosError } from 'axios';
import { createContext } from 'react';
import { PopNotificationProps } from './components/PopNotification/PopNotification.component';

export type Notification = Omit<PopNotificationProps, 'notId'> & {
  content: React.ReactNode;
};

type PopNotificationsContextProps = {
  popInfo: (conf: Notification) => void;
  popSuccess: (value: string) => void;
  popServerError: (error: AxiosError | unknown) => void;
};

export default createContext<PopNotificationsContextProps>({
  popInfo: () => {},
  popSuccess: () => {},
  popServerError: () => {},
});
