import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M11.6667 1H2.33333C1.59695 1 1 1.59695 1 2.33333V11.6667C1 12.403 1.59695 13 2.33333 13H11.6667C12.403 13 13 12.403 13 11.6667V2.33333C13 1.59695 12.403 1 11.6667 1Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.6665 5.66669C5.21879 5.66669 5.6665 5.21897 5.6665 4.66669C5.6665 4.1144 5.21879 3.66669 4.6665 3.66669C4.11422 3.66669 3.6665 4.1144 3.6665 4.66669C3.6665 5.21897 4.11422 5.66669 4.6665 5.66669Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6003 8.66673L9.66699 5.66673L1.66699 12.3334"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
