import React from 'react';
import classNames from 'classnames';
import FieldLabel from 'components/Fields/components/FieldLabel';

import './FormRow.styles.scss';

type FormRowProps = {
  className?: string;
  label?: string;
  description?: string;
  tooltip?: React.FC;
};

const FormRow: React.FC<FormRowProps> = (props) => {
  const { children, label, description, tooltip, className } = props;

  const classes = classNames('yx-form-row', className);

  return (
    <div className={classes}>
      {label && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      {description && <p className="yx-form-row__description">{description}</p>}
      <div className="yx-form-row__row">{children}</div>
    </div>
  );
};

export default FormRow;
