import React from 'react';
import classNames from 'classnames';
import StepProgressVertical from 'components/StepProgress';
import StepProgressResponsive from 'components/StepProgressResponsive';

import './FormContent.styles.scss';
import './FormContent.responsive.styles.scss';

type FormContentProps = {
  className?: string;
  children?: React.ReactNode;
};

const FormContent: React.FC<FormContentProps> = (props) => {
  const { children, className } = props;

  const classes = classNames('yx-form-content', className);

  return (
    <main className={classes}>
      <StepProgressVertical />
      <StepProgressResponsive />
      <div className="yx-form-content__container">{children}</div>
    </main>
  );
};

export default FormContent;
