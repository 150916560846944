import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" {...props}>
    <path
      d="M10.3463 12.25V11.0321C10.3463 10.3861 10.0897 9.76662 9.63289 9.30983C9.17611 8.85305 8.55658 8.59644 7.9106 8.59644H3.03921C2.39322 8.59644 1.77369 8.85305 1.31691 9.30983C0.860133 9.76662 0.603516 10.3861 0.603516 11.0321V12.25"
      stroke="currentColor"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.47524 6.16076C6.82044 6.16076 7.91094 5.07026 7.91094 3.72506C7.91094 2.37986 6.82044 1.28937 5.47524 1.28937C4.13005 1.28937 3.03955 2.37986 3.03955 3.72506C3.03955 5.07026 4.13005 6.16076 5.47524 6.16076Z"
      stroke="currentColor"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0001 12.25V11.0321C13.9997 10.4925 13.8201 9.9682 13.4894 9.54168C13.1588 9.11515 12.6959 8.81052 12.1733 8.6756"
      stroke="currentColor"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.7373 1.36847C10.2612 1.50262 10.7256 1.80732 11.0572 2.23455C11.3889 2.66177 11.5689 3.18722 11.5689 3.72805C11.5689 4.26887 11.3889 4.79432 11.0572 5.22155C10.7256 5.64877 10.2612 5.95348 9.7373 6.08762"
      stroke="currentColor"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
