import AddIcon from 'icons/Add.icon';
import { Button, Toggle } from 'ncoded-component-library';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import MyYachtsContext from 'router/subrouters/Dashboard/providers/MyYachts/MyYachts.context';

const SearchActions: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      icon={AddIcon}
      onClick={() =>
        history.push({
          pathname: '/create-search-profile',
          state: { skipFrom: true },
        })
      }
    >
      {t('createNewSearch')}
    </Button>
  );
};

const YacthActions: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      icon={AddIcon}
      onClick={() =>
        history.push({
          pathname: '/create-yacht',
          state: { skipFrom: true },
        })
      }
    >
      {t('addNewYacht')}
    </Button>
  );
};

const YacthsActions: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { publishedYachts, setActiveUrlSource } = useContext(MyYachtsContext);

  return (
    <>
      <Toggle
        checked={publishedYachts}
        onChange={(e) => setActiveUrlSource(e.target.checked)}
        onContent={<span>{t('active')}</span>}
        offContent={<span>{t('drafts')}</span>}
      />
      <Button
        icon={AddIcon}
        onClick={() =>
          history.push({ pathname: '/create-yacht', state: { skipFrom: true } })
        }
      >
        {t('addNewYacht')}
      </Button>
    </>
  );
};

export default [
  {
    path: '/my-searches',
    component: SearchActions,
  },
  {
    path: '/my-yacht',
    component: YacthActions,
  },
  {
    path: '/my-yachts',
    component: YacthsActions,
  },
] as Array<EnhancedRouteProps>;
