import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { RangeSlider } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import { RangeValueType } from 'ncoded-component-library/build/components/molecules/RangeSlider/RangeSlider.component';
import { getUnitValue } from 'router/subrouters/CreateYacht/services/utils';
import FieldLabel from '../components/FieldLabel';
import { NumberRange } from 'models/Search';
import utils from 'utils';

import './RangeSliderField.styles.scss';

export type RangeHelperProps = {
  minValue: number;
  maxValue: number;
  showMax?: boolean;
};

type RangeSliderProps = ExtractPropTypes<typeof RangeSlider>;

type RangeSliderFieldProps = RangeSliderProps &
  FieldRenderProps<NumberRange, HTMLElement> & {
    className?: string;
    label?: string;
    tooltip?: React.FC;
  };

const RangeSliderField: React.FC<RangeSliderFieldProps> = (props) => {
  const {
    input,
    meta: { touched, error },
    className,
    min,
    max,
    label,
    tooltip,
    variant = 'double',
    ...rest
  } = props;

  const { value: pValue, onChange, ...restInput } = input;
  const [rangeValue, setRangeValue] = useState<NumberRange>();

  const hasError = useMemo(() => touched && error, [error, touched]);
  const changeRef = useRef<boolean>(false);

  const classes = classNames(
    'yx-range-slider-field',
    { 'yx-range-slider-field--error': hasError },
    className,
  );

  const value = useMemo(
    () =>
      ({
        min: pValue.from ? getUnitValue(pValue.from) : min,
        max: pValue.to ? getUnitValue(pValue.to) : max,
      } as RangeValueType),
    [max, min, pValue.from, pValue.to],
  );

  const handleOnChange = useCallback((values: RangeValueType) => {
    const rangeValue = {
      from: utils.getDotedNumber(values.min.toFixed(0)),
      to: utils.getDotedNumber(values.max.toFixed(0)),
    } as NumberRange;

    changeRef.current = true;
    setRangeValue(rangeValue);
  }, []);

  useEffect(() => {
    if (changeRef.current) {
      if (variant === 'double') {
        onChange(rangeValue);
      } else if (variant === 'single') onChange(getUnitValue(rangeValue?.from));
      changeRef.current = false;
    }
  }, [onChange, rangeValue, variant]);

  return (
    <div className={classes}>
      {label && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      <RangeSlider
        {...rest}
        {...restInput}
        min={min}
        max={max}
        variant={variant}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default RangeSliderField;
