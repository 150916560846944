import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import FormRow from 'components/FormRow';
import RadioFields from 'components/Fields/RadioFields';
import { fuelTypeFields, preferedEngineFields } from './radioFields';
import { Field } from 'react-final-form';
import YesNoField from 'components/Fields/YesNoField';

import './FuelConsumptionStep.styles.scss';

type FuelConsumptionStepProps = {
  className?: string;
};

const FuelConsumptionStep: React.FC<FuelConsumptionStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-fuel-consumption-step', className);

  const { t } = useTranslation();

  return (
    <StepWrapper className={classes}>
      <FormRow
        label={t('dieselOrPetrolPreference')}
        description={t('dieselOrPetrol-info')}
        className="yx-fuel-consumption-step__row"
      >
        <RadioFields name="fuelType" radioFields={fuelTypeFields} />
      </FormRow>

      <FormRow
        label={t('fuelConsumptionPlayRoleInDesicion')}
        description={t('fuelConsumption-info')}
        className="yx-fuel-consumption-step__row"
      >
        <Field name="doesFuelConsumptionMatter" component={YesNoField} />
      </FormRow>

      <FormRow
        label={t('preferInboardOrOutboardEngine')}
        description={t('inboardOutboardEngine-info')}
        className="yx-fuel-consumption-step__row"
      >
        <RadioFields name="preferedEngine" radioFields={preferedEngineFields} />
      </FormRow>
    </StepWrapper>
  );
};

export default FuelConsumptionStep;
