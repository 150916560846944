import React from 'react';
import classNames from 'classnames';
import { PopNotificationProps } from '../PopNotification/PopNotification.component';
import Image from 'components/Image';
import { useTranslation } from 'react-i18next';
import PopNotification from '../PopNotification';

import './PopYachtNotification.styles.scss';
import './PopYachtNotification.responsive.styles.scss';

export type PopYachtNotificationProps = {
  title: string;
  description: string;
  imageUrl?: string;
} & PopNotificationProps;

const PopYachtNotification: React.FC<PopYachtNotificationProps> = (props) => {
  const { className, title, description, imageUrl, ...rest } = props;

  const classes = classNames('yx-pop-yacht-notification', className);
  const { t } = useTranslation();

  return (
    <PopNotification className={classes} {...rest}>
      {imageUrl && <Image src="/assets/images/favicon.png" />}
      <div className="yx-pop-yacht-notification__content">
        <p className="yx-pop-yacht-notification__title">{t(title)}</p>
        <p className="yx-pop-yacht-notification__description">
          {t(description)}
        </p>
      </div>
    </PopNotification>
  );
};

export default PopYachtNotification;
