import React from 'react';
import classNames from 'classnames';
import './LockedOverlay.styles.scss';
import LockIcon from '../../icons/Lock.icon';

type LockedOverlayProps = {
  className?: string;
  text?: string;
};

const LockedOverlay: React.FC<LockedOverlayProps> = (props) => {
  const { text, className } = props;

  const classes = classNames('yx-locked-overlay', className);

  return (
    <div className={classes}>
      <LockIcon />
      <span>{text}</span>
    </div>
  );
};

export default LockedOverlay;
