import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" {...props}>
    <line
      x1="0.5"
      y1="0.5"
      x2="0.5"
      y2="9.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <line
      x1="6.5"
      y1="0.5"
      x2="6.5"
      y2="9.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <line
      x1="12.5"
      y1="0.5"
      x2="12.5"
      y2="9.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
