import React from 'react';
import Datepicker from './Datepicker.component';
import { DatepickerProps } from './models';
import DatepickerProvider from './provider';

function WrappedDatepicker(props: DatepickerProps) {
  return (
    <DatepickerProvider {...props}>
      <Datepicker />
    </DatepickerProvider>
  );
}

export default WrappedDatepicker;
