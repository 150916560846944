import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import FormRow from 'components/FormRow';
import { Field, useField, useForm } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import { composeValidators } from 'validators/general';
import validators from 'validators';
import LinesIcon from 'icons/Lines.icon';
import utils from 'utils';
import RangeSliderField from 'components/Fields/RangeSliderField';
import { RangeHelperProps } from 'components/Fields/RangeSliderField/RangeSliderField.component';

import './BudgetStep.styles.scss';

type BudgetStepProps = {
  className?: string;
};

const RangeMinContent: React.FC<RangeHelperProps> = ({ minValue }) => {
  return <p>{utils.getDotedNumber(minValue.toFixed(0).toString())}</p>;
};

const RangeMaxContent: React.FC<RangeHelperProps> = ({ maxValue }) => {
  return <p>{utils.getDotedNumber(maxValue.toFixed(0).toString())}</p>;
};

const BudgetStep: React.FC<BudgetStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-budget-step', className);
  const { t } = useTranslation();

  const {
    input: { value: budgetFrom },
  } = useField('budget.from');

  const {
    input: { value: budgetTo },
  } = useField('budget.to');

  const { change } = useForm();

  useEffect(
    () =>
      change(
        'runningCost.from',
        utils.getDotedNumber(
          (utils.getIntegerFromDottedNumber(budgetFrom) * 0.15).toFixed(2),
        ),
      ),
    [budgetFrom, change],
  );

  useEffect(
    () =>
      change(
        'runningCost.to',
        utils.getDotedNumber(
          (utils.getIntegerFromDottedNumber(budgetTo) * 0.15).toFixed(2),
        ),
      ),
    [budgetTo, change],
  );

  const validatorsObject = useMemo(
    () => ({
      range: composeValidators(
        validators.required(t('requiredField')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      budget: validators.checkIfOtherFieldIsGratherThenRange(
        'budget',
        t('budget-validator'),
      ),
      runningCostTo: composeValidators(
        validators.checkIfOtherFieldIsGratherThenRange(
          'runningCost',
          t('runningCost-validator'),
        ),
      ),
      sizeRangeTo: composeValidators(
        validators.checkIfOtherFieldIsGratherThenRange(
          'sizeRange',
          t('sizeRange-validator'),
        ),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper title={t('purchasingBudget')} className={classes}>
      <Field
        name="budget"
        label={t('settingTheBudget')}
        component={RangeSliderField}
        min={10000}
        max={500000000}
        maxIcon={LinesIcon}
        minIcon={LinesIcon}
        minContent={RangeMinContent}
        maxContent={RangeMaxContent}
        debounceChange
      />
      <FormRow>
        <Field
          name="budget.from"
          component={InputField}
          label={t('from')}
          placeholder={t('enterAmount')}
          min={1}
          unitType="EUR"
          validate={validatorsObject.range}
        />
        <Field
          name="budget.to"
          component={InputField}
          label={t('to')}
          placeholder={t('enterAmount')}
          min={1}
          unitType="EUR"
          validate={composeValidators(
            validatorsObject.range,
            validatorsObject.budget,
          )}
        />
      </FormRow>

      <FormRow label={t('runningCosts')} description={t('runningCosts-info')}>
        <Field
          name="runningCost.from"
          component={InputField}
          placeholder={t('autoFill')}
          min={1}
          unitType="EUR"
          readOnly
        />
        <Field
          name="runningCost.to"
          component={InputField}
          placeholder={t('autoFill')}
          min={1}
          unitType="EUR"
          readOnly
        />
      </FormRow>

      <FormRow label={t('sizeRange')} description={t('sizeRange-info')}>
        <Field
          name="sizeRange.from"
          component={InputField}
          placeholder={t('enterAmount')}
          min={1}
          unitType="meters"
          validate={validatorsObject.range}
        />
        <Field
          name="sizeRange.to"
          component={InputField}
          placeholder={t('enterAmount')}
          min={1}
          unitType="meters"
          validate={composeValidators(
            validatorsObject.range,
            validatorsObject.sizeRangeTo,
          )}
        />
      </FormRow>
    </StepWrapper>
  );
};

export default BudgetStep;
