import { Yacht } from 'models/Yacht';
import { createContext } from 'react';
import utils from 'utils';

type MyYachtsContextProps = {
  loading: boolean;
  loadingYachts: boolean;
  yachts: Array<Yacht>;
  publishedYachts: boolean;
  setPublishedYachts: (value: boolean) => void;
  setYachts: (values: Array<Yacht>) => void;
  editYacht: (yachtId: string, isPublished?: boolean) => void;
  deleteYacht: (yachtId: string) => void;
  publishYacht: (
    yachtId: string,
    yachtData: Yacht,
  ) => Promise<Yacht> | undefined;
  unpublishYacht: (yachtId: string) => void;
  setActiveUrlSource: (value: boolean) => void;
  onYachtsScroll: () => void;
};

export default createContext<Partial<MyYachtsContextProps>>({
  loading: false,
  loadingYachts: false,
  yachts: [],
  publishedYachts: false,
  setPublishedYachts: utils.noop,
  setYachts: utils.noop,
  editYacht: utils.noop,
  deleteYacht: utils.noop,
  publishYacht: utils.noop,
  unpublishYacht: utils.noop,
  setActiveUrlSource: utils.noop,
  onYachtsScroll: utils.noop,
});
