import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Checkbox } from 'ncoded-component-library';
import { ExtractPropTypes } from 'types';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import './CheckboxField.styles.scss';

type CheckboxComponentProps = ExtractPropTypes<typeof Checkbox>;

type CheckboxFieldProps = CheckboxComponentProps &
  FieldRenderProps<boolean, HTMLElement> & {
    className?: string;
    optional?: boolean;
  };

const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  const {
    children,
    className,
    required,
    optional = required !== undefined && !required,
    input: { value, ...inputRest },
    meta: { touched, error },
    ...rest
  } = props;

  const { t } = useTranslation();

  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = classNames(
    'yx-checkbox-field',
    { 'yx-checkbox-field--error': true },
    className,
  );

  return (
    <div className={classes}>
      <div className="yx-checkbox-field__content">
        <Checkbox
          {...inputRest}
          {...rest}
          checked={!!value}
          hasError={hasError}
        />
        {optional && (
          <p className="yx-checkbox-field__optional-label">{t('optional')}</p>
        )}
      </div>
      {hasError && <p>{error}</p>}
    </div>
  );
};

export default CheckboxField;
