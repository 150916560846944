import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import CheckIcon from 'icons/Check.icon';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import StepsContext from './providers/Steps/Steps.context';

import './StepProgressVertical.styles.scss';
import './StepProgressVertical.responsive.styles.scss';

export type StepProgressProps = {
  className?: string;

  renderAsLinks?: boolean;
  onStepClick?: (stepIndex: number) => void;
};

const StepProgressVertical: React.FC<StepProgressProps> = (props) => {
  const { className, onStepClick, renderAsLinks: pRenderAsLinks } = props;
  const {
    currentStep,
    stepLabels,
    linkSteps,
    completedSteps,
    renderAsLinks: cRenderAsLinks,
    setCurrentStep,
  } = useContext(StepsContext);

  const renderAsLinks = useMemo(
    () => pRenderAsLinks || cRenderAsLinks,
    [pRenderAsLinks, cRenderAsLinks],
  );

  const { current: uid } = useRef(`stepper-${Date.now()}`);
  const oldStep = useRef(0);

  const HTMLEl = useMemo(() => (renderAsLinks ? 'a' : 'div'), [renderAsLinks]);
  const history = useHistory();

  const classes = classNames('yx-step-progress-v', className, uid);

  const getCircleContent = useCallback(
    (index: number) => {
      if (index < currentStep) return <CheckIcon />;
      else return index + 1;
    },
    [currentStep],
  );

  const handleOnStepClick = useCallback(
    (stepIndex: number) => {
      if (onStepClick) {
        onStepClick(stepIndex);
      } else {
        setCurrentStep(stepIndex);
      }
    },
    [onStepClick, setCurrentStep],
  );

  const steps = useMemo(
    () => (renderAsLinks ? linkSteps.map((step) => step.label) : stepLabels),
    [renderAsLinks, linkSteps, stepLabels],
  );

  const dynamicSpacerStyles = useMemo(() => {
    const min = Math.min(oldStep.current, currentStep);
    const max = Math.max(oldStep.current, currentStep);
    const diff = max - min;
    const animationDuration = 300 / (diff || 1);

    const directionDown = currentStep > oldStep.current;

    if (diff <= 1) return null;

    return Array(diff)
      .fill(1)
      .reduce(
        (acc, _, ind) =>
          acc +
          `.${uid} > [class*="step"]:nth-child(${
            min + ind + 1
          }) [class*="spacer"] {
        --spacer-animaiton-duration: ${animationDuration}ms;
        --space-animation-delay: ${
          (directionDown ? ind : diff - ind) * animationDuration
        }ms;
      } \n`,
        '',
      );
  }, [currentStep, uid]);

  useLayoutEffect(() => {
    oldStep.current = currentStep;
  }, [currentStep]);

  return (
    <>
      <style>{dynamicSpacerStyles}</style>
      <div className={classes}>
        {steps.map((step, index, { length }) => (
          <HTMLEl
            className={classNames('yx-step-progress-v__step', {
              'yx-step-progress-v__step--disabled': completedSteps < index,
              'yx-step-progress-v__step--prev': index < currentStep,
              'yx-step-progress-v__step--active': index === currentStep,
              'yx-step-progress-v__step--next': index > currentStep,
            })}
            key={typeof step === 'string' ? step : index}
            {...(renderAsLinks
              ? {
                  onClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    ev.preventDefault();
                    if (completedSteps < index) return;
                    history.push(`${linkSteps[index].path}`);
                  },
                  href: `${linkSteps[index].path}`,
                }
              : {
                  onClick: () => {
                    if (completedSteps < index) return;
                    handleOnStepClick(index);
                  },
                })}
          >
            <span className="yx-step-progress-v__circle">
              {getCircleContent(index)}
            </span>
            <div className="yx-step-progress-v__step__title">
              {step}

              {index < length - 1 && (
                <span
                  className={classNames('yx-step-progress-v__spacer', {
                    'yx-step-progress-v__spacer--filled': index < currentStep,
                  })}
                />
              )}
            </div>
          </HTMLEl>
        ))}
      </div>
    </>
  );
};

export default StepProgressVertical;
