import React from 'react';
import classNames from 'classnames';
import utils from 'utils';

import './InfoCard.styles.scss';

export type InfoCardType = {
  icon?: React.ReactNode;
  title: string;
  content: string;
};

type InfoCardProps = {
  className?: string;
  infoData: InfoCardType;
  onClick?: () => void;
};

const InfoCard: React.FC<InfoCardProps> = (props) => {
  const {
    className,
    infoData: { icon, title, content },
    onClick,
  } = props;

  const classes = classNames('yx-info-card', className);

  return (
    <div className={classes} onClick={onClick}>
      <span>{utils.renderIcon(icon)}</span>
      <div className="yx-info-card__content">
        <p>{title}</p>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default InfoCard;
