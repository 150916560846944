import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import RadioCard from 'components/RadioCard';
import { ExtractPropTypes } from 'types';
import { useTranslation } from 'react-i18next';

import './RadioCardField.styles.scss';

type RadioCardProps = ExtractPropTypes<typeof RadioCard>;

export type RadioCardFieldProps = RadioCardProps &
  FieldRenderProps<string, HTMLElement> & {
    className?: string;
  };

const RadioCardField: React.FC<RadioCardFieldProps> = (props) => {
  const {
    className,
    input,
    label,
    meta: { touched, error },
    ...rest
  } = props;

  const { t } = useTranslation();
  const fLabel = useMemo(() => t(label), [t, label]);

  const classes = classNames('yx-radio-card-field', className);

  const hasError = useMemo(() => touched && error, [error, touched]);

  return (
    <div className={classes}>
      <RadioCard hasError={hasError} label={fLabel} {...input} {...rest} />
    </div>
  );
};

export default RadioCardField;
