import React from 'react';
import classNames from 'classnames';
import SuggestWaitIcon from '../../../../../../../icons/SuggestWait.icon';
import './WaitScreen.styles.scss';
import MessageScreen from '../MessageScreen';

type WaitScreenProps = {
  className?: string;
  title?: string;
  description?: string;
};

const WaitScreen: React.FC<WaitScreenProps> = (props) => {
  const { className, title, description } = props;

  const classes = classNames('yx-wait-screen', className);

  return (
    <MessageScreen
      className={classes}
      icon={SuggestWaitIcon}
      title={title}
      description={description}
    />
  );
};

export default WaitScreen;
