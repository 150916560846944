import React from 'react';
import classNames from 'classnames';
import './NotificationBadge.styles.scss';
import NotificationIcon from '../../icons/Notification.icon';

type NotificationBadgeProps = {
  className?: string;
  numberOfNotifications?: number;
  icon?: React.FC;
  onClick?: () => void;
};

const NotificationBadge: React.FC<NotificationBadgeProps> = (props) => {
  const {
    numberOfNotifications,
    className,
    icon = <NotificationIcon />,
    onClick,
  } = props;

  const classes = classNames('yx-notification-badge', className);

  return (
    <div className={classes} onClick={onClick}>
      <span>
        {icon}

        {!!numberOfNotifications && (
          <span className="yx-notification-badge__badge">
            {numberOfNotifications}
          </span>
        )}
      </span>
    </div>
  );
};

export default NotificationBadge;
