import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.99963 14.6667C11.6815 14.6667 14.6663 11.6819 14.6663 8C14.6663 4.31809 11.6815 1.33331 7.99963 1.33331C4.31776 1.33331 1.33301 4.31809 1.33301 8C1.33301 11.6819 4.31776 14.6667 7.99963 14.6667Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 8H10.6663"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
