import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import classNames from 'classnames';
import {
  CardElementProps,
  CardNumberElementProps,
  CardExpiryElementProps,
  CardCvcElementProps,
} from '@stripe/react-stripe-js';
import './CardField.styles.scss';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';

type CardSubcomponentProps =
  | CardNumberElementProps
  | CardExpiryElementProps
  | CardCvcElementProps;

type CardFieldProps = {
  className?: string;
  component: React.FC<any>;
  defaultErrorCode: string;
} & CardSubcomponentProps;

const CardField: React.FC<CardFieldProps> = (props) => {
  const { submitFailed } = useFormState();
  const {
    component: CardComponent,
    defaultErrorCode,
    className,
    ...cardProps
  } = props;
  const { setCompletedSteps, currentStep } = useContext(StepsContext);

  const divRef = useRef<HTMLDivElement>();
  const { onChange, ...restCardProps } = cardProps;

  const { t } = useTranslation();

  const [error, setError] = useState(t(defaultErrorCode));

  const [showError, setShowError] = useState(false);

  const classes = classNames('yx-card-field', className);

  const cardClasses = classNames('StripeElement', {
    'StripeElement--invalid': error && showError,
  });

  const onChangeDefault: CardElementProps['onChange'] = useCallback(
    (ev) => {
      setError(t(ev.error?.code));
      setShowError(!!ev.error?.code);
      onChange?.(ev as any);
    },
    [onChange, t],
  );

  useEffect(() => {
    setShowError(submitFailed);
  }, [submitFailed]);

  useEffectSkipFirst(
    () => !!error && setCompletedSteps(currentStep),
    [currentStep, error, setCompletedSteps],
  );

  return (
    <div ref={divRef} className={classes}>
      <CardComponent
        {...(restCardProps as any)}
        className={cardClasses}
        onChange={onChangeDefault as any}
      />
      {showError && error && <small>{error}</small>}
    </div>
  );
};

export default CardField;
