import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Button, Modal, Select } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Field, withTypes } from 'react-final-form';
import DropzoneField from 'components/Fields/DropzoneField';
import { useTranslation } from 'react-i18next';
import validators from 'validators';
import DropzoneRequestsProvider from 'providers/DropzoneRequests';
import FormRow from 'components/FormRow';
import { FileType } from 'models/File';
import { OptionalDocumentsKeys } from 'models/Yacht';
import { documentsOptions } from 'router/subrouters/CreateYacht/services/options';
import useDropzoneRequest from 'router/subrouters/CreateYacht/hooks/useDropzoneRequest';
import { getYachOptionalDocuments } from 'router/subrouters/CreateYacht/services/utils';
import YachtContext from '../../pages/YachtPreview/providers/Yacht/Yacht.context';

import './UploadDocumentModal.styles.scss';

type UploadFormType = { [key in OptionalDocumentsKeys]: FileType };

type UploadDocumentModalProps = {
  className?: string;
  onClose?: () => void;
};

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = (props) => {
  const { className, onClose } = props;

  const classes = classNames('yx-upload-document-modal', className);

  const { t } = useTranslation();
  const { Form } = withTypes<UploadFormType>();
  const { uploadYachtDocument, yacht } = useContext(YachtContext);
  const { uploadFile, deleteFile } = useDropzoneRequest(yacht?.id);

  const modalRef = useRef<ModalRef>(null);
  const [selectedDocumentName, setSelectedDocumentName] =
    useState<OptionalDocumentsKeys>('antiFoulingSystemCertificate');

  const handleSubmit = useCallback(
    (values: UploadFormType) => {
      uploadYachtDocument(values[selectedDocumentName]);
      onClose?.();
    },
    [selectedDocumentName, onClose, uploadYachtDocument],
  );

  const availalbeOptions = useMemo(
    () =>
      documentsOptions.filter(
        (option) =>
          !getYachOptionalDocuments(yacht)?.some(
            (document) => document[option.value] !== undefined,
          ),
      ),
    [yacht],
  );

  return (
    <DropzoneRequestsProvider
      uploadRequest={uploadFile}
      deleteRequest={deleteFile}
    >
      <Modal className={classes} onClose={onClose} ref={modalRef} open>
        <Form onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h1>{t('uploadDocument')}</h1>
              <Field
                name={selectedDocumentName}
                maxFiles={1}
                component={DropzoneField}
                validate={validators.requiredArray(
                  t('basicInfo-masterCabinShots-validator'),
                )}
              />

              <Select
                placeholder={t('selectTypeOfDocument')}
                searchable
                value={selectedDocumentName}
                options={availalbeOptions}
                onChange={(option) => setSelectedDocumentName(option.value)}
              />
              <FormRow>
                <Button styleType="secondary" type="button" onClick={onClose}>
                  {t('cancel')}
                </Button>
                <Button type="submit">{t('submit')}</Button>
              </FormRow>
            </form>
          )}
        </Form>
      </Modal>
    </DropzoneRequestsProvider>
  );
};

export default UploadDocumentModal;
