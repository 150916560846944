import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import { FUEL_TYPE_SEARCH, PREFERED_ENGINE_SEARCH } from 'models/Search';

export const fuelTypeFields = [
  {
    value: FUEL_TYPE_SEARCH.DIESEL,
    label: 'diesel',
  },
  {
    value: FUEL_TYPE_SEARCH.PETROL,
    label: 'petrol',
  },
] as Array<RadioFieldsType>;

export const preferedEngineFields = [
  {
    value: PREFERED_ENGINE_SEARCH.INBOARD,
    label: 'inboard',
  },
  {
    value: PREFERED_ENGINE_SEARCH.OUTBOARD,
    label: 'outboard',
  },
] as Array<RadioFieldsType>;
