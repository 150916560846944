import SelectionCard from 'components/SelectionCard';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useRoleOptions from './hooks/useRoleOptions';

import './RoleSelection.styles.scss';
import './RoleSelection.responsive.styles.scss';

type RoleSelectionProps = {};

const RoleSelection: React.FC<RoleSelectionProps> = () => {
  const { t } = useTranslation();

  const roleOptions = useRoleOptions();

  const renderCards = useMemo(
    () =>
      roleOptions.map(({ path, handler, ...rest }) => (
        <NavLink
          className="yx-role-selection-step__link-card"
          key={path}
          to={`${path}/contact-details`}
        >
          <SelectionCard onClick={handler} {...rest} />
        </NavLink>
      )),
    [roleOptions],
  );

  return (
    <div className="yx-role-selection-step">
      <p>{t('roleSelection-title')}?</p>
      <div className="yx-role-selection-step__cards">{renderCards}</div>
    </div>
  );
};

export default RoleSelection;
