import { Hour } from 'components/Calendar/models';
import { DateTime } from 'luxon';

type TimeslotStart = 0 | 12;

type PartOfTheDay = 'afternoon' | 'morning';

export type PartOfDay = 'AM' | 'PM';

export const DAY_PART: Record<PartOfDay, PartOfDay> = {
  AM: 'AM',
  PM: 'PM',
} as const;

export const GENERATE_TIMESLOTS_START: Record<PartOfDay, TimeslotStart> = {
  AM: 0,
  PM: 12,
} as const;

export const DAY_PART_TRANSLATION_KEY: Record<PartOfDay, PartOfTheDay> = {
  AM: 'morning',
  PM: 'afternoon',
} as const;

export const generateTimeslots = (start: TimeslotStart) => {
  return Array(12)
    .fill(0)
    .map((_, i) => i + start);
};

export const stringifyTimeslot = (timeslot: number) => {
  const stringTimeslot = String(timeslot);

  if (stringTimeslot.length === 2) {
    return `${stringTimeslot}:00`;
  } else {
    return `0${stringTimeslot}:00`;
  }
};

export const isTimeslotSelected = (
  currentDate: DateTime,
  hour: Hour,
  values: DateTime[],
): [boolean, number] => {
  const dateTime = DateTime.fromObject({
    ...currentDate.toObject(),
    hour,
    minute: 0,
  });

  let i: number;

  return [
    values.some((date, index) => {
      i = index;
      return (
        date.day === dateTime.day &&
        date.month === dateTime.month &&
        date.year === dateTime.year &&
        date.hour === dateTime.hour
      );
    }),
    i,
  ];
};
