export const STORAGE_KEYS = {
  AUTH: 'auth',
  USER: 'user',
  VERIFICATION_URL: 'verification-url',
  PARTICIPANT: 'participant',
  SETTINGS_POPPED: 'customer-profile-settings-popped',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh-token',
  COMPLETED_STEPS: 'yx-c-steps',
  CREATING_YACHT_ID: 'yx-c-yid',
  CREATING_SEARCH_ID: 'yx-c-sid',
  EDITING_SEARCH_ID: 'yx-e-sid',
  IS_LOGGED: 'IS_LOGGED',
  PAYMENT_INFO: 'payment-info',
};

export default {
  ram: {} as Record<string, string>,
  setItem(key: string, value: {} | string) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key]);
    }
  },
  removeDraftYacht() {
    this.removeItem(STORAGE_KEYS.CREATING_YACHT_ID);
  },
  removeDraftSearch() {
    this.removeItem(STORAGE_KEYS.EDITING_SEARCH_ID);
  },
};
