import api from 'api';
import BrokerIcon from 'icons/Broker.icon';
import UserIcon from 'icons/User.icon';
import { USER_REGISTRATION_STEPS } from 'models/Auth';
import { UserTypes, USER_TYPES } from 'models/User';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import credentialsService from 'services/credentialsService';

export default function () {
  const { t } = useTranslation();
  const { popServerError } = useContext(PopNotificationsContext);

  const sendUserType = useCallback(
    async (userType: UserTypes) => {
      try {
        if (!credentialsService.user)
          throw new Error('There is no user data in Local Storage');

        await api.users.patchUserByStep(USER_REGISTRATION_STEPS.USER_TYPE, {
          userType,
        });
      } catch (e) {
        popServerError(e);
      }
    },
    [popServerError],
  );

  const onPrivatePersonHandler = useCallback(() => {
    sendUserType(USER_TYPES.PRIVATE);
  }, [sendUserType]);

  const onBrokerHandler = useCallback(() => {
    sendUserType(USER_TYPES.BROKER);
  }, [sendUserType]);

  const roleOptions = useMemo(
    () => [
      {
        title: t('rolePrivatePerson-title'),
        description: t('rolePrivatePerson-description'),
        icon: UserIcon,
        path: 'private-person',
        handler: onPrivatePersonHandler,
      },
      {
        title: t('roleBroker-title'),
        description: t('roleBroker-description'),
        icon: BrokerIcon,
        path: 'broker',
        handler: onBrokerHandler,
      },
    ],
    [onBrokerHandler, onPrivatePersonHandler, t],
  );

  return roleOptions;
}
