import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MatchContext from './Match.context';
import api from '../../../../../../../api';
import PopNotificationsContext from '../../../../../../../providers/PopNotifications/PopNotifications.context';
import { Loader } from 'ncoded-component-library';
import { Match } from '../../../../../../../models/Match';
import {
  MatchContextProps,
  MatchStatus,
  SearchProp,
  MATCH_TYPE,
} from '../../models';
import {
  BUYER_MATCH_ROUTE,
  SELLER_MATCH_ROUTE,
  createTimeSlots,
  BUYER_MATCH_STEP,
  SELLER_MATCH_STEP,
} from '../../utils';
import MatchesError from '../../pages/MatchesError';
import CurrentUserContext from '../../../../providers/CurrentUser/CurrentUser.context';
import yachtSocketService from 'services/socketService/yachtSocketService';
import socketEventNames from 'services/socketService/socketEventNames';
import { Notification } from 'models/Notification';
import { useTranslation } from 'react-i18next';
import _merge from 'lodash.merge';

type MatchProviderProps = {};

const MatchProvider: React.FC<MatchProviderProps> = (props) => {
  const { children } = props;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { popServerError, popInfo } = useContext(PopNotificationsContext);
  const {
    currentUser: { _id: userId },
  } = useContext(CurrentUserContext);
  const [match, setMatch] = useState<Match>();
  const [serverError, setServerError] = useState(false);

  const search = useMemo(() => {
    let search: SearchProp;
    match?.searches.forEach((el) => {
      if (!(search?.matchingScore > el.matchingScore)) {
        search = el;
      }
    });

    return search;
  }, [match]);

  const buyerProfile = useMemo(() => {
    if (!match?.buyer) return null;
    const {
      buyer: {
        userType,
        address: { country, city },
      },
    } = match;

    return {
      country,
      city,
      userType,
    };
  }, [match]);

  const updateMatch = useCallback((match: Match) => {
    match &&
      setMatch((oldVal) => ({
        ..._merge(oldVal, match),
        timeSlotOptions: match.timeSlotOptions?.map(({ from, to }) => ({
          from: new Date(from),
          to: new Date(to),
        })),
        meetingDate: match.meetingDate ? new Date(match.meetingDate) : null,
      }));
  }, []);

  const routeName = useMemo(
    () => (userId === match?.buyerId ? MATCH_TYPE.BUYER : MATCH_TYPE.SELLER),
    [match, userId],
  );

  const currentStep = useMemo(
    () =>
      match &&
      (routeName === MATCH_TYPE.BUYER
        ? BUYER_MATCH_STEP[match.status as MatchStatus]
        : SELLER_MATCH_STEP[match.status as MatchStatus]),
    [match, routeName],
  );

  const MATCH_ROUTE = useMemo(
    () => (userId === match?.buyerId ? BUYER_MATCH_ROUTE : SELLER_MATCH_ROUTE),
    [match, userId],
  );

  const matchScore = useMemo(() => {
    let max = 0;
    match?.searches.forEach(({ matchingScore }) => {
      if (matchingScore > max) {
        max = matchingScore;
      }
    });
    return max;
  }, [match]);

  useEffect(
    () =>
      match &&
      userId !== match.buyerId &&
      userId !== match.sellerId &&
      history.replace('/home'),
    [history, match, userId],
  );

  useEffect(() => {
    const currentMatchStep = pathname.split('/').slice(-1)[0];

    match &&
      currentMatchStep !== MATCH_ROUTE[match.status as MatchStatus] &&
      history.replace(
        `/matches/match/${id}/${MATCH_ROUTE[match.status as MatchStatus]}`,
      );
  }, [MATCH_ROUTE, history, id, match, pathname]);

  useEffect(() => {
    const onMatchStatusUpdate = (notification: Notification) => {
      if (notification.typeCode >= 0 && notification.typeCode <= 5) {
        const {
          content: { match },
        } = notification;

        if (match) updateMatch(match);
      }
    };

    yachtSocketService.addListener(
      socketEventNames.NOTIFICATION_CREATED,
      onMatchStatusUpdate,
    );

    return () => {
      yachtSocketService.removeListener(
        socketEventNames.NOTIFICATION_CREATED,
        onMatchStatusUpdate,
      );
    };
  }, [updateMatch]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { match },
        } = await api.matches.getMatch(id, {
          $relations: ['buyer', 'seller', 'searches.search', 'yacth'],
        });
        updateMatch(match);
      } catch (e) {
        popServerError(e);
        setServerError(true);
      }
    })();
  }, [id, popServerError, updateMatch]);

  //***************************************************************\\

  const onArrangeMeeting = useCallback(async () => {
    try {
      const { data } = await api.matches.viewingRequest(match._id);
      updateMatch(data);
    } catch (e) {
      popServerError(e);
    }
  }, [match, popServerError, updateMatch]);

  const onViewingRequestAccept = useCallback(
    async ({
      timeSlotOptions,
      ...rest
    }: {
      timeSlotOptions: Date[];
      location: string;
      locationCoordinates: { latitude: number; longitude: number };
    }) => {
      const timeslots = createTimeSlots(timeSlotOptions);

      try {
        const { data } = await api.matches.viewingAccept(match._id, {
          timeSlotOptions: timeslots,
          ...rest,
        });
        updateMatch(data);
      } catch (e) {
        popServerError(e);
      }
    },
    [match, popServerError, updateMatch],
  );

  const onChoseMeetingDate = useCallback(
    async (date: Date) => {
      try {
        const { data } = await api.matches.chooseMeetingDate(match._id, date);
        updateMatch(data);
      } catch (e) {
        popServerError(e);
      }
    },
    [match, popServerError, updateMatch],
  );

  const postponeMatch = useCallback(async () => {
    try {
      const { data } = await api.matches.postoneMatchRequest(match._id);
      updateMatch(data);
      popInfo({
        type: t('success'),
        content: t('successfullyPostponed'),
      });
    } catch (e) {
      popServerError(e);
    }
  }, [match, popInfo, popServerError, t, updateMatch]);

  const acceptPostponement = useCallback(async () => {
    try {
      const { data } = await api.matches.postoneMatchAccept(match._id);
      updateMatch(data);
    } catch (e) {
      popServerError(e);
    }
  }, [match, popServerError, updateMatch]);

  const cancelPostponement = useCallback(async () => {
    try {
      const { data } = await api.matches.cancelMatch(match._id, {
        cancellationReason: t('cancelPostponement'),
      });
      updateMatch(data);
    } catch (e) {
      popServerError(e);
    }
  }, [match, popServerError, t, updateMatch]);

  const cancelMatch = useCallback(
    async (values) => {
      try {
        const { data } = await api.matches.cancelMatch(match._id, values);
        updateMatch(data);
      } catch (e) {
        popServerError(e);
      }
    },
    [match, popServerError, updateMatch],
  );

  //***************************************************************\\

  const providerValue: MatchContextProps = useMemo(
    () => ({
      currentStep,
      match,
      routeName,
      matchScore,
      buyerProfile,
      search,
      onChoseMeetingDate,
      onViewingRequestAccept,
      onArrangeMeeting,
      postponeMatch,
      acceptPostponement,
      cancelPostponement,
      cancelMatch,
    }),
    [
      acceptPostponement,
      buyerProfile,
      cancelPostponement,
      currentStep,
      match,
      matchScore,
      onArrangeMeeting,
      onChoseMeetingDate,
      onViewingRequestAccept,
      postponeMatch,
      routeName,
      search,
      cancelMatch,
    ],
  );

  if (serverError) {
    return <MatchesError />;
  }

  if (!match) {
    return <Loader />;
  }

  return (
    <MatchContext.Provider value={providerValue}>
      {children}
    </MatchContext.Provider>
  );
};

export default MatchProvider;
