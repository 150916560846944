import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    backend: {
      loadPath: '/assets/lang/{{lng}}/{{ns}}.json',
    },
    // react: {
    //   useSuspense: false,
    // },
    detection: {
      order: [
        'querystring',
        'localStorage',
        'cookie',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupQuerystring: 'language',
    },
    supportedLngs: ['en', 'no', 'it', 'de', 'fr', 'nl', 'sr'],
    // nonExplicitSupportedLngs: true, // omit en-US
    // load: 'unspecific',
    fallbackLng: 'en',
    // debug: true,
    returnEmptyString: false,
    // is24HourFormat: false,
    // default namespace (needs no prefix on calling t)
    ns: 'translation',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
