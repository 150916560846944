import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';

export const yachtTypeFields = [
  {
    value: 'Motor',
    label: 'motor',
    imagePath: '/assets/images/motor.png',
  },
  {
    value: 'Sailing',
    label: 'sailing',
    imagePath: '/assets/images/sailing.png',
  },
  {
    value: "I don't care",
    label: 'iDontCare',
    imagePath: '/assets/images/sailing.png',
  },
] as Array<RadioFieldsType>;
