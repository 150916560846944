import React, { useRef, useState } from 'react';
import { useCallback } from 'react';
import PopNotification from './components/PopNotification';
import { PopNotificationRef } from './components/PopNotification/PopNotification.component';
import PopNotificationsContext, {
  Notification,
} from './PopNotifications.context';
import uniqid from 'uniqid';
import { AxiosError } from 'axios';
import utils from 'utils';
import useMatchMedia from 'hooks/useMatchMedia';

type PopNotificationsProps = {};

const PopNotifications: React.FC<PopNotificationsProps> = (props) => {
  const { children } = props;

  const [notifications, setNotifications] = useState<
    Array<Notification & { notId: string }>
  >([]);
  const notificationsRef = useRef(new Set<PopNotificationRef>());
  const isPhablet = useMatchMedia('isPhablet');

  const onNotificationClose = useCallback((n: Notification) => {
    n.onClose?.();
    setNotifications((old) => old.filter((not) => not !== n));
  }, []);

  const popInfo = useCallback((conf: Notification) => {
    Array.from(notificationsRef.current)
      .find((n) => n.open)
      ?.setOpen(false);
    setNotifications((old) => [...old, { ...conf, notId: uniqid() }]);
  }, []);

  const popServerError = useCallback(
    (error: AxiosError | unknown) => {
      const message = utils.getStringError(error as AxiosError);
      popInfo({
        type: 'Error',
        content: message,
      });
      console.error(error);
    },
    [popInfo],
  );

  const popSuccess = useCallback(
    (message: string) => {
      popInfo({
        type: 'Success',
        content: message,
      });
    },
    [popInfo],
  );

  return (
    <PopNotificationsContext.Provider
      value={{ popInfo, popSuccess, popServerError }}
    >
      {notifications.map((n, ind) => (
        <PopNotification
          side={isPhablet ? 'top' : 'bottom-right'}
          ref={(r) => r && notificationsRef.current.add(r)}
          key={n.notId}
          {...n}
          onClose={() => {
            notificationsRef.current.delete(
              Array.from(notificationsRef.current)[ind],
            );
            onNotificationClose?.(n);
          }}
        >
          {n.content}
        </PopNotification>
      ))}
      {children}
    </PopNotificationsContext.Provider>
  );
};

export default PopNotifications;
