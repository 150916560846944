import React, { useCallback } from 'react';
import classNames from 'classnames';
import utils from 'utils';

import './InfiniteScroll.styles.scss';

declare global {
  interface Window {
    infiniteLocalLoading?: boolean;
  }
}

type InfiniteScrollProps = {
  treshold?: number;
  debounceTime?: number;
  className?: string;
  scrollRef?: (element: HTMLDivElement) => void;
  onDefaultScroll?: React.UIEventHandler<HTMLDivElement>;
  onScroll: () => void;
};

const InfiniteScrollLocal: React.FC<InfiniteScrollProps> = (props) => {
  const {
    children,
    className,
    treshold = 90,
    debounceTime = 50,
    onDefaultScroll,
    onScroll,
  } = props;
  const classes = classNames('yx-infinite-scroll', className);

  const handleScroll = useCallback(
    utils.debounce(
      (div: HTMLDivElement, treshold: number, onScroll: () => void) => {
        if (!div) return;

        window.infiniteLocalLoading = true;
        setTimeout(() => (window.infiniteLocalLoading = false), 200);
        if (
          ((div.clientHeight + Math.abs(div.scrollTop)) / div.scrollHeight) *
            100 >=
          treshold
        ) {
          onScroll?.();
        }
      },
      debounceTime,
    ),
    [debounceTime],
  );

  return (
    <div
      className={classes}
      onScroll={(e) => {
        onDefaultScroll?.(e);
        handleScroll(e.target, treshold, onScroll);
      }}
    >
      {children}
    </div>
  );
};

export default InfiniteScrollLocal;
