/* eslint-disable import/no-anonymous-default-export */
import api from '../api';
import bus, { AuthEventTypes } from '../modules/bus';
import credentialsService from './credentialsService';

export default {
  refreshingAtm: false,
  triedRefresh: false,
  checkRefreshingTreshold: 10000,
  async refreshToken() {
    this.refreshingAtm = true;

    const {
      data: { token, refreshToken },
    } = await api.auth.refreshToken(credentialsService.refreshToken);

    credentialsService.token = token;
    credentialsService.refreshToken = refreshToken;

    bus.broadcastEvent(AuthEventTypes.REFRESH_TOKEN_SUCCESS);

    this.refreshingAtm = false;
    this.triedRefresh = true;

    setTimeout(() => {
      this.triedRefresh = false;
    }, 10000);
  },
  reinit() {
    this.refreshingAtm = false;
  },
};
