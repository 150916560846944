import React, {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
} from 'react';
import classNames from 'classnames';
import './SendAppointmentToClient.styles.scss';
import { Button, Modal } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import InputField from '../../../../../../../components/Fields/InputField';
import PopNotificationsContext from '../../../../../../../providers/PopNotifications/PopNotifications.context';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import api from '../../../../../../../api';
import validators from '../../../../../../../validators';
import { composeValidators } from '../../../../../../../validators/general';
import useCallbackRef from '../../../../../../../hooks/useCallbackRef';
import { useTranslation } from 'react-i18next';
import MatchContext from '../../providers/Match/Match.context';

type SendAppointmentToClientProps = {
  className?: string;
};

const SendAppointmentToClient: React.ForwardRefRenderFunction<
  ModalRef,
  SendAppointmentToClientProps
> = (props, ref) => {
  const { className } = props;
  const { t } = useTranslation();
  const { popServerError, popInfo } = useContext(PopNotificationsContext);
  const [modal, modalRef] = useCallbackRef<ModalRef>();
  const {
    match: { _id },
  } = useContext(MatchContext);

  const classes = classNames('yx-send-appointment-to-client', className);

  useImperativeHandle(ref, () => modal as any, [modal]);

  const validationObject = useMemo(
    () => ({
      required: validators.required(t('requiredField')),
      email: composeValidators(
        validators.required(t('requiredField')),
        validators.emailValidation(t('invalidEmailAddress')),
      ),
    }),
    [t],
  );

  const onSubmit = useCallback(
    async ({ emails }: { emails: string }) => {
      const valueArray = emails.split(',').map((email: string) => email.trim());

      try {
        await api.matches.sendCalendarInvitation(_id, valueArray);
        popInfo({ content: t('succesSendInvites'), type: 'Success' });
        modal?.close();
      } catch (e) {
        popServerError(e);
      }
    },
    [_id, modal, popInfo, popServerError, t],
  );

  return (
    <Modal className={classes} ref={modalRef}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <p className="title">{t('sendAppointmentToClient-title')}</p>
            <p className="description">
              {t('sendAppointmentToClient-description')}
            </p>
            <Field
              name="emails"
              placeholder={t('sendAppointmentToClient-placeholder')}
              component={InputField}
              validate={validationObject.required}
            />
            <div className="action-container">
              <Button styleType="secondary" onClick={() => modal?.close()}>
                {t('cancel')}
              </Button>
              <Button type="submit">{t('send')}</Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};
export default forwardRef(SendAppointmentToClient);
