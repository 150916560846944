import { Search } from 'models/Search';
import { createContext } from 'react';
import utils from 'utils';

type SearchContextProps = {
  loading: boolean;
  loadingSearches: boolean;
  searches: Array<Search>;
  setSearches: (values: Array<Search>) => void;
  deleteSearch: (search: Search) => void;
  onSearchScroll: () => void;
};

export default createContext<Partial<SearchContextProps>>({
  loading: false,
  loadingSearches: false,
  searches: [],
  setSearches: utils.noop,
  deleteSearch: utils.noop,
  onSearchScroll: utils.noop,
});
