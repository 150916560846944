import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import SuccessRegistrationCard from '../../components/SuccessRegistrationCard';
import { SelectionCardType } from '../../components/SuccessRegistrationCard/SuccessRegistrationCard.component';
import SearchIcon from 'icons/Search.icon';
import YachtIcon from 'icons/Yacht.icon';
import { useTranslation } from 'react-i18next';
import credentialsService from 'services/credentialsService';
import api from 'api';
import { USER_REGISTRATION_STEPS } from 'models/Auth';
import { UserRoles, USER_ROLES } from 'models/User';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import { useForm } from 'react-final-form';
import AgreementStep from 'router/subrouters/SignUp/components/CommonSteps/AgreementStep';

import './ConfidentialityAgreementStep.styles.scss';
import confirm from 'modules/confirm';
import GeneralContext from 'providers/General/General.context';

type ConfidentialityAgreementStepProps = {
  className?: string;
};

const ConfidentialityAgreementStep: React.FC<ConfidentialityAgreementStepProps> =
  (props) => {
    const { className } = props;
    const { t } = useTranslation();
    const [acceptAgreement, setAcceptAgreement] = useState<boolean>(false);
    const { popServerError, popInfo } = useContext(PopNotificationsContext);
    const { logout } = useContext(GeneralContext);
    const { submit } = useForm();

    const classes = classNames('yx-confidentiality-agreement-step', className);

    const setUserRole = useCallback(
      async (userRole: UserRoles) => {
        try {
          if (!credentialsService.user)
            throw new Error('There is no user data in Local Storage');

          const { data } = await api.users.patchUserByStep(
            USER_REGISTRATION_STEPS.CONFIDENTIALITY_AGREEMENT,
            { role: userRole },
          );
          credentialsService.saveUserData(data.user.updatedTmpUser);

          submit();
        } catch (e) {
          popServerError(e);
        }
      },
      [popServerError, submit],
    );

    const onSearchHandler = useCallback(() => {
      setUserRole(USER_ROLES.SELLER);
    }, [setUserRole]);

    const onDashHandler = useCallback(() => {
      setUserRole(USER_ROLES.SELLER);
    }, [setUserRole]);

    const selectionLinks = useMemo(
      () =>
        [
          {
            title: t('lookingForYacht'),
            icon: SearchIcon,
            path: '/creating-profile',
            handler: onSearchHandler,
          },
          {
            title: t('wantToSellYacht'),
            icon: YachtIcon,
            path: '/creating-profile',
            handler: onDashHandler,
          },
        ] as Array<SelectionCardType>,
      [onDashHandler, onSearchHandler, t],
    );

    if (acceptAgreement)
      return <SuccessRegistrationCard selectionLinks={selectionLinks} />;
    else
      return (
        <AgreementStep
          className={classes}
          onAccept={() => setAcceptAgreement(true)}
          onDecline={async () => {
            if (await confirm({ content: t('declineAgreement') })) {
              try {
                await api.users.removeTmpUser();
                logout();
                popInfo({ content: t('weHaveDeletedDataAboutYou') });
              } catch (er) {
                popServerError(er);
              }
            }
          }}
        />
      );
  };

export default ConfidentialityAgreementStep;
