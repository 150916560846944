import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { CountryNames } from 'services/countries';
import { LegalPerson } from './Auth';
import { Subscription } from './Subscription';

export type VerificationStatus =
  | 'Unverified'
  | 'Submitted'
  | 'Approved'
  | 'Rejected';

export type UserRoles = typeof USER_ROLES[keyof typeof USER_ROLES];
export const USER_ROLES = {
  SELLER: 'Seller',
  BUYER: 'Buyer',
} as const;

export type UserTypes = typeof USER_TYPES[keyof typeof USER_TYPES];

export const USER_TYPES = {
  BROKER: 'Broker',
  PRIVATE: 'Private',
} as const;

export type AddressType = {
  streetAddress: string;
  building: string;
  zipCode: string;
  city: string;
  country: CountryNames | OptionValue<CountryNames>;
  countryFlag: string;
};

export type WorkYearType = {
  from: Date;
  to: Date;
};

export type VerificationType = {
  id: string;
  sessionToken: string;
  status: string;
  url: string;
  vendorData: string;
};

export type CardTypes = 'mastercard' | 'visa';

export type CreditCard = {
  brand: CardTypes;
  expMonth: string;
  expYear: string;
  last4: string;
};

export type User = {
  _id?: string;
  patchToken?: string;
  roles?: UserRoles[];
  role?: UserRoles;
  userType?: UserTypes;
  email?: string;
  password?: string;
  createdAt?: string;
  hasVerifiedEmail?: boolean;
  hasActiveSubscription?: boolean;
  isBlocked?: boolean;
  isOnFreeTrial?: boolean;
  legalForm?: string;
  updatedAt?: string;
  address?: AddressType;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  isRepresentingLegalPerson?: boolean;
  legalPersonInfo?: LegalPerson;
  workYears?: WorkYearType;
  linkedInProfile?: string;
  workTitle?: UserTypes;
  proofOfAddressKey?: string;
  numberOfUnreadNotifications?: number;
  verificationSessionInfo?: VerificationType;
  verificationStatus?: VerificationStatus;
  emailNotificationSettings?: string[];
  profileImage?: Record<string, any>;
  subscription?: Array<Subscription>;
  subscriptionId?: string;
  paymentInfo?: {
    stripeId: string;
    type: string;
    card: CreditCard;
  };
};
