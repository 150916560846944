import React from 'react';
import classNames from 'classnames';
import './ViewingRequest.styles.scss';
import WaitScreen from '../../../components/WaitScreen';
import { useTranslation } from 'react-i18next';

type ViewingRequestProps = {
  className?: string;
};

const ViewingRequest: React.FC<ViewingRequestProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-viewing-request', className);

  return (
    <div className={classes}>
      <WaitScreen
        title={t('viewingRequest-buyerTitle')}
        description={t('viewingRequest-buyerDescription')}
      />
    </div>
  );
};

export default ViewingRequest;
