import React from 'react';
import classNames from 'classnames';
import StepWrapper from '../../../../../components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field, useField } from 'react-final-form';
import DropzoneField from '../../../../../components/Fields/DropzoneField';
import YesNoField from '../../../../../components/Fields/YesNoField';
import validators from 'validators';
import Tooltip from 'components/Tooltip';
import useDropzoneRequest from '../../hooks/useDropzoneRequest';
import credentialsService from 'services/credentialsService';

import './CommercialComplianceStep.styles.scss';

type CommercialComplianceStepProps = {
  className?: string;
};

const CommercialComplianceStep: React.FC<CommercialComplianceStepProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-commercial-compliance-step', className);

  const {
    input: { value: showDropzone },
  } = useField<boolean>('hasCommercialCompliance');

  const { uploadFile } = useDropzoneRequest(credentialsService.draftYachtId);

  return (
    <StepWrapper
      title={t('commercialCompliance-title')}
      description={t('commercialCompliance-subTitle')}
      className={classes}
    >
      <Field
        name="hasCommercialCompliance"
        component={YesNoField}
        label={t('commercialCompliance-capacity')}
        tooltip={<Tooltip>{t('commercialCompliance-tooltip')}</Tooltip>}
      />
      {showDropzone && (
        <Field
          uploadRequest={uploadFile}
          maxFiles={1}
          name="commercialYachtSurveyReport"
          label={t('commercialCompliance')}
          required
          component={DropzoneField}
          validate={validators.required(t('commercialCompliance-validation'))}
        />
      )}
    </StepWrapper>
  );
};

export default CommercialComplianceStep;
