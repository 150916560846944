import React from 'react';
import classNames from 'classnames';
import './CanceledMatch.styles.scss';
import MessageScreen from '../../components/MessageScreen';
import { useTranslation } from 'react-i18next';
import CanceledIcon from '../../../../../../../icons/Canceled.icon';

type CanceledMatchProps = {
  className?: string;
};

const CanceledMatch: React.FC<CanceledMatchProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-canceled-match', className);

  return (
    <MessageScreen
      className={classes}
      icon={CanceledIcon}
      title={t('canceledMatch-title')}
      description={t('canceledMatch-description')}
    />
  );
};

export default CanceledMatch;
