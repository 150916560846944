import React from 'react';
import classNames from 'classnames';
import ShowLocation from '../ShowLocation';
import './ShowAppointment.styles.scss';
import './ShowAppointment.styles.responsive.scss';
import AppointmentCard from './components/AppointmentCard/AppointmentCard.component';
import { useTranslation } from 'react-i18next';
import { XOR } from 'models/TypeOperations';
import SendAppointmentToClientComponent from 'router/subrouters/Dashboard/subrouters/Match/modals/SendAppointmentToClient/SendAppointmentToClient.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';

type CenterLatLong = {
  lat: number;
  long: number;
};

type CenterString = {
  place: string;
};

type ShowAppointmentProps = {
  className?: string;
  date: Date;
  location: string;
  contact: string;
  contactDescription: string;
} & XOR<CenterLatLong, CenterString>;

const ShowAppointment: React.FC<ShowAppointmentProps> = (props) => {
  const { className, date, location, contact, contactDescription, ...rest } =
    props;
  const { t } = useTranslation();
  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const classes = classNames('yx-show-appointment', className);

  return (
    <div className={classes}>
      <ShowLocation
        {...rest}
        title={t('showAppointment-title')}
        description={t('showAppointment-description')}
        actionText={t('showAppointment-actionText')}
        onActionClick={() => modal?.open()}
      />
      <div className="yx-show-appointment__card-container">
        <AppointmentCard
          title={t('dateAndLocation')}
          mainInfo={date?.toDateString()}
          description={location}
        />
        <AppointmentCard
          title={t('contact')}
          mainInfo={contact}
          description={contactDescription}
        />
      </div>
      <SendAppointmentToClientComponent ref={modalRef} />
    </div>
  );
};

export default ShowAppointment;
