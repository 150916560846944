import React from 'react';
import classNames from 'classnames';
import './StatusLabel.styles.scss';

export type StatusLabelTypes = 'active' | 'inactive' | 'error';

type StatusLabelProps = {
  className?: string;
  label?: string;
  statusType: StatusLabelTypes;
};

const StatusLabel: React.FC<StatusLabelProps> = (props) => {
  const { label, statusType = 'active', className } = props;

  const classes = classNames(
    'yx-status-label',
    { 'yx-status-label__active': statusType === 'active' },
    { 'yx-status-label__inactive': statusType === 'inactive' },
    { 'yx-status-label__error': statusType === 'error' },
    className,
  );

  return <span className={classes}>{label}</span>;
};

export default StatusLabel;
