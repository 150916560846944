import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import HomeSegment from './components/HomeSegment';
import EmptyState from 'components/EmptyState';
import HomeMatchCard from './components/HomeMatchCard';
import EmptyMatchesIcon from 'icons/EmptyMatches.icon';
import EmptyYachtIcon from 'icons/EmptyYacht.icon';
import EmptySearchIcon from 'icons/EmptySearch.icon';
import { useHistory } from 'react-router-dom';
import CurrentUserContext from '../../providers/CurrentUser/CurrentUser.context';
import HomeContext from './providers/Home/Home.context';
import SimpleYachtCard from './components/SimpleYachtCard';
import { Loader } from 'ncoded-component-library';
import SimpleSearchCard from './components/SimpleSearchCard';

import './Home.styles.scss';
import './Home.responsive.styles.scss';
import { MATCH_TYPE } from '../../subrouters/Match/models';

type HomeProps = {
  className?: string;
};

const Home: React.FC<HomeProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const classes = classNames('yx-home', className);
  const {
    currentUser: { firstName, _id: userId },
  } = useContext(CurrentUserContext);

  const {
    yachts = [],
    searches = [],
    matches = [],
    yachtsLoading,
    matchesLoading,
    searchesLoading,
  } = useContext(HomeContext);

  const isYachtsEmpty = useMemo(() => yachts?.length === 0, [yachts]);
  const isSearchesEmpty = useMemo(() => searches?.length === 0, [searches]);
  const isMatchesEmpty = useMemo(() => matches?.length === 0, [matches]);

  const searchesContent = useMemo(
    () =>
      isSearchesEmpty ? (
        <EmptyState
          icon={EmptySearchIcon}
          label={t('youHaveNoSearchesYet')}
          buttonText={t('createNewSearch')}
          onButtonClick={() => history.push('/create-search-profile')}
        />
      ) : (
        <div className="yx-home__cards__container">
          {searchesLoading && <Loader />}

          {searches?.map(
            ({
              id,
              searchName,
              yachtStatus,
              yachtType,
              budget,
              guestCapacity,
              location,
            }) => (
              <SimpleSearchCard
                key={id}
                searchName={searchName}
                yachtStatus={yachtStatus}
                yachtType={yachtType}
                budget={budget}
                guestCapacity={guestCapacity}
                location={location}
              />
            ),
          )}
        </div>
      ),
    [history, isSearchesEmpty, searchesLoading, searches, t],
  );

  const yachtsContent = useMemo(
    () =>
      isYachtsEmpty ? (
        <EmptyState
          icon={EmptyYachtIcon}
          label={t('noYachtsYet')}
          buttonText={t('createNewYacht')}
          onButtonClick={() => history.push('/create-yacht')}
        />
      ) : (
        <div className="yx-home__cards__container">
          {yachtsLoading && <Loader />}

          {yachts?.map(
            ({ id, overallLength, askingPrice, profileShots, vesselName }) => (
              <SimpleYachtCard
                key={id}
                id={id}
                overallLength={overallLength}
                askingPrice={askingPrice}
                profileShots={profileShots}
                vesselName={vesselName}
              />
            ),
          )}
        </div>
      ),
    [isYachtsEmpty, t, yachtsLoading, yachts, history],
  );

  const matchesContent = useMemo(
    () =>
      isMatchesEmpty ? (
        <EmptyState icon={EmptyMatchesIcon} label={t('noMatchesYet')} />
      ) : (
        <div className="yx-home__matches">
          {matchesLoading && <Loader />}

          {matches?.map(
            ({
              _id,
              buyerId,
              yachtName,
              yachtLength,
              yachtAskingPrice,
              bestSearch: { matchingScore },
              status,
            }) => (
              <HomeMatchCard
                key={_id}
                type={userId === buyerId ? MATCH_TYPE.BUYER : MATCH_TYPE.SELLER}
                name={yachtName}
                length={yachtLength}
                price={yachtAskingPrice}
                status={status}
                matchingScore={matchingScore}
                onView={() => history.push(`matches/match/${_id}`)}
              />
            ),
          )}
        </div>
      ),
    [isMatchesEmpty, t, matchesLoading, matches, userId, history],
  );

  return (
    <div className={classes}>
      <header className="yx-home__header">
        <h2>{t('home-header', { name: firstName })}</h2>
      </header>

      <HomeSegment className="yx-home__my-matches" title={t('myMatches')}>
        {matchesContent}
      </HomeSegment>

      <HomeSegment
        title={t('myYachts')}
        className={classNames('yx-home__cards', {
          'one-child': yachts?.length === 1,
          empty: yachts.length === 0,
        })}
        btnTitle={!isYachtsEmpty && t('seeAll')}
        onClick={() => history.push('/my-yachts')}
      >
        {yachtsContent}
      </HomeSegment>

      <HomeSegment
        title={t('mySearches')}
        className={classNames('yx-home__cards', {
          'one-child': searches?.length === 1,
          empty: searches.length === 0,
        })}
        btnTitle={!isYachtsEmpty && t('seeAll')}
        onClick={() => history.push('/my-searches')}
      >
        {searchesContent}
      </HomeSegment>
    </div>
  );
};

export default Home;
