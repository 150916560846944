export default {
  AUTH: 'auth',
  AUTH_SUCCESS: 'auth:success',
  AUTH_FAIL: 'auth:fail',
  DISCONNECT: 'disconnect',
  KICKED: 'kicked',

  ONLINE_ACKNOWLEDGE: 'online-acknowledge',
  ONLINE_ACKNOWLEDGE_SUCCESS: 'online-acknowledge:success',

  VERIFICATION_SUBMITTED: 'verification-submitted',
  VERIFICATION_APPROVED: 'verification-approved',
  VERIFICATION_REJECTED: 'verification-rejected',

  NOTIFICATION_CREATED: 'notification-created',
} as const;
