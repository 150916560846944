import React from 'react';
import { DownArrow } from '.';
import './svg.styles.scss';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <DownArrow className="svg-styles__right" {...props} />
);
