import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-nextLine import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 384 384"
    fill="currentColor"
    {...props}
  >
    <path d="m336 0h-288c-26.472656 0-48 21.527344-48 48v288c0 26.472656 21.527344 48 48 48h288c26.472656 0 48-21.527344 48-48v-288c0-26.472656-21.527344-48-48-48zm-288 32h288c8.824219 0 16 7.175781 16 16v113.375l-52.6875-52.6875c-6.25-6.246094-16.375-6.246094-22.625 0l-112.6875 112.6875-40.6875-40.6875c-6.25-6.246094-16.375-6.246094-22.625 0l-68.6875 68.6875v-201.375c0-8.824219 7.175781-16 16-16zm288 320h-288c-8.824219 0-16-7.175781-16-16v-41.375l80-80 92.6875 92.679688c3.128906 3.136718 7.214844 4.695312 11.3125 4.695312s8.183594-1.558594 11.3125-4.6875c6.246094-6.25 6.246094-16.375 0-22.625l-40.6875-40.6875 101.375-101.367188 64 64v129.367188c0 8.824219-7.175781 16-16 16zm0 0" />
    <path d="m128 96c0 17.671875-14.328125 32-32 32s-32-14.328125-32-32 14.328125-32 32-32 32 14.328125 32 32zm0 0" />
  </svg>
);
