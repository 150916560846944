import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';
import Donut from './components/Donut';
import { useTranslation } from 'react-i18next';
import OptionMenu from 'components/OptionMenu';
import { Button } from 'ncoded-component-library';

import { NavLink } from 'react-router-dom';
import { OptionItem } from 'components/OptionMenu/OptionMenu.component';
import './StepProgressResponsive.styles.scss';

type StepProgressResponsiveProps = {
  className?: string;
};

const StepProgressResponsive: React.FC<StepProgressResponsiveProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation();

  const { completedSteps, currentStep, stepLabels, linkSteps } =
    useContext(StepsContext);

  const classes = classNames('yx-step-progress-responsive', className);

  const options = useMemo<OptionItem[]>(
    () =>
      linkSteps.map(({ path, label }, ind) => ({
        label: (
          <NavLink
            to={path}
            className={classNames({
              disabled: completedSteps < ind,
              active: currentStep === ind,
            })}
          >
            <span>
              {ind + 1}.&nbsp;
              {label}
            </span>
          </NavLink>
        ),
      })),
    [completedSteps, currentStep, linkSteps],
  );

  return (
    <div className={classes}>
      <h1>{stepLabels[currentStep]}</h1>

      <OptionMenu
        title={t('selectStep')}
        contentClassName="step-progressive-menu"
        trigger={<Button variant="link">{t('showAllSteps')}</Button>}
        options={options}
      />

      <Donut
        percentage={`${((100 * completedSteps) / linkSteps.length).toFixed(0)}`}
      />
    </div>
  );
};

export default StepProgressResponsive;
