import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="transparent"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M11.557 18.103a1.8 1.8 0 01-3.114 0M19 14.503H1a2.7 2.7 0 002.7-2.701v-4.5a6.302 6.302 0 0110.755-4.456A6.302 6.302 0 0116.3 7.3v4.5a2.7 2.7 0 002.7 2.701v0z" />
  </svg>
);
