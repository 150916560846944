import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import PopNotificationsContext from '../providers/PopNotifications/PopNotifications.context';
import { DropzoneFieldFile } from '../components/Fields/DropzoneField/DropzoneField.component';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import FileIcon from 'icons/File.icon';
import VideoIcon from 'icons/Video.icon';
import ImageIcon from 'icons/Image.icon';

type UploaderProps = {
  title: string;
  size: number;
  percentage: number;
  icon?: React.FC;
};

const fileTypeIcon = {
  image: ImageIcon,
  video: VideoIcon,
  application: FileIcon,
} as const;

type FileType = keyof typeof fileTypeIcon;

export type DropzoneFieldRequests = {
  uploadRequest: (
    file: File,
    fileName: string,
    config?: AxiosRequestConfig,
  ) => Promise<
    AxiosResponse<
      | {
          file: DropzoneFieldFile;
        }
      | { files: DropzoneFieldFile[] }
    >
  >;
  deleteRequest: (
    dropzoneFile: DropzoneFieldFile,
    name: string,
  ) => Promise<AxiosResponse<any>>;
};

type UseDropzoneField = {
  fileName: string;
  value: Array<DropzoneFieldFile>;
  maxFiles?: number;
  onChange: (value: Array<DropzoneFieldFile> | DropzoneFieldFile) => void;
} & Pick<DropzoneFieldRequests, 'uploadRequest'>;

const useDropzoneField = ({
  fileName,
  onChange,
  value,
  maxFiles,
  uploadRequest,
}: UseDropzoneField) => {
  const { popServerError } = useContext(PopNotificationsContext);

  const [uploaderProps, setUploaderProps] = useState<
    Record<string, UploaderProps>
  >({});

  const uploaderArray = useMemo(
    () => Object.values(uploaderProps),
    [uploaderProps],
  );

  const createFileConfig = useCallback(
    (file: File) => ({
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { name, type } = file;
        const { total, loaded } = progressEvent;
        const percentage = ~~((loaded / total) * 10000) / 100;

        setUploaderProps((old) => ({
          ...old,
          [name]: {
            percentage,
            size: total,
            title: name,
            icon: fileTypeIcon[type.split('/')[0] as FileType],
          },
        }));
      },
    }),
    [],
  );

  const deltaValue = useRef<DropzoneFieldFile[]>([]);

  const updateUploadedFilesValue = useCallback(
    (newFiles: DropzoneFieldFile[]) => {
      const newFilesTransformed = newFiles.map((newFile) => ({
        ...newFile,
        type: newFile.type.split('/')[0] as FileType,
      }));

      deltaValue.current = [...deltaValue.current, ...newFilesTransformed];
      const file = maxFiles === 1 ? deltaValue.current[0] : deltaValue.current;

      onChange(file);

      setUploaderProps((old) => {
        const newUP = { ...old };
        newFilesTransformed.forEach((newFile) => delete newUP[newFile.name]);
        return newUP;
      });
    },
    [onChange, maxFiles],
  );

  const onDropUpload = useCallback(
    (filesToUpload: File[]) => {
      if (!uploadRequest) throw Error('Upload request is not passed as a prop');
      if (!filesToUpload || !filesToUpload.length) return;

      deltaValue.current = value;

      filesToUpload.forEach(async (file) => {
        try {
          const { data } = await uploadRequest(
            file,
            fileName,
            createFileConfig(file),
          );
          const fileArray = 'file' in data ? [data.file] : data.files;

          updateUploadedFilesValue(
            fileArray.filter((f) => f.name === file.name),
          );
        } catch (e) {
          popServerError(e);
          setUploaderProps(({ [file.name]: _, ...rest }) => rest);
        }
      });
    },
    [
      createFileConfig,
      fileName,
      popServerError,
      updateUploadedFilesValue,
      uploadRequest,
      value,
    ],
  );

  return [uploaderArray, onDropUpload] as const;
};

export default useDropzoneField;
