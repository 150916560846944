import httpClient from 'api/httpClient';
import { Notification } from 'models/Notification';
import { PaginatedData, PaginationData } from 'models/PaginatedData';

const notificationPath = (routePath: TemplateStringsArray) =>
  `notifications/${routePath}`;

function getNotificationsForUser(params: PaginationData) {
  return httpClient.get<PaginatedData<Notification>>('notifications', {
    params,
  });
}

function getNotificationById(notificationId: string) {
  return httpClient.get<Notification>(`notifications/${notificationId}`);
}

function readAllNotifications() {
  return httpClient.post(notificationPath`read-all`);
}

function readNotification(notificationId: string) {
  return httpClient.post<Notification>(`notifications/${notificationId}/read`);
}
export default {
  getNotificationsForUser,
  getNotificationById,
  readAllNotifications,
  readNotification,
};
