import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { CalendarProviderValues, CalendarProviderProps, Hour } from '../models';
import CalendarContext from './Calendar.context';
import { sortedDatesInsert } from '../utils';

const CalendarProvider: React.FC<CalendarProviderProps> = (props) => {
  const { children, onChange: onChangeValue, value = [] } = props;
  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());
  const dates = useMemo(
    () => value.map((date) => DateTime.fromJSDate(date)),
    [value],
  );

  const isFull = useMemo(() => value.length >= 10, [value.length]);

  const onChange = useCallback(
    (hour: Hour) => {
      const date = currentDate.toObject();
      !isFull &&
        onChangeValue(
          sortedDatesInsert(
            value,
            DateTime.fromObject({ ...date, hour, minute: 0 }).toJSDate(),
          ),
        );
    },
    [currentDate, isFull, onChangeValue, value],
  );

  const removeDate = useCallback(
    (index: number) => {
      onChangeValue(value.filter((el, i) => index !== i));
    },
    [onChangeValue, value],
  );

  const providerValues: CalendarProviderValues = useMemo(
    () => ({
      currentDate,
      setCurrentDate,
      onChange,
      removeDate,
      dates,
      isFull,
    }),
    [currentDate, dates, isFull, onChange, removeDate],
  );

  return (
    <CalendarContext.Provider value={providerValues}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;
