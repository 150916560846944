import React from 'react';
import AgreementWrapper from 'components/AgreementWrapper';
import AgreementChild from 'components/AgreementWrapper/components/AgreementChild';
import { useTranslation } from 'react-i18next';
import { AgreementWrapperProps } from 'components/AgreementWrapper/AgreementWrapper.component';
import classNames from 'classnames';

import './AgreementStep.styles.scss';

type AgreementStepProps = Pick<
  AgreementWrapperProps,
  'onAccept' | 'onDecline' | 'className'
>;

const AgreementStep: React.FC<AgreementStepProps> = ({
  className,
  onAccept,
  onDecline,
}) => {
  const { t } = useTranslation();

  return (
    <AgreementWrapper
      className={classNames('yx-agreement-step', className)}
      title={t('confidentialityAgreement-title')}
      onAccept={onAccept}
      onDecline={onDecline}
    >
      <AgreementChild
        title={t('confidentialityAgreement-confidentialityAgreement')}
      >
        {t('confidentialityAgreement-confidentialityAgreementText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-premable')}>
        {t('confidentialityAgreement-premableText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-definitions')}>
        {t('confidentialityAgreement-definitionsText')}
      </AgreementChild>
      <AgreementChild
        title={t('confidentialityAgreement-confidentialityObligations')}
      >
        {t('confidentialityAgreement-confidentialityObligationsText')}
      </AgreementChild>
      <AgreementChild
        title={t(
          'confidentialityAgreement-exceptionsToConfidentialityObligations',
        )}
      >
        {t(
          'confidentialityAgreement-exceptionsToConfidentialityObligationsText',
        )}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-dataProtection')}>
        {t('confidentialityAgreement-dataProtectionText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-informationTransfer')}>
        {t('confidentialityAgreement-informationTransferText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-nonCompetition')}>
        {t('confidentialityAgreement-nonCompetitionText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-thirdPariesContract')}>
        {t('confidentialityAgreement-thirdPariesContractText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-termOfAgreement')}>
        {t('confidentialityAgreement-termOfAgreementText')}
      </AgreementChild>
      <AgreementChild
        title={t('confidentialityAgreement-transferabilityOfRights')}
      >
        {t('confidentialityAgreement-transferabilityOfRightsText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-writtenForm')}>
        {t('confidentialityAgreement-writtenFormText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-partialInvalidity')}>
        {t('confidentialityAgreement-partialInvalidityText')}
      </AgreementChild>
      <AgreementChild title={t('confidentialityAgreement-choiceOfLaw')}>
        {t('confidentialityAgreement-choiceOfLawText')}
      </AgreementChild>
    </AgreementWrapper>
  );
};

export default AgreementStep;
