import React from 'react';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import { RoutingStep } from '../SignUp/models/Routes';
import TypeOfVessel from './pages/TypeOfVessel';
import NewOrUsedStep from './pages/NewOrUsedStep';
import {
  MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER,
  Search,
  SearchSteps,
  SEARCH_CREATE_STEPS,
} from 'models/Search';
import BudgetStep from './pages/BudgetStep';
import GuestCapacityStep from './pages/GuestCapacityStep';
import SpeedStep from './pages/SpeedStep';
import YearOfBuildStep from './pages/YearOfBuildStep';
import FuelConsumptionStep from './pages/FuelConsumptionStep';
import SpecificEquipmentStep from './pages/SpecificEquipmentStep';
import CharterStep from './pages/CharterStep';
import AdvancedSearch from './pages/AdvancedSearch';
import CreateSearchSuccess from './pages/CreateSearchSuccess';
import PreviewStep from './pages/PreviewStep';
import LocationStep from './pages/LocationStep';
import StepsProvider from 'components/StepProgress/providers/Steps/Steps.provider';
import CreateSearchProfileRouter, {
  CreateSearchProfileProps,
} from './CreateSearchProfile.router';
import redirect from 'router/modules/redirect';
import DecidingSearch from './components/DecidingSearch';
import { getUnitValue } from './services/utils';

export const steps = [
  {
    path: '/type-of-vessel',
    exact: true,
    component: TypeOfVessel,
    stepName: SEARCH_CREATE_STEPS.TYPE_OF_VESSEL,
    labelId: 'typeOfVessel',
  },
  {
    path: '/new-or-used',
    exact: true,
    component: NewOrUsedStep,
    stepName: SEARCH_CREATE_STEPS.NEW_OR_USED,
    labelId: 'newOrUsed',
  },
  {
    path: '/budget',
    exact: true,
    component: BudgetStep,
    stepName: SEARCH_CREATE_STEPS.BUDGET,
    labelId: 'budget',
    patchMap: (values: Search) =>
      ({
        budget: {
          from: getUnitValue(values.budget.from, 'toServer'),
          to: getUnitValue(values.budget.to, 'toServer'),
        },
        runningCost: {
          from: getUnitValue(values.runningCost.from, 'toServer'),
          to: getUnitValue(values.runningCost.to, 'toServer'),
        },
        sizeRange: {
          from: getUnitValue(values.sizeRange.from, 'toServer'),
          to: getUnitValue(values.sizeRange.to, 'toServer'),
        },
      } as Search),
  },
  {
    path: '/guest-capacity',
    exact: true,
    component: GuestCapacityStep,
    stepName: SEARCH_CREATE_STEPS.GUEST_CAPACITY,
    labelId: 'guestCapacity',
  },
  {
    path: '/location',
    exact: true,
    component: LocationStep,
    stepName: SEARCH_CREATE_STEPS.LOCATION,
    labelId: 'location',
  },
  {
    path: '/speed',
    exact: true,
    component: SpeedStep,
    stepName: SEARCH_CREATE_STEPS.SPEED,
    labelId: 'speed',
  },
  {
    path: '/year-of-build',
    exact: true,
    component: YearOfBuildStep,
    stepName: SEARCH_CREATE_STEPS.YEAR_OF_BUILD,
    labelId: 'yearOfBuild',
  },
  {
    path: '/fuel-consumption',
    exact: true,
    component: FuelConsumptionStep,
    stepName: SEARCH_CREATE_STEPS.FUEL_CONSUMPTION,
    labelId: 'fuelConsumption',
  },
  {
    path: '/specific-equipment',
    exact: true,
    component: SpecificEquipmentStep,
    stepName: SEARCH_CREATE_STEPS.SPECIFIC_EQUIPMENT,
    labelId: 'specificEquipment',
  },
  {
    path: '/charter',
    exact: true,
    component: CharterStep,
    stepName: SEARCH_CREATE_STEPS.CHARTER,
    labelId: 'charter',
    patchMap: ({
      vesselAvailableForCommercialCharterInfo,
      ...restData
    }: Search) => {
      if (
        restData.makeVesselCommercialyAvailable ===
        MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER.YES
      )
        return {
          ...restData,
          vesselAvailableForCommercialCharterInfo: {
            ...vesselAvailableForCommercialCharterInfo,
            potentialAnnualCharterIncome: {
              from: getUnitValue(
                vesselAvailableForCommercialCharterInfo
                  ?.potentialAnnualCharterIncome.from,
                'toServer',
              ),
              to: getUnitValue(
                vesselAvailableForCommercialCharterInfo
                  ?.potentialAnnualCharterIncome.to,
                'toServer',
              ),
            },
            runningCost: {
              from: getUnitValue(
                vesselAvailableForCommercialCharterInfo?.runningCost.from,
                'toServer',
              ),
              to: getUnitValue(
                vesselAvailableForCommercialCharterInfo?.runningCost.to,
                'toServer',
              ),
            },
          },
        } as Search;
      else return restData;
    },
  },
  {
    path: '/preview',
    exact: true,
    component: PreviewStep,
    stepName: 'Preview',
    labelId: 'Preview',
  },
] as Array<RoutingStep<SearchSteps>>;

export const searchRoutes = [
  ...steps,
  {
    path: '/success',
    exact: true,
    component: CreateSearchSuccess,
  },
  {
    path: '/advanced-search',
    exact: true,
    component: AdvancedSearch,
  },
  {
    path: '',
    component: redirect('/type-of-vessel'),
  },
] as Array<EnhancedRouteProps>;

const WrappedSearch = (props: CreateSearchProfileProps) => (
  <StepsProvider
    storageKeySuffix="search"
    steps={steps}
    closeFallbackRoute="/my-searches"
  >
    <CreateSearchProfileRouter routes={searchRoutes} {...props} />
  </StepsProvider>
);

export default [
  {
    path: '/:searchId',
    exact: false,
    component: WrappedSearch,
  },
  {
    path: '',
    component: DecidingSearch,
  },
] as Array<EnhancedRouteProps>;
