import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CreditCard } from '../../../../../../../models/User';
import { CardTypeImageUrls } from '../../../../utils';
import { cardTypeName } from '../../../../models';
import { XOR } from '../../../../../../../models/TypeOperations';

import './CreditCardCard.styles.scss';
import { Button } from 'ncoded-component-library';
import DeleteIcon from 'icons/Delete.icon';

type CreditCardCardProps = {
  className?: string;
  onRemove?: () => void;
} & XOR<CreditCard, { addCard: boolean }>;

const CreditCardCard: React.FC<CreditCardCardProps> = (props) => {
  const { className, last4, expMonth, expYear, brand, addCard, onRemove } =
    props;

  const { t } = useTranslation();

  const classes = classNames('yx-credit-card-card', className);

  const stars = useMemo(() => Array(12).fill('*').join(''), []);

  const cardNumberString = (stars + last4).match(/.{1,4}/g).join(' ');

  return (
    <div className={classes}>
      {addCard ? (
        <>
          <span className="add-new-card">{t('addNewCreditCard')}</span>
        </>
      ) : (
        <>
          <div className="yx-credit-card-card__image">
            <img src={CardTypeImageUrls[brand]} alt={cardTypeName[brand]} />
          </div>
          <div className="yx-credit-card-card__content">
            <span className="card-number">{cardNumberString}</span>
            <div className="card-info">
              <span>{cardTypeName[brand]}</span>
              <span>
                {t('expires')} {expMonth}/{expYear}
              </span>
            </div>
          </div>
          {onRemove && (
            <div className="yx-credit-card-card__remove">
              <Button variant="icon" onClick={onRemove}>
                <DeleteIcon />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreditCardCard;
