import React, { useCallback, useContext, useState } from 'react';
import './AddCreditCardStep.styles.scss';
import '../../../../../../components/StepWrapper/StepWrapper.styles.scss';
import '../../../../../../components/StepWrapper/StepWrapper.responsive.styles.scss';
import { useTranslation } from 'react-i18next';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import api from 'api';
import { USER_REGISTRATION_STEPS } from 'models/Auth';
import { Loader } from 'ncoded-component-library';
import classNames from 'classnames';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import StepsContext from 'components/StepProgress/providers/Steps/Steps.context';
import { Field, useForm, useFormState } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import validators from 'validators';
import { cardElementOptions } from './utils';
import { CardTypes } from 'models/User';
import CardField from './components/CardField';
import StepWrapper from 'components/StepWrapper';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import CreditCardCard from 'router/subrouters/Dashboard/pages/ProfileSettings/components/CreditCardCard';
import confirm from 'modules/confirm';

type PaymentInfo = {
  stripeId: string;
  type: string;
  card: {
    brand: CardTypes;
    last4: string;
    expMonth: string;
    expYear: string;
  };
};

const AddCreditCardStep: React.FC = (props) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { popServerError } = useContext(PopNotificationsContext);
  const { setCompletedSteps, currentStep } = useContext(StepsContext);

  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(
    storageService.getItem(STORAGE_KEYS.PAYMENT_INFO),
  );

  const classes = classNames('yx-step-wrapper', 'yx-add-credit-card-step');

  const { submitting, values, valid } = useFormState();
  const { submit } = useForm();

  const onCardRemove = useCallback(async () => {
    if (await confirm({ content: t('areYouSureYouWantToRemoveCard') })) {
      setPaymentInfo(null);
      setCompletedSteps(currentStep);
    }
  }, [currentStep, setCompletedSteps, t]);

  const onSubmit = useCallback(async () => {
    const { cardName: name, cardPostalCode: postalCode } = values;

    if (paymentInfo) {
      submit();
      return;
    }

    if (!valid) {
      submit();
      setCompletedSteps(currentStep);
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name,
        address: {
          postal_code: postalCode,
        },
      },
    });

    if (error) {
      popServerError(t('stripeError'));
    } else {
      const {
        id,
        card: { brand, last4, exp_month, exp_year },
      } = paymentMethod;

      const paymentInfo = {
        stripeId: id,
        type: 'card',
        card: {
          brand: brand as CardTypes,
          last4: last4,
          expMonth: String(exp_month),
          expYear: String(exp_year),
        },
      };

      try {
        await api.users.patchUserByStep(
          USER_REGISTRATION_STEPS.ADD_CREDIT_CARD,
          {
            paymentInfo,
          },
        );

        storageService.setItem(STORAGE_KEYS.PAYMENT_INFO, paymentInfo);

        submit();
      } catch (e) {
        popServerError(t('serverCardError'));
      }
    }
  }, [
    values,
    valid,
    elements,
    stripe,
    paymentInfo,
    submit,
    setCompletedSteps,
    currentStep,
    popServerError,
    t,
  ]);

  return (
    <StepWrapper className={classes} onSubmit={onSubmit}>
      {(submitting || !stripe || !elements) && <Loader />}
      <div className="yx-step-wrapper__heading">
        <h1>{t('paymentMethod')}</h1>
      </div>
      {paymentInfo ? (
        <CreditCardCard {...paymentInfo.card} onRemove={onCardRemove} />
      ) : (
        <div className="yx-add-credit-card-step__content">
          <CardField
            component={CardNumberElement}
            options={cardElementOptions}
            className="yx-add-credit-card-step__ncoded-input"
            defaultErrorCode="incomplete_number"
          />
          <Field
            name="cardName"
            component={InputField}
            placeholder={t('name')}
            validate={validators.required(t('requiredField'))}
          />

          <div className="yx-add-credit-card-step__row">
            <CardField
              component={CardExpiryElement}
              options={cardElementOptions}
              className="yx-add-credit-card-step__ncoded-input"
              defaultErrorCode="incomplete_expiry"
            />
            <CardField
              component={CardCvcElement}
              options={cardElementOptions}
              className="yx-add-credit-card-step__ncoded-input"
              defaultErrorCode="incomplete_cvc"
            />
          </div>

          <Field
            name="cardPostalCode"
            component={InputField}
            placeholder={t('postalCode')}
            validate={validators.required(t('requiredField'))}
          />
        </div>
      )}
    </StepWrapper>
  );
};

export default AddCreditCardStep;
