import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'ncoded-component-library';
import { ExtractPropTypes } from 'types';
import useCallbackRef from 'hooks/useCallbackRef';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { useTranslation } from 'react-i18next';

import './OptionMenu.styles.scss';
import './OptionMenu.responsive.styles.scss';
import useMatchMedia from 'hooks/useMatchMedia';

export type OptionItem = {
  id?: string;
  label: ReactNode;
  preventClose?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
};
type DropdownProps = ExtractPropTypes<typeof Dropdown>;

type OptionMenuProps = DropdownProps & {
  options: OptionItem[];
  title?: string;
};

const OptionMenu: React.FC<OptionMenuProps> = (props) => {
  const {
    className,
    options,
    trigger = <span>Menu</span>,
    contentClassName,
    title,
    ...rest
  } = props;
  const [dropdown, dropdownRef] = useCallbackRef<DropdownRef>();
  const { t } = useTranslation();

  const isPhablet = useMatchMedia('isPhablet');
  const classes = classNames('yx-option-menu', className);

  const optionsEl = useMemo(
    () => (
      <ul className="option-list">
        {options.map(({ id, label, preventClose = false, onClick }, ind) => (
          <li
            onClick={(ev) => {
              if (!preventClose) dropdown.setIsOpen(false);
              onClick?.(ev);
            }}
            className="option"
            key={id || ind}
          >
            {typeof label === 'string' ? (
              <span className="option__default">{t(label)}</span>
            ) : (
              label
            )}
          </li>
        ))}
      </ul>
    ),
    [t, options, dropdown],
  );
  return (
    <Dropdown
      trigger={trigger}
      ref={dropdownRef}
      className={classes}
      renderAsPortal={isPhablet}
      portalClassName="yx-option-menu-portal"
      contentClassName={classNames('yx-option-menu__content', contentClassName)}
      closeWithJS={isPhablet}
      {...rest}
    >
      {title && <p className="yx-option-menu__title">{title}</p>}
      {optionsEl}
    </Dropdown>
  );
};

export default OptionMenu;
