import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import AllBoatsIcon from 'icons/AllBoats.icon';
import NewBoatIcon from 'icons/NewBoat.icon';
import UsedBoatIcon from 'icons/UsedBoat.icon';
import { YACHT_SEARCH_STATUS } from 'models/Search';

export const yachtStatusFields = [
  {
    value: YACHT_SEARCH_STATUS.NEW,
    label: 'new',
    description: 'newYachtsUsuallyHaveNoEffects',
    icon: NewBoatIcon,
  },
  {
    value: YACHT_SEARCH_STATUS.USED,
    label: 'used',
    description: 'usedYachtsAReGenerallyMoreAffordable',
    icon: UsedBoatIcon,
  },
  {
    value: YACHT_SEARCH_STATUS.I_DONT_CARE,
    label: 'iDontCare',
    icon: AllBoatsIcon,
  },
] as Array<RadioFieldsType>;
