import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import MatchCard from 'components/MatchCard';
import './Matches.styles.scss';
import './Matches.responsive.styles.scss';
import api from 'api';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import { Loader } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import CurrentUserContext from 'router/subrouters/Dashboard/providers/CurrentUser/CurrentUser.context';
import {
  MatchesRoutes,
  MATCH_TYPE,
} from 'router/subrouters/Dashboard/subrouters/Match/models';
import { Match, MatchSearch } from '../../models/Match';
import { RoleType } from 'components/MatchCard/utils';
import PageContext from 'router/subrouters/Dashboard/providers/Page/Page.context';
import { countryListAllIsoData } from 'services/countries';
import MessageScreen from 'router/subrouters/Dashboard/subrouters/Match/components/MessageScreen';
import EmptyMatchesIcon from 'icons/EmptyMatches.icon';
import GlowScroll from 'components/GlowScroll';
import InfiniteScroll from 'components/InfiniteScroll';
import useInfinitePagination from 'hooks/useInfinitePagination';

type MatchesProps = {
  className?: string;
};

const MATCHES_TYPE = {
  YACHT: 'yacht',
  SEARCH: 'search',
};

const Matches: React.FC<MatchesProps> = (props) => {
  const { className } = props;
  const { popServerError } = useContext(PopNotificationsContext);
  const {
    currentUser: { _id: userId },
  } = useContext(CurrentUserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [serverError, setSerrverError] = useState(false);
  const { setTitle } = useContext(PageContext);

  const fetchMatch = useCallback(
    async (currentPage: number) => {
      const routeName = window.location.pathname.split('/').slice(-1)[0];

      return api.matches[
        routeName === MATCHES_TYPE.YACHT
          ? 'getYachtMatches'
          : 'getSearchMatches'
      ](id, {
        $page: currentPage.toString(),
        $limit: '10',
        $relations: ['seller', 'buyer', 'searches.search'],
      })
        .then(({ data }) => data)
        .catch((e) => {
          popServerError(e);
          setSerrverError(true);
        });
    },
    [id, popServerError],
  );

  const {
    items: matches,
    loading: loadingMatches,
    onContainerScrolled: onMatchesScroll,
  } = useInfinitePagination<Match>({
    makeRequest: fetchMatch,
    dependencies: [fetchMatch],
    resetDeps: [fetchMatch],
  });

  useEffect(
    () =>
      matches.length > 0 &&
      setTitle(`${t('matches')} · ${matches[0].yachtName}`),
    [matches, setTitle, t],
  );

  const searches: MatchSearch[] = useMemo(
    () =>
      matches.map((match) => {
        let max = 0;
        let maxSearch;
        match.searches.forEach((search: MatchSearch) => {
          if (max < search.matchingScore) {
            max = search.matchingScore;
            maxSearch = search;
          }
        });
        return maxSearch;
      }),
    [matches],
  );

  const roles: [MatchesRoutes, RoleType][] = useMemo(
    () =>
      matches.map((match) =>
        match.buyerId === userId
          ? [MATCH_TYPE.SELLER, match.seller.userType]
          : [MATCH_TYPE.BUYER, match.buyer.userType],
      ),
    [matches, userId],
  );

  const locations: string[] = useMemo(
    () =>
      matches.map((match) =>
        match.buyerId === userId
          ? `${match.seller.address.city}, ${
              countryListAllIsoData.find(
                (country) =>
                  (country.code as string) === match.seller.address.country,
              ).name
            }`
          : `${match.buyer.address.city}, ${
              countryListAllIsoData.find(
                (country) =>
                  (country.code as string) === match.buyer.address.country,
              ).name
            }`,
      ),
    [matches, userId],
  );

  const matchesError = useMemo(
    () => (
      <div className="yx-matches__error">
        <h2>{t('serverErrorMatchesTitle')}</h2>
        <p>{t('serverErrorMatchesDescription')}</p>
      </div>
    ),
    [t],
  );

  const renderMatches = useMemo(() => {
    const routeName = window.location.pathname.split('/').slice(-1)[0];

    return matches.length > 0 ? (
      matches.map(({ status, _id }, index) => (
        <li key={_id}>
          <MatchCard
            score={searches?.[index].matchingScore}
            status={status}
            onButtonClick={() => history.push(`/matches/match/${_id}`)}
            role={roles[index][0]}
            userType={roles[index][1]}
            location={locations[index]}
          />
        </li>
      ))
    ) : (
      <MessageScreen
        icon={EmptyMatchesIcon}
        title={t('emptyMatches')}
        description={t('youHaveNoMatches', { role: routeName })}
      />
    );
  }, [history, locations, matches, roles, searches, t]);

  const classes = classNames('yx-matches', className);

  const renderContent = useMemo(
    () => (serverError ? matchesError : <ul>{renderMatches}</ul>),
    [matchesError, renderMatches, serverError],
  );

  return (
    <GlowScroll>
      {loadingMatches && <Loader />}
      <InfiniteScroll onScroll={onMatchesScroll} className={classes}>
        {renderContent}
      </InfiniteScroll>
    </GlowScroll>
  );
};

export default Matches;
