import React, { useContext } from 'react';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import MatchContext from '../../providers/Match/Match.context';

const ArrangeMeetingButton: React.FC = () => {
  const { t } = useTranslation();
  const { onArrangeMeeting } = useContext(MatchContext);

  return (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        onArrangeMeeting();
      }}
    >
      {t('arrangeAViewing')}
    </Button>
  );
};

export default ArrangeMeetingButton;
