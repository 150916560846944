import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import './PostponementRequested.styles.scss';
import MessageScreen from '../../components/MessageScreen';
import { useTranslation } from 'react-i18next';
import ClockIcon from 'icons/Clock.icon';
import MatchContext from '../../providers/Match/Match.context';
import { Button } from 'ncoded-component-library';
import { MATCH_TYPE } from '../../models';
import confirm from 'modules/confirm';

type PostponementRequestedProps = {
  className?: string;
};

const PostponementRequested: React.FC<PostponementRequestedProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const {
    routeName,
    match: { status },
    cancelPostponement,
    acceptPostponement,
  } = useContext(MatchContext);

  const whoPostponed = useMemo(
    () => (status === 'Seller request for postponoment' ? 'seller' : 'buyer'),
    [status],
  );

  const classes = classNames('yx-postponement-requested', className);

  const handleCancel = useCallback(async () => {
    if (
      await confirm({
        title: t('cancelPostponeTitle'),
        content: t('cancelPostponeContent'),
      })
    ) {
      cancelPostponement();
    }
  }, [cancelPostponement, t]);

  const handleAccept = useCallback(async () => {
    if (
      await confirm({
        title: t('acceptPostponeTitle'),
        content: t('acceptPostponeContent'),
      })
    ) {
      acceptPostponement();
    }
  }, [acceptPostponement, t]);

  return (
    <div className={classes}>
      {routeName === whoPostponed ? (
        <MessageScreen
          icon={ClockIcon}
          title={t('postponedTitle', { who: t('you') })}
          description={t('postponedDescription-you', {
            who: t(
              routeName === MATCH_TYPE.BUYER
                ? MATCH_TYPE.SELLER
                : MATCH_TYPE.BUYER,
            ),
          })}
        />
      ) : (
        <>
          <MessageScreen
            icon={ClockIcon}
            title={t('postponedTitle', { who: t(whoPostponed) })}
            description={t('postponedDescription-whoPostponed', {
              who: t(whoPostponed),
            })}
          />
          <div className="yx-postponement-requested__actions">
            <Button styleType="secondary" onClick={() => handleCancel()}>
              {t('cancel')}
            </Button>
            <Button onClick={() => handleAccept()}>{t('accept')}</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PostponementRequested;
