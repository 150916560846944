import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="transparent"
    stroke="currentColor"
    strokeWidth="1.6"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M15.832 11.6045V15.832C15.832 16.2519 15.6652 16.6546 15.3683 16.9515C15.0714 17.2485 14.6686 17.4153 14.2487 17.4153H3.16536C2.74544 17.4153 2.34271 17.2485 2.04578 16.9515C1.74885 16.6546 1.58203 16.2519 1.58203 15.832V4.74862C1.58203 4.32869 1.74885 3.92596 2.04578 3.62903C2.34271 3.3321 2.74544 3.16528 3.16536 3.16528H7.39286" />
    <path d="M14.2487 1.58203L17.4154 4.7487L9.4987 12.6654H6.33203V9.4987L14.2487 1.58203Z" />
  </svg>
);
