import React from 'react';
import classNames from 'classnames';
import './AgreementNavigation.styles.scss';
import { transformTitle } from '../../utils';

type AgreementNavigationProps = {
  className?: string;
  titles: string[];
  activeLink?: string[];
};

const AgreementNavigation: React.FC<AgreementNavigationProps> = (props) => {
  const { className, titles, activeLink } = props;

  const classes = classNames('yx-agreement-navigation', className);

  return (
    <nav className={classes}>
      <ul>
        {titles.map((title) => (
          <li
            key={title}
            className={classNames({
              'active-item': activeLink.includes(transformTitle(title)),
            })}
          >
            <a
              id={`a${transformTitle(title)}`}
              className={classNames('yx-agreement-navigation__link')}
              href={`#${transformTitle(title)}`}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AgreementNavigation;
