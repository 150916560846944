import React from 'react';
import StepWrapper from 'components/StepWrapper';
import InputField from 'components/Fields/InputField';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormSection from 'components/FormSection';
import FormRow from 'components/FormRow';
import SelectField from 'components/Fields/SelectField';
import { countries, CountryNames } from 'services/countries';
import { useMemo } from 'react';
import validators from 'validators';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './ContactDetailsStep.styles.scss';

export type BrokerContactDetailsValues = {
  firstName: string;
  lastName: string;
  company: string;
  legalFormFrom: string;
  legalFormTo: string;
  linkedInProfile: string;
  workTitle: string;
  streetAddress: string;
  country: OptionValue<CountryNames>;
  building: string;
  zipCode: String;
  city: string;
};

const ContactDetails: React.FC = () => {
  const { t } = useTranslation();

  const { required } = validators;

  const validateObject = useMemo(
    () => ({
      required: required(t('requiredField')),
    }),
    [required, t],
  );

  return (
    <StepWrapper title={t('contactDetails')}>
      <FormSection title={t('personalDetails')}>
        <Field
          name="firstName"
          placeholder={t('firstName')}
          component={InputField}
          required
          validate={validateObject.required}
        />
        <Field
          name="lastName"
          placeholder={t('lastName')}
          component={InputField}
          required
          validate={validateObject.required}
        />
      </FormSection>
      <FormSection title={t('brokerageDetails')}>
        <Field
          name="company"
          placeholder={t('companyName')}
          component={InputField}
          required
          validate={validateObject.required}
        />
        <Field
          name="legalForm"
          placeholder={t('legalForm')}
          validate={validateObject.required}
          component={InputField}
        />
        <Field
          name="linkedInProfile"
          placeholder={t('websiteOrLinkedin')}
          component={InputField}
          optional
        />
        <Field
          name="workTitle"
          placeholder={t('yourPosition')}
          component={InputField}
          required
          validate={validateObject.required}
        />
      </FormSection>
      <FormSection title={t('brokerageCompanyAddress')}>
        <Field
          name="address.streetAddress"
          placeholder={t('streetAndNumber')}
          component={InputField}
          required
          validate={validateObject.required}
        />
        <Field
          name="address.building"
          placeholder={t('buildingApartmentComplex')}
          component={InputField}
          optional
        />
        <FormRow>
          <Field
            name="address.zipCode"
            placeholder={t('zipCode')}
            component={InputField}
            required
            validate={validateObject.required}
          />
          <Field
            name="address.city"
            placeholder={t('city')}
            component={InputField}
            required
            validate={validateObject.required}
          />
        </FormRow>
        <Field
          name="address.country"
          placeholder={t('country')}
          component={SelectField}
          options={countries}
          required
          searchable
          validate={validateObject.required}
        />
      </FormSection>
    </StepWrapper>
  );
};

export default ContactDetails;
