import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import { YACHT_STATUS } from 'models/Yacht';

export const yachtTypeFiels = [
  {
    value: 'Motor',
    label: 'motor',
    imagePath: '/assets/images/motor.png',
  },
  {
    value: 'Sailing',
    label: 'sailing',
    imagePath: '/assets/images/sailing.png',
  },
  {
    value: 'Expedition',
    label: 'expedition',
    imagePath: '/assets/images/expedition.png',
  },
  {
    value: 'Gulet',
    label: 'gulet',
    imagePath: '/assets/images/gulet.png',
  },
  {
    value: 'Open',
    label: 'open',
    imagePath: '/assets/images/open.png',
  },
  {
    value: 'Catamaran',
    label: 'catamaran',
    imagePath: '/assets/images/catamaran.png',
  },
  {
    value: 'Sports Fishing',
    label: 'sportsFishing',
    imagePath: '/assets/images/sports-fishing.png',
  },
] as Array<RadioFieldsType>;

export const yachtStatusFields = [
  {
    value: YACHT_STATUS.NEW,
    label: 'new',
  },
  {
    value: YACHT_STATUS.IN_USE,
    label: 'inUse',
  },
  {
    value: YACHT_STATUS.CONCEPT,
    label: 'concept',
  },
] as Array<RadioFieldsType>;
