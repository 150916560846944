import React from 'react';
import classNames from 'classnames';
import './ProgressBar.styles.scss';
import useCSSVariables from 'hooks/useCSSVariables';

type ProgressBarProps = {
  className?: string;
  percentage: number;
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { className, percentage } = props;

  const progressStyles = useCSSVariables({
    'progress-percentage': `${percentage - 100}%`,
  });

  const classes = classNames('yx-progress-bar', className);

  return (
    <>
      {progressStyles}
      <div
        className={classes}
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={percentage}
        aria-valuetext={`${percentage}%`}
      />
    </>
  );
};

export default ProgressBar;
