import { MATCH_STATUS } from '../../../router/subrouters/Dashboard/subrouters/Match/utils';

export type RoleType = 'Private' | 'Broker';

export const ROLE_TYPE = {
  PRIVATE_PERSON: 'Private',
  BROKER: 'Broker',
};

export type StatusStyleKeys = typeof MATCH_STATUS[keyof typeof MATCH_STATUS];

export const STATUS_STYLES: Record<StatusStyleKeys, string> = {
  [MATCH_STATUS.NEW]: 'new',
  [MATCH_STATUS.REQUEST_FOR_VIEWING]: 'viewing',
  [MATCH_STATUS.ACCPET_REQUEST_FOR_VIEWING]: 'viewing',
  [MATCH_STATUS.VIEWING]: 'viewing',
  [MATCH_STATUS.BUYER_POSTPONEMENT_REQUESTED]: 'postponement-requested',
  [MATCH_STATUS.SELLER_POSTPONEMENT_REQUESTED]: 'postponement-requested',
  [MATCH_STATUS.BUYER_CANCELLED]: 'cancelled',
  [MATCH_STATUS.SELLER_CANCELLED]: 'cancelled',
} as const;

export const STATUS_LABELS: Record<StatusStyleKeys, string> = {
  [MATCH_STATUS.NEW]: 'New',
  [MATCH_STATUS.REQUEST_FOR_VIEWING]: 'Viewing',
  [MATCH_STATUS.ACCPET_REQUEST_FOR_VIEWING]: 'Viewing',
  [MATCH_STATUS.VIEWING]: 'Viewing',
  [MATCH_STATUS.BUYER_POSTPONEMENT_REQUESTED]: 'Postponement',
  [MATCH_STATUS.SELLER_POSTPONEMENT_REQUESTED]: 'Postponement',
  [MATCH_STATUS.BUYER_CANCELLED]: 'Cancelled',
  [MATCH_STATUS.SELLER_CANCELLED]: 'Cancelled',
} as const;
