import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import InputField from '../../../../../components/Fields/InputField';
import { useTranslation } from 'react-i18next';
import SelectField from '../../../../../components/Fields/SelectField';
import ChangeProfilePicture from './components/ChangeProfilePicture';
import { Button, Loader } from 'ncoded-component-library';
import CheckboxGroup from '../../../../../components/CheckboxGroup';
import { emailNotificationsOptions } from './utils';
import CreditCardCard from './components/CreditCardCard';
import CurrentUserContext from '../../providers/CurrentUser/CurrentUser.context';
import validators from 'validators';
import { countries } from 'services/countries';
import { User } from 'models/User';
import useValidation from 'hooks/useValidation';

import './ProfileSettings.styles.scss';
import './ProfileSettings.responsive.styles.scss';

type ProfileSettingsProps = {
  className?: string;
};

const ProfileSettings: React.FC<ProfileSettingsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const [emailNotificationsLoader, setEmailNotificationsLoader] =
    useState(false);

  const {
    currentUser: {
      firstName,
      lastName,
      email,
      address: { streetAddress, zipCode, city, country },
      emailNotificationSettings,
      paymentInfo: { card } = { card: null },
    },
    patchUser,
    changePassword,
  } = useContext(CurrentUserContext);

  const validatorsObject = useMemo(
    () => ({
      required: validators.required(t('required')),
    }),
    [t],
  );

  const { validations: passwordValidation } = useValidation({
    passwordFieldName: 'newPassword',
  });

  const classes = classNames('yx-profile-settings', className);
  const profileDetailsClasses = classNames(
    'yx-profile-settings__card',
    'yx-profile-settings__row',
    'yx-profile-settings__profile-details',
  );
  const emailNotificationsClasses = classNames(
    'yx-profile-settings__card',
    'yx-profile-settings__column',
    'yx-profile-settings__email-settings',
  );
  const changePasswordClasses = classNames(
    'yx-profile-settings__card',
    'yx-profile-settings__column',
    'yx-profile-settings__change-password',
  );
  const creditCardSettingsClasses = classNames(
    'yx-profile-settings__card',
    'yx-profile-settings__credit-card-settings',
  );

  const emailNotificationsChange = useCallback(
    async (value: string[]) => {
      setEmailNotificationsLoader(true);
      await patchUser({ emailNotificationSettings: value });
      setEmailNotificationsLoader(false);
    },
    [patchUser],
  );

  const profileDetaildsChange = useCallback(
    async (value: User) => {
      setEmailNotificationsLoader(true);
      const { firstName, lastName, email, ...rest } = value;
      await patchUser(rest);
      setEmailNotificationsLoader(false);
    },
    [patchUser],
  );

  return (
    <div className={classes}>
      <section className={profileDetailsClasses}>
        <ChangeProfilePicture className="change-profile-picture" />
        <Form
          initialValues={{
            firstName,
            lastName,
            email,
            address: {
              streetAddress,
              zipCode,
              city,
              country,
            },
          }}
          onSubmit={profileDetaildsChange}
          render={({ dirty, submitting, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="yx-profile-settings__row">
              {submitting && <Loader />}
              <div className="yx-profile-settings__column">
                <Field
                  className="yx-profile-settings__readonly-input"
                  name="firstName"
                  label={t('firstName')}
                  readOnly
                  component={InputField}
                />
                <Field
                  className="yx-profile-settings__readonly-input"
                  name="lastName"
                  label={t('lastName')}
                  readOnly
                  component={InputField}
                />
                <Field
                  name="email"
                  label={t('email')}
                  component={InputField}
                  className="yx-profile-settings__readonly-input"
                  readOnly
                />
              </div>
              <div className="yx-profile-settings__column">
                <Field
                  name="address.streetAddress"
                  label={t('streetAndNumber')}
                  component={InputField}
                />
                <div className="yx-profile-settings__row">
                  <Field
                    name="address.zipCode"
                    label={t('zipCode')}
                    component={InputField}
                  />
                  <Field
                    name="address.city"
                    label={t('city')}
                    component={InputField}
                  />
                </div>
                <Field
                  name="address.country"
                  label={t('country')}
                  component={SelectField}
                  options={countries}
                />
                <Button type="submit" className={!dirty && 'hide'}>
                  {t('saveChanges')}
                </Button>
              </div>
            </form>
          )}
        />
      </section>
      <section className={emailNotificationsClasses}>
        <CheckboxGroup
          label={t('emailNotificationSettings')}
          options={emailNotificationsOptions}
          onChange={emailNotificationsChange}
          defaultValue={emailNotificationSettings}
        />
        {emailNotificationsLoader && <Loader />}
      </section>
      <section className={changePasswordClasses}>
        <Form
          onSubmit={changePassword}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={handleSubmit}
              className="yx-profile-settings__column"
            >
              {submitting && <Loader />}
              <label>{t('changePassword')}</label>
              <Field
                name="oldPassword"
                placeholder={t('currentPassword')}
                component={InputField}
                type="password"
                required
                validate={validatorsObject.required}
              />
              <Field
                name="newPassword"
                placeholder={t('newPassword')}
                component={InputField}
                type="password"
                required
                validate={validators.composeValidators(
                  validatorsObject.required,
                  passwordValidation.password,
                )}
              />
              <Field
                name="confirmNewPassword"
                placeholder={t('confirmNewPassword')}
                component={InputField}
                type="password"
                required
                validate={validators.composeValidators(
                  validatorsObject.required,
                  passwordValidation.confirmPassword,
                )}
              />
              <Button type="submit">{t('saveChanges')}</Button>
            </form>
          )}
        />
      </section>
      {card && (
        <section className={creditCardSettingsClasses}>
          <label>{t('creditCardSettings')}</label>
          <CreditCardCard
            className="yx-profile-settings__credit-card-settings__card"
            {...card}
          />
        </section>
      )}
    </div>
  );
};

export default ProfileSettings;
