import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'ncoded-component-library';
import MoreInfoIcon from '../../icons/MoreInfo.icon';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import useCallbackRef from 'hooks/useCallbackRef';
import useMatchMedia from 'hooks/useMatchMedia';

import './Tooltip.styles.scss';
import './Tooltip.responsive.styles.scss';

export type TooltipProps = {
  title?: string;
  className?: string;
  renderAsPortal?: boolean;
};

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { children, title, className, renderAsPortal } = props;

  const isPhablet = useMatchMedia('isPhablet');
  const [dropdown, dropdownRef] = useCallbackRef<DropdownRef>();
  const classes = classNames(
    'yx-tooltip',
    {
      'yx-tooltip--open': dropdown?.isOpen,
      'yx-tooltip--upwards': dropdown?.isUpwards,
    },
    className,
  );

  const trigger = useMemo(
    () => (
      <button type="button" className="trigger">
        <MoreInfoIcon />
      </button>
    ),
    [],
  );

  return (
    <Dropdown
      closeWithJS={!isPhablet}
      renderAsPortal={isPhablet || renderAsPortal}
      ref={dropdownRef}
      className={classes}
      trigger={trigger}
      contentClassName="yx-tooltip__content"
    >
      {title && (
        <p role="heading" aria-level={2} className="yx-tooltip__content__title">
          {title}
        </p>
      )}
      {children}
    </Dropdown>
  );
};

export default Tooltip;
