import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99867 7.99943H7.99908C8.5516 7.99943 8.9995 7.55152 8.9995 6.99901C8.9995 6.4465 8.5516 5.99859 7.99908 5.99859H6.99867C6.44615 5.99859 5.99825 6.4465 5.99825 6.99901C5.99825 7.55152 6.44615 7.99943 6.99867 7.99943ZM6.99867 10.0003C6.44615 10.0003 5.99825 10.4482 5.99825 11.0007C5.99825 11.5532 6.44615 12.0011 6.99867 12.0011H13.0012C13.5537 12.0011 14.0016 11.5532 14.0016 11.0007C14.0016 10.4482 13.5537 10.0003 13.0012 10.0003H6.99867ZM18.0032 6.93898C17.9928 6.84708 17.9727 6.75654 17.9432 6.66887V6.57884C17.8951 6.47597 17.831 6.38142 17.7531 6.29872L11.7506 0.296219C11.6679 0.218403 11.5734 0.154241 11.4705 0.10614C11.4407 0.101898 11.4104 0.101898 11.3805 0.10614C11.2789 0.0478567 11.1666 0.0104439 11.0504 -0.00390625H4.99783C3.34029 -0.00390625 1.99658 1.3398 1.99658 2.99734V17.0032C1.99658 18.6607 3.34029 20.0044 4.99783 20.0044H15.002C16.6595 20.0044 18.0032 18.6607 18.0032 17.0032V6.99901C18.0032 6.99901 18.0032 6.99901 18.0032 6.93898ZM12.0007 3.40751L14.5918 5.99859H13.0012C12.4486 5.99859 12.0007 5.55068 12.0007 4.99817V3.40751ZM16.0024 17.0032C16.0024 17.5557 15.5545 18.0036 15.002 18.0036H4.99783C4.44532 18.0036 3.99742 17.5557 3.99742 17.0032V2.99734C3.99742 2.44483 4.44532 1.99693 4.99783 1.99693H9.99992V4.99818C9.99992 6.65572 11.3436 7.99943 13.0012 7.99943H16.0024V17.0032ZM13.0012 14.0019H6.99867C6.44615 14.0019 5.99825 14.4498 5.99825 15.0023C5.99825 15.5549 6.44615 16.0028 6.99867 16.0028H13.0012C13.5537 16.0028 14.0016 15.5549 14.0016 15.0023C14.0016 14.4498 13.5537 14.0019 13.0012 14.0019Z"
      fill="currentColor"
    />
  </svg>
);
