import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import './ViewingArrangement.styles.scss';
import CalendarComponent from 'components/Calendar';
import ChoseLocation from 'components/ChoseLocation';
import MatchContext from '../../../providers/Match/Match.context';
import { MATCH_STATUS } from '../../../utils';
import LockedOverlay from 'components/LockedOverlay';
import { useTranslation } from 'react-i18next';

type ViewingArrangementPageProps = {
  className?: string;
};

const ViewingArrangementPage: React.FC<ViewingArrangementPageProps> = (
  props,
) => {
  const { className } = props;
  const {
    match: { timeSlotOptions = [], status },
    onViewingRequestAccept,
  } = useContext(MatchContext);
  const { t } = useTranslation();
  const [dates, setDates] = useState<Date[]>(
    timeSlotOptions?.map((timeslot) => timeslot.from),
  );
  const [place, setPlace] = useState<string>('');
  const [coordinates, setCoordinates] =
    useState<{ lat: number; lng: number }>(null);

  const isLocked = useMemo(() => status === MATCH_STATUS.NEW, [status]);

  const classes = classNames('yx-viewing-arrangement', className);

  const changedPlace = useCallback(
    (place: string, latlng: { lat: number; lng: number }) => {
      setPlace(place);
      setCoordinates(latlng);
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    onViewingRequestAccept({
      timeSlotOptions: dates,
      location: place,
      locationCoordinates: {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      },
    });
  }, [coordinates, dates, onViewingRequestAccept, place]);

  return (
    <div className={classes}>
      {isLocked && <LockedOverlay text={t('lockedViewingArangement')} />}
      <CalendarComponent onChange={setDates} value={dates} onlyFuture />
      <ChoseLocation
        onChange={changedPlace}
        buttonDisabled={!(dates.length !== 0 && place && coordinates)}
        onSubmit={() => handleSubmit()}
      />
    </div>
  );
};

export default ViewingArrangementPage;
