import React, { useMemo } from 'react';
import classNames from 'classnames';
import TemporaryHeader from 'pages/TemporaryUser/components/TemporaryHeader';
import WaitScreen from 'router/subrouters/Dashboard/subrouters/Match/components/WaitScreen';
import { useTranslation } from 'react-i18next';
import ExclamationMarkIcon from 'icons/ExclamationMark.icon';

import './FreeTrialExpired.styles.scss';
import './FreeTrialExpired.responsive.styles.scss';

type FreeTrialExpiredProps = {
  className?: string;
};

const FreeTrialExpired: React.FC<FreeTrialExpiredProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-free-trial-expired', className);
  const { t } = useTranslation();

  const actionButtons = useMemo(
    () => (
      <div className="yx-free-trial-expired__actions">
        <a
          className="yx-free-trial-expired__actions__link"
          href="mailto:Support@YachtX.com"
        >
          {t('contactUs')}
        </a>
        <p className="yx-free-trial-expired__actions__title">
          {t('pleasePlacePayment')}
        </p>
      </div>
    ),
    [t],
  );

  return (
    <div className={classes}>
      <TemporaryHeader />

      <div className="yx-free-trial-expired__topbar">
        <ExclamationMarkIcon />
        <p className="yx-free-trial-expired__title">
          {t('freeTrialHasExpired')}
        </p>
      </div>

      <div className="yx-free-trial-expired__container">
        <div className="yx-free-trial-expired__content">
          <WaitScreen />
          <h1>{t('yourTrialHasExpired')}</h1>
          <h2>{t('trialVersionOfAppHasExpired')}</h2>
          {actionButtons}
        </div>
      </div>
    </div>
  );
};

export default FreeTrialExpired;
