import React, { useState, useMemo, useContext } from 'react';
import classNames from 'classnames';
import {
  generateTimeslots,
  GENERATE_TIMESLOTS_START,
  stringifyTimeslot,
  DAY_PART,
  PartOfDay,
  DAY_PART_TRANSLATION_KEY,
  isTimeslotSelected,
} from './utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { LeftArrow, RightArrow } from '../Datepicker/svgs';
import { useCallback } from 'react';
import './SelectTimeSlot.styles.scss';
import './SelectTimeSlot.styles.responsive.scss';
import CalendarContext from 'components/Calendar/provider/Calendar.context';
import { Hour } from 'components/Calendar/models';

type SelectTimeSlotProps = {
  className?: string;
};

const SelectTimeSlot: React.FC<SelectTimeSlotProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const [partOfDay, setPartOfDay] = useState<PartOfDay>(DAY_PART.PM);
  const { onChange, currentDate, dates, removeDate, isFull } =
    useContext(CalendarContext);

  const togglePartOfDay = useCallback(() => {
    setPartOfDay((oldVal) =>
      oldVal === DAY_PART.AM ? DAY_PART.PM : DAY_PART.AM,
    );
  }, []);

  const timeslots = useMemo(
    () => generateTimeslots(GENERATE_TIMESLOTS_START[partOfDay]),
    [partOfDay],
  );

  const classes = classNames('yx-select-time-slot', className);

  const renderTimeslots = useMemo(
    () =>
      timeslots.map((timeslot) => {
        const [selected, selectedIndex] = isTimeslotSelected(
          currentDate,
          timeslot as Hour,
          dates,
        );

        return (
          <Button
            key={'yx-select-time-slot' + timeslot}
            onClick={() =>
              selected ? removeDate(selectedIndex) : onChange(timeslot as Hour)
            }
            className={classNames({
              active: selected,
              disabled: !selected && isFull,
            })}
          >
            {stringifyTimeslot(timeslot)}
          </Button>
        );
      }),
    [currentDate, dates, isFull, onChange, removeDate, timeslots],
  );

  return (
    <div className={classes}>
      <header onClick={togglePartOfDay}>
        <LeftArrow />
        <span>{t(DAY_PART_TRANSLATION_KEY[partOfDay])}</span>
        <RightArrow />
      </header>
      <div className="yx-select-time-slot__timeslots-container">
        {renderTimeslots}
      </div>
    </div>
  );
};

export default SelectTimeSlot;
