import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useHistory } from 'react-router-dom';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

import './SuccessYachtCreationCard.styles.scss';

type SuccessYachtCreationCardProps = {
  className?: string;
};

const SuccessYachtCreationCard: React.FC<SuccessYachtCreationCardProps> = (
  props,
) => {
  const { className } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const classes = classNames('yx-success-yacht-creation-card', className);

  return (
    <StepWrapper className={classes} renderActions={false}>
      <img src="/assets/images/yacht-success.png" alt="yacht-success" />
      <h1>{t('newYacht-success')}</h1>
      <p>{t('newYacht-publishYourYacht')}</p>

      <div className="yx-publish-step__billing">
        <p>
          {t('billing')}:<span>{t('monthly')}</span>
        </p>
        <p>
          {t('price')}:<span>EUR: 45</span>
        </p>
      </div>
      <div className="yx-success-yacht-creation-card__actions">
        <Button
          styleType="secondary"
          onClick={() => history.push('/my-yachts')}
        >
          {t('backToMyYachts')}
        </Button>
        <Button styleType="primary" type="submit">
          {t('publish')}
        </Button>
      </div>
    </StepWrapper>
  );
};

export default SuccessYachtCreationCard;
