import React, { useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Dropdown, Loader } from 'ncoded-component-library';
import NotificationBadge from '../NotificationBadge';
import { NavLink } from 'react-router-dom';
import NotificationContext from 'router/subrouters/Dashboard/providers/Notification/Notification.context';
import NotificationCard from 'components/NotificationCard';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { useTranslation } from 'react-i18next';
import PageContext from 'router/subrouters/Dashboard/providers/Page/Page.context';
import InfiniteScrollLocal from 'components/InfiniteScroll/InfiniteScrollLocal.component';
import EmptyState from 'components/EmptyState';
import NotificationIcon from 'icons/Notification.icon';

import './NotificationDropdown.styles.scss';
import './NotificationDropdown.responsive.styles.scss';

export type NotificationOption = {
  label: string;
  to: string;
  isNotRead?: boolean;
};

type NotificationDropdownProps = {
  className?: string;
  options: NotificationOption[];
  setOptionIsRead?: (label: string) => void;
};

const NotificationDropdown: React.FC<NotificationDropdownProps> = (props) => {
  const { className } = props;

  const dropdownRef = useRef<DropdownRef>();
  const { readNotification } = useContext(NotificationContext);
  const { setTitle } = useContext(PageContext);

  const { t } = useTranslation();

  const {
    loadingNotifications,
    notifications,
    numOfUnreadNotifications,
    onNotificationScroll,
  } = useContext(NotificationContext);

  const classes = classNames(
    'yx-notification-dropdown',
    { 'yx-notification-dropdown--empty': notifications.length === 0 },
    className,
  );

  const trigger = useMemo(
    () => (
      <NotificationBadge numberOfNotifications={numOfUnreadNotifications} />
    ),
    [numOfUnreadNotifications],
  );

  const notificationList = useMemo(
    () =>
      notifications.map((notif) => (
        <NotificationCard
          onClick={() => {
            readNotification(notif._id);
            dropdownRef.current.setIsOpen(false);
          }}
          to={`/matches/match/${notif.content.matchId}`}
          key={notif._id}
          {...notif}
          asDropdown
        />
      )),
    [notifications, readNotification],
  );

  const emptyState = useMemo(
    () =>
      notifications.length === 0 && (
        <EmptyState
          icon={NotificationIcon}
          label={t('youHaveNoNotificationsYet')}
        />
      ),
    [notifications, t],
  );

  return (
    <Dropdown
      closeWithJS
      ref={dropdownRef}
      className={classes}
      trigger={trigger}
    >
      {loadingNotifications && <Loader />}

      <InfiniteScrollLocal onScroll={onNotificationScroll}>
        <div className="yx-notification-dropdown__header">
          <h1>{t('notifications')}</h1>
          {notifications.length !== 0 && (
            <NavLink
              to={'/notifications'}
              onClick={() => {
                setTitle(t('notifications'));
                dropdownRef.current.setIsOpen(false);
              }}
            >
              {t('seeAll')}
            </NavLink>
          )}
        </div>

        {notifications.length === 0 ? emptyState : notificationList}
      </InfiniteScrollLocal>
    </Dropdown>
  );
};

export default NotificationDropdown;
