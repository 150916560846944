import React, { useContext } from 'react';
import '../../../styles/Header.scss';
import classNames from 'classnames';
import DatepickerContext from '../../../provider/Datepicker.context';
import { LeftArrow, RightArrow } from '../../../svgs';
import { getDecrementedYear, getIncrementedYear } from '../../../../utils';
import { Pickers } from '../../../utils';

type HeaderProps = {
  className?: string;
};

const { DAY_PICKER } = Pickers;

const Header: React.FC<HeaderProps> = ({ className }) => {
  const headerClassNames = classNames('header', className);

  const { setCurrentDate, currentDate, setShowInMenu } =
    useContext(DatepickerContext);

  return (
    <div className={headerClassNames}>
      <LeftArrow
        onClick={() => setCurrentDate?.((oldVal) => getDecrementedYear(oldVal))}
      />
      <div
        className="header__heading"
        onClick={() => setShowInMenu?.(DAY_PICKER)}
      >
        <p>{currentDate?.year}</p>
      </div>
      <RightArrow
        onClick={() => setCurrentDate?.((oldVal) => getIncrementedYear(oldVal))}
      />
    </div>
  );
};
export default Header;
