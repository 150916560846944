import { CheckboxOption } from '../../../../../../components/CheckboxGroup/CheckboxGroup.component';

export const emailNotificationsOptions: CheckboxOption[] = [
  {
    label: 'Match',
    value: 'match',
  },
  {
    label: 'Match decay',
    value: 'matchDecay',
  },
  {
    label: 'Appointment',
    value: 'appointment',
  },
  {
    label: 'Chat message',
    value: 'chatMessage',
  },
  {
    label: 'Contract deadline reminders',
    value: 'contractDeadlineReminders',
  },
];
