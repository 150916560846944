import { Pickers } from './utils'
import { DayPicker, MonthPicker, YearPicker } from './components'
import { DayPickerHeader } from './components/DayPicker'
import { MonthPickerHeader } from './components/MonthPicker'
import { YearPickerHeader } from './components/YearPicker'

const { DAY_PICKER, YEAR_PICKER, MONTH_PICKER } = Pickers

export type PickersType = typeof Pickers[keyof typeof Pickers]

type PickerRoute = {
  header: React.FC
  picker: React.FC
}

const pickerRoutes: Record<PickersType, PickerRoute> = {
  [DAY_PICKER]: {
    header: DayPickerHeader,
    picker: DayPicker,
  },
  [MONTH_PICKER]: {
    header: MonthPickerHeader,
    picker: MonthPicker,
  },
  [YEAR_PICKER]: {
    header: YearPickerHeader,
    picker: YearPicker,
  },
}
export default pickerRoutes
