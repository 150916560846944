export const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#000053',
      '::placeholder': {
        color: '#8080a980',
      },
    },
    invalid: {
      color: '#fd635d',
    },
  },
};
