import React, { useContext } from 'react';
import classNames from 'classnames';
import './ViewingConfirmation.styles.scss';
import ShowAppointment from 'components/ShowAppointment';
import MatchContext from '../../../providers/Match/Match.context';
import ViewingStepActions from '../../../components/ViewingStepActions';

type ViewingConfirmationProps = {
  className?: string;
};

const ViewingConfirmation: React.FC<ViewingConfirmationProps> = (props) => {
  const { className } = props;
  const {
    match: {
      buyer,
      location,
      locationCoordinates: { latitude, longitude },
      meetingDate,
    },
  } = useContext(MatchContext);

  const classes = classNames('yx-viewing-confirmation', className);

  return (
    <div className={classes}>
      <ShowAppointment
        location={location}
        lat={latitude}
        long={longitude}
        date={meetingDate}
        contact={buyer && `${buyer.firstName} ${buyer.lastName}`}
        contactDescription={buyer.email}
      />
      <ViewingStepActions />
    </div>
  );
};

export default ViewingConfirmation;
