import React, { useCallback, useContext, useState } from 'react';
import InputField from 'components/Fields/InputField';
import { Button, Loader } from 'ncoded-component-library';
import { withTypes, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useValidation from 'hooks/useValidation';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import api from 'api';

import './ForgotPasswordPage.styles.scss';

type ForgotPasswordFormType = {
  email: string;
};

const ForgotPasswordPage: React.FC = () => {
  const { Form } = withTypes<ForgotPasswordFormType>();

  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { validations } = useValidation();

  const { popServerError, popInfo } = useContext(PopNotificationsContext);

  const onForgotPassword = useCallback(
    async (values: ForgotPasswordFormType) => {
      try {
        setLoading(true);

        await api.auth.forgotPassword(values.email);
        popInfo({ content: t('emailSent') });
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [popInfo, popServerError, t],
  );

  return (
    <Form
      onSubmit={onForgotPassword}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="yx-forgot-password-page">
          {loading && <Loader />}
          <div className="yx-forgot-password-page__form">
            <h2>{t('forgotPassword')}</h2>
            <p>{t('forgotPassword-enterEmail')}</p>
            <Field
              name="email"
              component={InputField}
              placeholder={t('email')}
              validate={validations.email}
            />
            <Button type="submit">{t('send')}</Button>
            <NavLink
              to={'/login'}
              className="yx-forgot-password-page__remember-it"
            >
              {t('rememberedIt')}
            </NavLink>
          </div>
        </form>
      )}
    />
  );
};

export default ForgotPasswordPage;
