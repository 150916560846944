import React from 'react';
import Calendar from './Calendar.component';
import { CalendarProps, CalendarProviderProps } from './models';
import CalendarProvider from './provider';

function WrappedCalendar({
  onChange,
  value,
  ...props
}: CalendarProps & CalendarProviderProps) {
  return (
    <CalendarProvider onChange={onChange} value={value}>
      <Calendar {...props} />
    </CalendarProvider>
  );
}

export default WrappedCalendar;
