import { ContactDetailsPrivateData, UserRegistrationStep } from 'models/Auth';
import redirect from 'router/modules/redirect';
import { RoutingStep } from '../../models/Routes';
import ConfidentialityAgreementStep from '../commonSteps/ConfidentialityAgreementStep';
import ContactDetails from './pages/ContactDetailsStep/ContactDetailsStep.page';
import IdentityVerification from '../../components/CommonSteps/IdentityVerificationStep';
import AddCreditCardStep from '../commonSteps/AddCreditCardStep';

export const steps: RoutingStep<UserRegistrationStep>[] = [
  {
    path: '/contact-details',
    component: ContactDetails,
    stepName: 'Contact details - private',
    labelId: 'contactDetails',
    patchMap: ({
      proofOfPresentation,
      ...values
    }: ContactDetailsPrivateData) => ({
      ...values,
      isRepresentingLegalPerson: !!values.legalPersonInfo,
    }),
  },
  {
    path: '/identity-verification',
    authorized: false,
    exact: true,
    component: IdentityVerification,
    stepName: 'Identity verification',
    labelId: 'identityVerification',
    skipPatch: true,
  },
  {
    path: '/add-credit-card',
    authorized: false,
    exact: true,
    component: AddCreditCardStep,
    labelId: 'paymentMethod',
    stepName: 'Payment method',
    skipPatch: true,
  },
  {
    path: '/confidentiality-agreement',
    authorized: false,
    exact: true,
    component: ConfidentialityAgreementStep,
    stepName: 'Confidentiality agreement',
    labelId: 'confidentialityAgreement',
  },
];

export default [
  ...steps,
  {
    path: '',
    component: redirect('/contact-details'),
  },
];
