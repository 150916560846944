import React, { useMemo } from 'react';
import classNames from 'classnames';
import './FileUploader.styles.scss';
import PdfIcon from 'icons/Pdf.icon';
import ProgressBar from 'components/ProgressBar';
import { Button } from 'ncoded-component-library';
import XIcon from 'icons/X.icon';
import { useTranslation } from 'react-i18next';

export type FileUploaderProps = {
  className?: string;
  title: string;
  size: number;
  percentage: number;
  icon?: React.FC;
  onXClick?: () => void;
};

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const {
    className,
    title,
    size,
    percentage,
    icon: Icon = PdfIcon,
    onXClick,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('yx-file-uploader', className);

  const sizeInKb = useMemo(() => (size / (2 << 9)).toFixed(2), [size]);

  return (
    <div className={classes}>
      {onXClick && (
        <Button className="x-button" icon={XIcon} onClick={() => onXClick()} />
      )}
      <Icon />
      <span className="text--title">{title}</span>
      <ProgressBar
        className="yx-file-uploader__progress-bar"
        percentage={percentage}
      />
      <span className="text--percentage">
        {t('uploading')}&nbsp;{`${percentage}%`}
      </span>
      <span className="text--size">{`of ${sizeInKb} KB`}</span>
    </div>
  );
};

export default FileUploader;
