import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/CheckboxField';
import ContactForm from '../../components/ContactForm';
import DropzoneField from 'components/Fields/DropzoneField';
import validators from 'validators';

import './ContactDetailsStep.styles.scss';
import api from 'api';

type ContactDetailsProps = {
  className?: string;
};

const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-contact-details', className);

  const {
    input: { value: legalPerson },
  } = useField<boolean>('isRepresentingLegalPerson');

  const { t } = useTranslation();

  return (
    <StepWrapper className={classes} title={t('contactDetails')}>
      <ContactForm />
      <Field
        initialValue={false}
        name="isRepresentingLegalPerson"
        component={CheckboxField}
        label={t('PrivatePerson-ContactDetails-LegalPerson')}
      />
      {legalPerson && (
        <>
          <p>{t('PrivatePerson-ContactDetails-WhoDoYouRepresent')}</p>
          <ContactForm isLegalPerson />
          <Field
            name="proofOfPresentation"
            component={DropzoneField}
            label={t('contactDetails-proofOfRepresentation')}
            validate={validators.requiredArray(
              t('contactDetails-validation-proofOfRepresentation'),
            )}
            uploadRequest={api.users.uploadFileOnTemporaryUser}
            deleteRequest={api.users.deleteFileOnTemporaryUser}
          />
        </>
      )}
    </StepWrapper>
  );
};

export default ContactDetails;
