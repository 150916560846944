import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Search } from 'models/Search';

import './SimpleSearchCard.styles.scss';
import TableData from 'components/TableData';
import { TableDataType } from 'components/TableData/TableData.component';
import { useTranslation } from 'react-i18next';
import SimpleCard from '../SimpleCard';
import { useHistory } from 'react-router-dom';
import utils from 'utils';

type SimpleSearchInformation = Pick<
  Search,
  | 'yachtStatus'
  | 'yachtType'
  | 'budget'
  | 'guestCapacity'
  | 'location'
  | 'searchName'
>;

type SimpleSearchCardProps = {
  className?: string;
} & SimpleSearchInformation;

const SimpleSearchCard: React.FC<SimpleSearchCardProps> = (props) => {
  const {
    className,
    yachtStatus,
    yachtType,
    budget,
    guestCapacity,
    location,
    searchName,
  } = props;

  const classes = classNames('yx-simple-search-card', className);

  const { t } = useTranslation();
  const history = useHistory();

  const searchData = useMemo(
    () =>
      [
        { key: t('newOrUsed'), value: yachtStatus },
        { key: t('type'), value: yachtType },
        {
          key: t('budget'),
          value: `${utils.toAmountForm(
            budget?.from || '',
          )} to ${utils.toAmountForm(budget?.to || '')}`,
        },
        { key: t('guests'), value: guestCapacity },
        { key: t('location'), value: location },
      ] as Array<TableDataType>,
    [t, yachtStatus, yachtType, budget, guestCapacity, location],
  );

  return (
    <SimpleCard
      className={classes}
      title={searchName}
      onClick={() => history.push(`/my-searches`)}
    >
      <TableData tableData={searchData} tableColumnCount={1} />
    </SimpleCard>
  );
};

export default SimpleSearchCard;
