import React, { useContext, useState } from 'react';
import Header from '../Header';
import Navigation from '../Navigation';
import classNames from 'classnames';
import { NavigationProps } from '../Navigation/Navigation.component';
import { HeaderProps } from 'components/Header/Header.component';
import PageContext from 'router/subrouters/Dashboard/providers/Page/Page.context';
import HeaderActions from './components/HeaderActions';
import Logo from 'icons/Logo';

import './PageWrapper.styles.scss';
import './PageWrapper.responsive.styles.scss';
import { NavLink } from 'react-router-dom';

export const ROUTE_NAMES = {
  HOME: 'home',
  MY_SEARCHES: 'my-searches',
  MY_YACHTS: 'my-yachts',
  MY_YACHT: 'my-yacht',
} as const;

type PageWrapperProps = {
  rootClassName?: string;
  header?: HeaderProps;
  navigation: NavigationProps;
  className?: string;
};

type ButtonProps = React.HTMLAttributes<HTMLButtonElement>;

const Burger: React.FC<ButtonProps> = (props) => (
  <button {...props}>
    <span />
    <span />
    <span />
  </button>
);

const PageWrapper: React.FC<PageWrapperProps> = (props) => {
  const { children, rootClassName, navigation, header, className } = props;
  const { title } = useContext(PageContext);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const classes = classNames('yx-navigation-wrapper', rootClassName);
  const childrenClasses = classNames(
    'yx-navigation-wrapper__content-container',
    className,
  );

  return (
    <div className={classes}>
      <Header
        title={title}
        {...header}
        childrenClassName="yx-navigation-content__header"
      >
        <Burger
          onClick={() => setIsNavOpen((old) => !old)}
          className={classNames('burger', { 'burger--open': isNavOpen })}
        />
        <NavLink className="logo" to="/">
          <Logo />
        </NavLink>
        <HeaderActions />
      </Header>
      <Navigation {...navigation} isOpen={isNavOpen} setIsOpen={setIsNavOpen} />
      <div className={childrenClasses}>
        <div className="yx-responsive-header">
          {title && <h1>{title}</h1>}
          <div className="yx-responsive-header__actions">
            <HeaderActions />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageWrapper;
