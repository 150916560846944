import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none">
    <path
      d="M6.00156 13.5C6.00156 13.5 1.00156 10.6875 1.00156 6.125C0.984881 5.45795 1.10188 4.7943 1.34568 4.17317C1.58947 3.55204 1.95513 2.98599 2.42109 2.50838C2.88705 2.03077 3.4439 1.65125 4.05883 1.3922C4.67375 1.13314 5.3343 0.999792 6.00156 1C6.66882 0.999792 7.32938 1.13314 7.9443 1.3922C8.55922 1.65125 9.11607 2.03077 9.58204 2.50838C10.048 2.98599 10.4137 3.55204 10.6574 4.17317C10.9012 4.7943 11.0182 5.45795 11.0016 6.125C11.0016 10.6875 6.00156 13.5 6.00156 13.5Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.00195 7.875C7.03749 7.875 7.87695 7.03553 7.87695 6C7.87695 4.96447 7.03749 4.125 6.00195 4.125C4.96642 4.125 4.12695 4.96447 4.12695 6C4.12695 7.03553 4.96642 7.875 6.00195 7.875Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
