import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Loader } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import api from 'api';
import confirm from 'modules/confirm';
import { useHistory } from 'react-router-dom';
import useIsEditRoute from 'hooks/useIsEditRoute';

import './DecidingSearch.styles.scss';

type DecidingSearchProps = {
  className?: string;
};

const DecidingSearch: React.FC<DecidingSearchProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-deciding-search', className);
  const { t } = useTranslation();
  const history = useHistory();

  const isEdit = useIsEditRoute();

  const storageKey = useMemo(
    () =>
      isEdit ? STORAGE_KEYS.EDITING_SEARCH_ID : STORAGE_KEYS.CREATING_SEARCH_ID,
    [isEdit],
  );

  useEffect(() => {
    const decideSearch = async () => {
      const lsSearchId = storageService.getItem<string>(storageKey);

      const createInitialSearch = async () => {
        const {
          data: { search },
        } = await api.search.createInitialSearch();
        storageService.setItem(storageKey, search.id);
        history.replace(`/create-search-profile/${search.id}`);
      };

      if (
        lsSearchId &&
        (await confirm({ content: t('wantToContinueFromLastSearch') }))
      )
        history.replace(`/create-search-profile/${lsSearchId}`);
      else createInitialSearch();
    };

    decideSearch();
  }, [history, storageKey, t]);

  return (
    <div className={classes}>
      <img src="/assets/images/logo.png" alt="yachtx-logo" />
      <Loader size="big" inline />
    </div>
  );
};

export default DecidingSearch;
