import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import RadioCard from 'components/RadioCard';
import { FieldRenderProps } from 'react-final-form';
import { ConfirmationType, CONFIRMATION_TYPE } from 'models/Yacht';
import { useTranslation } from 'react-i18next';
import FieldLabel from '../components/FieldLabel';

import './YesNoField.styles.scss';

type RadioCardProps = ExtractPropTypes<typeof RadioCard>;

type YesNoFieldProps = {
  className?: string;
  tooltip?: React.FC;
} & FieldRenderProps<boolean, HTMLElement> &
  RadioCardProps;

const YesNoField: React.FC<YesNoFieldProps> = (props) => {
  const {
    label,
    input: { onChange, value, ...restInput },
    meta: { touched, error },
    className,
    tooltip,
    ...rest
  } = props;

  const hasError = useMemo(() => touched && error, [error, touched]);
  const { t } = useTranslation();

  const classes = classNames(
    'yx-yes-no-field',
    { 'yx-yes-no-field--error': hasError },
    className,
  );

  const isChecked = useCallback(
    (value: ConfirmationType) => value === CONFIRMATION_TYPE.YES,
    [],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChange(isChecked(value as ConfirmationType));
    },
    [isChecked, onChange],
  );

  return (
    <div className={classes}>
      {label && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      <div className="yx-yes-no-field__actions">
        <RadioCard
          {...rest}
          {...restInput}
          value={CONFIRMATION_TYPE.YES}
          label={t('yes')}
          checked={value}
          onChange={handleOnChange}
        />
        <RadioCard
          {...rest}
          {...restInput}
          value={CONFIRMATION_TYPE.NO}
          label={t('no')}
          checked={!value}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default YesNoField;
