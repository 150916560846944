import React, { useContext } from 'react';
import classNames from 'classnames';
import './CollapsibleContainer.styles.scss';
import './CollapsibleContainer.styles.responsive.scss';
import { useTranslation } from 'react-i18next';
import PercentageValue from '../../../../../../../components/PercentageValue';
import ArrowheadIcon from '../../../../../../../icons/Arrowhead.icon';
import Collapsible from 'components/Collapsible';
import useCallbackRef from 'hooks/useCallbackRef';
import { CollapsibleRef } from 'components/Collapsible/Collapsible.component';
import MatchContext from '../../providers/Match/Match.context';
import { MATCH_TYPE } from '../../models';
import ArrangeMeetingButton from '../ArangeMeetingButton';

type CollapsibleContainerProps = {
  className?: string;
  title: string;
  description?: string;
  matchScore: number;
  pageContent?: React.ReactNode;
  defaultOpen?: boolean;
};

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = (props) => {
  const { className, title, description, matchScore, children, defaultOpen } =
    props;
  const { t } = useTranslation();
  const [collapsible, collapsibleRef] = useCallbackRef<CollapsibleRef>();

  const classes = classNames('yx-collapsible-container', className);
  const arrowheadClasses = classNames('arrowhead', {
    rotated: collapsible?.open,
  });
  const { currentStep = 1, routeName } = useContext(MatchContext);

  return (
    <Collapsible
      className={classes}
      defaultOpen={defaultOpen}
      ref={collapsibleRef}
      trigger={
        <div className="yx-collapsible-container__collapse-trigger">
          <div className="yx-collapsible-container__collapse-trigger__content">
            <div className="left-content">
              <div className="left-content__search-info">
                <label>{title}</label>
                {description && <p>{description}</p>}
              </div>
              {currentStep === 1 && routeName === MATCH_TYPE.BUYER && (
                <div className="left-content__page-content">
                  <ArrangeMeetingButton />
                </div>
              )}
            </div>

            <div className="right-content">
              <label>{t('matchingScore')}</label>
              <PercentageValue value={matchScore} />
            </div>
          </div>
          <ArrowheadIcon className={arrowheadClasses} />
        </div>
      }
    >
      <div className="yx-collapsible-container__collapsible-content">
        {children}
      </div>
    </Collapsible>
  );
};

export default CollapsibleContainer;
