import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import FormRow from 'components/FormRow';
import SelectField from 'components/Fields/SelectField';
import RadioFields from 'components/Fields/RadioFields';
import { yachtStatusFields, yachtTypeFields } from './radioFields';
import FieldLabel from 'components/Fields/components/FieldLabel';
import CheckboxField from 'components/CheckboxField';
import {
  fuelOptions,
  hullMaterialOptions,
  searchLocations,
  yearOptions,
} from 'router/subrouters/CreateYacht/services/options';
import validators from 'validators';
import { countries } from 'services/countries';
import { composeValidators } from 'validators/general';
import useIsEditRoute from 'hooks/useIsEditRoute';

import './AdvancedSearch.styles.scss';
import './AdvancedSearch.responsive.styles.scss';
import { Button } from 'ncoded-component-library';
import { useHistory, useLocation } from 'react-router';

type AdvancedSearchProps = {
  className?: string;
};

const AdvancedSearch: React.FC<AdvancedSearchProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-advanced-search', className);

  const { t } = useTranslation();
  const isEdit = useIsEditRoute();
  const history = useHistory();
  const { pathname } = useLocation();

  const validatorsObject = useMemo(
    () => ({
      range: composeValidators(
        validators.required(t('requiredField')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      sizeRangeTo: validators.checkIfOtherFieldIsGratherThenRange(
        'sizeRange',
        t('sizeRange-validator'),
      ),
      budget: validators.checkIfOtherFieldIsGratherThenRange(
        'budget',
        t('budget-validator'),
      ),
      grossTonnage: validators.checkIfOtherFieldIsGratherThenRange(
        'grossTonnage',
        t('grossTonnage-validator'),
      ),
      draft: validators.checkIfOtherFieldIsGratherThenRange(
        'draft',
        t('draft-validator'),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('advancedSearch')}
      description={t('advancedSearch-info')}
      className={classes}
      buttonTitle={t(isEdit ? 'save' : 'create')}
      additionalActions={
        <div style={{ width: '100%' }}>
          <Button
            styleType="secondary"
            onClick={() =>
              history.push(pathname.split('/').slice(0, -1).join('/'))
            }
          >
            {t('backToWholeForm')}
          </Button>
        </div>
      }
    >
      <style>
        {`
          .yx-form-content {
            display: flex;
            justify-content: center;
            padding: 42px 0 24px 0;
          }

          .yx-step-progress-v {
            display: none;
          }

          .yx-step-progress-responsive{
            display: none;
          }
        `}
      </style>

      <div className="yx-advanced-search__search">
        <Field
          name="searchName"
          label={t('searchProfileName')}
          placeholder={t('enterDescriptiveName')}
          component={InputField}
          validate={validators.required(t('requiredField'))}
        />
      </div>
      <div className="yx-advanced-search__root-container">
        <div className="yx-advanced-search__container">
          <RadioFields
            name="yachtType"
            label={t('type')}
            radioFields={yachtTypeFields}
          />
          <RadioFields
            name="yachtStatus"
            label={t('conditions')}
            radioFields={yachtStatusFields}
          />
          <Field
            name="category"
            label={t('category')}
            placeholder={t('enterCategory')}
            component={InputField}
          />
          <FormRow>
            <Field
              name="sizeRange.from"
              unitType="meters"
              label={t('minLoa')}
              placeholder={t('enterAmount')}
              component={InputField}
              validate={validatorsObject.range}
            />
            <Field
              name="sizeRange.to"
              unitType="meters"
              label={t('maxLoa')}
              placeholder={t('enterAmount')}
              component={InputField}
              validate={composeValidators(
                validatorsObject.range,
                validatorsObject.sizeRangeTo,
              )}
            />
          </FormRow>
          <FormRow>
            <Field
              unitType="EUR"
              name="budget.from"
              component={InputField}
              label={t('minPrice')}
              placeholder={t('enterAmount')}
              validate={validatorsObject.range}
            />
            <Field
              unitType="EUR"
              name="budget.to"
              component={InputField}
              label={t('maxPrice')}
              placeholder={t('enterAmount')}
              validate={composeValidators(
                validatorsObject.range,
                validatorsObject.budget,
              )}
            />
          </FormRow>
          <Field
            type="number"
            min={0}
            name="guestCapacity"
            component={InputField}
            label={t('guestCapacity')}
            placeholder={t('enterGuestCapacity')}
            validate={validators.required(t('requiredField'))}
          />
          <Field
            type="number"
            min={1}
            name="numberOfCabins"
            placeholder={t('enterAmount')}
            component={InputField}
            label={t('numberOfCabins')}
            validate={validators.required(t('requiredField'))}
          />
          <Field
            name="location"
            component={SelectField}
            options={searchLocations}
            label={t('location')}
            placeholder={t('selectLocation')}
            validate={validators.required('requiredField')}
          />

          <Field
            name="builderName"
            component={InputField}
            label={t('builderName')}
            placeholder={t('enterBuilderName')}
          />
          <Field
            name="yachtModel"
            component={InputField}
            label={t('model')}
            placeholder={t('enterModel')}
          />
          <FormRow>
            <Field
              name="yearDelivery.from"
              component={SelectField}
              options={yearOptions}
              label={t('minDelivery')}
              placeholder={t('enterMinYear')}
            />
            <Field
              name="yearDelivery.to"
              component={SelectField}
              options={yearOptions}
              label={t('maxDelivery')}
              placeholder={t('enterMaxYear')}
            />
          </FormRow>
          <FormRow>
            <Field
              name="yearBuild.from"
              component={SelectField}
              options={yearOptions}
              label={t('minBuildYear')}
              placeholder={t('enterMinYear')}
            />
            <Field
              name="yearBuild.to"
              component={SelectField}
              options={yearOptions}
              label={t('maxBuildYear')}
              placeholder={t('enterMaxYear')}
            />
          </FormRow>
          <FormRow>
            <Field
              name="yearRefit.from"
              component={SelectField}
              options={yearOptions}
              label={t('minRefitYear')}
              placeholder={t('enterMinYear')}
            />
            <Field
              name="yearRefit.to"
              component={SelectField}
              options={yearOptions}
              label={t('maxRefitYear')}
              placeholder={t('enterMaxYear')}
            />
          </FormRow>
          <div className="yx-advanced-search__container__checkboxes">
            <FieldLabel>{t('auxiliaryEquipment')}</FieldLabel>
            <Field
              name="hasGenerator"
              component={CheckboxField}
              label={t('generator')}
              optional
            />
            <Field
              name="hasTender"
              component={CheckboxField}
              label={t('tender')}
              optional
            />
            <Field
              name="hasAutopilot"
              component={CheckboxField}
              label={t('autopilot')}
              optional
            />
            <Field
              name="hasCockpitSpeakers"
              component={CheckboxField}
              label={t('cockpitSpeakers')}
              optional
            />
            <Field
              name="hasMarineHead"
              component={CheckboxField}
              label={t('marineHead')}
              optional
            />
            <Field
              name="hasBowThruster"
              component={CheckboxField}
              label={t('bowThruster')}
              optional
            />
          </div>
        </div>
        <div className="yx-advanced-search__container">
          <FormRow>
            <Field
              name="hullType"
              label={t('hullType')}
              placeholder={t('enterHullType')}
              options={hullMaterialOptions}
              component={SelectField}
            />
            <Field
              name="hullConfiguration"
              label={t('hullConfiguration')}
              placeholder={t('enterHullConfiguration')}
              component={InputField}
            />
          </FormRow>
          <Field
            name="hullMaterial"
            label={t('hullMaterial')}
            placeholder={t('enterHullMaterial')}
            component={InputField}
          />

          <FormRow>
            <Field
              name="grossTonnage.from"
              label={t('minimumGrossTonnage')}
              placeholder={t('enterMinTonnage')}
              component={InputField}
              unitType="t"
            />
            <Field
              name="grossTonnage.to"
              label={t('maximumGrossTonnage')}
              placeholder={t('enterMaxTonnage')}
              component={InputField}
              unitType="t"
              validate={validatorsObject.grossTonnage}
            />
          </FormRow>
          <Field
            name="minBeam"
            label={t('minBeam')}
            placeholder={t('enterMinBeam')}
            component={InputField}
            unitType="meters"
          />
          <FormRow>
            <Field
              name="draft.from"
              label={t('minDraft')}
              placeholder={t('enterMinDraft')}
              component={InputField}
              unitType="meters"
            />
            <Field
              name="draft.to"
              label={t('maxDraft')}
              placeholder={t('enterMaxDraft')}
              component={InputField}
              unitType="meters"
              validate={validatorsObject.draft}
            />
          </FormRow>
          <Field
            name="engineManufacturer"
            label={t('engineManufacturer')}
            placeholder={t('enterEngineManufacturer')}
            component={InputField}
          />
          <Field
            name="engineModel"
            label={t('engineModel')}
            placeholder={t('enterEngineModel')}
            component={InputField}
          />
          <Field
            name="fuelType"
            label={t('engineFuelType')}
            placeholder={t('selectFuelType')}
            options={fuelOptions}
            component={SelectField}
          />
          <Field
            name="maxSpeed"
            unitType="kn"
            label={t('maxSpeed')}
            placeholder={t('enterMaxSpeed')}
            component={InputField}
          />
          <Field
            name="country"
            label={t('country')}
            placeholder={t('selectCountry')}
            options={countries}
            searchable
            component={SelectField}
          />
          <Field
            name="state"
            label={t('state')}
            placeholder={t('enterState')}
            component={InputField}
          />
          <Field
            name="city"
            label={t('city')}
            placeholder={t('enterCity')}
            component={InputField}
          />
          <Field
            name="taxStatus"
            label={t('taxStatus')}
            placeholder={t('enterTaxStatus')}
            component={InputField}
          />
        </div>
      </div>
    </StepWrapper>
  );
};

export default AdvancedSearch;
