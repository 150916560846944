import React, { useCallback, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import NotificationContext from '../../providers/Notification/Notification.context';
import InfiniteScroll from 'components/InfiniteScroll';
import NotificationCard from 'components/NotificationCard';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Loader } from 'ncoded-component-library';
import DotsIcon from 'icons/Dots.icon';
import CheckIcon from 'icons/Check.icon';
import { useHistory } from 'react-router-dom';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import confirm from 'modules/confirm';
import EmptyState from 'components/EmptyState';
import NotificationIcon from 'icons/Notification.icon';

import './Notification.styles.scss';
import './Notification.responsive.styles.scss';

type NotificationProps = {
  className?: string;
};

const Notification: React.FC<NotificationProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const dropdownRef = useRef<DropdownRef>();

  const {
    loadingNotifications,
    notifications,
    numOfUnreadNotifications,
    onNotificationScroll,
    readAllNotifications,
  } = useContext(NotificationContext);

  const classes = classNames('yx-notification', className);

  const headerClasses = useMemo(
    () =>
      classNames('yx-notification__header', {
        'yx-notification__header--unread': numOfUnreadNotifications > 0,
      }),
    [numOfUnreadNotifications],
  );

  const handleOnReadNotifications = useCallback(async () => {
    dropdownRef.current.setIsOpen(false);
    if (
      !(await confirm({
        title: t('markAllNotificationsAsRead'),
        content: t('areYouSureYouWantToMarkAllNotificationsAsRead'),
      }))
    )
      return;
    readAllNotifications();
  }, [readAllNotifications, t]);

  const dropdownOptions = useMemo(
    () => (
      <Dropdown
        renderAsPortal
        ref={dropdownRef}
        className="yx-notification__actions"
        trigger={
          <Button className="yx-notification__action-button" icon={DotsIcon} />
        }
      >
        <Button
          variant="link"
          icon={CheckIcon}
          onClick={() => handleOnReadNotifications()}
        >
          {t('markAllAsRead')}
        </Button>
        <Button
          variant="link"
          icon={CheckIcon}
          onClick={() => history.push('/profile-settings')}
        >
          {t('notificationSettings')}
        </Button>
      </Dropdown>
    ),
    [handleOnReadNotifications, history, t],
  );

  const emptyState = useMemo(
    () =>
      notifications.length === 0 && (
        <EmptyState
          icon={NotificationIcon}
          label={t('youHaveNoNotificationsYet')}
        />
      ),
    [notifications, t],
  );

  return (
    <InfiniteScroll onScroll={() => onNotificationScroll()} className={classes}>
      {loadingNotifications && <Loader />}

      <div className={headerClasses}>
        <h1>
          {numOfUnreadNotifications > 0 &&
            t('youHaveUnreadNotification', {
              count: numOfUnreadNotifications,
            })}
        </h1>
        {dropdownOptions}
      </div>

      {notifications.length === 0
        ? emptyState
        : notifications.map((notification) => (
            <NotificationCard
              {...notification}
              key={notification._id}
              to={`/matches/match/${notification.content.matchId}`}
            />
          ))}
    </InfiniteScroll>
  );
};

export default Notification;
