import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Collapsible from 'components/Collapsible';
import GridIcon from 'icons/Grid.icon';
import useCallbackRef from 'hooks/useCallbackRef';
import { CollapsibleRef } from 'components/Collapsible/Collapsible.component';
import CloseIcon from 'icons/Close.icon';
import { Button, ImageSlider, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import {
  ImageSliderRef,
  ImageSlideType,
} from 'ncoded-component-library/build/components/organisms/ImageSlider/ImageSlider.component';
import { useTranslation } from 'react-i18next';
import { ImageCarouselType } from 'components/ImageCarousel/ImageCarousel.component';

import './ImagePreview.styles.scss';
import './ImagePreview.responsive.styles.scss';

type ImagePreviewProps = {
  className?: string;
  images?: Array<ImageCarouselType>;
  title?: string;
  activeIndex?: number;
  onClose?: () => void;
};

const ImagePreview: React.FC<ImagePreviewProps> = (props) => {
  const { className, images, title = '', activeIndex = 0, onClose } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-image-preview', className);

  const modalRef = useRef<ModalRef>(null);

  const [imageElement, imageSliderRef] = useCallbackRef<ImageSliderRef>(null);

  const imagesCount = useMemo(() => images.length, [images]);

  const onImageClick = useCallback(
    (imageIndex: number) => {
      imageElement?.setActiveImageIndex(imageIndex);
    },
    [imageElement],
  );

  const slidingImages = useMemo(
    () =>
      images.map((image) => {
        return {
          imageUrl: image.url,
        } as ImageSlideType;
      }) as Array<ImageSlideType>,
    [images],
  );

  const [collapsible, collapseRef] = useCallbackRef<CollapsibleRef>(null);
  const [activeImageElement, activeImageRef] =
    useCallbackRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!activeImageElement) return;
    const { contentElement } = collapsible;

    const { offsetWidth: contentOffsetWidth } = contentElement;
    const activeImageOffsetLeft = activeImageElement.offsetLeft;
    const activeImageRect = activeImageElement.getBoundingClientRect();

    const activeOffsetLeft =
      activeImageOffsetLeft -
      (contentOffsetWidth / 2 - activeImageRect.width / 2);

    const scrollOptions = {
      left: activeOffsetLeft,
      behavior: 'smooth',
    } as ScrollToOptions;

    contentElement.scrollTo(scrollOptions);
  }, [collapsible, activeImageElement, imageElement]);

  return (
    <Modal
      ref={modalRef}
      open
      className={classes}
      background="black"
      onClose={onClose}
      footer={
        <Collapsible
          ref={collapseRef}
          className="yx-image-preview__image-grid"
          trigger={
            <>
              <p className="yx-image-preview__col-title">
                {title ||
                  (collapsible?.open
                    ? t('hideAll')
                    : `${t('seeAll')} (${slidingImages.length})`)}
              </p>
              <GridIcon />
            </>
          }
        >
          {images.map((image, index) => (
            <div
              ref={
                index === imageElement?.activeImageIndex ? activeImageRef : null
              }
              className={classNames('yx-image-preview__image', {
                'yx-image-preview__image--active':
                  index === imageElement?.activeImageIndex,
              })}
              key={index}
            >
              <img
                key={index}
                src={image.url}
                alt="preview"
                onClick={() => onImageClick(index)}
              />
            </div>
          ))}
        </Collapsible>
      }
    >
      <div className="yx-image-preview__image-container">
        <div className="yx-image-preview__image-container__header">
          <p>{`${imageElement?.activeImageIndex + 1}/${imagesCount}`}</p>
          <Button
            variant="icon"
            className="yx-image-preview__slider-button"
            icon={CloseIcon}
            onClick={() => onClose?.()}
          />
        </div>

        <ImageSlider
          ref={imageSliderRef}
          showDots={false}
          activeIndex={activeIndex || imageElement?.activeImageIndex}
          slides={slidingImages}
        />
      </div>
    </Modal>
  );
};

export default ImagePreview;
