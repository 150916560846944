import React, { useCallback, useContext, useState } from 'react';
import { Button, Loader } from 'ncoded-component-library';
import { withTypes, Field } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PasswordInputField from './components/PasswordInputField';
import api from 'api';
import credentialsService from 'services/credentialsService';
import useValidation from 'hooks/useValidation';
import validators from 'validators';

import './LoginPage.styles.scss';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

export type SignInFormType = {
  email: string;
  password: string;
};

const LoginPage: React.FC = () => {
  const history = useHistory();
  const { Form } = withTypes<SignInFormType>();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const { popServerError } = useContext(PopNotificationsContext);

  const onLoginSubmit = useCallback(
    async (values: SignInFormType) => {
      try {
        setLoading(true);
        const { email, password } = values;

        const { data: authBody } = await api.auth.login(email, password);

        if (authBody.token || authBody.refreshToken) {
          credentialsService.saveAuthBody(authBody);
        }

        credentialsService.isLogged = true;
        history.push('/home');
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [history, popServerError],
  );

  const { validations } = useValidation();

  return (
    <Form
      onSubmit={onLoginSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="yx-login-page">
          {loading && <Loader />}
          <div className="yx-login-page__header">
            <p>{t('notAMemberYet')}</p>
            <Button
              styleType="secondary"
              variant="outline"
              onClick={() => history.push('/signup')}
            >
              {t('signUp')}
            </Button>
          </div>

          <div className="yx-login-page__form">
            <img src="/assets/images/logo.png" alt="yachtx-logo" />
            <h2>{t('memberLogin')}</h2>
            <Field
              name="email"
              component={InputField}
              placeholder={t('email')}
              validate={validations.email}
            />
            <Field
              name="password"
              type="password"
              component={PasswordInputField}
              placeholder={t('password')}
              validate={validators.required(t('passwordValidation-required'))}
            />
            <Button type="submit">{t('signIn')}</Button>
          </div>
        </form>
      )}
    />
  );
};

export default LoginPage;
