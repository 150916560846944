import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import useCallbackRef from './useCallbackRef';
import uniqid from 'uniqid';

export default (object: Object) => {
  const [styles, stylesRef] = useCallbackRef<HTMLStyleElement>(null);
  const [setupFinished, setSetupFinished] = useState(false);
  const stylesId = useRef(uniqid());

  const styleString = useMemo(
    () => `[data-styles-id="${stylesId.current}"]{
    ${Object.entries(object).reduce(
      (stylesString, item) => `${stylesString} --${item[0]}: ${item[1]};`,
      '',
    )}
  }`,
    [object],
  );

  useEffect(() => {
    if (styles && !setupFinished) {
      styles.parentElement.setAttribute(`data-styles-id`, stylesId.current);
      setSetupFinished(true);
    }
  }, [setupFinished, styles]);

  const cssStyles = useMemo(() => {
    return <style ref={stylesRef}> {styleString} </style>;
  }, [styleString, stylesRef]);

  if (!setupFinished) return cssStyles;
  return ReactDOM.createPortal(cssStyles, document.head);
};
