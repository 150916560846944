import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'hooks/useQueryParams';
import { useState } from 'react';
import { useEffect } from 'react';
import api from 'api';
import { Loader } from 'ncoded-component-library';
import { useHistory } from 'react-router-dom';
import credentialsService from 'services/credentialsService';
import { useMemo } from 'react';

import './EmailVerifiedStep.styles.scss';
import './EmailVerifiedStep.responsive.styles.scss';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

export const CHOOSE_ROLE_STEP = '/signup/role';

type EmailVerifiedStepProps = {
  className?: string;
};

const EmailVerifiedStep: React.FC<EmailVerifiedStepProps> = (props) => {
  const { className } = props;

  const history = useHistory();
  const { params } = useQueryParams<{ token: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [verifiedEmail, setVerifiedEmail] = useState<boolean>(false);
  const { popServerError } = useContext(PopNotificationsContext);

  const classes = classNames('yx-email-verified-step', className);

  const { t } = useTranslation();

  const message = useMemo(
    () =>
      verifiedEmail
        ? t('emailVerified-yourEmailHasBeenVerified')
        : t('emailVerified-validatingEmail'),
    [t, verifiedEmail],
  );

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (!params.token) return;
        setLoading(true);

        const { data: userData } = await api.auth.verifyEmail(params.token);

        credentialsService.saveUserData(userData);
        setVerifiedEmail(userData.hasVerifiedEmail);
      } catch (e) {
        popServerError(e);
        history.push('/signup');
      }
    };

    setTimeout(verifyEmail, 2000);
  }, [history, params, popServerError]);

  useEffect(() => {
    if (verifiedEmail) setTimeout(() => history.push(CHOOSE_ROLE_STEP), 1000);
  }, [history, verifiedEmail]);

  return (
    <div className={classes}>
      {loading && <Loader />}

      <div className="yx-email-verified-step__container">
        <img
          src="/assets/images/email-verification.png"
          alt="email-verification"
        />

        <h1>{message}</h1>
      </div>
    </div>
  );
};

export default EmailVerifiedStep;
