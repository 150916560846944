import React, { useEffect, useMemo } from 'react';
import PageWrapper from '../../../components/PageWrapper';
import Routes from '../../components/Routes';
import CurrentUserProvider from './providers/CurrentUser';
import routes from './routes';
import { NavigationOption } from 'components/Navigation/Navigation.component';
import MyYachtsProvider from './providers/MyYachts';
import NotificationProvider from './providers/Notification';
import yachtSocketService from 'services/socketService/yachtSocketService';
import './Dashboard.styles.scss';

const DashboardRouter: React.FC = () => {
  const navigationProps = useMemo(
    () =>
      routes
        .filter((route) => route.navigation)
        .map((route) => {
          return {
            icon: route.icon,
            label: route.label,
            to: route.path,
          } as NavigationOption;
        }),
    [],
  );

  useEffect(() => {
    yachtSocketService.connect();

    return () => {
      yachtSocketService.disconnect();
    };
  }, []);

  return (
    <NotificationProvider>
      <MyYachtsProvider>
        <CurrentUserProvider>
          <PageWrapper
            navigation={{ options: navigationProps }}
            className="yx-dashboard__content"
            rootClassName="yx-dashboard-page"
          >
            <Routes routes={routes} />
          </PageWrapper>
        </CurrentUserProvider>
      </MyYachtsProvider>
    </NotificationProvider>
  );
};

export default DashboardRouter;
