import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="25" height="36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.827 25.48a18.46 18.46 0 0 0 4.194-11.75A18.46 18.46 0 0 0 3.818 3.302 18.476 18.476 0 0 1 14.15 19.917c0 1.938-.296 3.807-.847 5.563a11.268 11.268 0 0 0-8.64-.616l-1.837.616Z"
      fill="#9EBBE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.728 28.778c-.004.001-.007-.003-.004-.006a24.029 24.029 0 0 0 5.408-15.24c0-4.75-1.367-9.176-3.724-12.9-.11-.174.077-.384.258-.288 7.537 4.012 12.676 12.009 12.676 21.22 0 2.514-.383 4.938-1.093 7.215-.001.002-.003.003-.005.002a14.492 14.492 0 0 0-11.156-.798l-2.36.795Z"
      fill="#0D2CA7"
    />
    <path
      d="M24.363 28.929.5 35.429c-.223.06-.166.376.065.36l17.392-2.58c5.074-.749 5.264-1.025 6.515-4.167.027-.067-.037-.133-.108-.113Z"
      fill="#000053"
    />
  </svg>
);
