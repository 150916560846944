import React from 'react';
import classNames from 'classnames';
import './PasswordInput.styles.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type PasswordInputProps = {
  className?: string;
  hasError?: boolean;
};

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const { className, hasError, ...rest } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-password-input', 'ncoded-input', className, {
    'yx-password-input--error': hasError,
  });

  return (
    <div className={classes}>
      <input type="password" {...rest} />
      <NavLink to="/forgot-password">{t('forgotPassword')}?</NavLink>
    </div>
  );
};

export default PasswordInput;
