import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <path
      d="M1 3.59985H2.3H12.7"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.24883 3.6V2.3C4.24883 1.95522 4.38579 1.62456 4.62959 1.38076C4.87339 1.13696 5.20405 1 5.54883 1H8.14883C8.49361 1 8.82427 1.13696 9.06807 1.38076C9.31187 1.62456 9.44883 1.95522 9.44883 2.3V3.6M11.3988 3.6V12.7C11.3988 13.0448 11.2619 13.3754 11.0181 13.6192C10.7743 13.863 10.4436 14 10.0988 14H3.59883C3.25405 14 2.92339 13.863 2.67959 13.6192C2.43579 13.3754 2.29883 13.0448 2.29883 12.7V3.6H11.3988Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.55078 6.85001V10.75"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.14941 6.85001V10.75"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
