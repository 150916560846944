import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-nextLine import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 55.125 55.125"
    fill="currentColor"
    {...props}
  >
    <path
      d="M4.167,47.007h46.792c2.302,0,4.167-1.866,4.167-4.167V12.285c0-2.301-1.865-4.167-4.167-4.167H4.167
			C1.866,8.118,0,9.984,0,12.285V42.84C0,45.141,1.866,47.007,4.167,47.007z M30.771,44.082h-6.418v-3.965h6.418V44.082z
			 M46.065,11.138h6.418v3.965h-6.418V11.138z M45.875,40.117h6.419v3.965h-6.419V40.117z M35.303,11.138h6.419v3.965h-6.419V11.138
			z M35.114,40.117h6.419v3.965h-6.419V40.117z M24.543,11.138h6.418v3.965h-6.418V11.138z M22.743,20.717l9.639,4.82
			c2.237,1.119,2.237,2.933,0,4.051l-9.639,4.821c-2.237,1.118-4.052-0.003-4.052-2.506v-8.681
			C18.69,20.72,20.505,19.599,22.743,20.717z M13.782,11.138h6.419v3.965h-6.419V11.138z M13.593,40.117h6.418v3.965h-6.418V40.117z
			 M3.021,11.138h6.419v3.965H3.021V11.138z M2.832,40.117h6.419v3.965H2.832V40.117z"
    />
  </svg>
);
