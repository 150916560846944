import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { DropzoneInputProps } from 'react-dropzone';
import { FieldRenderProps, useForm } from 'react-final-form';
import './DropzoneField.styles.scss';
import Dropzone from 'components/Dropzone';
import FieldLabel from '../components/FieldLabel';
import FileUploader from 'components/FileUploader/FileUploader.component';
import useDropzoneField, {
  DropzoneFieldRequests,
} from 'hooks/useDropzoneField';
import DisplayDropzoneFile, {
  FileType,
} from './components/DisplayDropzoneFile/DisplayDropzoneFile.component';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import { useTranslation } from 'react-i18next';
import DropzoneRequestsContext from 'providers/DropzoneRequests/DropzoneRequests.context';

export type DropzoneFieldProps = {
  className?: string;
  label?: string;
  tooltip?: React.FC;
  fileName?: string;
  onFileDelete?: () => void;
} & FieldRenderProps<DropzoneFieldFile[], HTMLElement> &
  DropzoneFieldRequests &
  DropzoneInputProps;

export type DropzoneFieldFile = {
  key: string;
  name: string;
  url: string;
  type: FileType;
  id: string;
};

const DropzoneField: React.FC<DropzoneFieldProps> = (props) => {
  const { uploadRequest: cUploadRequest, deleteRequest: cDeleteRequest } =
    useContext(DropzoneRequestsContext);

  const {
    fileName: pFileName,
    className,
    input: { onChange, value: formValue, name },
    label,
    meta: { touched, error },
    tooltip,
    maxFiles,
    onFileDelete,
    uploadRequest = cUploadRequest,
    deleteRequest = cDeleteRequest,
    ...rest
  } = props;

  const value = useMemo(
    () =>
      formValue ? (Array.isArray(formValue) ? formValue : [formValue]) : [],
    [formValue],
  );

  const { popInfo, popServerError } = useContext(PopNotificationsContext);

  const { t } = useTranslation();

  const classes = classNames('yx-dropzone-field', className);

  const { change } = useForm();

  const hasError = useMemo(() => touched && error, [error, touched]);

  const fileName = useMemo(
    () => (pFileName ? pFileName : name),
    [name, pFileName],
  );

  const [uploadingFiles, onDropUpload] = useDropzoneField({
    fileName,
    value,
    maxFiles,
    onChange,
    uploadRequest,
  });

  const containerClasses = classNames('yx-dropzone-field__container', {
    'yx-dropzone-field__container--empty':
      !value.length && !uploadingFiles?.length,
  });

  const handleFileDelete = useCallback(
    (dropzoneFile: DropzoneFieldFile) => {
      (async () => {
        if (!deleteRequest)
          throw Error('Upload request is not passed as a prop');

        try {
          await deleteRequest(dropzoneFile, fileName);
          onFileDelete?.();

          const newValue =
            maxFiles === 1
              ? undefined
              : value.filter(
                  (file: DropzoneFieldFile) => file.id !== dropzoneFile.id,
                );

          change(name, newValue);
        } catch (e) {
          popServerError(e);
        }
      })();
    },
    [
      name,
      fileName,
      maxFiles,
      value,
      deleteRequest,
      onFileDelete,
      change,
      popServerError,
    ],
  );

  const renderUploadedFile = useCallback(
    (dropzoneFieldFileProps: DropzoneFieldFile) => {
      return (
        <DisplayDropzoneFile
          key={dropzoneFieldFileProps.key}
          onFileDelete={handleFileDelete}
          {...dropzoneFieldFileProps}
          fileKey={dropzoneFieldFileProps.key}
        />
      );
    },
    [handleFileDelete],
  );

  const validateDrop = (acceptedFiles: File[]) => {
    if (
      value.some((existingFile: DropzoneFieldFile) =>
        acceptedFiles.some(
          (uploadingFile) => uploadingFile.name === existingFile.name,
        ),
      )
    ) {
      popInfo({
        type: 'Error',
        content: t('dropzone-filesAlreadyExist'),
      });
    } else {
      onDropUpload(acceptedFiles);
    }
  };

  const showDropzone =
    !maxFiles || value.length + uploadingFiles.length < maxFiles;

  return (
    <div className={classes}>
      {(label || tooltip) && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      <div className={containerClasses}>
        {value.map(renderUploadedFile)}

        {uploadingFiles.map((file) => (
          <FileUploader key={file.title} {...file} />
        ))}

        {showDropzone && (
          <Dropzone
            className="yx-dropzone-field__container__dropzone"
            files={[]}
            onDropFiles={validateDrop}
            maxFiles={maxFiles}
            {...rest}
          />
        )}
      </div>
      {hasError && <small>{error}</small>}
    </div>
  );
};
export default DropzoneField;
