import httpClient from 'api/httpClient';
import { DropzoneFieldFile } from 'components/Fields/DropzoneField/DropzoneField.component';
import {
  AuthBody,
  TemporaryUserFiles,
  UserRegistrationStep,
} from 'models/Auth';
import { CreditCard, User } from 'models/User';
import credentialsService from 'services/credentialsService';

const usersPath = (routePath: TemplateStringsArray) => `users/${routePath}`;

function getUserById(userId: string) {
  return httpClient.get<User>(`users/${userId}`);
}

function createUser(patchToken: string) {
  return httpClient.post<AuthBody>(usersPath``, { patchToken });
}

function finishRegistration() {
  return httpClient.post(
    `users/temporary/${credentialsService.patchToken}/finish-registration`,
  );
}

function getUserByPatchToken() {
  const { patchToken: token } = credentialsService;
  if (!token) throw Error('No patch token present.');
  return httpClient.get<{ user: Partial<User> }>(`users/temporary/${token}`);
}

function patchUserByStep(step: UserRegistrationStep, data: Partial<User>) {
  const { patchToken: token } = credentialsService;
  if (!token) throw Error('No patch token present.');

  return httpClient
    .patch<{
      user: { updatedTmpUser: User; verificationUrl: string };
    }>(`users/temporary/${token}/step/${step}`, {
      ...data,
    })
    .then((data) => {
      const {
        data: { user },
      } = data;

      if (!credentialsService.verificationUrl && user.verificationUrl) {
        const verificationUrl = `${user.verificationUrl}?time=${Date.now()}`;

        credentialsService.saveVerificationCode(verificationUrl);
      }

      credentialsService.saveUserData(user.updatedTmpUser);
      return data;
    });
}

function sendIndetityVerificationSMS(patchToken: string, phoneNumber: string) {
  return httpClient.post<User>(
    `users/temporary/${patchToken}/send-identity-verification-sms`,
    { phoneNumber },
  );
}

function uploadFileOnTemporaryUser(
  file: File,
  fileName: string,
  config: Record<string, any>,
) {
  const formData = new FormData();

  formData.append('fileName', fileName);
  formData.append('file', file);

  return httpClient.post<{ file: DropzoneFieldFile }>(
    `users/temporary/${credentialsService.patchToken}/file`,
    formData,
    config,
  );
}

function uploadFilesOnTemporaryUser(
  files: Array<File>,
  fileName: TemporaryUserFiles,
  config: Record<string, any>,
) {
  const formData = new FormData();

  formData.append('fileName', fileName);
  files.forEach((file) => formData.append(`file`, file));

  return httpClient.post(
    `users/temporary/${credentialsService.patchToken}/files`,
    formData,
    config,
  );
}

function deleteFileOnTemporaryUser(fileName: string, fileId: string) {
  return httpClient.delete(
    `users/temporary/${credentialsService.patchToken}/files/${fileName}/${fileId}`,
  );
}

function patchUser(userId: string, user: User) {
  return httpClient.patch<{ user: User }>(`users/${userId}`, user);
}

function updateCreditCard(cardValue: CreditCard) {
  return httpClient.patch(
    `users/${credentialsService.patchToken}/update-credit-card`,
    cardValue,
  );
}

function addCreditCard(cardValue: CreditCard) {
  return httpClient.patch(
    `users/${credentialsService.patchToken}/update-credit-card`,
    cardValue,
  );
}

function uploadProfilePicture(image: File, imageFileName: string) {
  const formData = new FormData();

  formData.append('fileName', imageFileName);
  formData.append('file', image);

  return httpClient.post(`users/file`, formData);
}

function deleteProfileImage(userId: string, fileName: string, fileId: string) {
  return httpClient.delete(`users/files/${fileName}/${fileId}`);
}

function getCurrentUser() {
  return httpClient.get(`/users/auth-user`);
}

function removeTmpUser() {
  return httpClient.delete(`/users/temporary/:token/decline`);
}

export default {
  getUserById,
  getUserByPatchToken,
  patchUserByStep,
  createUser,
  removeTmpUser,
  sendIndetityVerificationSMS,
  uploadFileOnTemporaryUser,
  uploadFilesOnTemporaryUser,
  deleteFileOnTemporaryUser,
  patchUser,
  updateCreditCard,
  addCreditCard,
  uploadProfilePicture,
  deleteProfileImage,
  getCurrentUser,
  finishRegistration,
};
