import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { TextArea } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import CloseIcon from 'icons/Close.icon';
import FieldLabel from '../components/FieldLabel';

import './TextAreaField.styles.scss';

type TextAreaProps = ExtractPropTypes<typeof TextArea>;

type TextAreaFieldProps = TextAreaProps &
  FieldRenderProps<string, HTMLElement> & {
    label?: string;
    tooltip?: React.FC;
    className?: string;
  };

const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const {
    label,
    input: { name, value, onChange, ...textAreaRest },
    meta: { touched, error },
    className,
    tooltip,
    ...textareaProps
  } = props;

  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = useMemo(
    () =>
      classNames(
        'yx-textarea-field',
        { 'yx-textarea-field--error': hasError },
        className,
      ),
    [className, hasError],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const deleteFieldValue = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <div className={classes}>
      {label && <FieldLabel tooltip={tooltip}>{label}</FieldLabel>}
      <div className="yx-textarea-field__content">
        {value && (
          <button
            className="yx-textarea-field__delete"
            onClick={deleteFieldValue}
          >
            <CloseIcon />
          </button>
        )}

        <TextArea
          {...textareaProps}
          {...textAreaRest}
          name={name}
          onChange={handleOnChange}
          value={value}
          hasError={hasError}
        />
      </div>
      {hasError && <p>{error}</p>}
    </div>
  );
};

export default TextAreaField;
