import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { useHistory } from 'react-router-dom';
import storageService, { STORAGE_KEYS } from 'services/storageService';

import './CreateSearchSuccess.styles.scss';
import './CreateSearchSuccess.responsive.styles.scss';

type CreateSearchSuccessProps = {
  className?: string;
};

const CreateSearchSuccess: React.FC<CreateSearchSuccessProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-create-search-success', className);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes}>
      <style>
        {`
          .yx-form-content {
            display: flex;
            justify-content: center;
            padding: 42px 0 24px 0;
          }

          .yx-step-progress-v {
            display: none;
          }

          .yx-step-progress-responsive{
            display:none;
          }
          `}
      </style>

      <img src="/assets/images/success-search.png" alt="" />
      <h1>{t('successfullyCreatedSearch')}</h1>
      <h2>{t('waitForOwnersOfMatching')}</h2>
      <Button
        onClick={() => {
          storageService.removeDraftSearch();
          history.push('/my-searches');
        }}
      >
        {t('backToMySearches')}
      </Button>

      <div className="yx-create-search-success__refinement">
        <p>{t('wantToRefineYourSearch')}</p>
        <Button
          styleType="secondary"
          onClick={() =>
            history.push(
              `/create-search-profile/${storageService.getItem<string>(
                STORAGE_KEYS.CREATING_SEARCH_ID,
              )}/advanced-search`,
            )
          }
        >
          {t('advancedSearch')}
        </Button>
      </div>
    </div>
  );
};

export default CreateSearchSuccess;
