import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    {...props}
    width="15"
    height="16"
    viewBox="0 0 15 16"
    stroke="currentColor"
    fill="none"
  >
    <path
      d="M7.5 14.3289C11.0899 14.3289 14 11.3451 14 7.66443C14 3.98377 11.0899 1 7.5 1C3.91015 1 1 3.98377 1 7.66443C1 11.3451 3.91015 14.3289 7.5 14.3289Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 4.99878V10.3303"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.8999 7.66431H10.0999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
