import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './GlowScroll.styles.scss';

type GlowScrollProps = {
  elementId?: string;
  className?: string;
} & Partial<CSSStyleDeclaration>;

const GlowScroll: React.FC<GlowScrollProps> = (props) => {
  const { children, elementId = 'header-content' } = props;

  const isMounted = useRef(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const elementRef = useRef<HTMLElement>(null);

  const classes = classNames(
    { 'yx-glow-scroll--active': isScrollable },
    { 'yx-glow-scroll--inactive': !isScrollable },
  );

  const calculateGlow = useCallback((element: HTMLDivElement) => {
    if (!isMounted.current) return;

    setIsScrollable(element.scrollTop > 0);
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleOnScroll = useCallback(
    (event: Event) => {
      if (!elementRef.current) return;
      const scrollElement = event.target as HTMLDivElement;

      calculateGlow(scrollElement);
    },
    [calculateGlow],
  );

  const handleOnResize = useCallback(
    (event: Event) => {
      calculateGlow(event.target as HTMLDivElement);
    },
    [calculateGlow],
  );

  useEffect(() => {
    const targetElement = document.getElementById(elementId);
    elementRef.current = targetElement;
  }, [elementId]);

  useEffect(() => {
    if (!elementRef.current) return;

    elementRef.current.classList.add(classes);
    elementRef.current.classList.add('yx-glow-scroll');
    return () => {
      elementRef.current.classList.remove(classes);
      elementRef.current.classList.remove('yx-glow-scroll');
    };
  }, [classes]);

  useEffect(() => {
    if (!elementRef.current) return;

    window.addEventListener('resize', handleOnResize);
    window.addEventListener('scroll', handleOnScroll, true);
    return () => {
      window.removeEventListener('scroll', handleOnScroll, true);
      window.removeEventListener('resize', handleOnResize);
    };
  });

  return <>{children}</>;
};

export default GlowScroll;
