import React from 'react';
import classNames from 'classnames';
import ArrowheadIcon from 'icons/Arrowhead.icon';

import './SelectionCard.styles.scss';

export type SelectionCardProps = {
  className?: string;
  title: string;
  description: string;
  icon: React.FC;
  onClick?: () => void;
};

const SelectionCard: React.FC<SelectionCardProps> = (props) => {
  const { className, title, description, icon: Icon, onClick } = props;

  const classes = classNames('yx-selection-card', className);

  return (
    <article className={classes} onClick={onClick}>
      <div className="yx-selection-card__avatar">
        <Icon />
      </div>
      <section className="yx-selection-card__content">
        <p
          role="heading"
          aria-level={1}
          className="yx-selection-card__content__title"
        >
          {title}
        </p>
        <p className="yx-selection-card__content__description">{description}</p>
      </section>
      <ArrowheadIcon />
    </article>
  );
};

export default SelectionCard;
