import React from 'react';
import classNames from 'classnames';

import './FormSection.styles.scss';

type FormSectionProps = {
  className?: string;
  title?: string;
};

const FormSection: React.FC<FormSectionProps> = (props) => {
  const { className, title, children } = props;

  const classes = classNames('yx-form-section', className);

  return (
    <section className={classes}>
      {title && <h2>{title}</h2>}
      <div className="yx-form-section__children">{children}</div>
    </section>
  );
};

export default FormSection;
