import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M6.54297 4.55H14.1263C14.6363 4.55 15.0513 4.13503 15.0513 3.625V1.875C15.0513 1.36497 14.6363 0.95 14.1263 0.95H6.54297C6.03294 0.95 5.61797 1.36497 5.61797 1.875V3.625C5.61797 4.13503 6.03294 4.55 6.54297 4.55ZM6.3013 1.875C6.3013 1.74168 6.4101 1.63333 6.54297 1.63333H14.1263C14.2592 1.63333 14.368 1.74168 14.368 1.875V3.625C14.368 3.75832 14.2592 3.86667 14.1263 3.86667H6.54297C6.4101 3.86667 6.3013 3.75832 6.3013 3.625V1.875Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M0.95 2.75C0.95 3.74245 1.75755 4.55 2.75 4.55C3.74245 4.55 4.55 3.74245 4.55 2.75C4.55 1.75755 3.74245 0.95 2.75 0.95C1.75755 0.95 0.95 1.75755 0.95 2.75ZM1.63333 2.75C1.63333 2.1342 2.1342 1.63333 2.75 1.63333C3.3658 1.63333 3.86667 2.1342 3.86667 2.75C3.86667 3.3658 3.3658 3.86667 2.75 3.86667C2.1342 3.86667 1.63333 3.3658 1.63333 2.75Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M8.8737 6.29935H14.707C14.8956 6.29935 15.0487 6.1463 15.0487 5.95768C15.0487 5.76907 14.8956 5.61602 14.707 5.61602H8.8737C8.68508 5.61602 8.53203 5.76907 8.53203 5.95768C8.53203 6.1463 8.68508 6.29935 8.8737 6.29935Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M8.8737 8.04935H14.707C14.8956 8.04935 15.0487 7.8963 15.0487 7.70768C15.0487 7.51907 14.8956 7.36602 14.707 7.36602H8.8737C8.68508 7.36602 8.53203 7.51907 8.53203 7.70768C8.53203 7.8963 8.68508 8.04935 8.8737 8.04935Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M8.8737 9.79935H14.707C14.8956 9.79935 15.0487 9.6463 15.0487 9.45768C15.0487 9.26907 14.8956 9.11602 14.707 9.11602H8.8737C8.68508 9.11602 8.53203 9.26907 8.53203 9.45768C8.53203 9.6463 8.68508 9.79935 8.8737 9.79935Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M8.8737 11.5493H14.707C14.8956 11.5493 15.0487 11.3963 15.0487 11.2077C15.0487 11.0191 14.8956 10.866 14.707 10.866H8.8737C8.68508 10.866 8.53203 11.0191 8.53203 11.2077C8.53203 11.3963 8.68508 11.5493 8.8737 11.5493Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M8.8737 13.2993H14.707C14.8956 13.2993 15.0487 13.1463 15.0487 12.9577C15.0487 12.7691 14.8956 12.616 14.707 12.616H8.8737C8.68508 12.616 8.53203 12.7691 8.53203 12.9577C8.53203 13.1463 8.68508 13.2993 8.8737 13.2993Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M8.8737 15.0493H14.707C14.8956 15.0493 15.0487 14.8963 15.0487 14.7077C15.0487 14.5191 14.8956 14.366 14.707 14.366H8.8737C8.68508 14.366 8.53203 14.5191 8.53203 14.7077C8.53203 14.8963 8.68508 15.0493 8.8737 15.0493Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M1.875 9.79935H6.54167C7.0517 9.79935 7.46667 9.38438 7.46667 8.87435V6.54102C7.46667 6.03098 7.0517 5.61602 6.54167 5.61602H1.875C1.36497 5.61602 0.95 6.03098 0.95 6.54102V8.87435C0.95 9.38438 1.36497 9.79935 1.875 9.79935ZM1.63333 6.54102C1.63333 6.40769 1.74213 6.29935 1.875 6.29935H6.54167C6.67453 6.29935 6.78333 6.4077 6.78333 6.54102V8.87435C6.78333 9.00767 6.67453 9.11602 6.54167 9.11602H1.875C1.74213 9.11602 1.63333 9.00767 1.63333 8.87435V6.54102Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M1.875 15.0493H6.54167C7.0517 15.0493 7.46667 14.6344 7.46667 14.1243V11.791C7.46667 11.281 7.0517 10.866 6.54167 10.866H1.875C1.36497 10.866 0.95 11.281 0.95 11.791V14.1243C0.95 14.6344 1.36497 15.0493 1.875 15.0493ZM1.63333 11.791C1.63333 11.6577 1.74213 11.5493 1.875 11.5493H6.54167C6.67453 11.5493 6.78333 11.6577 6.78333 11.791V14.1243C6.78333 14.2577 6.67453 14.366 6.54167 14.366H1.875C1.74213 14.366 1.63333 14.2577 1.63333 14.1243V11.791Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
  </svg>
);
