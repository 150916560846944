import { FileType } from 'models/File';
import { OptionalDocumentsKeys, Yacht } from 'models/Yacht';
import utils from 'utils';

export const getUnitValue = (value: string | number) => {
  if (typeof value === 'string')
    return utils.getIntegerFromDottedNumber(value as string);
  else if (typeof value === 'number')
    return utils.getDotedNumber(value.toString());
};

export const getYachOptionalDocuments = (yachtData: Yacht) => {
  const optionalDocuments = {
    shipStationLicence: yachtData.shipStationLicence,
    cargoShipSafetyRadioCertificate: yachtData.cargoShipSafetyRadioCertificate,
    internationalAirPollutionCertificate:
      yachtData.internationalAirPollutionCertificate,
    antiFoulingSystemCertificate: yachtData.antiFoulingSystemCertificate,
    internationalLoadLineCertficate: yachtData.internationalLoadLineCertficate,
    internationalSewagePollutionPreventionCertificate:
      yachtData.internationalSewagePollutionPreventionCertificate,
    statementOfComplianceForLabourConvention:
      yachtData.statementOfComplianceForLabourConvention,
    minimumSafeManningCertificate: yachtData.minimumSafeManningCertificate,
    maltaCertificateOfSurvey: yachtData.maltaCertificateOfSurvey,
    internationalTonnageCertificate: yachtData.internationalTonnageCertificate,
    certificateOfCompliance: yachtData.certificateOfCompliance,
    internationalOilPollutionPreventionCertificate:
      yachtData.internationalOilPollutionPreventionCertificate,
    marpolCompliance: yachtData.marpolCompliance,
    internationalAntiFoulingSystemCertificate:
      yachtData.internationalAntiFoulingSystemCertificate,
  } as Record<OptionalDocumentsKeys, FileType>;

  return Object.keys(optionalDocuments)
    .map((key) => {
      if (optionalDocuments[key as OptionalDocumentsKeys] !== undefined)
        return {
          [key]: optionalDocuments[key as OptionalDocumentsKeys],
        };
      else return null;
    })
    .filter((document) => document !== null);
};
