import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import useInitialValues from '../hooks/useInitialValues';
import { DatepickerProps, DatepickerProviderValues } from '../models';
import DatepickerContext from './Datepicker.context';

const Datepicker: React.FC<DatepickerProps> = (props) => {
  const { children, onChange: onChangeValue, value, ...rest } = props;

  const {
    currentDate,
    setCurrentDate,
    minDate,
    maxDate,
    date,
    showInMenu,
    setShowInMenu,
    yearFrom,
    setYearFrom,
  } = useInitialValues({ ...rest, value });

  const onChange = useCallback(
    (date: DateTime) => {
      onChangeValue(date.toJSDate());
    },
    [onChangeValue],
  );

  const providerValues: DatepickerProviderValues = useMemo(
    () => ({
      currentDate,
      setCurrentDate,
      minDate,
      maxDate,
      onChange,
      date,
      showInMenu,
      setShowInMenu,
      yearFrom,
      setYearFrom,
    }),
    [
      currentDate,
      setCurrentDate,
      minDate,
      maxDate,
      onChange,
      date,
      showInMenu,
      setShowInMenu,
      yearFrom,
      setYearFrom,
    ],
  );

  return (
    <DatepickerContext.Provider value={providerValues}>
      {children}
    </DatepickerContext.Provider>
  );
};

export default Datepicker;
