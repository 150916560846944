import React from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import AddFields from 'components/Fields/AddFields';
import TextAreaField from 'components/Fields/TextAreaField';
import { tenderAndToysOptions } from '../../services/options';

import './TenderAndToysStep.styles.scss';

type TenderAndToysStepProps = {
  className?: string;
};

const TenderAndToysStep: React.FC<TenderAndToysStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-tender-and-toys-step', className);

  const { t } = useTranslation();
  return (
    <StepWrapper
      title={t('tenderAndToys')}
      description={t('tenderToys-info')}
      className={classes}
    >
      <AddFields
        name="tenderAndToysInfo"
        secondField={TextAreaField}
        selectPlaceholder={t('enterType')}
        buttonLabel={t('addNew')}
        selectName="tenderAndToysType"
        options={tenderAndToysOptions}
        tooltipContent={'content'}
      />
    </StepWrapper>
  );
};

export default TenderAndToysStep;
