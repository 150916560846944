import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { Field, FieldRenderProps } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import SelectField from '../SelectField';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import FormRow from 'components/FormRow';
import { useTranslation } from 'react-i18next';
import AddIcon from 'icons/Add.icon';
import CloseIcon from 'icons/Close.icon';
import Tooltip from 'components/Tooltip';
import validators from 'validators';
import FieldLabel from '../components/FieldLabel';
import { FieldValidator } from 'final-form';
import { composeValidators } from 'validators/general';

import './AddFields.styles.scss';

type AddFieldsProps<T extends FieldRenderProps<any, HTMLElement>> = {
  name: string;
  options: Array<OptionValue<string | number>>;
  selectName?: string;
  selectPlaceholder?: string;
  secondField: React.FC<T>;
  secondFieldName?: string;
  initialCount?: number;
  className?: string;
  tooltipContent?: React.ReactNode;
  label?: string;
  tooltip?: React.FC;
  firstRemovable?: boolean;
  buttonLabel?: string;
  selectValidate?: FieldValidator<any>;
} & Omit<T, 'input' | 'meta'>;

function AddFields<T extends FieldRenderProps<any, HTMLElement>>(
  props: AddFieldsProps<T>,
) {
  const { t } = useTranslation();

  const {
    name,
    className,
    selectName = 'title',
    firstRemovable = false,
    selectPlaceholder = t('select'),
    secondField: SecondField,
    secondFieldName = 'description',
    options,
    initialCount = 1,
    tooltipContent,
    label,
    tooltip,
    buttonLabel = 'New',
    selectValidate: pValidate,
    ...rest
  } = props;

  const classes = classNames('yx-add-fields', className);

  const initialValue = useMemo(() => {
    return Array.from({ length: initialCount }).map((_) => {
      return { [selectName]: '', [secondFieldName]: '' };
    });
  }, [initialCount, selectName, secondFieldName]);

  const selectValidation = useMemo(
    () =>
      pValidate
        ? composeValidators(validators.required(t('requiredField')), pValidate)
        : validators.required(t('requiredField')),
    [pValidate, t],
  );

  return (
    <FieldArray name={name} initialValue={initialValue}>
      {({ fields, fields: { push, remove } }) => {
        return (
          <div className={classes}>
            {(label || tooltip) && (
              <FieldLabel tooltip={tooltip}>{label}</FieldLabel>
            )}
            {fields.map((name, ind) => (
              <FormRow key={name}>
                <Field
                  name={`${name}.${selectName}`}
                  component={SelectField}
                  label={t(selectName)}
                  options={options}
                  placeholder={selectPlaceholder}
                  validate={selectValidation}
                />
                <Field
                  name={`${name}.${secondFieldName}`}
                  component={SecondField}
                  label={secondFieldName}
                  placeholder={t('enterDescription')}
                  tooltip={<Tooltip>{tooltipContent}</Tooltip>}
                  validate={validators.required(t('requiredField'))}
                  {...rest}
                />
                {(ind > 0 || firstRemovable) && (
                  <Button
                    className="yx-remove-row"
                    icon={CloseIcon}
                    onClick={() => remove(ind)}
                    styleType="secondary"
                    variant="icon"
                  />
                )}
              </FormRow>
            ))}
            <Button
              className="yx-add-another"
              styleType="primary"
              variant="link"
              onClick={() => push({ [selectName]: '', [secondFieldName]: '' })}
            >
              <span>
                <AddIcon />
              </span>
              {buttonLabel}
            </Button>
          </div>
        );
      }}
    </FieldArray>
  );
}

export default AddFields;
