import redirect from 'router/modules/redirect';
import IdentityVerification from '../../components/CommonSteps/IdentityVerificationStep';
import { RoutingStep } from '../../models/Routes';
import ContactDetails from './pages/ContactDetailsStep/ContactDetailsStep.page';
import ProofOfAddressStep from '../Broker/pages/ProofOfAddressStep';
import ConfidentialityAgreementStep from '../commonSteps/ConfidentialityAgreementStep';
import { UserRegistrationStep } from 'models/Auth';
import AddCreditCardStep from '../commonSteps/AddCreditCardStep';

export const steps: RoutingStep<UserRegistrationStep>[] = [
  {
    path: '/contact-details',
    authorized: false,
    exact: true,
    component: ContactDetails,
    labelId: 'contactDetails',
    stepName: 'Contact details - broker',
  },
  {
    path: '/identity-verification',
    authorized: false,
    exact: true,
    component: IdentityVerification,
    labelId: 'identityVerification',
    stepName: 'Identity verification',
    skipPatch: true,
  },
  {
    path: '/proof-of-address',
    authorized: false,
    exact: true,
    component: ProofOfAddressStep,
    labelId: 'proofOfAddress',
    stepName: 'Proof of address',
    patchMap: ({ proofOfAddress, ...rest }) => rest,
  },
  {
    path: '/add-credit-card',
    authorized: false,
    exact: true,
    component: AddCreditCardStep,
    labelId: 'paymentMethod',
    stepName: 'Payment method',
    skipPatch: true,
  },
  {
    path: '/confidentiality-agreement',
    authorized: false,
    exact: true,
    component: ConfidentialityAgreementStep,
    labelId: 'confidentialityAgreement',
    stepName: 'Confidentiality agreement',
  },
];

export default [
  ...steps,
  {
    path: '',
    exact: true,
    component: redirect('/contact-details'),
  },
] as Array<RoutingStep>;
