import React from 'react';
import StepProvider from 'components/StepProgress/providers/Steps';
import PersonaRouter, { PersonaRouterProps } from './PersonaRouter.component';

function WrappedPersona(props: PersonaRouterProps) {
  return (
    <StepProvider
      storageKeySuffix="persona"
      blockOtherStepsWhenCurrentIsInvalid
      steps={props.steps}
    >
      <PersonaRouter {...props} />
    </StepProvider>
  );
}

export default WrappedPersona;
