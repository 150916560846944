import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import {
  Button,
  Input,
  Modal,
  ImageSlider,
  Toggle,
} from 'ncoded-component-library';
import CheckboxField from 'components/CheckboxField';
import StepWrapper from 'components/StepWrapper';
import SelectField from 'components/Fields/SelectField';
import { countries } from 'services/countries';
import InputField from 'components/Fields/InputField';
import validators from 'validators';
import {
  composeValidators,
  numbersOnlyValidation,
  required,
} from 'validators/general';
import FormWrapper from 'components/FormWrapper';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';
import FileUploader from 'components/FileUploader';
import Dropzone from 'components/Dropzone';
import { useState } from 'react';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import RoleSelection from 'router/subrouters/SignUp/pages/RoleSelection';
import TextAreaField from 'components/Fields/TextAreaField';
import RadioCardField from 'components/Fields/RadioCardField';
import RadioFields from 'components/Fields/RadioFields';
import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import YesNoField from 'components/Fields/YesNoField';
import Tooltip from '../../components/Tooltip';
import FileIcon from 'icons/File.icon';
import AddFields from 'components/Fields/AddFields';
import DropzoneField from 'components/Fields/DropzoneField';
import confirm from 'modules/confirm';
import CheckIcon from 'icons/Check.icon';
import InputUnitField from 'components/Fields/InputUnitField';
import AgreementStep from 'router/subrouters/SignUp/components/CommonSteps/AgreementStep';
import InfoCard from 'components/InfoCard';
import DeleteIcon from 'icons/Delete.icon';
import ImageCarousel, {
  ImageCarouselType,
} from 'components/ImageCarousel/ImageCarousel.component';
import ChoseLocation from 'components/ChoseLocation';
import PercentageValue from 'components/PercentageValue';
import './Catalog.styles.scss';
import Calendar from 'components/Calendar';

type CatalogProps = {
  className?: string;
};

const Catalog: React.FC<CatalogProps> = (props) => {
  const { className } = props;
  const classes = classNames('yx-catalog', className);
  const [files, setFiles] = useState<File[]>([]);

  const { popInfo } = useContext(PopNotificationsContext);

  const handleOnDelete = useCallback(async () => {
    if (
      await confirm({
        title: 'Are you sure you want to delete your yacht?',
        content:
          'All your matches for this yacht will be automatically cancelled. You will not be able to restore it.',
      })
    ) {
      popInfo({
        type: 'Success',
        content: 'Success submit',
      });
    }
  }, [popInfo]);

  const radioFields = useMemo(
    () =>
      [
        {
          validate: required('required'),
          value: 'motor',
          label: 'Motor',
          imagePath: '/assets/images/motor.png',
        },
        {
          validate: required('required'),
          value: 'sailing',
          label: 'Sailing',
          imagePath: '/assets/images/motor.png',
        },
        {
          validate: required('required'),
          value: 'expedition',
          label: 'Expedition',
          imagePath: '/assets/images/expedition.png',
        },
        {
          validate: required('required'),
          value: 'gulet',
          label: 'Gulet',
          imagePath: '/assets/images/gulet.png',
        },
        {
          validate: required('required'),
          value: 'open',
          label: 'Open',
          imagePath: '/assets/images/open.png',
        },
        {
          validate: required('required'),
          value: 'catamaran',
          label: 'Catamaran',
          imagePath: '/assets/images/catamaran.png',
        },
        {
          validate: required('required'),
          value: 'sportsFishing',
          label: 'Sport Fishing',
          imagePath: '/assets/images/sports-fishing.png',
        },
      ] as Array<RadioFieldsType>,
    [],
  );

  const images = useMemo(
    () =>
      [
        {
          url: 'https://images.unsplash.com/photo-1567899378494-47b22a2ae96a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8eWFjaHR8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80',
          type: 'image/jpeg',
        },
        {
          url: 'https://www.imperial-yachts.com/img/cache/enlarge/Flying%20Fox-image-Yevv.jpg',
          type: 'image/jpeg',
        },
        {
          url: 'https://www.elitetraveler.com/wp-content/uploads/2021/03/octopus_00006307_vb1574492_optimized.jpg',
          type: 'image/jpeg',
        },
        {
          url: 'https://www.imperial-yachts.com/img/cache/enlarge/Flying%20Fox-image-Yevv.jpg',
          type: 'image/jpeg',
        },
        {
          url: 'https://www.elitetraveler.com/wp-content/uploads/2021/03/octopus_00006307_vb1574492_optimized.jpg',
          type: 'image/jpeg',
        },
        {
          url: 'https://www.imperial-yachts.com/img/cache/enlarge/Flying%20Fox-image-Yevv.jpg',
          type: 'image/jpeg',
        },
        {
          url: 'https://images.unsplash.com/photo-1567899378494-47b22a2ae96a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8eWFjaHR8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80',
          type: 'image/jpeg',
        },
        {
          url: 'https://www.imperial-yachts.com/img/cache/enlarge/Flying%20Fox-image-Yevv.jpg',
          type: 'image/jpeg',
        },
        {
          url: 'https://images.unsplash.com/photo-1567899378494-47b22a2ae96a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8eWFjaHR8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80',
          type: 'image/jpeg',
        },
        {
          url: 'https://www.imperial-yachts.com/img/cache/enlarge/Flying%20Fox-image-Yevv.jpg',
          type: 'image/jpeg',
        },
      ] as Array<ImageCarouselType>,
    [],
  );

  const [modal, setModal] = useState<boolean>(false);

  const [date, setDate] = useState<Date[]>([]);

  return (
    <div>
      {/* <div>
        <h1>Yacht Preview</h1>
        <YachtPreview yachtId="61484c1291247c4ec9ec7291" />
      </div> */}
      <div className={classes}>
        {/* <ShowAppointment
          date={new Date()}
          place="Brooklyn+Bridge,New+York,NY"
          location="leskovac"
          contact="api"
          contactDescription="radnicko trxrx"
        /> */}
        <ChoseLocation className="margin-bot" />

        <Calendar onlyFuture value={date} onChange={setDate} />
        <PercentageValue value={87} className="margin-bot" />
        {/* <MatchCard
          className="margin-bot"
          status="New"
          score={87}
          onButtonClick={() => {}}
        /> */}
        <AgreementStep
          onAccept={() => console.log('accept')}
          onDecline={() => console.log('decline')}
        />
        <div>
          <h3>Text Styles</h3>
          <h1>H1</h1>
          <h2>H2</h2>
          <h3>H3</h3>
          <p>P</p>
        </div>

        <div>
          <h3>Select Component</h3>
          <Form
            onSubmit={() => {}}
            render={() => (
              <>
                <Field
                  name="email"
                  label="Enter your email"
                  component={InputField}
                />
                <Field
                  name="country"
                  label="Select country"
                  component={SelectField}
                  options={countries}
                />
              </>
            )}
          />
        </div>

        <Form
          onSubmit={(data) => console.log(data)}
          initialValues={{ country: 'RS' }}
        >
          {() => (
            <StepWrapper title="Form Wrapper">
              <Field
                name="password"
                label="Password"
                tooltip={<Tooltip>Testing tooltip component</Tooltip>}
                component={InputField}
                suffixNode={<FileIcon />}
                optional
                validate={composeValidators(
                  validators.mustContainerSpecialChar(
                    'must container spec char',
                  ),
                  validators.minLength('min 6', 6),
                  validators.maxLength('max 20', 20),
                  validators.mustContainerNumber('must container number'),
                )}
              />
              <Field
                name="legalPerson"
                label="Legal Person"
                component={CheckboxField}
              />
              <Field
                name="country"
                options={countries}
                component={SelectField}
                validate={required('required field')}
              />
            </StepWrapper>
          )}
        </Form>

        <Form
          onSubmit={(data: any) => console.log(data)}
          initialValues={{ country: 'RS' }}
        >
          {() => (
            <StepWrapper title="Form Wrapper">
              <Field
                name="password"
                label="Password"
                tooltip={<Tooltip>Testing tooltip component</Tooltip>}
                component={InputField}
                suffixNode={<FileIcon />}
                optional
                validate={composeValidators(
                  validators.mustContainerSpecialChar(
                    'must container spec char',
                  ),
                  validators.minLength('min 6', 6),
                  validators.maxLength('max 20', 20),
                  validators.mustContainerNumber('must container number'),
                )}
              />
              <Field
                name="legalPerson"
                label="Legal Person"
                component={CheckboxField}
              />
              <Field
                name="country"
                options={countries}
                component={SelectField}
                validate={required('required field')}
              />
              <Field
                name="textArea"
                label="label"
                placeholder="Start typing.."
                component={TextAreaField}
                validate={required('required field')}
              />
              <Field
                name="kw"
                component={InputField}
                unitType="kW"
                validate={composeValidators(
                  numbersOnlyValidation('not valid unit value'),
                  required('required'),
                )}
              />
              <Field
                name="meters"
                component={InputField}
                unitType="meters"
                validate={composeValidators(
                  numbersOnlyValidation('not valid unit value'),
                  required('required'),
                )}
              />
              <Field
                name="length"
                measure="length"
                component={InputUnitField}
                placeholder="Input Length"
                validate={composeValidators(
                  required('required filed'),
                  numbersOnlyValidation('not valid unit value'),
                )}
              />
              <Field name="mass" component={InputUnitField} measure="mass" />
            </StepWrapper>
          )}
        </Form>

        <RoleSelection />
        <Form
          onSubmit={() => {}}
          render={() => (
            <FormWrapper>
              <Field name="fild" component={InputField} optional />
            </FormWrapper>
          )}
        ></Form>

        <FileUploader title="title" size={350000} percentage={78} />

        <Dropzone files={files} onFilesChange={(val: any) => setFiles(val)} />

        <div className="yx-catalog__notifications">
          <h3>Pop Notifications</h3>
          <Button
            onClick={() => popInfo({ content: 'Error Message', type: 'Error' })}
          >
            Error
          </Button>
          <Button
            onClick={() =>
              popInfo({ content: 'Success Message', type: 'Success' })
            }
          >
            Success
          </Button>
          <Button
            onClick={() =>
              popInfo({ content: 'Success Message', type: 'Info' })
            }
          >
            Info
          </Button>
        </div>

        <ImageSlider
          showDots={false}
          slides={[
            { imageUrl: '/assets/images/profile-shot-1.jpeg' },
            { imageUrl: '/assets/images/profile-shot-2.jpeg' },
            { imageUrl: '/assets/images/profile-shot-3.jpeg' },
          ]}
        />

        <div>
          <h3>Confirm modal</h3>
          <Button
            icon={CheckIcon}
            className="yx-catalog__delete"
            onClick={handleOnDelete}
          />
        </div>

        <Tooltip title="How to make appropriate profile shots">
          <img
            alt="zhemska"
            src="https://images.unsplash.com/photo-1629239680936-e9b646847a49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
          />
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit.
        </Tooltip>

        <div>
          <h3>Radio Card</h3>

          <Form
            onSubmit={(values: any) => console.log(values)}
            initialValues={{ boat: 'sailing', hasGenerator: true }}
            mutators={{ ...arrayMutators }}
            render={() => (
              <StepWrapper title="Form Wrapper">
                <Field
                  name="textArea"
                  label="label"
                  placeholder="Start typing.."
                  component={TextAreaField}
                  validate={required('required field')}
                />
                <Field
                  name="kw"
                  component={InputField}
                  unitType="kW"
                  validate={composeValidators(
                    numbersOnlyValidation('not valid unit value'),
                    required('required'),
                  )}
                />
                <Field
                  name="meters"
                  component={InputField}
                  unitType="meters"
                  validate={composeValidators(
                    numbersOnlyValidation('not valid unit value'),
                    required('required'),
                  )}
                />
                <Field
                  name="length"
                  measure="length"
                  component={InputUnitField}
                  placeholder="Input Length"
                  validate={composeValidators(
                    required('required filed'),
                    numbersOnlyValidation('not valid unit value'),
                  )}
                />
                <Field name="mass" component={InputUnitField} measure="mass" />
              </StepWrapper>
            )}
          />
        </div>

        <RoleSelection />

        <Form
          onSubmit={() => {}}
          render={() => (
            <FormWrapper>
              <Field name="fild" component={InputField} optional />
            </FormWrapper>
          )}
        ></Form>
        <FileUploader title="title" size={350000} percentage={78} />

        <Dropzone files={files} onFilesChange={(val: any) => setFiles(val)} />

        <div className="yx-catalog__notifications">
          <h3>Pop Notifications</h3>
          <Button
            onClick={() => popInfo({ content: 'Error Message', type: 'Error' })}
          >
            Error
          </Button>
          <Button
            onClick={() =>
              popInfo({ content: 'Success Message', type: 'Success' })
            }
          >
            Success
          </Button>
          <Button
            onClick={() =>
              popInfo({ content: 'Success Message', type: 'Info' })
            }
          >
            Info
          </Button>
        </div>

        <Tooltip title="How to make appropriate profile shots">
          <img
            alt="zhemska"
            src="https://images.unsplash.com/photo-1629239680936-e9b646847a49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
          />
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit.
        </Tooltip>

        <div>
          <h3>Radio Card</h3>

          <Form
            onSubmit={(values) => console.log(values)}
            initialValues={{ boat: 'sailing', hasGenerator: true }}
            mutators={{ ...arrayMutators }}
            render={() => (
              <StepWrapper title="Form Wrapper">
                <Field
                  name="boat"
                  type="radio"
                  validate={required('required')}
                  label="Big Yacht"
                  value="bigYacht"
                  component={RadioCardField}
                  optional
                />
                <RadioFields
                  radioFields={radioFields}
                  name="boat"
                  renderAsGrid
                />
                <Field
                  name="hasGenerator"
                  component={YesNoField}
                  label="power"
                />
                <AddFields
                  name="toys"
                  secondField={TextAreaField}
                  selectName="year"
                  options={[]}
                />
                <Field
                  name="proofOfAddress"
                  maxFiles={3}
                  component={DropzoneField}
                />
              </StepWrapper>
            )}
          ></Form>
        </div>

        <Tooltip>
          <img
            alt="zhemska"
            src="https://images.unsplash.com/photo-1629239680936-e9b646847a49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
          />
          dasdsasdasd
        </Tooltip>

        <div>
          <h3>Modal Component</h3>
          <Button onClick={() => setModal(true)}>Open Modal</Button>

          {modal && (
            <Modal
              footer={
                <>
                  <Button styleType="secondary">Cancel</Button>
                  <Button>Submit</Button>
                </>
              }
              open
              onClose={() => setModal(false)}
            >
              <h1>Auth Modal</h1>
              <Input placeholder="Email" />
              <Input placeholder="Password" />
            </Modal>
          )}
        </div>

        <div>
          <h3>Confirm modal</h3>
          <Button
            icon={CheckIcon}
            className="yx-catalog__delete"
            onClick={handleOnDelete}
          />
        </div>

        <ImageSlider
          showDots={false}
          slides={[
            { imageUrl: '/assets/images/profile-shot-1.jpeg' },
            { imageUrl: '/assets/images/profile-shot-2.jpeg' },
            { imageUrl: '/assets/images/profile-shot-3.jpeg' },
          ]}
        />
        <div>
          <h3>Confirm modal</h3>
          <Button
            icon={CheckIcon}
            className="yx-catalog__delete"
            onClick={handleOnDelete}
          />
        </div>

        <div>
          <h3>Info Card</h3>
          <InfoCard
            infoData={{ icon: DeleteIcon, title: 'beam', content: '140' }}
          />
        </div>

        <div>
          <h3>Image Preview</h3>
          <ImageCarousel title="Blue Marine" elements={images} />
        </div>

        <div>
          <h3>Toggle</h3>
          <Toggle />
        </div>

        <Tooltip title="How to make appropriate profile shots">
          <img
            alt="zhemska"
            src="https://images.unsplash.com/photo-1629239680936-e9b646847a49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
          />
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit.
        </Tooltip>

        <div>
          <h3>Radio Card</h3>

          <Form
            onSubmit={(values) => console.log(values)}
            initialValues={{ boat: 'sailing', hasGenerator: true }}
            mutators={{ ...arrayMutators }}
            render={() => (
              <StepWrapper title="Form Wrapper">
                <Field
                  name="boat"
                  type="radio"
                  validate={required('required')}
                  label="Big Yacht"
                  value="bigYacht"
                  component={RadioCardField}
                  optional
                />
                <RadioFields
                  radioFields={radioFields}
                  name="boat"
                  renderAsGrid
                />
                <Field
                  name="hasGenerator"
                  component={YesNoField}
                  label="power"
                />
                <AddFields
                  name="toys"
                  secondField={TextAreaField}
                  selectName="year"
                  options={[]}
                />
                <Field
                  name="proofOfAddress"
                  maxFiles={3}
                  component={DropzoneField}
                />
              </StepWrapper>
            )}
          ></Form>
        </div>

        <Tooltip>
          <img
            alt="zhemska"
            src="https://images.unsplash.com/photo-1629239680936-e9b646847a49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
          />
          dasdsasdasd
        </Tooltip>

        <div>
          <h3>Modal Component</h3>
          <Button onClick={() => setModal(true)}>Open Modal</Button>

          {modal && (
            <Modal
              footer={
                <>
                  <Button styleType="secondary">Cancel</Button>
                  <Button>Submit</Button>
                </>
              }
              open
              onClose={() => setModal(false)}
            >
              <h1>Auth Modal</h1>
              <Input placeholder="Email" />
              <Input placeholder="Password" />
            </Modal>
          )}
        </div>

        <div>
          <h3>Confirm modal</h3>
          <Button
            icon={CheckIcon}
            className="yx-catalog__delete"
            onClick={handleOnDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default Catalog;
