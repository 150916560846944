import React from 'react';
import classNames from 'classnames';

import './HistoryList.styles.scss';
import { useTranslation } from 'react-i18next';

export type HistoryItem<T extends string = string> = {
  label: string;
  description?: T;
};

type HistoryListProps = {
  className?: string;
  items: Array<HistoryItem>;
};

const HistoryList: React.FC<HistoryListProps> = (props) => {
  const { className, items } = props;

  const classes = classNames('yx-history-list', className);
  const { t } = useTranslation();

  return (
    <div className={classes}>
      {items?.map((item, index) => (
        <div className="yx-history-list__container" key={index}>
          <p>{item.label}</p>
          <span className="yx-history-list__circle" />

          <div className="yx-history-list__content">
            {item.description}
            {index < items?.length - 1 && (
              <span className="yx-history-list__spacer" />
            )}
          </div>
        </div>
      ))}
      {!items?.length && <p className="empty-text">{t('historyNotDefined')}</p>}
    </div>
  );
};

export default HistoryList;
