import React from 'react';
import classNames from 'classnames';
import './EmptyState.styles.scss';
import util from '../../utils';
import { Button } from 'ncoded-component-library';
import AddIcon from '../../icons/Add.icon';

type EmptyStateProps = {
  className?: string;
  icon: React.ReactNode;
  label: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { className, icon, label, buttonText, onButtonClick } = props;

  const classes = classNames('yx-empty-state', className);

  return (
    <div className={classes}>
      <div className="yx-empty-state__icon-container">
        {util.renderIcon(icon)}
      </div>
      <label>{label}</label>
      {onButtonClick && (
        <Button icon={AddIcon} onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
