import React from 'react';
import classNames from 'classnames';
import TemporaryHeader from './components/TemporaryHeader';
import { useTranslation } from 'react-i18next';
import ExclamationMarkIcon from 'icons/ExclamationMark.icon';
import WaitScreen from 'router/subrouters/Dashboard/subrouters/Match/components/WaitScreen';

import './TemporaryUser.styles.scss';
import './TemporaryUser.responsive.styles.scss';

type TemporaryUserProps = {
  className?: string;
};

const TemporaryUser: React.FC<TemporaryUserProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-temporary-user', className);
  const { t } = useTranslation();

  return (
    <div className={classes}>
      <TemporaryHeader />
      <div className="yx-temporary-user__content">
        <ExclamationMarkIcon />
        <p className="yx-temporary-user__title">{t('notVerifiedYet')}</p>
      </div>
      <div className="yx-temporary-user__container">
        <WaitScreen />
        <h1>{t('pleaseWaitUntilWeVerifyYourAccount')}</h1>
      </div>
    </div>
  );
};

export default TemporaryUser;
