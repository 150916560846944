import { PaginationData } from 'models/PaginatedData';
import utils from 'utils';
import httpClient from '../httpClient';

function getMatch(
  id: string,
  { $relations, ...restParams }: PaginationData = {},
) {
  const relations = utils.getRelationsFromPaginatedData($relations);

  return httpClient.get(`matches/${id}?${relations}`, { params: restParams });
}

function getSearchMatches(
  searchId: string,
  { $relations, ...restParams }: PaginationData = {},
) {
  const relations = utils.getRelationsFromPaginatedData($relations);

  return httpClient.get(`matches/search/${searchId}?${relations}`, {
    params: restParams,
  });
}

function getYachtMatches(
  yachtId: string,
  { $relations, ...restParams }: PaginationData = {},
) {
  const relations = utils.getRelationsFromPaginatedData($relations);

  return httpClient.get(`matches/yacht/${yachtId}?${relations}`, {
    params: restParams,
  });
}

function getUserMatches(params: PaginationData) {
  const { $relations, ...restParams } = params;
  const relations = utils.getRelationsFromPaginatedData($relations);
  return httpClient.get(`matches?${relations}`, { params: restParams });
}

function viewingRequest(matchId: string) {
  return httpClient.post(`/matches/${matchId}/viewing-request`);
}

function viewingAccept(
  matchId: string,
  values: {
    timeSlotOptions: { from: Date; to: Date }[];
    location: string;
    locationCoordinates: { latitude: number; longitude: number };
  },
) {
  return httpClient.post(`matches/${matchId}/viewing-accept`, values);
}

function chooseMeetingDate(matchId: string, meetingDate: Date) {
  return httpClient.post(`matches/${matchId}/choose-viewing-date`, {
    meetingDate,
  });
}

function sendCalendarInvitation(matchId: string, emails: string[]) {
  return httpClient.post(`matches/${matchId}/send-calendar-invitation`, {
    emails,
  });
}
function postoneMatchRequest(matchId: string) {
  return httpClient.post(`matches/${matchId}/request-for-postponoment`);
}

function postoneMatchAccept(matchId: string) {
  return httpClient.post(`matches/${matchId}/accept-request-for-postponoment`);
}

function cancelMatch(
  matchId: string,
  cancellationReasons: {
    cancellationReason: string;
    otherCancellationReason?: string;
  },
) {
  return httpClient.post(`matches/${matchId}/cancel`, cancellationReasons);
}

export default {
  getMatch,
  getSearchMatches,
  getYachtMatches,
  getUserMatches,
  cancelMatch,
  chooseMeetingDate,
  viewingAccept,
  viewingRequest,
  postoneMatchRequest,
  postoneMatchAccept,
  sendCalendarInvitation,
};
