import React, { useMemo } from 'react';
import classNames from 'classnames';
import './MatchCard.styles.scss';
import './MatchCard.responsive.styles.scss';
import { useTranslation } from 'react-i18next';
import PercentageValue from '../PercentageValue';
import { Button } from 'ncoded-component-library';
import {
  MatchesRoutes,
  MatchStatus,
  MATCH_TYPE,
} from '../../router/subrouters/Dashboard/subrouters/Match/models';
import { RoleType, ROLE_TYPE, STATUS_LABELS, STATUS_STYLES } from './utils';

type MatchCardProps = {
  className?: string;
  score: number;
  status: MatchStatus;
  onButtonClick: () => void;
  role: MatchesRoutes;
  userType: RoleType;
  location: String;
};

const MatchCard: React.FC<MatchCardProps> = (props) => {
  const { className, score, status, onButtonClick, role, userType, location } =
    props;
  const { t } = useTranslation();

  const label = useMemo(
    () =>
      `${role === MATCH_TYPE.BUYER ? t('buyer') : t('seller')} ${t('profile')}`,
    [role, t],
  );

  const description = useMemo(
    () =>
      `${
        userType === ROLE_TYPE.PRIVATE_PERSON ? t('privateBuyer') : t('broker')
      } ${t('from')} ${location}`,
    [location, t, userType],
  );

  const classes = classNames('yx-match-card', className);
  const statusClasses = classNames(
    'yx-match-card__status',
    `yx-match-card__status--${STATUS_STYLES[status]}`,
  );

  return (
    <div className={classes}>
      <div className="yx-match-card__content">
        <section className="yx-match-card__column">
          <label>{label}</label>
          <span className="yx-match-card__description">{description}</span>
        </section>
        <section className="yx-match-card__column">
          <label>{t('status')}</label>
          <span className={statusClasses}>{STATUS_LABELS[status]}</span>
        </section>
        <section className="yx-match-card__row">
          <label>{t('matchingScore')}</label>
          <PercentageValue
            value={score}
            className="yx-match-card__match-score"
          />
        </section>
      </div>
      <Button styleType="secondary" onClick={onButtonClick}>
        {t('view')}
      </Button>
    </div>
  );
};

export default MatchCard;
