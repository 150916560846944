import { OptionValue } from "ncoded-component-library/build/components/molecules/Select/Select.component";


export const methodOptions: Array<OptionValue<string>> = [
    {
        label: "Utility bill",
        value: "Utility bill",
    },
    {
        label: "Commercial rental lease agreement",
        value: "Commercial rental lease agreement",
    },
    {
        label: "Mortgage statement",
        value: "Mortgage statement",
    },
    {
        label: "A current business license",
        value: "A current business license",
    },
    {
        label: "Articles of Incorporation",
        value: "Articles of Incorporation",
    },
    {
        label: "Business tax return",
        value: "Business tax return",
    },
]