import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import RadioFields from 'components/Fields/RadioFields';
import { yachtTypeFields } from './radioFields';
import { Button } from 'ncoded-component-library';
import { useHistory } from 'react-router-dom';

import './TypeOfVessel.styles.scss';
import useIsEditRoute from 'hooks/useIsEditRoute';
import storageService, { STORAGE_KEYS } from 'services/storageService';

type TypeOfVesselProps = {
  className?: string;
};

const TypeOfVessel: React.FC<TypeOfVesselProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-tov', className);
  const { t } = useTranslation();
  const history = useHistory();

  const isEdit = useIsEditRoute();

  const storageKey = useMemo(
    () =>
      isEdit ? STORAGE_KEYS.EDITING_SEARCH_ID : STORAGE_KEYS.CREATING_SEARCH_ID,
    [isEdit],
  );

  return (
    <StepWrapper title={t('typeOfVesselPrefer')} className={classes}>
      <RadioFields
        className="yacht-type"
        radioFields={yachtTypeFields}
        name="yachtType"
        renderAsGrid
      />

      <div className="is-expert">
        <p>{t('isExpert')}</p>
        <Button
          styleType="secondary"
          onClick={() =>
            history.push(
              `/${
                isEdit ? 'edit' : 'create'
              }-search-profile/${storageService.getItem(
                storageKey,
              )}/advanced-search`,
            )
          }
        >
          {t('advancedSearch')}
        </Button>
      </div>
    </StepWrapper>
  );
};

export default TypeOfVessel;
