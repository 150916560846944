import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import RadioFields from 'components/Fields/RadioFields';
import { speedTypeFields } from './radioFields';
import { Button } from 'ncoded-component-library';
import { useHistory } from 'react-router-dom';
import storageService, { STORAGE_KEYS } from 'services/storageService';

import './SpeedStep.styles.scss';
import './SpeedStep.responsive.styles.scss';

type SpeedStepProps = {
  className?: string;
};

const SpeedStep: React.FC<SpeedStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-speed-step', className);

  const { t } = useTranslation();
  const history = useHistory();
  const [nextSubmitted, setNextSubmitted] = useState<boolean>(false);

  const mandatoryQueestionsAnswered = useMemo(
    () => (
      <StepWrapper
        key="speed1"
        onSubmit={() => setNextSubmitted(true)}
        className="yx-speed-step__success"
      >
        <img src="/assets/images/success-search.png" alt="search" />
        <h1>{t('youAnsweredAllQuestions')}</h1>
        <p>{t('ifYouFeelLikeYouHaveSaidAll')}</p>
        <Button
          styleType="secondary"
          type="button"
          onClick={() =>
            history.push(
              `/create-search-profile/${storageService.getItem<string>(
                STORAGE_KEYS.CREATING_SEARCH_ID,
              )}/preview`,
            )
          }
        >
          {t('finish')}
        </Button>
        <p>{t('IfYouWouldLikeToSpecifyMore')}</p>
      </StepWrapper>
    ),
    [t, history],
  );

  const stepWrapper = useMemo(
    () => (
      <StepWrapper
        key="speed2"
        title={t('whichOneDoYouPrefer')}
        description={t('guestCapacity-info')}
        className={classes}
      >
        <RadioFields
          className="yx-speed-step__fields"
          radioFields={speedTypeFields}
          name="speedType"
        />
      </StepWrapper>
    ),
    [classes, t],
  );

  return nextSubmitted ? stepWrapper : mandatoryQueestionsAnswered;
};

export default SpeedStep;
