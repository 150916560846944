import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="transparent"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" />
    <path d="M5.6084 5.55047C5.76122 5.11605 6.06285 4.74974 6.45987 4.5164C6.85689 4.28307 7.32368 4.19778 7.77756 4.27563C8.23145 4.35348 8.64313 4.58946 8.9397 4.94176C9.23627 5.29406 9.39858 5.73996 9.3979 6.20047C9.3979 7.50047 7.4479 8.15047 7.4479 8.15047" />
    <path d="M7.5 10.75H7.507" />
  </svg>
);
