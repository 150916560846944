import httpClient from 'api/httpClient';
import publicHttpClient from 'api/publicHttpClient';
import { AxiosRequestConfig } from 'axios';
import { DropzoneFieldFile } from 'components/Fields/DropzoneField/DropzoneField.component';
import { PaginatedData, PaginationData } from 'models/PaginatedData';
import { Yacht, YachtStatus } from 'models/Yacht';
import utils from 'utils';

const yachtPath = (routePath: TemplateStringsArray) => `yachts/${routePath}`;

function createInitialYacht() {
  return httpClient.post<{ yacht: Partial<Yacht> }>(yachtPath``);
}

function uploadYachtFile(
  file: File,
  fileName: string,
  yachtId: string,
  config: AxiosRequestConfig,
) {
  const formData = new FormData();

  formData.append('fileName', fileName);
  formData.append('file', file);

  return httpClient.post<{ file: DropzoneFieldFile }>(
    `yachts/${yachtId}/file`,
    formData,
    config,
  );
}

function deleteYachtFile(yachtId: string, fileName: string, fileId: string) {
  return httpClient.delete(`yachts/${yachtId}/files/${fileName}/${fileId}`);
}

function uploadYachtFiles(
  file: File,
  fileName: string,
  yachtId: string,
  config: AxiosRequestConfig,
) {
  const formData = new FormData();

  formData.append('fileName', fileName);
  formData.append('file', file);

  return httpClient.post<{ file: DropzoneFieldFile }>(
    `yachts/${yachtId}/files`,
    formData,
    config,
  );
}

function patchYachtData(yachtId: string, step: string, data: Partial<Yacht>) {
  return httpClient.post(`yachts/${step}/yacht/${yachtId}`, data);
}

function publishYacht(yachtId: string, data: Partial<Yacht>) {
  return httpClient.post<{ yacht: Yacht }>(`yachts/${yachtId}/publish`, data);
}

function unpublishYacht(yachtId: string) {
  return httpClient.post(`yachts/${yachtId}/unpublish`);
}

function editYacht(yachtId: string, data: Partial<Yacht>) {
  return httpClient.post(`yachts/${yachtId}/edit`, data);
}

function deleteYacht(yachtId: string) {
  return httpClient.delete(`yachts/${yachtId}`);
}

function getYachtById(yachtId: string, token?: string) {
  return (token ? publicHttpClient : httpClient).get<{ yacht: Yacht }>(
    `yachts/${yachtId}`,
    {
      headers: { Authorization: token && `RBearer ${token}` },
    },
  );
}

function getYachts(
  params: PaginationData & { status: YachtStatus | YachtStatus[] },
) {
  const { status, ...restParams } = params;

  const $status = utils.getCompositeParamFromPaginatedData('status', status);
  return httpClient.get<PaginatedData<Yacht>>(`yachts?${$status}`, {
    params: restParams,
  });
}

export default {
  createInitialYacht,
  uploadYachtFile,
  deleteYachtFile,
  uploadYachtFiles,
  patchYachtData,
  publishYacht,
  unpublishYacht,
  editYacht,
  deleteYacht,
  getYachtById,
  getYachts,
};
