import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="transparent"
    stroke="currentColor"
    strokeWidth="1.2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M1 3.2229H2.11111H11" />
    <path d="M3.77751 3.22222V2.11111C3.77751 1.81643 3.89457 1.53381 4.10294 1.32544C4.31132 1.11706 4.59393 1 4.88862 1H7.11084C7.40552 1 7.68814 1.11706 7.89651 1.32544C8.10489 1.53381 8.22195 1.81643 8.22195 2.11111V3.22222M9.88862 3.22222V11C9.88862 11.2947 9.77155 11.5773 9.56318 11.7857C9.35481 11.994 9.07219 12.1111 8.77751 12.1111H3.22195C2.92727 12.1111 2.64465 11.994 2.43628 11.7857C2.2279 11.5773 2.11084 11.2947 2.11084 11V3.22222H9.88862Z" />
    <path d="M4.88916 6V9.33333" />
    <path d="M7.11084 6V9.33333" />
  </svg>
);
