import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    strokeWidth="2"
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <line x1="0" y1="0" x2="16" y2="16" />
    <line x1="0" y1="16" x2="16" y2="0" />
  </svg>
);
