import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import routes from 'router/routes';
import Routes from 'router/components/Routes/Routes';
import PopNotificationsProvider from 'providers/PopNotifications';
import 'flag-icon-css/css/flag-icon.min.css';
import GeneralProvider from 'providers/General';
import './index.scss';

function App() {
  return (
    <BrowserRouter>
      <PopNotificationsProvider>
        <GeneralProvider>
          <Routes routes={routes} />
        </GeneralProvider>
      </PopNotificationsProvider>
    </BrowserRouter>
  );
}

export default App;
