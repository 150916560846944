import React from 'react';
import DashboardRouter from './Dashboard.router';
import PageProvider from './providers/Page';

const WrappedDRouter = (props: any) => {
  return (
    <PageProvider>
      <DashboardRouter {...props} />
    </PageProvider>
  );
};

export default WrappedDRouter;
