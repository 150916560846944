import { AddressType, User } from './User';

export type AuthBody = {
  token: string;
  refreshToken: string;
  user: User;
};

export const TEMPORARY_USER_FILES = {
  PROOF_OF_ADDRESS: 'proofOfAddress',
  PROOF_OF_PRESENTATION: 'proofOfPresentation',
} as const;

export type TemporaryUserFiles =
  typeof TEMPORARY_USER_FILES[keyof typeof TEMPORARY_USER_FILES];

export const BROKER_REGISTRATION_STEPS = [
  'Contact details - broker',
  'Identity verification',
  'Confidentiality agreement',
] as const;

export const USER_REGISTRATION_STEPS = {
  USER_TYPE: 'User type',
  CONTACT_DETAILS_PRIVATE: 'Contact details - private',
  CONTACT_DETAILS_BROKER: 'Contact details - broker',
  IDENTITY_VERIFICATION: 'Identity verification',
  PROOF_OF_ADDRESS: 'Proof of address',
  CONFIDENTIALITY_AGREEMENT: 'Confidentiality agreement',
  ADD_CREDIT_CARD: 'Payment method',
} as const;

export type UserRegistrationStep =
  typeof USER_REGISTRATION_STEPS[keyof typeof USER_REGISTRATION_STEPS];

export type LegalPerson = {
  firstName: string;
  lastName: string;
  company: string;
  address: AddressType;
  proofOfPresentationKey: string;
  proofOfPresentationFile?: File;
};

export type ContactDetailsPrivateData = {
  firstName: string;
  lastName: string;
  company?: string;
  address: AddressType;
  isRepresentingLegalPerson: boolean;
  legalPersonInfo: LegalPerson;
  phoneNumber: string;
  proofOfPresentation: File;
};

export type SignupFormType = {
  email: string;
  password: string;
  confirmPassword: string;
};
