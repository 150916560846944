import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import InputField from 'components/Fields/InputField';
import { Yacht } from 'models/Yacht';
import { Field, useFormState } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import YesNoField from 'components/Fields/YesNoField';
import { composeValidators } from 'validators/general';
import validators from 'validators';
import {
  electricityOptions,
  stabilizationOptions,
} from '../../services/options';
import SelectField from 'components/Fields/SelectField';

import './AuxiliaryEquipmentStep.styles.scss';

type AuxiliaryEquipmentStepProps = {
  className?: string;
};

const AuxiliaryEquipmentStep: React.FC<AuxiliaryEquipmentStepProps> = (
  props,
) => {
  const { className } = props;

  const classes = classNames('yx-auxiliary-equipment-step', className);

  const { t } = useTranslation();

  const {
    values: { hasAirCondition, hasGenerator, hasWaterMarks },
  } = useFormState<Yacht>();

  const validatorsObject = useMemo(
    () => ({
      numberOfGenerators: composeValidators(
        validators.required(t('auxiliary-numberOfGenerators-validator')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
        validators.maxNumber(t('maxNum', { maxNum: 10 }), 10),
      ),
      brand: validators.required(t('auxiliary-brand-validator')),
      model: validators.required(t('auxiliary-model-validator')),
      hours: validators.required(t('auxiliary-hours-validator')),
      capacity: composeValidators(
        validators.required(t('auxiliary-capacity-validator')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
      ),
      airConditionCapacity: composeValidators(
        validators.required(t('auxiliary-capacity-validator')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
        validators.maxNumber(t('maxNum', { maxNum: 10 }), 10),
      ),
      electricity: validators.required(t('auxiliary-electricity-validator')),
      numberOfWaterMakers: composeValidators(
        validators.required(t('auxiliary-amountOfWaterMarks-validator')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
        validators.maxNumber(t('maxNum', { maxNum: 10 }), 10),
      ),
      numberOfLiters: composeValidators(
        validators.required(t('auxiliary-liters-validator')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
      ),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('auxiliaryEquipment')}
      description={t('auxiliary-info')}
      className={classes}
    >
      <Field
        component={YesNoField}
        name="hasGenerator"
        label={t('auxiliary-generator')}
      />
      {hasGenerator && (
        <>
          <Field
            type="number"
            name="generatorInfo.numberOfGenerators"
            component={InputField}
            label={t('auxiliary-numberOfGenerators')}
            placeholder={t('enterNumberGenerator')}
            validate={validatorsObject.numberOfGenerators}
          />

          <Field
            name="generatorInfo.brand"
            component={InputField}
            label={t('auxiliary-brand')}
            placeholder={t('enterBrand')}
            validate={validatorsObject.brand}
          />

          <Field
            name="generatorInfo.model"
            component={InputField}
            label={t('auxiliary-model')}
            placeholder={t('enterModel')}
            validate={validatorsObject.model}
          />
          <Field
            type="number"
            name="generatorInfo.hours"
            component={InputField}
            label={t('auxiliary-hours')}
            placeholder={t('enterAmount')}
            validate={validatorsObject.hours}
          />

          <Field
            name="generatorInfo.capacity"
            component={InputField}
            unitType="kW"
            label={t('auxiliary-capacity')}
            placeholder={t('enterNumber')}
            validate={validatorsObject.capacity}
          />
          <Field
            type="number"
            name="generatorInfo.electricity"
            component={SelectField}
            options={electricityOptions}
            label={t('auxiliary-electricity')}
            placeholder={t('selectVoltage')}
            validate={validatorsObject.electricity}
          />
        </>
      )}
      <Field
        name="hasAirCondition"
        component={YesNoField}
        label={t('auxiliary-airConditioning')}
      />
      {hasAirCondition && (
        <Field
          type="number"
          name="airConditioningInfo.capacity"
          component={InputField}
          label={t('auxiliary-airConditioning')}
          placeholder={t('enterNumber')}
          validate={validatorsObject.airConditionCapacity}
        />
      )}
      <Field
        name="stabilization"
        component={SelectField}
        options={stabilizationOptions}
        label={t('auxiliary-stabilization')}
        placeholder={t('enterType')}
      />
      <Field
        component={YesNoField}
        name="hasWaterMarks"
        label={t('auxiliary-waterMarks')}
      />
      {hasWaterMarks && (
        <>
          <Field
            type="number"
            name="waterMakerInfo.numberOfWaterMakers"
            component={InputField}
            label={t('auxiliary-amountOfWaterMarks')}
            placeholder={t('enterNumber')}
            validate={validatorsObject.numberOfWaterMakers}
          />

          <Field
            type="number"
            name="waterMakerInfo.numberOfLitersPerHourPerWaterMaker"
            component={InputField}
            label={t('auxiliary-liters')}
            placeholder={t('enterNumber')}
            validate={validatorsObject.numberOfLiters}
          />
        </>
      )}
      <Field
        component={YesNoField}
        name="hasMarineHead"
        label={t('auxiliary-marineHead')}
      />
      <Field
        component={YesNoField}
        name="hasInverter"
        label={t('auxiliary-inverter')}
      />
      <div className="yx-auxiliary-equipment-step__check-fields">
        <div>
          <label>{t('auxiliary-thrusters')}</label>
          <Field
            name="hasBowThruster"
            component={CheckboxField}
            label={t('bowThruster')}
          />
          <Field
            name="hasSternThruster"
            component={CheckboxField}
            label={t('sternThruster')}
          />
        </div>
        <div>
          <label>{t('auxiliary-power')}</label>
          <Field
            unitType="kW"
            name="bowThrusterPower"
            component={InputField}
            placeholder={t('enterPower')}
            validate={validators.validateIfOtherFieldIs(
              'hasBowThruster',
              true,
              [validators.required(t('auxiliary-bowThruster-validator'))],
            )}
          />
          <Field
            unitType="kW"
            name="sternThrusterPower"
            component={InputField}
            placeholder={t('enterPower')}
            validate={validators.validateIfOtherFieldIs(
              'hasSternThruster',
              true,
              [validators.required(t('auxiliary-sternThruster-validator'))],
            )}
          />
        </div>
      </div>
      <Field
        name="hasAncillaryEquipment"
        component={CheckboxField}
        label={t('ancillaryEquipment')}
      />
      <Field
        name="hasFireChainWasher"
        component={CheckboxField}
        label={t('fireChainWasher')}
      />

      <Field
        name="hasBilgePump"
        component={CheckboxField}
        label={t('bilgePump')}
      />

      <Field
        name="hasSewageTreatment"
        component={CheckboxField}
        label={t('sewageTreatment')}
      />

      <Field
        name="hasEngineRoomBlowers"
        component={CheckboxField}
        label={t('engineRoomBlowers')}
      />
    </StepWrapper>
  );
};

export default AuxiliaryEquipmentStep;
