import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import './TableData.styles.scss';
import './TableData.responsive.styles.scss';
import Tooltip from 'components/Tooltip';

export type TableDataType<T extends any = React.ReactText> = {
  key: string;
  value: T;
  tooltip?: React.ReactNode;
};

type TableDataProps = {
  className?: string;
  tableData: Array<TableDataType>;
  tableColumnCount?: number;
} & React.HTMLAttributes<HTMLTableElement>;

const TableData: React.FC<TableDataProps> = (props) => {
  const { tableData, tableColumnCount = 3, className, ...rest } = props;

  const classes = classNames('yx-table-data', className);
  const nKeys = useMemo(
    () => (!tableData ? 0 : Object.keys(tableData).length),
    [tableData],
  );

  const numberOfRows = useMemo(
    () => Math.ceil(tableData?.length / tableColumnCount),
    [tableColumnCount, tableData],
  );

  const tableTooltip = useCallback(
    (tooltipContent: React.ReactNode) => (
      <Tooltip className="yx-table-data__tooltip" renderAsPortal>
        {tooltipContent}
      </Tooltip>
    ),
    [],
  );

  return (
    <table className={classes} {...rest}>
      <tbody>
        {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: tableColumnCount }).map((_, columnIndex) => {
              const ind = rowIndex * tableColumnCount + columnIndex;
              const activeTableData = tableData[ind];

              return (
                ind < nKeys && (
                  <td colSpan={ind === nKeys - 1 ? 2 : 1} key={columnIndex}>
                    {activeTableData?.key ? `${activeTableData?.key}:` : null}
                    <span
                      className={classNames({
                        'not-specified': !activeTableData?.value,
                      })}
                    >
                      {activeTableData?.value ? activeTableData?.value : '-'}
                      {activeTableData?.tooltip &&
                        tableTooltip(activeTableData.tooltip)}
                    </span>
                  </td>
                )
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableData;
