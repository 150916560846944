import api from 'api';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { User } from 'models/User';
import { Loader } from 'ncoded-component-library';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import credentialsService from 'services/credentialsService';
import CurrentUserContext from './CurrentUser.context';

type CurrentUserProps = {
  children?: React.ReactNode;
};

export const AVATAR_IMAGE_URL = '/assets/images/no-img-avatar.jpeg';

const PROFILE_PICTURE = 'profileImage';

const CurrentUser: React.FC<CurrentUserProps> = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const { popServerError, popInfo } = useContext(PopNotificationsContext);
  const [currentUser, setCurrentUser] = useState<User>();
  const [userIsFetching, setUserIsFetching] = useState(true);
  const [userFetchError, setUserFetchError] = useState(false);

  //this should probably be removed, also noa need for whole user in localStorage ( seems like nno ned for it at all )
  useEffectSkipFirst(() => {
    credentialsService.saveUserData(currentUser);
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { user },
        } = await api.users.getCurrentUser();
        setCurrentUser(user);
        setUserFetchError(false);
      } catch (e) {
        popServerError(e);
        setUserFetchError(true);
      } finally {
        setUserIsFetching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    if (!currentUser.profileImage?.url) {
      setCurrentUser((oldUser) => ({
        ...oldUser,
        profileImage: { url: AVATAR_IMAGE_URL },
      }));
    }
  }, [currentUser]);

  const uploadProfileImage = useCallback(
    async (value: File) => {
      if (!value) return;
      try {
        const {
          data: { file },
        } = await api.users.uploadProfilePicture(value, PROFILE_PICTURE);
        setCurrentUser((oldUser) => ({ ...oldUser, profileImage: file }));
        popInfo({ type: 'Success', content: t('imageUploaded') });
      } catch (e) {
        popServerError(e);
      }
    },
    [popInfo, popServerError, t],
  );

  const deleteProfileImage = useCallback(async () => {
    try {
      await api.users.deleteProfileImage(
        currentUser._id,
        PROFILE_PICTURE,
        currentUser.profileImage._id,
      );
      setCurrentUser((oldUser) => ({ ...oldUser, profileImage: {} }));
      popInfo({ type: 'Success', content: t('imageDeleted') });
    } catch (e) {
      popServerError(e);
    }
  }, [currentUser, popInfo, popServerError, t]);

  const changePassword = useCallback(
    async (value: Record<string, string>) => {
      const { oldPassword, newPassword } = value;
      try {
        await api.auth.changePassword(oldPassword, newPassword);
        popInfo({ type: 'Success', content: t('changePasswordSuccess') });
      } catch (e) {
        popServerError(e);
      }
    },
    [popInfo, popServerError, t],
  );

  const patchUser = useCallback(
    async (value: User) => {
      try {
        const {
          data: { user },
        } = await api.users.patchUser(currentUser?._id, value);
        setCurrentUser(user);
        popInfo({ type: 'Success', content: t('patchUserSuccess') });
      } catch (e) {
        popServerError(e);
      }
    },
    [currentUser, popInfo, popServerError, t],
  );

  if (!currentUser) {
    if (userIsFetching) return <Loader />;
    if (userFetchError) return <div>{t('serverError')}</div>;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        changePassword,
        patchUser,
        uploadProfileImage,
        deleteProfileImage,
      }}
    >
      {userIsFetching ? <Loader /> : children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUser;
