import React from 'react';
import classNames from 'classnames';
import utils from '../../../../../../../utils';
import './MessageScreen.styles.scss';

type MessageScreenProps = {
  className?: string;
  title: string;
  description?: string;
  icon: React.ReactNode;
};

const MessageScreen: React.FC<MessageScreenProps> = (props) => {
  const { className, title, description, icon } = props;

  const classes = classNames('yx-message-screen', className);

  return (
    <div className={classes}>
      <div className="yx-message-screen__icon-container">
        {utils.renderIcon(icon)}
      </div>
      <p className="yx-message-screen__title">{title}</p>
      {description && (
        <p className="yx-message-screen__description">{description}</p>
      )}
    </div>
  );
};

export default MessageScreen;
