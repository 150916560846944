import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import InputField from 'components/Fields/InputField';
import FormRow from 'components/FormRow';
import { Field, useField } from 'react-final-form';
import { composeValidators } from 'validators/general';
import validators from 'validators';
import RadioFields from 'components/Fields/RadioFields';
import { AvailableVesselForCommercilaCharterFields } from './radioFields';
import utils from 'utils';
import {
  MakeVesselAvailableForCommercialCharter,
  MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER,
} from 'models/Search';

import './CharterStep.styles.scss';
import './CharterStep.responsive.styles.scss';

type CharterStepProps = {
  className?: string;
};

const CharterStep: React.FC<CharterStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-charter-step', className);
  const { t } = useTranslation();

  const validatorsObject = useMemo(
    () => ({
      range: composeValidators(
        validators.required(t('requiredField')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      annualCharter: validators.checkNestedObjectIfOtherFieldIsGreater(
        'vesselAvailableForCommercialCharterInfo.potentialAnnualCharterIncome.from',
        t('potentialAnnual-validator'),
      ),
      runningCost: composeValidators(
        validators.checkNestedObjectIfOtherFieldIsGreater(
          'vesselAvailableForCommercialCharterInfo.runningCost.from',
          t('runningCost-validator'),
        ),
      ),
    }),
    [t],
  );

  const {
    input: { value: availableVessel },
  } = useField<MakeVesselAvailableForCommercialCharter>(
    'makeVesselCommercialyAvailable',
  );

  return (
    <StepWrapper
      title={t('vesselAvailableForCommercialCharter')}
      className={classes}
    >
      <FormRow className="yx-charter-step__row">
        <RadioFields
          radioFields={AvailableVesselForCommercilaCharterFields}
          name="makeVesselCommercialyAvailable"
        />
      </FormRow>

      {availableVessel === MAKE_VESSEL_AVAILABLE_FOR_COMMERCILA_CHARTER.YES && (
        <>
          <FormRow
            label={t('potentialAnnualCharterIncome')}
            description={t('potentialAnnualCharterIncome-info')}
          >
            <Field
              name="vesselAvailableForCommercialCharterInfo.potentialAnnualCharterIncome.from"
              component={InputField}
              placeholder={utils.getDotedNumber('100000')}
              min={1}
              unitType="EUR"
              validate={validatorsObject.range}
            />
            <Field
              name="vesselAvailableForCommercialCharterInfo.potentialAnnualCharterIncome.to"
              component={InputField}
              placeholder={utils.getDotedNumber('100000')}
              min={1}
              unitType="EUR"
              validate={composeValidators(
                validatorsObject.range,
                validatorsObject.annualCharter,
              )}
            />
          </FormRow>

          <FormRow
            label={t('runningCostsReduced')}
            description={t('runningCostsReduced-info')}
          >
            <Field
              name="vesselAvailableForCommercialCharterInfo.runningCost.from"
              component={InputField}
              placeholder={utils.getDotedNumber('100000')}
              min={1}
              unitType="EUR"
              validate={validatorsObject.range}
            />
            <Field
              name="vesselAvailableForCommercialCharterInfo.runningCost.to"
              component={InputField}
              placeholder={utils.getDotedNumber('100000')}
              min={1}
              unitType="EUR"
              validate={composeValidators(
                validatorsObject.range,
                validatorsObject.runningCost,
              )}
            />
          </FormRow>
        </>
      )}
    </StepWrapper>
  );
};

export default CharterStep;
