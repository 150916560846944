import React from 'react';
import YachtProvider from './providers/Yacht';
import { YachtProps } from './providers/Yacht/Yacht.provider';
import YachtPreview from './YachtPreview.page';

const wrapperYacht = ({ isLocked, className, ...props }: YachtProps) => (
  <YachtProvider {...props}>
    <YachtPreview isLocked={isLocked} className={className} />
  </YachtProvider>
);

export default wrapperYacht;
