import React, { useMemo } from 'react';
import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import Image from 'components/Image';
import { Button, Dropdown } from 'ncoded-component-library';
import DotsIcon from 'icons/Dots.icon';
import LocationIcon from 'icons/Location.icon';
import { CountryCodes, countryNamesByCode } from 'services/countries';

import './Card.styles.scss';
import './Card.responsive.styles.scss';

export type CardProps = {
  className?: string;
  children: React.ReactNode;
  dropdownContent: React.ReactNode;
  sideContent: React.ReactNode;
  linkProps?: NavLinkProps;
  imageUrl?: string;
  countryFlag?: CountryCodes | string;
  title: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const Card: React.FC<CardProps> = (props) => {
  const {
    className,
    children,
    dropdownContent,
    sideContent,
    imageUrl,
    title,
    countryFlag,
    linkProps,
    onClick,
  } = props;

  const classes = classNames('yx-card', className);

  const cardOptions = useMemo(
    () => (
      <Dropdown
        className="yx-card__dropdown"
        trigger={<Button icon={DotsIcon} />}
      >
        {dropdownContent}
      </Dropdown>
    ),
    [dropdownContent],
  );

  const flagTitle = useMemo(
    () =>
      typeof countryFlag === 'string'
        ? countryFlag
        : countryNamesByCode[countryFlag],
    [countryFlag],
  );

  return (
    <div className={classes} onClick={onClick}>
      {linkProps && (
        <NavLink {...linkProps} className="yx-card__link-section">
          <Image src={imageUrl} alt="card" />
        </NavLink>
      )}

      <section className="yx-card__content">
        <div className="yx-card__content__heading">
          <h1>{title}</h1>
          {cardOptions}
        </div>

        <span>
          <LocationIcon />
          {flagTitle}
        </span>

        {children}
      </section>

      <section className="yx-card__side-content">{sideContent}</section>
    </div>
  );
};

export default Card;
