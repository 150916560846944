import { DateTime } from 'luxon';

const MONTH_END = 12,
  MONTH_START = 1,
  MONTH_STARTING_DAY = 1;

export const isDateValid = (
  date: DateTime,
  minDate: DateTime,
  maxDate: DateTime,
) => {
  if (date < minDate || date > maxDate) {
    return false;
  }
  return true;
};
//placing zero if its single digit number
export const stringifyDatePart = (value: number) => {
  let string = String(value);
  if (string.length < 2) {
    string = '0' + string;
  }
  return string;
};
//creating date from expected value string
export const transformDefaultValue = (value: string) => {
  let timePart = value.split('T')[1].split(':');
  let datePart = value.split('T')[0].split('-');
  let date = DateTime.fromObject({
    year: Number(datePart[0]),
    month: Number(datePart[1]),
    day: Number(datePart[2]),
    hour: Number(timePart[0]),
    minute: Number(timePart[1]),
  });
  return date;
};
//increment and decrement date month
export const getIncrementedMonth = (date: DateTime) => {
  if (date.month !== MONTH_END) {
    return setValidMonthDay(date, date.month + 1);
  } else {
    return setValidYearDay(date.set({ month: MONTH_START }), date.year + 1);
  }
};
export const getDecrementedMonth = (date: DateTime) => {
  if (date.month !== 1) {
    return setValidMonthDay(date, date.month - 1);
  } else {
    return setValidYearDay(date.set({ month: MONTH_END }), date.year - 1);
  }
};
//increment and decrement date year
export const getIncrementedYear = (date: DateTime) => {
  return setValidYearDay(date, date.year + 1);
};
export const getDecrementedYear = (date: DateTime) => {
  return setValidYearDay(date, date.year - 1);
};
//seting closest valid day on month change:
export const setValidMonthDay = (date: DateTime, month: number) => {
  const newDate = DateTime.fromObject({
    year: date.year,
    month: month,
    day: MONTH_STARTING_DAY,
  });
  if (newDate.daysInMonth < date.day) {
    return newDate.set({ day: newDate.daysInMonth });
  } else {
    return newDate.set({ day: date.day });
  }
};
//setting closest valid day on year change:
//(validation for 29 february, when changing from leap year to another)
export const setValidYearDay = (date: DateTime, year: number) => {
  const newDate = DateTime.fromObject({
    year: year,
    month: date.month,
    day: MONTH_STARTING_DAY,
  });
  if (newDate.daysInMonth < date.day) {
    return newDate.set({ day: newDate.daysInMonth });
  } else {
    return newDate.set({ day: date.day });
  }
};

export const isYearValid = (
  year: number,
  minDate: DateTime,
  maxDate: DateTime,
): boolean => {
  return year >= minDate.year && year <= maxDate.year;
};

export const isMonthValid = (
  month: number,
  currentDate: DateTime,
  minDate: DateTime,
  maxDate: DateTime,
) => {
  if (currentDate.year !== minDate.year && currentDate.year !== maxDate.year) {
    return currentDate.year > minDate.year && currentDate.year < maxDate.year;
  }
  if (currentDate.year === minDate.year) {
    return month >= minDate.month;
  }
  if (currentDate.year === maxDate.year) {
    return month <= maxDate.month;
  }
};
