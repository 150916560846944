import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { Field, withTypes } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import { Button, Loader } from 'ncoded-component-library';
import { useHistory } from 'react-router-dom';
import FormWrapper from 'components/FormWrapper';
import { useTranslation } from 'react-i18next';
import useValidation from 'hooks/useValidation';
import { SignupFormType } from 'models/Auth';
import api from 'api';
import credentialsService from 'services/credentialsService';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

import './SignupStep.styles.scss';

export const EMAIL_CONFIRMATION_STEP = '/signup/email-verification';

type SignupStepProps = {
  className?: string;
};

const SignupStep: React.FC<SignupStepProps> = (props) => {
  const { className } = props;

  const { popServerError } = useContext(PopNotificationsContext);
  const history = useHistory();

  const classes = classNames('yx-signup-step', className);

  const { t } = useTranslation();
  const { validations } = useValidation();

  const { Form } = withTypes<SignupFormType>();

  const [loading, setLoading] = useState<boolean>(false);

  const onFormSubmit = useCallback(
    async (values: SignupFormType) => {
      const { email, password } = values;
      try {
        setLoading(true);
        const { data: userData } = await api.auth.signup(email, password);

        credentialsService.saveUserData(userData);
        history.push(EMAIL_CONFIRMATION_STEP);
      } catch (e) {
        popServerError(e);
      } finally {
        setLoading(false);
      }
    },
    [history, popServerError],
  );

  return (
    <Form
      onSubmit={onFormSubmit}
      render={({ errors }) => (
        <>
          {loading && <Loader />}
          <FormWrapper className={classes}>
            <div className="yx-signup-step__header">
              <p>{t('alreadyMember')}</p>
              <Button
                styleType="secondary"
                variant="outline"
                onClick={() => history.push('/login')}
              >
                {t('login')}
              </Button>
            </div>

            <div className="yx-signup-step__form">
              <img src="/assets/images/logo.png" alt="logo" />
              <h2>{t('memberRegistration')}</h2>
              <Field
                name="email"
                component={InputField}
                placeholder={t('email')}
                validate={validations.email}
              />
              <Field
                name="password"
                type="password"
                component={InputField}
                placeholder={t('password')}
                validate={validations.password}
              />
              <Field
                name="confirmPassword"
                type="password"
                component={InputField}
                placeholder={t('repeatPassword')}
                validate={validations.confirmPassword}
              />
              <Button type="submit">{t('becomeMember')}</Button>
              {errors?.matchError && (
                <p className="yx-signup-step__error">{errors?.matchError}</p>
              )}
            </div>
          </FormWrapper>
        </>
      )}
    />
  );
};

export default SignupStep;
