import React, { useCallback, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import utils from 'utils';
import { Notification } from 'models/Notification';
import { NOTIFICATIONS_MESSAGES } from './codes';
import { Button, Dropdown } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import CheckIcon from 'icons/Check.icon';
import NotificationContext from 'router/subrouters/Dashboard/providers/Notification/Notification.context';
import DotsIcon from 'icons/Dots.icon';
import Image from 'components/Image';

import './NotificationCard.styles.scss';
import './NotificationCard.responsive.styles.scss';

type NotificationCardProps = {
  className?: string;
  asDropdown?: boolean;
  onClick?: () => void;
} & Notification &
  React.PropsWithoutRef<NavLinkProps> &
  React.RefAttributes<HTMLAnchorElement>;

const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  const {
    _id: notificationId,
    createdAt,
    updatedAt,
    className,
    typeCode,
    isRead,
    content,
    asDropdown = false,
    onClick,
    userId,
    ...linkProps
  } = props;

  const { match } = content ?? {};

  const { t } = useTranslation();
  const { readNotification } = useContext(NotificationContext);
  const dropdownRef = useRef<DropdownRef>();

  const classes = classNames(
    'yx-notification-card',
    { 'yx-notification-card__unread': !isRead },
    { 'yx-notification-card__dropdown': asDropdown },
    className,
  );

  const pastTime = useMemo(() => {
    const pastTime = utils.calculatePastTime(createdAt);
    return t(pastTime.type, { count: pastTime.num });
  }, [createdAt, t]);

  const notificationMessage = useMemo(
    () => (
      <>
        {!asDropdown && (
          <span>
            {t(`${isRead ? 'notification' : 'newNotification'}`, {
              name: match?.yachtName,
            })}
          </span>
        )}
        {t(NOTIFICATIONS_MESSAGES[typeCode], {
          yachtName: match?.yachtName,
          location: match?.location,
        })}
      </>
    ),
    [t, isRead, match, asDropdown, typeCode],
  );

  const onMarkAsRead = useCallback(() => {
    readNotification(notificationId);
    dropdownRef.current.setIsOpen(false);
  }, [notificationId, readNotification]);

  const notificationActions = useMemo(
    () => (
      <Dropdown
        renderAsPortal
        className="yx-notification-card__actions"
        ref={dropdownRef}
        onClick={(e) => e.preventDefault()}
        trigger={<Button icon={DotsIcon} />}
      >
        <Button variant="link" icon={CheckIcon} onClick={() => onMarkAsRead()}>
          {t('markAsRead')}
        </Button>
      </Dropdown>
    ),
    [onMarkAsRead, t],
  );

  return (
    <NavLink {...linkProps} className={classes} onClick={onClick}>
      <Image src="/assets/images/favicon.png" />
      <div className="yx-notification-card__content">
        <p className="yx-notification-card__description">
          {notificationMessage}
        </p>
        <p className="yx-notification-card__time">{pastTime}</p>
      </div>
      {!asDropdown && notificationActions}
    </NavLink>
  );
};

export default NotificationCard;
