import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M7.26468 13.8218C10.8859 13.8218 13.8216 10.8862 13.8216 7.26492C13.8216 3.64367 10.8859 0.708008 7.26468 0.708008C3.64343 0.708008 0.707764 3.64367 0.707764 7.26492C0.707764 10.8862 3.64343 13.8218 7.26468 13.8218Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path
      d="M3.55347 8.57617C4.04698 9.97236 5.30626 11.0069 6.82659 11.1749M16.4434 16.4445L12.0721 12.0732L16.4434 16.4445Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
