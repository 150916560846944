import React from 'react';
import SuccessYachtCreationCard from '../../components/SuccessYachtCreationCard';

import './PublishStep.styles.scss';

type PublishStepProps = {
  className?: string;
};

const PublishStep: React.FC<PublishStepProps> = () => {
  return <SuccessYachtCreationCard />;
};

export default PublishStep;
