//this can be some custom messages not specific these ones

export const NOTIFICATION_TRANSLATIONS = {
  BUYER_REQUESTED_VIEWING: 'someoneRequestedViewing',
  SELLER_ACCEPTED_REQUEST_FOR_VIEWING: 'theSellerAcceptedYourViewingRequest',
  BUYER_CHOOSED_AN_APPOINTMENT: 'thePotentialBuyerAcceptedAppointment',
  MATCH_CANCELLED: 'yourMatchHasBeenCancelled',
  REQUEST_FOR_POSTPONOMENT: 'youHaveReceivedPostponementRequest',
  ACCEPT_REQUEST_FOR_POSTPONOMENT: 'acceptRequestForPostponement',
};

export const NOTIFICATIONS_MESSAGES = [
  NOTIFICATION_TRANSLATIONS.BUYER_REQUESTED_VIEWING,
  NOTIFICATION_TRANSLATIONS.SELLER_ACCEPTED_REQUEST_FOR_VIEWING,
  NOTIFICATION_TRANSLATIONS.BUYER_CHOOSED_AN_APPOINTMENT,
  NOTIFICATION_TRANSLATIONS.MATCH_CANCELLED,
  NOTIFICATION_TRANSLATIONS.REQUEST_FOR_POSTPONOMENT,
  NOTIFICATION_TRANSLATIONS.ACCEPT_REQUEST_FOR_POSTPONOMENT,
];
