import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import './YearPicker.scss';
import '../../styles/YearAndMonthContainer.styles.scss';
import { DateTime } from 'luxon';
import { getValues, Pickers, YEARS_ON_PAGE } from '../../utils';
import classNames from 'classnames';
import * as utils from '../../../utils';
import { setValidYearDay } from '../../../utils';
import DatepickerContext from '../../provider/Datepicker.context';

const { MONTH_PICKER } = Pickers;

type YearPickerProps = {
  className?: string;
};

const classBase = (suffix: TemplateStringsArray | string = '') =>
  `year-month-picker__field${suffix}`;

const YearPicker: React.FC<YearPickerProps> = ({ className }) => {
  const {
    currentDate,
    setCurrentDate,
    minDate,
    maxDate,
    yearFrom = DateTime.now().year - Math.floor(YEARS_ON_PAGE / 2),
    setShowInMenu,
    setYearFrom,
    date,
  } = useContext(DatepickerContext);

  const yearsDisplayed = useMemo(
    () => getValues(yearFrom, yearFrom + YEARS_ON_PAGE - 1),
    [yearFrom],
  );

  const YearPickerClassNames = classNames(
    'year-picker',
    'year-month-picker',
    className,
  );

  useEffect(() => {
    if (currentDate)
      setYearFrom?.(currentDate.year - Math.floor(YEARS_ON_PAGE / 2));
  }, [currentDate, setYearFrom]);

  const isYearSelected = useCallback(
    (year: number) => year === date.year,
    [date.year],
  );

  const isYearValid = useCallback(
    (year: number) => utils.isYearValid(year, minDate, maxDate),
    [maxDate, minDate],
  );

  const onClickHandle = useCallback(
    (year: number) => {
      if (isYearValid(year)) {
        setCurrentDate?.((oldVal) => setValidYearDay(oldVal, year));
        setShowInMenu?.(MONTH_PICKER);
      }
    },
    [isYearValid, setCurrentDate, setShowInMenu],
  );

  return (
    <main className={YearPickerClassNames}>
      {yearsDisplayed.map((year) => (
        <div
          className={classNames(classBase(), {
            [classBase`--selected`]: isYearSelected(year),
            [classBase`--valid`]: isYearValid(year),
            [classBase`--invalid`]: !isYearValid(year),
          })}
          onClick={() => onClickHandle(year)}
          key={year}
        >
          <div className="year-month-picker__field__content">{year}</div>
        </div>
      ))}
    </main>
  );
};
export default YearPicker;
