import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import './ChangeProfilePicture.styles.scss';
import Avatar from '../../../../../../../components/Avatar';
import CurrentUserContext from '../../../../providers/CurrentUser/CurrentUser.context';
import EditIcon from '../../../../../../../icons/Edit.icon';
import { Button, Loader, Modal } from 'ncoded-component-library';
import useCallbackRef from 'hooks/useCallbackRef';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { useTranslation } from 'react-i18next';
import TrashIcon from 'icons/Trash.icon';
import confirm from 'modules/confirm';
import { AVATAR_IMAGE_URL } from 'router/subrouters/Dashboard/providers/CurrentUser/CurrentUser.provider';

type ChangeProfilePictureProps = {
  className?: string;
};

const ChangeProfilePicture: React.FC<ChangeProfilePictureProps> = (props) => {
  const { className } = props;

  const [imageUploading, setImageUploading] = useState(false);

  const {
    currentUser: { profileImage: { _id, url } = { _id: null, url: '' } },
    uploadProfileImage,
    deleteProfileImage,
  } = useContext(CurrentUserContext);

  const { t } = useTranslation();
  const [modal, modalRef] = useCallbackRef<OverlayRef>();

  const classes = classNames('yx-change-profile-picture', className);

  const uploadNewProfileImage = useCallback(
    async (file: File) => {
      setImageUploading(true);
      modal?.close();
      await uploadProfileImage(file);
      setImageUploading(false);
    },
    [modal, uploadProfileImage],
  );

  const onDeleteProfileImage = useCallback(async () => {
    setImageUploading(true);
    await deleteProfileImage();
    modal?.close();
    setImageUploading(false);
  }, [deleteProfileImage, modal]);

  const handleOnDelete = useCallback(async () => {
    if (
      await confirm({
        title: t('deleteProfileImage'),
        type: 'delete',
        content: t('confirmProfileImageDeletionMessage'),
      })
    ) {
      onDeleteProfileImage();
    }
  }, [onDeleteProfileImage, t]);

  return (
    <div className={classes}>
      {imageUploading && <Loader />}
      <Avatar
        imageUrl={url}
        onClick={() => _id && modal?.open()}
        className="yx-change-profile-picture__avatar"
      />
      {url !== AVATAR_IMAGE_URL && (
        <Button
          icon={<TrashIcon width="18px" height="18px" />}
          className="delete-button"
          onClick={handleOnDelete}
        />
      )}
      <input
        type="file"
        name="file"
        id="file"
        className="hidden-input"
        onChange={(ev) => uploadNewProfileImage(ev.target.files[0])}
      />
      <label htmlFor="file" className="yx-change-profile-picture__edit-button">
        <EditIcon />
      </label>
      <Modal className="yx-display-dropzone-file__modal" ref={modalRef}>
        <img src={url} alt={t('couldntLoadImage')} />
      </Modal>
    </div>
  );
};

export default ChangeProfilePicture;
