import { RadioFieldsType } from 'components/Fields/RadioFields/RadioFields.component';
import { SPEED_TYPE_SEARCH } from 'models/Search';

export const speedTypeFields = [
  {
    value: SPEED_TYPE_SEARCH.CRUISING,
    label: 'cruising',
    description: 'correspondsToLessThan15Knots',
  },
  {
    value: SPEED_TYPE_SEARCH.A_TO_B,
    label: 'gettingFromPointAToPointB',
    description: 'corresponsToMoreThan15Knots',
  },
  {
    value: SPEED_TYPE_SEARCH.I_DONT_CARE,
    label: 'iDontCare',
    description: '',
  },
] as Array<RadioFieldsType>;
