import React from 'react';
import classNames from 'classnames';
import {
  StatusStyleKeys,
  STATUS_LABELS,
  STATUS_STYLES,
} from '../../../../../../../components/MatchCard/utils';
import '../../../../../../../components/MatchCard/MatchCard.styles.scss';
import './HomeMatchCard.styles.scss';
import './HomeMatchCard.styles.responsive.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import PercentageValue from '../../../../../../../components/PercentageValue';
import { MATCH_TYPE } from 'router/subrouters/Dashboard/subrouters/Match/models';

export type MatchType = typeof MATCH_TYPE[keyof typeof MATCH_TYPE];

type HomeMatchCardProps = {
  className?: string;
  type: MatchType;
  name: string;
  length: number;
  price: number;
  status: StatusStyleKeys;
  matchingScore: number;
  onView: () => void;
};

const HomeMatchCard: React.FC<HomeMatchCardProps> = (props) => {
  const {
    className,
    type,
    name,
    length,
    price,
    status,
    matchingScore,
    onView,
  } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-home-match-card', 'yx-match-card', className);
  const statusClasses = classNames(
    'yx-match-card__status',
    `yx-match-card__status--${STATUS_STYLES[status]}`,
  );
  const columnClasses = classNames(
    'yx-match-card__column',
    'yx-home-match-card__column',
  );
  const rowClasses = classNames(
    'yx-match-card__row',
    'yx-home-match-card__row',
  );

  const typeClasses = classNames('yx-home-match-card__type', {
    'yx-home-match-card__type--buy': type === MATCH_TYPE.BUYER,
    'yx-home-match-card__type--sell': type !== MATCH_TYPE.BUYER,
  });

  return (
    <div className={classes}>
      <div className="yx-home-match-card__content">
        <section className={columnClasses}>
          <label>{t('type')}</label>
          <span className={typeClasses}>
            {type === MATCH_TYPE.BUYER ? t('buy') : t('sell')}
          </span>
        </section>
        <section className={columnClasses}>
          <label>{t('name')}</label>
          <span>{name}</span>
        </section>
        <section className={columnClasses}>
          <label>{t('length')}</label>
          <span>{`${length} ${t('meters')}`}</span>
        </section>
        <section className={columnClasses}>
          <label>{t('askingPrice')}</label>
          <span>{price}</span>
        </section>
        <section className={columnClasses}>
          <label>{t('status')}</label>
          <span className={statusClasses}>{STATUS_LABELS[status]}</span>
        </section>
        <section className={rowClasses}>
          <label>{t('matchingScore')}:</label>
          <PercentageValue
            value={matchingScore}
            className="yx-match-card__match-score"
          />
        </section>
      </div>
      <Button styleType="secondary" onClick={() => onView()}>
        {t('view')}
      </Button>
    </div>
  );
};

export default HomeMatchCard;
