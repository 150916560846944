import React, { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import './ViewingConfirmation.styles.scss';
import ChoseAppointmentTime from 'components/ChoseAppointmentTime';
import MatchContext from '../../../providers/Match/Match.context';
import { MATCH_STATUS } from '../../../utils';
import ShowApointment from 'components/ShowAppointment';
import ViewingStepActions from '../../../components/ViewingStepActions';

type ViewingConfirmationProps = {
  className?: string;
};

const ViewingConfirmation: React.FC<ViewingConfirmationProps> = (props) => {
  const { className } = props;
  const {
    match: {
      timeSlotOptions = [],
      meetingDate,
      location,
      locationCoordinates: { latitude, longitude },
      status,
      seller,
    },
    onChoseMeetingDate,
  } = useContext(MatchContext);

  const [selectedDate, setSelectedDate] = useState(meetingDate);

  const classes = classNames('yx-view', className);

  const choseMeeting = useMemo(() => status === MATCH_STATUS.VIEWING, [status]);

  return (
    <div className={classes}>
      {!choseMeeting ? (
        <ChoseAppointmentTime
          dates={timeSlotOptions?.map((timeslot) => timeslot.from)}
          selected={selectedDate}
          onChange={setSelectedDate}
          onSubmit={onChoseMeetingDate}
          location={location}
          lat={latitude}
          long={longitude}
        />
      ) : (
        <ShowApointment
          location={location}
          lat={latitude}
          long={longitude}
          date={meetingDate}
          contact={seller && `${seller.firstName} ${seller.lastName}`}
          contactDescription={seller && seller.email}
        />
      )}

      <ViewingStepActions />
    </div>
  );
};

export default ViewingConfirmation;
