import { Match } from 'models/Match';
import { Search } from 'models/Search';
import { Yacht } from 'models/Yacht';
import { createContext } from 'react';
import utils from 'utils';

type HomeContextProps = {
  yachtsLoading: boolean;
  searchesLoading: boolean;
  matchesLoading: boolean;
  yachts: Array<Yacht>;
  searches: Array<Search>;
  matches: Array<Match>;
  setYachts: (values: Array<Yacht>) => void;
  setSearches: (values: Array<Search>) => void;
  setMatches: (values: Array<Match>) => void;
};

export default createContext<Partial<HomeContextProps>>({
  yachtsLoading: false,
  searchesLoading: false,
  matchesLoading: false,
  yachts: [],
  searches: [],
  matches: [],
  setYachts: utils.noop,
  setSearches: utils.noop,
  setMatches: utils.noop,
});
