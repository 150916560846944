import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import EmailVerificationStep from './pages/EmailVerificationStep';
import RoleSelection from './pages/RoleSelection';
import EmailVerifiedStep from './pages/EmailVerifiedStep';
import SignupStep from './pages/SignupStep';
import BrokerRouter from './subrouters/Broker';
import PrivatePersonRouter from './subrouters/PrivatePerson';
import credentialsService from 'services/credentialsService';

export default [
  {
    path: '/email-verification',
    exact: true,
    component: EmailVerificationStep,
  },
  {
    path: '/email-verified',
    exact: false,
    component: EmailVerifiedStep,
  },
  {
    path: '/private-person',
    exact: false,
    component: PrivatePersonRouter,
    validationHook: () => !!credentialsService.patchToken,
  },
  {
    path: '/broker',
    exact: false,
    component: BrokerRouter,
    validationHook: () => !!credentialsService.patchToken,
  },
  {
    path: '/role',
    authorized: false,
    onlyPublic: true,
    exact: true,
    component: RoleSelection,
  },
  {
    path: '/',
    exact: true,
    component: SignupStep,
  },
] as Array<EnhancedRouteProps>;
