import { FieldState, FieldValidator } from 'final-form';
import { getUnitValue } from 'router/subrouters/CreateYacht/services/utils';

//Final Form use undefined value for successful validation
const VALID: any = undefined;

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const MUST_CONTAIN_LETTER = /(?=.*?[a-z, A-Z])/;
const MUST_CONTAIN_NUMBER = /(?=.*?[0-9])/;
const MUST_CONTAIN_SPECIAL_CHAR = /[`~!@#$%^&*()_\-=+[\]\\'/,.?":;{}|<>]+/;
const PHONE_NUMBER_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i;
export const NUMBERS_ONLY = /^[1-9][0-9]{0,2}(,?[0-9]{3})*(\.[0-9]+)?$/i;

export const isNonEmptyString = (value: any) => {
  return value.trim().length > 0;
};

export const required = (errorMessage: string) => (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return errorMessage;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : errorMessage;
  }

  return VALID;
};

export const requiredArray = (errorMessage: string) => (value: Array<any>) => {
  if (!Array.isArray(value)) return required(errorMessage)(value);
  if (!value) {
    // undefined or null values are invalid
    return errorMessage;
  }
  return !value.length ? errorMessage : VALID;
};

export const requiredObjOrArray =
  (errorMessage: string) => (value: Array<any>) => {
    if (!Array.isArray(value)) return required(errorMessage)(value);
    else return requiredArray(errorMessage)(value);
  };

export const minLength =
  (errorMessage: string, minLength: number) => (value: any) => {
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length >= minLength ? VALID : errorMessage;
  };

export const maxLength =
  (errorMessage: string, maxLength: number) => (value: any) => {
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length <= maxLength ? VALID : errorMessage;
  };

export const minNumber =
  (errorMessage: string, minNum: number) => (value: any) => {
    return parseInt(value) >= minNum ? VALID : errorMessage;
  };

export const maxNumber =
  (errorMessage: string, maxNum: number) => (value: any) => {
    return parseInt(value) <= maxNum ? VALID : errorMessage;
  };

export const mustContainerNumber = (errorMessage: string) => (value: any) => {
  return MUST_CONTAIN_NUMBER.test(value) ? VALID : errorMessage;
};

export const mustContainerLetter = (errorMessage: string) => (value: any) => {
  return MUST_CONTAIN_LETTER.test(value) ? VALID : errorMessage;
};

export const mustContainerSpecialChar =
  (errorMessage: string) => (value: any) => {
    return MUST_CONTAIN_SPECIAL_CHAR.test(value) ? VALID : errorMessage;
  };

export const emailValidation = (errorMsg: string) => (value: string) => {
  return value && EMAIL_REGEX.test(value) ? VALID : errorMsg;
};

export const phoneNumberValidation =
  (errorMessage: string) => (value: string) => {
    if (!value) return;
    return PHONE_NUMBER_REGEX.test(value) ? VALID : errorMessage;
  };

export const numbersOnlyValidation =
  (errorMessage: string) => (value: string) => {
    if (!value) return;
    return NUMBERS_ONLY.test(value) ? VALID : errorMessage;
  };

export const composeValidators =
  (...validators: FieldValidator<any>[]) =>
  (value: any, allValues: object, meta?: FieldState<any>) =>
    validators.reduce(
      (error, validator) => error || validator?.(value, allValues, meta),
      VALID,
    );

export const validateIfOtherFieldIs =
  (
    otherField: string,
    otherFieldAskedValue: any,
    validators: Array<FieldValidator<any>>,
  ) =>
  (value: any, allValues: any, meta?: FieldState<any>) => {
    if (allValues[otherField] === otherFieldAskedValue) {
      let err;
      validators.find((v) => {
        err = v(value, allValues, meta);
        return !!err;
      });
      return err;
    }
  };

export const checkIfOtherFieldIsGreaterThan =
  (otherField: string, errorMessage: string) => (value: any, allValues: any) =>
    allValues[otherField] > value ? errorMessage : VALID;

export const checkIfOtherFieldIsGratherThenRange =
  (otherField: string, errorMessage: string) =>
  (value: any, allValues: any) => {
    const from = parseInt(
      allValues[otherField]?.from?.toString().replaceAll(',', ''),
    );
    const to = parseInt(
      allValues[otherField]?.to?.toString().replaceAll(',', ''),
    );

    return from >= to ? errorMessage : VALID;
  };

export const checkNestedObjectIfOtherFieldIsGreater =
  (ohterField: string, errorMessage: string) =>
  (value: any, allValues: any) => {
    const filedKeys = ohterField.split('.');
    const values = allValues[filedKeys[0]][filedKeys[1]];

    const from = getUnitValue(values.from);
    const to = getUnitValue(values.to);

    return to > from ? VALID : errorMessage;
  };
