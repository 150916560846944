import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Button, Loader } from 'ncoded-component-library';
import BackIcon from 'icons/Back.icon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import api from 'api';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import { User } from 'models/User';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';

import './EmailVerificationStep.styles.scss';
import './EmailVerificationStep.responsive.styles.scss';

type EmailVerificationStepProps = {
  className?: string;
};

const EmailVerificationStep: React.FC<EmailVerificationStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-email-verification-step', className);

  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const { popServerError } = useContext(PopNotificationsContext);

  const requestAgain = useCallback(async () => {
    try {
      setLoading(true);

      const userData: User = storageService.getItem(STORAGE_KEYS.USER);
      await api.auth.resendEmail(userData._id);
    } catch (e) {
      popServerError(e);
    } finally {
      setLoading(false);
    }
  }, [popServerError]);

  return (
    <div className={classes}>
      {loading && <Loader />}
      <Button variant="link" icon={BackIcon} onClick={() => history.goBack()}>
        {t('back')}
      </Button>
      <div className="yx-email-verification-step__container">
        <img
          src="/assets/images/email-verification.png"
          alt="Email verification"
        />

        <h2>{t('confirmYourEmail')}</h2>
        <p>{t('emailVerification-verificationSent')}</p>

        <div className="yx-email-verification-step__request-again">
          <p>{t('emailVerification-didNotReceiveEmail')}</p>
          <Button variant="link" onClick={requestAgain}>
            {t('requestAgain')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationStep;
