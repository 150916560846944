import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import FormRow from 'components/FormRow';
import SelectField from 'components/Fields/SelectField';
import validators from 'validators';
import { composeValidators } from 'validators/general';
import { fuelOptions } from '../../services/options';

import './EngineStep.styles.scss';

type EngineStepProps = {
  className?: string;
};

const EngineStep: React.FC<EngineStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-engine-step', className);

  const { t } = useTranslation();

  const validationObject = useMemo(
    () => ({
      cruisingSpeed: composeValidators(
        validators.required(t('requiredField')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      enginePower: composeValidators(
        validators.required(t('requiredField')),
        validators.numbersOnlyValidation(t('notValidUnitValue')),
      ),
      fueldType: validators.required(t('requiredField')),
      numberOfEngines: composeValidators(
        validators.required(t('requiredField')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
        validators.maxNumber(t('maxNum', { maxNum: 10 }), 10),
      ),
      engineManufacturer: validators.required(t('requiredField')),
      engineModel: validators.required(t('requiredField')),
      engineHours: validators.required(t('requiredField')),
      fuelConsumption: composeValidators(
        validators.required(t('requiredField')),
        validators.numbersOnlyValidation('notValidunitValue'),
      ),
      range: validators.numbersOnlyValidation('notValidunitValue'),
    }),
    [t],
  );

  return (
    <StepWrapper
      title={t('engine')}
      description={t('engine-info')}
      className={classes}
    >
      <FormRow>
        <Field
          name="cruisingSpeed"
          unitType="kn"
          component={InputField}
          label={t('engine-cruisingSpeed')}
          placeholder={t('engine-cruisingSpeed-placeholder')}
          validate={validationObject.cruisingSpeed}
        />

        <Field
          name="enginePower"
          unitType="hp"
          component={InputField}
          label={t('engine-enginePower')}
          placeholder={t('engine-enginePower-placeholder')}
          validate={validationObject.enginePower}
        />
      </FormRow>

      <Field
        name="fuelType"
        component={SelectField}
        options={fuelOptions}
        label={t('engine-fuelType')}
        placeholder={t('engine-fuelType-placeholder')}
        validate={validationObject.fueldType}
      />

      <Field
        name="numberOfEngines"
        component={InputField}
        type="number"
        min={0}
        max={10}
        label={t('engine-numberOfEngines')}
        placeholder={t('engine-numOfEngines-placeholder')}
        validate={validationObject.numberOfEngines}
      />

      <Field
        name="engineManufacturer"
        component={InputField}
        label={t('engine-engineManufacturer')}
        placeholder={t('enterName')}
        validate={validationObject.engineManufacturer}
      />

      <Field
        name="engineModel"
        component={InputField}
        label={t('engine-model')}
        placeholder={t('enterName')}
        validate={validationObject.engineModel}
      />

      <Field
        name="engineHours"
        unitType="h"
        component={InputField}
        label={t('engine-hours')}
        placeholder={t('enterAmount')}
        validate={validationObject.engineHours}
      />

      <Field
        name="fuelConsumptionAtCruisingSpeed"
        unitType="liters"
        component={InputField}
        label={t('engine-fuelConsumptionAtCSpeed')}
        placeholder={t('enterAmount')}
        validate={validationObject.fuelConsumption}
      />

      <Field
        name="range"
        unitType="km"
        component={InputField}
        optional
        label={t('engine-range')}
        placeholder={t('enterAmount')}
        validate={validationObject.range}
      />

      <Field
        name="additionalComments"
        component={InputField}
        label={t('engine-additionalComments')}
        placeholder={t('enterYourComment')}
      />
    </StepWrapper>
  );
};

export default EngineStep;
