import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PersonaRouter from '../../components/PersonaRouter';
import routes, { steps } from './routes';

const PrivatePerson: React.FC<RouteComponentProps> = () => {
  return <PersonaRouter routes={routes} steps={steps} />;
};

export default PrivatePerson;
