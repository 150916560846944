import { EnhancedRouteProps } from './routes/Enhanced/Enhanced.route';
import redirect from 'router/modules/redirect';
import LoginPage from 'pages/LoginPage';
import Catalog from 'pages/Catalog';
import SignupRouter from './subrouters/SignUp';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import CreatingProfile from './subrouters/SignUp/components/CreatingProfile';
import credentialsService from 'services/credentialsService';
import CreateYachtRouter from './subrouters/CreateYacht';
import DashboardRouter from './subrouters/Dashboard';
import CreateSearchProfileRouter from './subrouters/CreateSearchProfile';
import TemporaryUserPage from '../pages/TemporaryUser';
import PublicYacht from '../pages/PublicYacht';
import FreeTrialExpired from 'pages/FreeTrialExpired';

export default [
  {
    path: '/login',
    authorized: false,
    onlyPublic: true,
    component: LoginPage,
    validationHook: () => !credentialsService.isLogged,
    fallbackTo: '/tmp-user',
  },
  {
    path: '/signup',
    authorized: false,
    onlyPublic: true,
    exact: false,
    component: SignupRouter,
    validationHook: () => !credentialsService.isLogged,
    fallbackTo: '/tmp-user',
  },
  {
    path: '/(create|edit)-yacht(-draft)?',
    authorized: true,
    exact: false,
    component: CreateYachtRouter,
  },
  {
    path: '/(create|edit)-search-profile',
    authorized: true,
    onlyPublic: false,
    exact: false,
    component: CreateSearchProfileRouter,
  },
  {
    path: '/forgot-password',
    authorized: false,
    onlyPublic: true,
    exact: false,
    component: ForgotPasswordPage,
  },
  {
    path: '/reset-password',
    authorized: false,
    onlyPublic: true,
    exact: false,
    component: ResetPasswordPage,
  },
  {
    path: '/catalog',
    component: Catalog,
  },
  {
    path: '/creating-profile',
    component: CreatingProfile,
    validationHook: () => !credentialsService.token,
  },
  {
    path: '/tmp-user',
    authorized: false,
    exact: false,
    validationHook: () => credentialsService.isLogged,
    component: TemporaryUserPage,
  },
  {
    path: '/free-trial-expired',
    authorized: true,
    exact: false,
    component: FreeTrialExpired,
  },
  {
    path: '/yacht/:id',
    authorized: false,
    exact: false,
    component: PublicYacht,
  },
  {
    path: '/',
    authorized: true,
    exact: false,
    component: DashboardRouter,
    validationHook: () =>
      credentialsService.user?.userType === 'Private' ||
      credentialsService.hasActiveSubscription,
    fallbackTo: () =>
      credentialsService.token
        ? '/free-trial-expired'
        : credentialsService.isLogged
        ? '/tmp-user'
        : '/login',
  },
  {
    path: '/not-found',
    component: () => `not found`,
  },
  {
    path: '*',
    component: redirect('/not-found', true),
  },
] as Array<EnhancedRouteProps>;
