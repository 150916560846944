import React, {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
} from 'react';
import classNames from 'classnames';
import './CancelMeeting.styles.scss';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import { required } from 'validators/general';
import { Button, Modal } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import RadioFields, {
  RadioFieldsType,
} from 'components/Fields/RadioFields/RadioFields.component';
import { CANCEL_MATCH_REASON } from '../../utils';
import TextAreaField from 'components/Fields/TextAreaField';
import MatchContext from '../../providers/Match/Match.context';
import confirm from 'modules/confirm';

type CancelMeetingProps = {
  className?: string;
};

const CancelMeeting: React.ForwardRefRenderFunction<
  ModalRef,
  CancelMeetingProps
> = (props, ref) => {
  const { className } = props;
  const { t } = useTranslation();

  const classes = classNames('yx-cancel-meeting', className);

  const { cancelMatch } = useContext(MatchContext);

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal as any, [modal]);

  const handleCancel = useCallback(
    async (values) => {
      if (
        await confirm({
          title: t('cancelTitle'),
          content: t('cancelContent'),
        })
      ) {
        cancelMatch(values);
        modal?.close();
      }
    },
    [cancelMatch, modal, t],
  );

  const radioFields = useMemo(
    () =>
      [
        {
          validate: required(t('requiredField')),
          value: CANCEL_MATCH_REASON.CHANGED_MY_MIND,
          label: CANCEL_MATCH_REASON.CHANGED_MY_MIND,
        },
        {
          validate: required(t('requiredField')),
          value: CANCEL_MATCH_REASON.HAD_ACCIDENT,
          label: CANCEL_MATCH_REASON.HAD_ACCIDENT,
        },
        {
          validate: required(t('requiredField')),
          value: CANCEL_MATCH_REASON.DONT_FEEL_WELL,
          label: CANCEL_MATCH_REASON.DONT_FEEL_WELL,
        },
        {
          validate: required(t('requiredField')),
          value: CANCEL_MATCH_REASON.ANOTHER_URGENT_MATTER,
          label: CANCEL_MATCH_REASON.ANOTHER_URGENT_MATTER,
        },
        {
          validate: required(t('requiredField')),
          value: CANCEL_MATCH_REASON.OTHER,
          label: CANCEL_MATCH_REASON.OTHER,
        },
      ] as Array<RadioFieldsType>,
    [t],
  );

  return (
    <Modal className={classes} ref={modalRef}>
      <Form
        onSubmit={handleCancel}
        render={({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <p className="title">{t('sendAppointmentToClient-title')}</p>
            <p className="description">
              {t('sendAppointmentToClient-description')}
            </p>
            <RadioFields
              radioFields={radioFields}
              name="cancellationReason"
              className="yx-cancel-meeting__radio-fields"
            />
            {values?.cancellationReason === CANCEL_MATCH_REASON.OTHER && (
              <Field
                name="otherCancellationReason"
                placeholder={t('specifyReasonPlaceholder')}
                component={TextAreaField}
                validate={required(t('requiredField'))}
              />
            )}
            <div className="action-container">
              <Button styleType="secondary" onClick={() => modal?.close()}>
                {t('cancel')}
              </Button>
              <Button type="submit">{t('send')}</Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(CancelMeeting);
