import React from 'react';
import ReactDOM from 'react-dom';
import PopYachtNotification from 'providers/PopNotifications/components/PopYachtNotification';
import { PopYachtNotificationProps } from 'providers/PopNotifications/components/PopYachtNotification/PopYachtNotification.component';

import './Notification.styles.scss';

export type PopNotificationConf = PopYachtNotificationProps;

export type ToastNotifications = {
  show: (config: PopNotificationConf) => void;
};

export default {
  show: function (config: PopNotificationConf) {
    const div = document.createElement('div');
    document.body.appendChild(div);

    const cleanUp = () => {
      document.body.removeChild(div);
    };

    ReactDOM.render(
      <PopYachtNotification {...config} onClose={() => cleanUp()} />,
      div,
    );
  },
} as ToastNotifications;
