import React, { useContext } from 'react';
import '../../../styles/Header.scss';
import classNames from 'classnames';
import DatepickerContext from '../../../provider/Datepicker.context';
import { Pickers, YEARS_ON_PAGE } from '../../../utils';
import { LeftArrow, RightArrow } from '../../../svgs';

type HeaderProps = {
  className?: string;
};

const { MONTH_PICKER } = Pickers;

const Header: React.FC<HeaderProps> = ({ className }) => {
  const headerClassNames = classNames('header', className);

  const { setYearFrom, yearFrom, setShowInMenu } =
    useContext(DatepickerContext);

  return (
    <div className={headerClassNames}>
      <LeftArrow
        onClick={() => setYearFrom?.((oldVal) => oldVal - YEARS_ON_PAGE)}
      />
      <div
        className="header__heading"
        onClick={() => setShowInMenu?.(MONTH_PICKER)}
      >
        <p>
          <span>{yearFrom}</span>
          <span>-</span>
          <span>{yearFrom && yearFrom + YEARS_ON_PAGE - 1}</span>
        </p>
      </div>
      <RightArrow
        onClick={() => setYearFrom?.((oldVal) => oldVal + YEARS_ON_PAGE)}
      />
    </div>
  );
};

export default Header;
