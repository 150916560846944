import React, { useMemo, useContext, useEffect } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import { UserRoles } from 'models/User';
import PageContext from 'router/subrouters/Dashboard/providers/Page/Page.context';

const useValid = () => true;

export type EnhancedRouteProps = {
  label?: string;
  labelId?: string;
  authorized?: boolean;
  onlyPublic?: boolean;
  authorizedRoles?: Array<UserRoles>;
  componentProps?: Object;
  fallbackTo?: () => string | string;
  validationHook?: (...args: any[]) => boolean;
  component: React.ComponentType<any>;
} & Pick<RouteProps<string>, 'exact' | 'path' | 'render' | 'location'>;

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const {
    authorized = false,
    onlyPublic = false,
    component: Component,
    componentProps,
    authorizedRoles,
    fallbackTo = '/login',
    validationHook: useValidation = useValid,
    label,
    labelId,
    ...rest
  } = props;

  const titleKey = useMemo(() => labelId || label, [label, labelId]);
  const { setTitle } = useContext(PageContext);

  const valid = useValidation();
  const isLoggedIn = useMemo(() => credentialsService.token, []);
  const user = useMemo(() => credentialsService.user, []);

  const fallbackRoute = useMemo(
    () => (typeof fallbackTo === 'function' ? fallbackTo() : fallbackTo),
    [fallbackTo],
  );

  useEffect(() => {
    setTitle(titleKey);
  }, [setTitle, titleKey]);

  const finalRoute = useMemo(() => {
    if (((authorized || authorizedRoles) && !isLoggedIn) || !valid) {
      return (
        <Redirect
          to={{ pathname: fallbackRoute, state: { from: props.location } }}
        />
      );
    }

    if (onlyPublic && isLoggedIn) {
      return <Redirect to="/home" />;
    }
    if (authorizedRoles) {
      const userAllowed = authService.checkRolesForUser(user, authorizedRoles);
      if (!userAllowed) credentialsService.removeAuthBody();
    }
    return <Component {...rest} {...componentProps} />;
  }, [
    Component,
    authorized,
    authorizedRoles,
    componentProps,
    fallbackRoute,
    isLoggedIn,
    onlyPublic,
    props.location,
    rest,
    user,
    valid,
  ]);

  return finalRoute;
};

export default EnhancedRoute;
