import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import {
  DatepickerProviderValues,
  UseInitialValues as UseInitialValuesType,
} from '../models/index';
import { PickersType } from '../picker';
import { Pickers, YEARS_ON_PAGE } from '../utils';

const { DAY_PICKER } = Pickers;

const UseInitialValues = ({
  maxDate: minDateProps,
  minDate: maxDateProps,
  value,
  onlyFuture,
  onlyPast,
}: UseInitialValuesType) => {
  const [minDate, setMinDate] = useState<DateTime>();
  const [maxDate, setMaxDate] = useState<DateTime>();
  const date = DateTime.fromJSDate(value);
  const [currentDate, setCurrentDate] = useState(date);
  const [showInMenu, setShowInMenu] = useState<PickersType>(DAY_PICKER);
  const [yearFrom, setYearFrom] = useState(
    currentDate.year - Math.floor(YEARS_ON_PAGE / 2),
  );

  useEffect(() => {
    if (onlyFuture) {
      setMinDate(DateTime.now().startOf('day'));
      setMaxDate(DateTime.fromObject({ day: 31, month: 12, year: 2100 }));
    }
    if (onlyPast) {
      setMinDate(
        DateTime.fromObject({ day: 1, month: 1, year: 1900 }).startOf('day'),
      );
      setMaxDate(DateTime.now());
    }
    if (minDateProps) {
      setMinDate(DateTime.fromJSDate(minDateProps).startOf('day'));
    }
    if (maxDateProps) {
      setMaxDate(DateTime.fromJSDate(maxDateProps));
    }
  }, [onlyFuture, onlyPast, minDateProps, maxDateProps]);

  return {
    currentDate,
    setCurrentDate,
    minDate,
    maxDate,
    date,
    showInMenu,
    setShowInMenu,
    yearFrom,
    setYearFrom,
  } as DatepickerProviderValues;
};

export default UseInitialValues;
