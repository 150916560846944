import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import SelectField from '../SelectField';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import FormRow from 'components/FormRow';
import { useTranslation } from 'react-i18next';
import AddIcon from 'icons/Add.icon';
import Tooltip from 'components/Tooltip';
import validators from 'validators';
import FieldLabel from '../components/FieldLabel';
import {
  OptionalDocumentsKeys,
  OptionalDocumentsType,
  Yacht,
} from 'models/Yacht';
import DropzoneField from '../DropzoneField';
import credentialsService from 'services/credentialsService';
import useDropzoneRequest from 'router/subrouters/CreateYacht/hooks/useDropzoneRequest';
import { FileType } from 'models/File';
import CloseIcon from 'icons/Close.icon';

import './AddDocumentFields.styles.scss';

export type AddDocumentsType = {
  file: FileType;
  fileName: OptionalDocumentsKeys | string;
};

type AddDocumentFieldsProps = {
  name: string;
  options: Array<OptionValue<string | number>>;
  className?: string;
  tooltipContent?: React.ReactNode;
  label?: string;
  tooltip?: React.FC;
  buttonLabel?: string;
};

function AddDocumentFields(props: AddDocumentFieldsProps) {
  const { t } = useTranslation();

  const {
    name,
    className,
    options,
    tooltipContent,
    label,
    tooltip,
    buttonLabel = 'New',
    ...rest
  } = props;

  const classes = classNames('yx-add-fields', className);

  const { values } = useFormState<Yacht>();

  const { uploadFile } = useDropzoneRequest(credentialsService.draftYachtId);

  const uploadedDocuments = useMemo(
    () =>
      ({
        shipStationLicence: values.shipStationLicence,
        cargoShipSafetyRadioCertificate: values.cargoShipSafetyRadioCertificate,
        internationalAirPollutionCertificate:
          values.internationalAirPollutionCertificate,
        antiFoulingSystemCertificate: values.antiFoulingSystemCertificate,
        internationalLoadLineCertficate: values.internationalLoadLineCertficate,
        internationalSewagePollutionPreventionCertificate:
          values.internationalSewagePollutionPreventionCertificate,
        statementOfComplianceForLabourConvention:
          values.statementOfComplianceForLabourConvention,
        minimumSafeManningCertificate: values.minimumSafeManningCertificate,
        maltaCertificateOfSurvey: values.maltaCertificateOfSurvey,
        internationalTonnageCertificate: values.internationalTonnageCertificate,
        certificateOfCompliance: values.certificateOfCompliance,
        internationalOilPollutionPreventionCertificate:
          values.internationalOilPollutionPreventionCertificate,
        marpolCompliance: values.marpolCompliance,
        internationalAntiFoulingSystemCertificate:
          values.internationalAntiFoulingSystemCertificate,
      } as OptionalDocumentsType),
    [values],
  );

  const initialDocuments = useMemo(
    () =>
      Object.keys(uploadedDocuments)
        .map((key) => {
          if (uploadedDocuments[key as OptionalDocumentsKeys])
            return {
              fileName: key as OptionalDocumentsKeys,
              file: uploadedDocuments[key as OptionalDocumentsKeys],
            } as AddDocumentsType;
          else return null;
        })
        .filter((document) => document?.file !== undefined),
    [uploadedDocuments],
  );

  return (
    <FieldArray<AddDocumentsType> name={name} initialValue={initialDocuments}>
      {({ fields, fields: { push, remove, value } }) => {
        return (
          <div className={classes}>
            {(label || tooltip) && (
              <FieldLabel tooltip={tooltip}>{label}</FieldLabel>
            )}
            {fields.map((name, ind) => {
              const optionsForField = options.filter(
                (option) =>
                  !value?.some(
                    (document, ind2) =>
                      document.fileName === option.value && ind !== ind2,
                  ),
              );

              return (
                <FormRow key={name}>
                  <Field
                    name={`${name}.fileName`}
                    component={SelectField}
                    label={t('optionalDocument')}
                    options={optionsForField}
                    placeholder={t('selectOptionalDocument')}
                    validate={validators.required(t('requiredField'))}
                  />
                  <Field
                    name={`${name}.file`}
                    fileName={value[ind].fileName}
                    component={DropzoneField}
                    placeholder={t('enterDescription')}
                    tooltip={<Tooltip>{tooltipContent}</Tooltip>}
                    validate={validators.required(t('requiredField'))}
                    uploadRequest={uploadFile}
                    onFileDelete={() => remove(ind)}
                    maxFiles={1}
                    {...rest}
                  />
                  {value[ind].file === null && (
                    <Button
                      className="yx-remove-row"
                      icon={CloseIcon}
                      onClick={() => remove(ind)}
                      styleType="secondary"
                      variant="icon"
                    />
                  )}
                </FormRow>
              );
            })}
            <Button
              className="yx-add-another"
              styleType="primary"
              variant="link"
              onClick={() => push({ fileName: '', file: null })}
            >
              <span>
                <AddIcon />
              </span>
              {buttonLabel}
            </Button>
          </div>
        );
      }}
    </FieldArray>
  );
}

export default AddDocumentFields;
