import React from 'react';
import classNames from 'classnames';
import './PublicYacht.styles.scss';
import YachtPreview from 'router/subrouters/Dashboard/pages/YachtPreview';
import { NavLink, useParams } from 'react-router-dom';
import LogoWithTextIcon from 'icons/LogoWithText.icon';
import useQueryParams from 'hooks/useQueryParams';

type PublicYachtProps = {
  className?: string;
};

const PublicYacht: React.FC<PublicYachtProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-public-yacht', className);

  const { id } = useParams<{ id: string }>();
  const {
    params: { token },
  } = useQueryParams<{ token: string }>();

  return (
    <div className={classes}>
      <header>
        <NavLink className="yx-public-yacht__logo" to="/">
          <LogoWithTextIcon />
        </NavLink>
      </header>
      <div className="yx-public-yacht__content">
        <YachtPreview
          className="yx-public-yacht__yacht-preview"
          token={token}
          yachtId={id}
        />
      </div>
    </div>
  );
};

export default PublicYacht;
