import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FileType } from 'models/File';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { Button } from 'ncoded-component-library';
import DeleteIcon from 'icons/Delete.icon';
import confirm from 'modules/confirm';
import AddIcon from 'icons/Add.icon';
import DownloadIcon from 'icons/Download.icon';
import PopNotificationsContext from 'providers/PopNotifications/PopNotifications.context';
import UploadDocumentModal from 'router/subrouters/Dashboard/modals/UploadDocumentModal';
import YachtContext from '../../providers/Yacht/Yacht.context';
import credentialsService from 'services/credentialsService';
import { Yacht } from 'models/Yacht';

import './DocumentsTable.styles.scss';
import './DocumentsTable.responsive.styles.scss';

type DocumentsTableProps = {
  className?: string;
  files: Array<FileType>;
} & Pick<Yacht, 'userId'>;

const DocumentsTable: React.FC<DocumentsTableProps> = (props) => {
  const { files, className, userId: yachtOwnerId } = props;
  const classes = classNames('yx-documents-table', className);

  const { t } = useTranslation();

  const { deleteYachtDocument } = useContext(YachtContext);
  const { popSuccess } = useContext(PopNotificationsContext);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);

  const isUserOwnerOfYacht = useMemo(
    () => yachtOwnerId === credentialsService.user._id,
    [yachtOwnerId],
  );

  const handleOnDeleteDocument = useCallback(
    async (file: FileType) => {
      if (
        await confirm({
          title: t('deleteYachtDocument'),
          type: 'delete',
          content: t('deleteYachtDocument-info', { name: file.name }),
        })
      ) {
        deleteYachtDocument(file);
      }
    },
    [deleteYachtDocument, t],
  );

  return (
    <div className={classes}>
      {uploadModalOpen && (
        <UploadDocumentModal onClose={() => setUploadModalOpen(false)} />
      )}

      {isUserOwnerOfYacht && (
        <div className="yx-documents-table__title">
          <p>{t('documentsAreOnlyAccessible')}</p>
          <Button icon={AddIcon} onClick={() => setUploadModalOpen(true)}>
            {t('uploadDocument')}
          </Button>
        </div>
      )}
      {files?.length > 0 ? (
        <table className="yx-documents-table__table">
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('uploadDocument')}</th>
              <th>{t('format')}</th>
            </tr>
          </thead>
          <tbody>
            {files?.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                <td>{utils.toDateFormat(file.createdAt)}</td>
                <td>
                  {file.type.includes('/')
                    ? file.type?.split('/')[1].toUpperCase()
                    : t('document')}
                </td>
                {isUserOwnerOfYacht && (
                  <td className={classNames('yx-documents-table__actions-row')}>
                    <a
                      className="yx-documents-table__download-button"
                      download
                      href={file.url}
                      onClick={() =>
                        popSuccess(t('successfullyDownloadedYachtDocument'))
                      }
                    >
                      <DownloadIcon />
                    </a>

                    <Button
                      className="yx-documents-table__delete-button"
                      icon={DeleteIcon}
                      onClick={() => handleOnDeleteDocument(file)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="empty-text">{t('noFilesUploaded')}</p>
      )}
    </div>
  );
};

export default DocumentsTable;
