import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    width="30"
    height="31"
    stroke="currentColor"
    fill="transparent"
    viewBox="0 0 30 31"
    {...props}
  >
    <path
      d="M17 30v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M9 20a4 4 0 100-8 4 4 0 000 8z"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.333 16a.6.6 0 00.205-.036l3.283-1.218c.394.066.79.1 1.179.1 3.86 0 7-3.105 7-6.923C29 4.106 25.86 1 22 1s-7 3.106-7 6.923c0 2.052.895 3.95 2.47 5.27l-.69 2.048a.573.573 0 00.148.598c.111.105.257.161.405.161zm3.498-1.151c.39.064.783.097 1.169.097 3.914 0 7.1-3.15 7.1-7.023C29.1 4.05 25.914.9 22 .9c-3.914 0-7.1 3.15-7.1 7.023 0 2.059.888 3.965 2.453 5.302l-.668 1.984s0 0 0 0a.673.673 0 00.175.702h0a.689.689 0 00.712.147h0l3.259-1.21zm.067-1.364h0a.69.69 0 00-.37.03s0 0 0 0l-2.093.777.366-1.084a.674.674 0 00-.238-.756h0c-1.46-1.085-2.296-2.736-2.296-4.529 0-3.125 2.57-5.67 5.733-5.67 3.162 0 5.733 2.545 5.733 5.67s-2.57 5.67-5.733 5.67c-.362 0-.733-.036-1.102-.108z"
      stroke="currentColor"
      strokeWidth=".2"
    />
    <path d="M19 10.155L24.155 5l.663.663-5.155 5.155-.663-.663zM23.555 10.485a.938.938 0 100-1.875.938.938 0 000 1.875zM20.273 7.204a.937.937 0 100-1.875.937.937 0 000 1.875z" />
  </svg>
);
