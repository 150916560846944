import React, { useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import YesNoField from 'components/Fields/YesNoField';
import { Field, useField } from 'react-final-form';
import { Search } from 'models/Search';
import RangeSliderField from 'components/Fields/RangeSliderField';
import LinesIcon from 'icons/Lines.icon';
import { RangeHelperProps } from 'components/Fields/RangeSliderField/RangeSliderField.component';

import './YearOfBuildStep.styles.scss';

type YearOfBuildStepProps = {
  className?: string;
};

const RangeContent: React.FC<RangeHelperProps> = ({ minValue }) => {
  const { t } = useTranslation();

  const message = useMemo(() => t('yeardOfBuild-yearInfo'), [t]);
  return (
    <div className="yx-year-of-build-step__range-content">
      <span>{minValue.toFixed(0)}</span>
      {minValue > 2005 && <p>{message}</p>}
    </div>
  );
};

const YearOfBuildStep: React.FC<YearOfBuildStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-year-of-build-step', className);

  const { t } = useTranslation();

  const {
    input: { value: yearBuildMatters },
  } = useField<Search>('yearBuildMatters');

  return (
    <StepWrapper
      title={t('doesTheYearBuildPlayRole')}
      description={t('yearOfBuildStep-info')}
      className={classes}
    >
      <Field name="yearBuildMatters" component={YesNoField} />
      {yearBuildMatters && (
        <>
          <p>{t('yearOfBuildYouConsiderTooOld')}</p>
          <Field
            name="yearBuilt"
            variant="single"
            component={RangeSliderField}
            minContent={RangeContent}
            minIcon={LinesIcon}
            min={1900}
            max={2021}
            debounceChange
          />
        </>
      )}
    </StepWrapper>
  );
};

export default YearOfBuildStep;
