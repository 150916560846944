import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import StepWrapper from 'components/StepWrapper';
import { useTranslation } from 'react-i18next';
import { Field, useField, useForm } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import Tooltip from 'components/Tooltip';
import validators from 'validators';
import { composeValidators } from 'validators/general';

import './GuestCapacityStep.styles.scss';

type GuestCapacityStepProps = {
  className?: string;
};

const GuestCapacityStep: React.FC<GuestCapacityStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('yx-guest-capacity-step', className);

  const { t } = useTranslation();

  const validatorsObject = useMemo(
    () => ({
      guestCapacity: composeValidators(
        validators.required(t('guestCapacity-validator')),
        validators.minNumber(t('minNum', { minNum: 1 }), 1),
      ),
      numberOfCabins: validators.required(t('numberOfCabins-validator')),
    }),
    [t],
  );

  const {
    input: { value: guestCapacity },
  } = useField('guestCapacity');

  const { change } = useForm();

  useEffect(
    () => change('numberOfCabins', (guestCapacity / 2).toFixed(0)),
    [guestCapacity, change],
  );

  return (
    <StepWrapper
      title={t('howManyGuestsYouLikeToHave')}
      description={t('guestCapacity-info')}
      className={classes}
    >
      <Field
        type="number"
        min={0}
        name="guestCapacity"
        component={InputField}
        label={t('guestCapacity')}
        placeholder={t('enterGuestCapacity')}
        validate={validatorsObject.guestCapacity}
      />

      <Field
        type="number"
        min={1}
        name="numberOfCabins"
        component={InputField}
        label={t('numberOfCabins')}
        readOnly
        placeholder={t('autoFill')}
        tooltip={<Tooltip>{t('ruleOfThumb')}</Tooltip>}
      />
    </StepWrapper>
  );
};

export default GuestCapacityStep;
